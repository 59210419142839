import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Expert from "../../../components/Expert"
import HorizontalNavBar from "../../../components/HorizontalNavBar"
import LoadingSpinner from "../../../components/LoadingSpinner"
import NavBar from "../../../components/NavBar"
import UploadFileSuccessDialog from "../../../components/UploadFileSuccessDialog"
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO_FOCUS, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET, trackingStates } from "../../../utils/Constants"
import { elvira } from "../../../utils/Experts"
import { handleConsultStep, handleDate, handleDaysDifference, handleEnergy, handleExpert, handleExtensionFromType, handleFolderFromChoice, handleHoursDifference, handleMinutesDifference, handleSleepQuality, handleWaterIntake } from "../../../utils/Functions"
import { CalendlyAppointment } from "../../../utils/api-objects/CalendlyAppointment"
import { CalendlyExpert } from "../../../utils/api-objects/CalendlyExpert"
import { DnaTestTracker } from "../../../utils/api-objects/DnaTestTracker"
import { OrderRetrieved } from "../../../utils/api-objects/OrderRetrieved"
import { StripeSubscription } from "../../../utils/api-objects/StripeSubscription"
import { User } from "../../../utils/api-objects/User"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { AppointmentStatus } from "../../../utils/enums/AppointmentStatus"
import { ConsultStep } from "../../../utils/enums/ConsultStep"
import { ExamsAndTestNavigation } from "../../../utils/enums/ExamsAndTestNavigation"
import { ExpertRole } from "../../../utils/enums/ExpertRole"
import { Mood } from "../../../utils/enums/Mood"
import { NavigationToDismissiblePaywall } from "../../../utils/enums/NavigationToDismissiblePaywall"
import { NavigationToQuizQ } from "../../../utils/enums/NavigationToQuizQ"
import { QuizType } from "../../../utils/enums/QuizType"
import { ShopifyItemSku } from "../../../utils/enums/ShopifyItemType"
import { SubscriptionStatus } from "../../../utils/enums/SubscriptionStatus"
import TrackingStatus from "../../../utils/enums/TrackingStatus"
import { Appointment } from "../components/Appointment"
import MenuPopup from "../components/MenuPopup"
import MoodDialog from "../components/MoodDialog"
import "../css/Platform.css"
import UploadFileDialog from "../menu/examsAndTests/components/UploadFileDialog"
import BookConsultancyWidget from "../widgets/BookConsultancyWidget"
import BookExpertConsultWidget from "../widgets/BookExpertConsultWidget"
import DNATestTrackerWidget from "../widgets/DNATestTrackerWidget"
import DismissiblePaywallWidget from "../widgets/DismissiblePaywallWidget"
import NextAppointmentWidget from "../widgets/NextAppointmentWidget"
import PlanTrackerWidget from "../widgets/PlanTrackerWidget"
import QuizQWidget from "../widgets/QuizQWidget"
import SurveyBWidget from "../widgets/SurveyBWidget"
import BookApointmentDialog from "./components/BookApointmentDialog"
import "./css/Dashboard.css"
import CollectingActionsWidget from "./widgets/CollectingActionsWidget"
import ExpertsWidget from "./widgets/ExpertsWidget"
import UpdateTrackingWidget from "./widgets/UpdateTrackingWidget"
import UploadExamWidget from "./widgets/UploadExamWidget"
import YourPlanWidget from "./widgets/YourPlanWidget"
import YourReportWidget from "./widgets/YourReportWidget"

const Dashboard = () => {

    // Navigation parameters
    const navigate = useNavigate();

    const { auth } = useAuth();
    const userId = auth.userId
    const idToken = auth.idToken!

    const { userData } = useUserData()
    const username = userData.name!
    const familyName = userData.familyName!
    const email = userData.email!

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(true)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // ********************************************************************************
    // API Section

    // GET users info by user_id
    async function callGetUserInfoApi() {
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        if (responseUserinfo.ok) {
            const userInfoJson: User = await responseUserinfo.json()
            callGetMedicalUserInfoApi(userInfoJson.email)
            callGetSpecialistApi(userInfoJson.email)
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    // GET an url to call
    async function callGetUrlApi(type: string) {
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/exam-url/${type}/upload?filename=${customFilename}-${customDate}${uploadedFileExtension}`, requestOptionsGET(idToken))
        if (response.ok) {
            const urlResponse = await response.json()
            const formData = new FormData()
            Object.entries(urlResponse.fields).forEach(([k, v]) => {
                formData.append(k, v as unknown as string)
            })
            formData.append('file', uploadedFile!)
            const s3response = await axios.post(urlResponse.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            if (s3response.status === 204) {
                console.log("File uploaded successfully")
                setShowUploadDialog(false)
                setShowUploadSuccessDialog(true)
            } else {
                console.log("File not uploaded")
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // API for getting the tracking by the user id
    async function callGetTrackingByUserIdAPI(atRefresh: boolean) {
        !atRefresh && setIsLoading(true)
        const responseTracking = await fetch(`${BASE_URL}/api/track/${userId}`, requestOptionsGET(idToken))
        !atRefresh && setIsLoading(false)
        if (responseTracking.ok) {
            const dnaTrackerJson: DnaTestTracker[] = await responseTracking.json()
            if (dnaTrackerJson.length !== 0) {
                setTrackingStatus(dnaTrackerJson[0].tracking_status)
                setTrackingCode(dnaTrackerJson[0].tracking_number_gone)
                setOrderId(dnaTrackerJson[0].order_id)
            }
        } else if (responseTracking.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseTracking.json()
            console.log(responseJson)
        }
    }

    // API for getting the tracking by the user id
    async function callUpdateTrackingByUserIdAPI() {
        setIsLoading(true)
        const responseTracking = await fetch(`${BASE_URL}/api/track/order/${orderId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    tracking_status: TrackingStatus.Delivered,
                })
        })
        setIsLoading(false)
        if (responseTracking.ok) {
            callGetTrackingByUserIdAPI(false)
        } else if (responseTracking.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseTracking.json()
            console.log(responseJson)
        }
    }

    // GET User surveys taken by user_id
    async function callGetUserEmrApi() {
        const response = await fetch(`${BASE_URL}/api/emr/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            setQuizQdone(true)
            const responseJson = await response.json()
            for (const survey of responseJson.surveys) {
                if (survey.type === QuizType.SurveyB) {
                    setSurveyBTaken(true)
                }
            }
            setWeight(responseJson.data.weight)
            setWaterIntake(handleWaterIntake(responseJson.data.water_intake))
            setEnergy(handleEnergy(responseJson.data.energy))
            setWaistLine(responseJson.data.waistline)
            setSleepQuality(handleSleepQuality(responseJson.data.sleep_quality))
            if (responseJson.mood !== null) {
                setMood(responseJson.mood.mood)
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET User orders retrieved by user_id
    async function callGetOrdersRetrievedByUserIdApi() {
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const responseJson: OrderRetrieved[] = await response.json()
            if (responseJson.length !== 0) {
                for (const order of responseJson) {
                    switch (order.items[0].type) {
                        case ShopifyItemSku.DnaTest:
                        case ShopifyItemSku.DnaTestOld: {
                            setUserHasTest(true)
                            break;
                        }
                        case ShopifyItemSku.ExpertConsult: {
                            setUserHasExpertConsult(true)
                            break;
                        }
                        case ShopifyItemSku.NutritionistConsult: {
                            setUserHasNutriConsult(true)
                            break;
                        }
                        case ShopifyItemSku.Membership:
                            setUserHasShopifySubs(true)
                            break;
                    }
                }
                setUserhasOrders(true)
            } else {
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // UPDATE user emr by user_id
    async function callAPIUpdateUserEmr(moodLog: string) {
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}/emr`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    mood_log: {
                        mood: mood,
                        log: moodLog,
                    }
                })
        })
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    // GET User subscriptions by user_id
    async function callGetSubscriptionsApi() {
        const response = await fetch(`${BASE_URL}/api/payment/subscription/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const responseJson: StripeSubscription = await response.json()
            if (responseJson.status === SubscriptionStatus.Active || responseJson.status === SubscriptionStatus.Trial) {
                setUserHasSubs(true)
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET nutritionist info by patient id
    async function callGetMedicalUserInfoApi(email: string) {
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/patient/${userId}`, requestOptionsGET(idToken))
        if (responseUserinfo.ok) {
            const nutritionist: CalendlyExpert = await responseUserinfo.json()
            const newExpert = handleExpert(nutritionist)
            setNutritionist(newExpert)
            callAPIRetrieveBookingInfo(email, newExpert)
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    // GET specialist info by patient id
    async function callGetSpecialistApi(email: string) {
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/role/${ExpertRole.Specialist}`, requestOptionsGET(idToken))
        if (responseUserinfo.ok) {
            const responseSpecialistJson: CalendlyExpert[] = await responseUserinfo.json()
            const elvis = responseSpecialistJson[0]
            const newExpert = handleExpert(elvis)
            setMedicoRefertante(newExpert)
            callAPIRetrieveSpecialistAppointments(email, newExpert)
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    // GET booking info by email
    async function callAPIRetrieveBookingInfo(email: string, nutritionist: Expert) {
        const response = await fetch(`${BASE_URL}/api/booking/appointments/${email}`, requestOptionsGET(idToken))
        if (response.ok) {
            const responseJson: CalendlyAppointment[] = await response.json()
            const appointmentsArray: Appointment[] = []
            for (const appointment of responseJson) {
                appointmentsArray.push({
                    date: appointment.payload.scheduled_event.start_time,
                    editUrl: appointment.payload.reschedule_url,
                    connectUrl: appointment.payload.scheduled_event.location.join_url,
                    remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
                    remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
                    remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
                    expert: nutritionist,
                    status: appointment.payload.status
                })
            }
            const newActiveAppointments = appointmentsArray.filter(appointment => appointment.status === AppointmentStatus.Active)
            setActiveAppointments(newActiveAppointments)
            setAllAppointments(appointmentsArray)
            setConsultStep(handleConsultStep(newActiveAppointments))
        } else if (response.status === 401) {
            navigate("/login")
        } else if (response.status === 404) {
            setConsultancyBooked(false)
            const responseJson = await response.json()
            console.log(responseJson)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET booking info by email
    async function callAPIRetrieveSpecialistAppointments(email: string, medico: Expert) {
        const response = await fetch(`${BASE_URL}/api/booking/appointments/${email}`, requestOptionsGET(idToken))
        if (response.ok) {
            const responseJson: CalendlyAppointment[] = await response.json()
            const expertAppointmentsArray: Appointment[] = []
            for (const appointment of responseJson) {
                if (appointment.organizer_email === "elvira.pistolesi@holifya.com") {
                    expertAppointmentsArray.push({
                        date: appointment.payload.scheduled_event.start_time,
                        editUrl: appointment.payload.reschedule_url,
                        connectUrl: appointment.payload.scheduled_event.location.join_url,
                        remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
                        remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
                        remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
                        expert: medico,
                        status: appointment.payload.status
                    })
                }
            }
            const newActiveAppointments = expertAppointmentsArray.filter(appointment => appointment.status === AppointmentStatus.Active)
            setExpertActiveAppointments(newActiveAppointments)
            setAllExpertAppointments(expertAppointmentsArray)
        }
    }

    // GET User plan by user_id
    async function callGetMostRecentPlanApi() {
        const response = await fetch(`${BASE_URL}/api/plans/user/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const responseJson = await response.json()
            setPlanAvailable(true)
            callGetPlanPDFUrlApi(responseJson.id)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET User plan by user_id
    async function callGetPlanPDFUrlApi(planId: string) {
        const response = await fetch(`${BASE_URL}/api/plans/patient/${userId}/plan-url?filename=${planId}.pdf`, requestOptionsGET(idToken))
        if (response.ok) {
            const responseJson = await response.json()
            setCurrentPlanUrl(responseJson.url)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        setIsLoading(true)
        callGetUserInfoApi()
        callGetTrackingByUserIdAPI(true)
        callGetUserEmrApi()
        callGetOrdersRetrievedByUserIdApi()
        callGetSubscriptionsApi()
        callGetMostRecentPlanApi()
        setTimeout(() => {
            setIsLoading(false)
        }, 750)
    }, [])

    // *******************************************************************************************************************

    // This is the status of the tracking, determining the path
    const [trackingStatus, setTrackingStatus] = useState<TrackingStatus>()
    // Tracking code to track the delivery of the test
    const [trackingCode, setTrackingCode] = useState("")

    const [consultStep, setConsultStep] = useState(ConsultStep.DnaOrPurchase)

    const [orderId, setOrderId] = useState("")

    // This boolean opens a dialog useful to book the next apointment
    const [showBookApointmentDialog, setShowBookApointmentDialog] = useState(false)

    const [showUploadExamDialog, setShowUploadDialog] = useState(false)

    // Related to the mood dialog
    const [mood, setMood] = useState(Mood.None)
    const [moodLog, setMoodLog] = useState("")
    const [showMoodDialog, setShowMoodDialog] = useState(false)

    // Parameters retrieved from emr, to be displayed in the widgets
    const [weight, setWeight] = useState("")
    const [waterIntake, setWaterIntake] = useState("")
    const [energy, setEnergy] = useState("")
    const [waistLine, setWaistLine] = useState("")
    const [sleepQuality, setSleepQuality] = useState("")

    // The nutritionist coming from my medical user info
    const [nutritionist, setNutritionist] = useState<Expert>()
    const [medicoRefertante, setMedicoRefertante] = useState<Expert>()

    // Boolean saying if I have a booked apointment
    const [consultancyBooked, setConsultancyBooked] = useState(false)

    const [activeAppointments, setActiveAppointments] = useState<Appointment[]>([])
    const [expertActiveAppointments, setExpertActiveAppointments] = useState<Appointment[]>([])
    const [allAppointments, setAllAppointments] = useState<Appointment[]>([])
    const [allExpertAppointments, setAllExpertAppointments] = useState<Appointment[]>([])

    const [schedulingUrl, setSchedulingUrl] = useState("")

    // *******************************************************************************************************************
    // Upload file dialog

    // File uploaded from upload dialog
    const [uploadedFileName, setUploadedFileName] = useState("")
    const [uploadedFileSize, setUploadedFileSize] = useState("")
    const [uploadedFileExtension, setUploadedFileExtension] = useState("")
    const [uploadedFile, setUploadedFile] = useState<File>()

    // Custom filename for the uploaded file
    const [customFilename, setCustomFilename] = useState("")
    // Custom upload date
    const [customDate, setCustomDate] = useState("")

    // This boolean checks if a file has been uploaded
    const fileUploaded = uploadedFile !== undefined

    // Choice for the exam type, when uploading
    const [uploadDialogRadioChoice, setUploadDialogRadioChoice] = useState("")

    const onDismissUploadExamDialogClick = () => {
        setShowUploadDialog(false)
        setUploadDialogRadioChoice("")
    }

    // What happens when the file is uploaded
    const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files
        const newName = files![0].name
        const newSize = files![0].size.toString()
        const newExtension = handleExtensionFromType(files![0].type)
        setUploadedFileName(newName)
        setUploadedFileSize(newSize)
        setUploadedFile(files![0])
        setUploadedFileExtension(newExtension)
    }

    // When the users removes the uploaded file from the dialog from the X button
    const onUploadedFileRemove = () => {
        setUploadedFile(undefined)
        setCustomFilename("")
        setCustomDate("")
    }

    // When the user clicks on undo from the upload dialog
    const onUndoUploadClick = () => {
        setShowUploadDialog(false)
        resetAnswers()
    }

    // When the user confirms the upload
    const onConfirmUploadButtonClick = () => {
        callGetUrlApi(handleFolderFromChoice(uploadDialogRadioChoice))
    }

    // Resets all the custom infos
    const resetAnswers = () => {
        setUploadedFile(undefined)
        setUploadDialogRadioChoice("")
        setCustomFilename("")
        setCustomDate("")
    }

    // Boolean handling the confirm upload button activation
    const uploadButtonDisabled = !(uploadDialogRadioChoice !== "" && customFilename !== "" && customDate !== "")

    const [showUploadSuccessDialog, setShowUploadSuccessDialog] = useState(false)

    // When the user dimisses the success dialog
    const onUploadSuccessDismissButtonClick = () => {
        setShowUploadSuccessDialog(false)
        resetAnswers()
    }

    const onBookConsultClick = (url: string) => {
        setShowBookApointmentDialog(true)
        setSchedulingUrl(url)
    }

    // *******************************************************************************************************************

    // Actions to perform when clicking on collecting widget or swab path links
    const handleCollectingActionsWidgetButtonClick = () => {
        switch (trackingStatus) {
            case TrackingStatus.Delivered:
                return navigate("/platform/exams-and-tests/collecting/tutorial")
            case TrackingStatus.TestDone:
                return navigate("/platform/exams-and-tests/collecting/register-swab-code")
            case TrackingStatus.SampleRegistered:
                return navigate("/platform/exams-and-tests/collecting/packing")
            case TrackingStatus.PickupRequested:
                return navigate("/platform/exams-and-tests/collecting/request-pickup")
        }
    }

    const [userHasOrders, setUserhasOrders] = useState(false)
    const [userHasSubs, setUserHasSubs] = useState(false)
    const [userHasShopifySubs, setUserHasShopifySubs] = useState(false)
    const [userHasNutriConsult, setUserHasNutriConsult] = useState(false)
    const [userHasExpertConsult, setUserHasExpertConsult] = useState(false)
    const [userHasTest, setUserHasTest] = useState(false)

    const userCanHaveConsult = userHasSubs || userHasNutriConsult
    const noFutureAppointments = activeAppointments.length === 0 || activeAppointments[activeAppointments.length - 1].remainingMinutes < -30
    const noFutureExpertAppointments = expertActiveAppointments.length === 0 || expertActiveAppointments[expertActiveAppointments.length - 1].remainingMinutes < -30

    const [quizQdone, setQuizQdone] = useState(false)
    const [surveyBTaken, setSurveyBTaken] = useState(false)

    const isUpdateTrackingButtonVisible = trackingStatus === TrackingStatus.Purchased

    const isTestTrackingVisible = trackingStatus !== undefined && userHasTest && trackingStates.indexOf(trackingStatus) < trackingStates.indexOf(TrackingStatus.ReportGenerated)
    const isPlanTrackerVisible = trackingStatus !== undefined && userHasTest && trackingStatus === TrackingStatus.ReportGenerated && userCanHaveConsult && consultStep !== ConsultStep.SecondConsult24HoursAgo
    const isPlanTrackerNoTestVisible = !userHasTest && userCanHaveConsult && consultStep !== ConsultStep.SecondConsult24HoursAgo
    const isNextAppointmentWidgetVisible = activeAppointments.length > 0 && activeAppointments[activeAppointments.length - 1].remainingMinutes >= -30
    const isNextExpertAppointmentWidgetVisible = expertActiveAppointments.length > 0 && expertActiveAppointments[expertActiveAppointments.length - 1].remainingMinutes >= -30
    const isBookAppointmentWidgetVisible = userCanHaveConsult && noFutureAppointments && (trackingStatus === undefined || trackingStatus === TrackingStatus.ReportGenerated)
    const isBookElvisConsultWidgetVisible = userHasExpertConsult && noFutureExpertAppointments && medicoRefertante !== undefined && !(expertActiveAppointments.length === 1 && expertActiveAppointments[0].status === AppointmentStatus.Active && expertActiveAppointments[0].remainingMinutes < -30)

    const isCollectingActionsWidgetVisible = trackingStatus === TrackingStatus.Delivered || trackingStatus === TrackingStatus.TestDone || trackingStatus === TrackingStatus.SampleRegistered || trackingStatus === TrackingStatus.PickupRequested
    const isQuizQWidgetVisible = !quizQdone
    const isRewardPageWidgetVisible = quizQdone && !userHasSubs
    const isSurveyBWidgetVisible = (userHasSubs && !surveyBTaken && quizQdone) || (userHasNutriConsult && !surveyBTaken)

    const [planAvailable, setPlanAvailable] = useState(false)
    const [currentPlanUrl, setCurrentPlanUrl] = useState("")

    const onPlanWidgetClick = () => {
        window.open(currentPlanUrl, "_blank")
    }

    // *******************************************************************************************************************

    return (
        <div className="App Quiz justify_content_center relative">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO_FOCUS}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsLogoClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container relative ${isLoading ? "visible-0-percent inactive" : ""}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-dashboard-full-container ${(focusOnMenu || showBookApointmentDialog || showMoodDialog || showUploadExamDialog || showUploadSuccessDialog) ? "visible_30_percent inactive" : ""}`}>
                    <div className="dashboard-title-row">
                        <div className="dashboard-title">
                            Ciao {username}! {String.fromCodePoint(0x1F642)}
                        </div>
                    </div>
                    <div className="vertical-scroll-group-90 width_100_percent">
                        <div className="platform-final-element-container">
                            {
                                isUpdateTrackingButtonVisible &&
                                <UpdateTrackingWidget
                                    onClick={callUpdateTrackingByUserIdAPI}
                                />
                            }
                            {
                                isTestTrackingVisible &&
                                <DNATestTrackerWidget
                                    trackingStatus={trackingStatus!!}
                                    trackingCode={trackingCode}
                                    onSubtitleClick={handleCollectingActionsWidgetButtonClick}
                                />
                            }
                            {
                                isPlanTrackerVisible &&
                                <PlanTrackerWidget
                                    onLinkClick={() => onBookConsultClick(nutritionist?.schedulingUrl!)}
                                    consultStep={(planAvailable && consultStep !== ConsultStep.SecondConsultDone) ? ConsultStep.PlanPublished : consultStep}
                                    dnaTestPurchased={true}
                                />
                            }
                            {
                                isPlanTrackerNoTestVisible &&
                                <PlanTrackerWidget
                                    onLinkClick={() => onBookConsultClick(nutritionist?.schedulingUrl!)}
                                    consultStep={(planAvailable && consultStep !== ConsultStep.SecondConsultDone) ? ConsultStep.PlanPublished : consultStep}
                                    dnaTestPurchased={false}
                                />
                            }
                            {
                                isBookAppointmentWidgetVisible &&
                                <div>
                                    <div className="height_35" />
                                    <BookConsultancyWidget
                                        name={nutritionist !== undefined ? nutritionist.name : "il tuo nutrizionista"}
                                        onClick={() => onBookConsultClick(nutritionist?.schedulingUrl!)}
                                        isFirst={activeAppointments.length === 0}
                                        lastAppointmentDate={activeAppointments.length !== 0 ? handleDate(activeAppointments[activeAppointments.length - 1].date) : ""}
                                    />
                                </div>
                            }
                            {
                                isBookElvisConsultWidgetVisible &&
                                <div>
                                    <div className="height_35" />
                                    <BookExpertConsultWidget
                                        specialistName={medicoRefertante.name}
                                        onBookClick={() => onBookConsultClick(medicoRefertante.schedulingUrl!)}
                                    />
                                </div>
                            }
                            {
                                isNextAppointmentWidgetVisible &&
                                activeAppointments.map(appointment => (
                                    appointment.remainingMinutes >= -30 &&
                                    <div key={activeAppointments.indexOf(appointment)}>
                                        <div className="height_35" />
                                        <NextAppointmentWidget
                                            appointment={appointment}
                                            isUrgent={appointment.remainingDays <= 1}
                                        />
                                    </div>
                                ))
                            }
                            {
                                isNextExpertAppointmentWidgetVisible &&
                                expertActiveAppointments.map(appointment => (
                                    appointment.remainingMinutes >= -30 &&
                                    <div key={expertActiveAppointments.indexOf(appointment)}>
                                        <div className="height_35" />
                                        <NextAppointmentWidget
                                            appointment={appointment}
                                            isUrgent={appointment.remainingDays <= 1}
                                        />
                                    </div>
                                ))
                            }
                            {
                                isCollectingActionsWidgetVisible &&
                                <div>
                                    <div className="height_35"></div>
                                    <CollectingActionsWidget
                                        trackingStatus={trackingStatus}
                                        onButtonClick={handleCollectingActionsWidgetButtonClick}
                                    />
                                </div>
                            }
                            {
                                isQuizQWidgetVisible &&
                                <div>
                                    <div className="height_35"></div>
                                    <QuizQWidget
                                        onClick={() => navigate("/quiz-q-home", { state: { fromWhere: NavigationToQuizQ.PlatformDashboard } })}
                                    />
                                </div>
                            }
                            {
                                isRewardPageWidgetVisible &&
                                <div>
                                    <div className="height_35"></div>
                                    <DismissiblePaywallWidget
                                        onClick={() => navigate("/dismissible-paywall-multiple-choice", { state: { fromWhere: NavigationToDismissiblePaywall.Platform } })}
                                    />
                                </div>
                            }
                            {
                                isSurveyBWidgetVisible &&
                                <div>
                                    <div className="height_35" />
                                    <SurveyBWidget
                                        onClick={() => navigate("/survey-b-new")}
                                        hasBigPriority={(trackingStates.indexOf(trackingStatus!!) >= trackingStates.indexOf(TrackingStatus.DeliveredToLaboratory) || (userHasSubs && !userHasOrders) || userHasNutriConsult)}
                                    />
                                </div>
                            }
                            <div className="height_35" />
                            <div className={`${trackingStates.indexOf(trackingStatus!!) < trackingStates.indexOf(TrackingStatus.AnalysisDone) ? "dashboard-wrap-row-425" : "dashboard-wrap-row-924"}`}>
                                {trackingStates.indexOf(trackingStatus!) >= trackingStates.indexOf(TrackingStatus.AnalysisDone) ? <YourReportWidget onClick={() => navigate(`/platform/exams-and-tests/${ExamsAndTestNavigation.Dna}`)} /> : null}
                                {
                                    planAvailable ?
                                        <YourPlanWidget
                                            onClick={onPlanWidgetClick}
                                        /> : null
                                }
                                <ExpertsWidget
                                    nutrionista={nutritionist}
                                    medicoRefertante={medicoRefertante !== undefined ? medicoRefertante : elvira}
                                />
                                <UploadExamWidget
                                    onUploadClick={() => setShowUploadDialog(true)}
                                />
                            </div>
                            <div className="height_35" />
                        </div>
                    </div>
                </div>
                {
                    showBookApointmentDialog &&
                    <BookApointmentDialog
                        onCloseClick={() => setShowBookApointmentDialog(false)}
                        email={email}
                        onBookClick={() => {
                            setShowBookApointmentDialog(false)
                            window.open(schedulingUrl, "_blank")
                            setSchedulingUrl("")
                        }}
                    />
                }
                {
                    showMoodDialog ?
                        <MoodDialog
                            mood={mood}
                            moodLog={moodLog}
                            onMoodLogChange={(e) => setMoodLog(e.target.value)}
                            onDismissClick={() => {
                                setShowMoodDialog(false)
                                callAPIUpdateUserEmr("")
                            }}
                            onDontSendButtonClick={() => {
                                setShowMoodDialog(false)
                                callAPIUpdateUserEmr("")
                            }}
                            onSendButtonClick={() => {
                                setShowMoodDialog(false)
                                callAPIUpdateUserEmr(moodLog)
                            }}
                        /> : null
                }
                {
                    showUploadExamDialog ?
                        <UploadFileDialog
                            fileUploaded={fileUploaded}
                            onDismissUploadDialogButtonClick={onDismissUploadExamDialogClick}
                            uploadDialogRadioChoice={uploadDialogRadioChoice}
                            setUploadDialogRadioChoice={setUploadDialogRadioChoice}
                            onFileInputChange={onFileInputChange}
                            uploadedFilename={uploadedFileName}
                            uploadedFileSize={uploadedFileSize}
                            uploadedFileExtension={uploadedFileExtension}
                            onUploadedFileRemove={onUploadedFileRemove}
                            onConfirmUploadButtonClick={onConfirmUploadButtonClick}
                            onUndoUploadClick={onUndoUploadClick}
                            uploadButtonDisabled={uploadButtonDisabled}
                            customFilename={customFilename}
                            setCustomFilename={(e) => setCustomFilename(e.target.value)}
                            customDate={customDate}
                            setCustomDate={(e) => setCustomDate(e.target.value)}
                        /> : null
                }
                {
                    showUploadSuccessDialog ?
                        <UploadFileSuccessDialog
                            onUploadSuccessDismissButtonClick={onUploadSuccessDismissButtonClick}
                            folder={uploadDialogRadioChoice}
                        />
                        : null
                }
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO_FOCUS}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsLogoClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default Dashboard
