import PrimaryButton from "../../../components/buttons/PrimaryButton"
import "./css/BookExpertConsultWidget.css"

interface Props {
    specialistName: string,
    onBookClick: () => void,
}

const BookExpertConsultWidget: React.FC<Props> = ({
    specialistName,
    onBookClick,
}) => {
    return (
        <div className="book-expert-widget-external-container">
            <div className="book-expert-widget-left-element-container">
                <div className="book-expert-widget-left-element-internal-container">
                    <div className="book-expert-widget-heading-copy-container">
                        <div className="book-expert-widget-heading">
                            Videoconsulto con specialista genetico
                        </div>
                        <div className="book-expert-widget-copy">
                            Prenota il tuo videoconsulto con <span className="w700 book-expert-widget-blue-color">{specialistName}</span>.
                        </div>
                    </div>
                    <div className="book-expert-widget-book-button">
                        <PrimaryButton
                            text="Prenota subito"
                            pointer={true}
                            disabled={false}
                            onClick={onBookClick}
                        />
                    </div>
                </div>
            </div>
            <div className="book-expert-widget-right-element-container" />
        </div>
    )
}

export default BookExpertConsultWidget
