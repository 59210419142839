import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL, requestOptionsGET } from "../../../../utils/Constants";
import "./css/PaymentMethodAddNewPaymentReturn.css";

const PaymentMethodAddNewPaymentReturn = () => {
    // To get query parameters
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    const userId = urlParams.get('userId');
    const idToken = urlParams.get('idToken');

    // The status of the add payment
    const [status, setStatus] = useState<string>("");

    // Needed fo rnavgation
    const navigate = useNavigate()

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        callSessionStatusApi()
    }, []);

    // GET the session status API
    async function callSessionStatusApi() {
        const response = await fetch(`${BASE_URL}/api/payment/session-status/${userId}?session_id=${sessionId}`, requestOptionsGET(idToken!))
        const responseJson = await response.json()
        setStatus(responseJson.status)
    }

    const handleHeading = () => {
        switch (status) {
            case 'complete': 
                return "Carta inserita correttamente"
            case 'open': 
                return "Errore nell'inserimento della carta"
            default:
                return "Qualcosa non torna"
        }
    }

    const handleCopy = () => {
        switch (status) {
            case 'complete': 
                return "Premi qui sotto per tornare ai tuoi metodi di pagamento"
            case 'open': 
                return "Torna al form per inserire la carta"
            default:
                return "Qualcosa non torna"
        }
    }

    const handleNavigate = () => {
        switch (status) {
            case 'complete': 
                return navigate("/platform/payment-method")
            case 'open': 
                return navigate("/platform/payment-method/add-new-payment-method")
            default:
                return window.open("https://www.holifya.com/", "_self")
        }
    }
    
    return (
        <div className="App justify_content_center">
            <div className="payment-method-add-new-payment-return-container">
                <div className="payment-method-add-new-payment-return-heading">
                    {handleHeading()}
                </div>
                <div className="payment-method-add-new-payment-return-copy">
                    {handleCopy()}
                </div>
                <button
                    className="payment-method-add-new-payment-return-button primary_action_color_background"
                    onClick={() => handleNavigate()}
                >
                    Vai
                </button>
            </div>
        </div>
    )
}

export default PaymentMethodAddNewPaymentReturn
