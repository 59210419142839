interface Props {
    image: string,
    text: string,
}

const ExampleNumberAndLetters: React.FC<Props> = ({
    image,
    text,
}) => {
    return (
        <div className="dna-test-register-swab-code-example-box">
            <div className="vertical_flex_group justify_content_center align_items_center width_62_percent height_40_percent white_background">
                <img src={image} />
            </div>
            <div className="dna-test-register-swab-code-example-box-text">
                {text}
            </div>
        </div>
    )
}

export default ExampleNumberAndLetters
