import PaywallProductContent from "../../components/PaywallProductContent"
import PaywallProductTag from "../../components/PaywallProductTag"
import "./css/MonetizationNutritionistConsultCard.css"

/** In a wall page, this card shows the contents of a nutritionist consult product */
const MonetizationNutritionistConsultCard = () => {
    return (
        <div className="monetization-nutritionist-consult-card-full-container">
            <div className="monetization-nutritionist-consult-card-heading">
                Il tuo consulto
            </div>
            <div className="monetization-nutritionist-consult-card-content-container">
                <div className="monetization-nutritionist-consult-card-content-internal-container">
                    <div className="monetization-nutritionist-consult-card-left-element-container">
                        <div className="monetization-nutritionist-consult-card-image-container">
                            <img className="monetization-nutritionist-consult-card-image" src="/images/paywall/reward-page-nutritionist-consult.svg" />
                        </div>
                    </div>
                    <div className="monetization-nutritionist-consult-card-right-element-container">
                        <PaywallProductTag
                            text="Consulto con Nutrizionista"
                        />
                        <div className="monetization-nutritionist-consult-card-product-contents-container">
                            <PaywallProductContent
                                text={`Nutrizionista dedicato (1 consulto)`}
                                image={"/images/paywall/paywall-tick.svg"}
                            />
                            <PaywallProductContent
                                text={`1 Piano alimentare`}
                                image={"/images/paywall/paywall-tick.svg"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MonetizationNutritionistConsultCard
