import CloseButton from "../../../components/CloseButton"
import Expert from "../../../components/Expert"
import PaywallExpertTagCard from "./PaywallExpertTagCard"
import "./css/NutritionistDetailsDialog.css"

interface Props {
  onCloseClick: () => void,
  nutritionist: Expert,
}

const NutritionistDetailsDialog: React.FC<Props> = ({
  onCloseClick,
  nutritionist,
}) => {
  return (
    <div className="nutritionist-details-dialog-full-container center-div">
      <div className="nutritionist-details-dialog-internal-container">
        <div className="nutritionist-details-dialog-upper-element-container">
          <div className="nutritionist-details-dialog-nutritionist-info-container">
            <div className="nutritionist-details-dialog-nutritionist-image-container">
              <img className="nutritionist-details-dialog-nutritionist-image" src={nutritionist.avatar} />
            </div>
            <div className="nutritionist-details-dialog-nutritionist-info">
              <div className="nutritionist-details-dialog-nutritionist-position">
                {nutritionist.position}
              </div>
              <div className="nutritionist-details-dialog-nutritionist-name">
                {nutritionist.name} {nutritionist.familyName}
              </div>
              <div className="nutritionist-details-dialog-nutritionist-role">
                {nutritionist.role}
              </div>
            </div>
          </div>
          <CloseButton
            onClick={onCloseClick}
            noFlexEnd={true}
          />
        </div>
        <div className="nutritionist-details-dialog-central-element-container">
          {
            nutritionist.skills.map(skill => (
              <PaywallExpertTagCard
              key={nutritionist.skills.indexOf(skill)}
              skill={skill}
              />
            ))
          }
        </div>
        <div className="nutritionist-details-dialog-bottom-element-container">
          <div className="nutritionist-details-dialog-bottom-element-heading">
            Su di me
          </div>
          <div className="nutritionist-details-dialog-summary">
            {nutritionist.summary}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NutritionistDetailsDialog
