import "./css/MonetizationPricePurchaseButtonSection.css"

interface Props {
    onPurchaseClick: () => void,
    price: string,
    onInfoClick: () => void,
}

const MonetizationPricePurchaseButtonSection: React.FC<Props> = ({
    onPurchaseClick,
    price,
    onInfoClick,
}) => {
  return (
    <div className="monetization-price-purchase-button-section-container">
      <div className="monetization-price-purchase-button-section-price-copy">
        Oggi paghi <span className="monetization-price-purchase-button-section-price">{price}</span> <img onClick={onInfoClick} className="pointer" src="/images/paywall-info-logo.svg"/>
      </div>
      <button
        className="monetization-price-purchase-button-section-purchase-button primary_action_color_background"
        onClick={onPurchaseClick}
      >
        Acquista
    </button>
    </div>
  )
}

export default MonetizationPricePurchaseButtonSection
