import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import BackButton from "../../components/BackButton"
import HolifyaFullLogo from "../../components/HolifyaFullLogo"
import LoadingSpinner from "../../components/LoadingSpinner"
import ShopifyItem from "../../components/ShopifyItem"
import { PHARMACIES_CONFIRMATION_NUMBER } from "../../utils/Constants"
import { findOrder, saveOrder } from "../../utils/apis/order-service"
import { useAuth } from "../../utils/context/AuthContext"
import { NavigationToInsertCodePage } from "../../utils/enums/NavigationToInsertCodePage"
import RedeemCodeBox from "./components/RedeemCodeBox"
import "./css/InsertProductCode.css"

const InsertProductCode = () => {

    // Navigation parameters
    const navigate = useNavigate();
    const location = useLocation();
    const fromWhere = location.state.fromWhere
    const fromWherePlatform = location.state.fromWherePlatform
    const nutritionist = location.state.nutritionist

    const { auth } = useAuth();
    const userId = auth.userId
    const idToken = auth.idToken

    const [codeErrorText, setCodeErrorText] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const handleBackButton = () => {
        switch (fromWhere) {
            case NavigationToInsertCodePage.OnboardingFork:
                return navigate("/onboarding-fork")
            case NavigationToInsertCodePage.PaywallMultipleChoice:
                return navigate("/paywall-multiple-choice", { state: { fromWhere, nutritionist } })
            case NavigationToInsertCodePage.DismissiblePaywallMultipleChoice:
                return navigate("/dismissible-paywall-multiple-choice", { state: { fromWhere: fromWherePlatform } })
            default:
                return
        }
    }

    const handleLoginInputsSubmit = (inputData: { [key: string]: string }) => {
        const confirmationCode = inputData.confirmationCode
        setIsLoading(true)
        setCodeErrorText("")
        idToken !== null && findOrder(
            idToken,
            confirmationCode,
            (orders) => {
                setIsLoading(false)
                const productsNumber = orders.items.length
                if (productsNumber > 1) {
                    const shopifyItems: ShopifyItem[] = []
                    for (const item of orders.items) {
                        shopifyItems.push(
                            {
                                id: item.id,
                                name: item.name,
                                price: item.price,
                                type: item.type,
                                shopifyId: orders.shopify_id,
                                confirmationNumber: orders.confirmation_number
                            }
                        )
                    }
                    navigate("/choose-product", { state: { shopifyItems } })
                } else if (confirmationCode === PHARMACIES_CONFIRMATION_NUMBER) {
                    navigate("pharmacies-swab-code-register", { state: { shopifyItem: orders } })
                } else {
                    setIsLoading(true)
                    userId !== null && saveOrder(
                        userId,
                        idToken,
                        orders,
                        () => {
                            setIsLoading(false)
                            navigate("/product-choice-success")
                        },
                        () => { 
                            setIsLoading(false)}
                    )
                }
            },
            (error) => {
                setIsLoading(false)
                if (error === "Sessione scaduta") {
                    navigate("/login")
                } else {
                    setCodeErrorText(error)
                }
            },
        )
    }

    useEffect(() => {
    }, [])

    // ********************************************************************************

    return (
        <div className="App Quiz relative">
            <div className={`insert-product-code-buttons-row ${isLoading && "visible_30_percent inactive"}`}>
                <BackButton
                    onClick={handleBackButton}
                />
                <div
                    className="insert-product-code-logo-mobile"
                >
                    <HolifyaFullLogo />
                </div>
            </div>
            <div className={`insert-product-code-container ${isLoading && "visible_30_percent inactive"}`}>
                <div className="insert-product-code-internal-container">
                    <RedeemCodeBox
                        onSubmit={handleLoginInputsSubmit}
                        errorText={codeErrorText}
                    />
                </div>
            </div>
            {
                isLoading && <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div>
            }
        </div>
    )
}

export default InsertProductCode
