import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HorizontalLogoText from "../../../../components/HorizontalLogoText";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET } from "../../../../utils/Constants";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem";
import MenuPopup from "../../components/MenuPopup";
import "./css/PersonalData.css";

const PersonalData = () => {

    // Navigation parameters
    const navigate = useNavigate();

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId
    const accessToken = auth.accessToken

    const { updateUserData, userData } = useUserData()
    const [username, setUsername] = useState(userData.name!)
    const [familyName, setFamilyName] = useState(userData.familyName!)
    const email = userData.email!

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
            setTaxNumber(responseJson.tax_number)
            setMobileNumber(responseJson.data.mobile_phone)
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
    }, [])

    // *******************************************************************************************************************

    // UPDATE user informations
    async function callUpdateUserInformationsAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/users/${userId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            }, body: JSON.stringify(
                {
                    tax_number: taxNumber,
                    data: {
                        name: username,
                        family_name: familyName,
                        mobile_phone: mobileNumber,
                    }
                })
        })
        setIsLoading(false)
        if (!response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
        } else {
            // setFamilyName(personalDataFamilyName)
            // setName(personalDataName)
            // onMenuIconClick();
            updateUserData(username, familyName, email)

        }
    }

    // CHANGE PASSWORD
    async function callChangePasswordAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/change-password`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    access_token: accessToken,
                    old_password: currentPassword,
                    new_password: newPassword
                }
            )
        })
        setIsLoading(false)
        if (response.status === 200) {
            const result = await response.json();
            console.log(result)
            onMenuIconClick();
            setCurrentPassword("")
            setNewPassword("")
        } else if (response.status === 422) {
            console.log("error 422")
        } else if (response.status === 400) {
            setCurrentPasswordErrorText("Password attuale errata")
        }
    }

    // User info to retrieve and display
    const [taxNumber, setTaxNumber] = useState("")
    const [mobileNumber, setMobileNumber] = useState("")

    // Rules for new password to be acceptable
    const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
    // regex /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/
    const symbols = [".", ",", ":", ";", "-", "_", "?", "!", "$", "€", "£", "&", "%", "/", "(", ")", "=", "'", "*", "\""]
    const lettersUppercase = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
    const isPasswordAcceptable = (newPassword: string) => newPassword.length >= 8 && numbers.some(el => newPassword.includes(el)) && symbols.some(el => newPassword.includes(el)) && lettersUppercase.some(el => newPassword.includes(el))

    // Passwords values and error texts
    const [currentPassword, setCurrentPassword] = useState("")
    const [currentPasswordErrorText, setCurrentPasswordErrorText] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordErrorText, setNewPasswordErrorText] = useState("")

    // Passwords icons and types
    const [currentPasswordIcon, setCurrentPasswordIcon] = useState("/images/show-password-disabled.svg")
    const [newPasswordIcon, setNewPasswordIcon] = useState("/images/show-password-disabled.svg")
    const [currentPasswordType, setCurrentPasswordType] = useState("password")
    const [newPasswordType, setNewPasswordType] = useState("password")

    // Submit button enabled or disabled
    const [personalDataSubmitButtonDisabled, setPersonalDataSubmitButtonDisabled] = useState(true)
    const modifyPasswordButtonDisabled = !(isPasswordAcceptable(newPassword) && newPassword !== currentPassword)

    // Handler for current password input field
    const currentPasswordInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newCurrentPassword = e.target.value
        setCurrentPassword(newCurrentPassword)
        setCurrentPasswordErrorText("")
        if (currentPassword === "" && newCurrentPassword !== "") {
            if (currentPasswordIcon === "/images/show-password-disabled.svg") {
                setCurrentPasswordIcon("/images/show-password.svg")
            } else if (currentPasswordIcon === "/images/hide-password-disabled.svg") {
                setCurrentPasswordIcon("/images/hide-password.svg")
            }
        } else if (currentPassword !== "" && newCurrentPassword === "") {
            if (currentPasswordIcon === "/images/show-password.svg") {
                setCurrentPasswordIcon("/images/show-password-disabled.svg")
            } else if (currentPasswordIcon === "/images/hide-password.svg") {
                setCurrentPasswordIcon("/images/hide-password-disabled.svg")
            }
        }
    }

    // Handler for new password input field
    const newPasswordInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPasswordConfirm = e.target.value
        setNewPassword(newPasswordConfirm)
        if (newPassword === "" && newPasswordConfirm !== "") {
            if (newPasswordIcon === "/images/show-password-disabled.svg") {
                setNewPasswordIcon("/images/show-password.svg")
            } else if (newPasswordIcon === "/images/hide-password-disabled.svg") {
                setNewPasswordIcon("/images/hide-password.svg")
            }
        } else if (newPassword !== "" && newPasswordConfirm === "") {
            if (newPasswordIcon === "/images/show-password.svg") {
                setNewPasswordIcon("/images/show-password-disabled.svg")
            } else if (newPasswordIcon === "/images/hide-password.svg") {
                setNewPasswordIcon("/images/hide-password-disabled.svg")
            }
        }
        if (!isPasswordAcceptable(newPasswordConfirm)) {
            setNewPasswordErrorText("Inserisci una password accettable")
        } else if (currentPassword === newPasswordConfirm) {
            setNewPasswordErrorText("Le due password devono essere diverse")
        } else {
            setNewPasswordErrorText("")
        }
    }

    // Handler the click on the show current password icon
    const onCurrentPasswordIconClick = () => {
        if (currentPassword !== "") {
            switch (currentPasswordIcon) {
                case "/images/show-password-disabled.svg":
                    setCurrentPasswordIcon("/images/hide-password-disabled.svg")
                    setCurrentPasswordType("text")
                    break;
                case "/images/hide-password-disabled.svg":
                    setCurrentPasswordIcon("/images/show-password-disabled.svg")
                    setCurrentPasswordType("password")
                    break;
                case "/images/show-password.svg":
                    setCurrentPasswordIcon("/images/hide-password.svg")
                    setCurrentPasswordType("text")
                    break;
                case "/images/hide-password.svg":
                    setCurrentPasswordIcon("/images/show-password.svg")
                    setCurrentPasswordType("password")
                    break;
                default:
                    break;
            }
        }
    }

    // Handler the click on the show new password icon
    const onNewPasswordIconClick = () => {
        if (newPassword !== "") {
            switch (newPasswordIcon) {
                case "/images/show-password-disabled.svg":
                    setNewPasswordIcon("/images/hide-password-disabled.svg")
                    setNewPasswordType("text")
                    break;
                case "/images/hide-password-disabled.svg":
                    setNewPasswordIcon("/images/show-password-disabled.svg")
                    setNewPasswordType("password")
                    break;
                case "/images/show-password.svg":
                    setNewPasswordIcon("/images/hide-password.svg")
                    setNewPasswordType("text")
                    break;
                case "/images/hide-password.svg":
                    setNewPasswordIcon("/images/show-password.svg")
                    setNewPasswordType("password")
                    break;
                default:
                    break;
            }
        }
    }

    const handlePersonalDataSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        callUpdateUserInformationsAPI();
        setPersonalDataSubmitButtonDisabled(true);
    }

    const handleChangePasswordSubmit = (e: React.FormEvent) => {
        callChangePasswordAPI();
        e.preventDefault();
    }

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible-0-percent inactive" : ""}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        currentPage={PlatformMenuItem.PersonalData}
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-personal-data-full-container ${focusOnMenu ? "visible_30_percent inactive" : ""}`}>
                    <div className="height_10_percent width_100_percent">
                        <HorizontalLogoText
                            image={"/images/personal-data-big-icon.svg"}
                            text="I miei dati personali"
                            isInOwnView={true}
                        />
                    </div>
                    <div className="vertical-scroll-group-90 width_100_percent">
                        <div className="platform-final-element-container">
                            <form
                                className="personal-data-form"
                                onSubmit={handlePersonalDataSubmit}
                            >
                                <div className="vertical_flex_group margin_bottom_20">
                                    <label className="s12 w600 uppercase">Nome</label>
                                    <div className="vertical_flex_group black_border_bottom align_items_end">
                                        <input
                                            className="input_field s16 w400"
                                            type="text"
                                            placeholder="Nome"
                                            onChange={(e) => {
                                                setUsername(e.target.value)
                                                setPersonalDataSubmitButtonDisabled(false)
                                            }}
                                            value={username}
                                        />
                                    </div>
                                </div>
                                <div className="vertical_flex_group margin_top_20 margin_bottom_20">
                                    <label className="s12 w600 uppercase">Cognome</label>
                                    <div className="vertical_flex_group black_border_bottom align_items_end">
                                        <input
                                            className="input_field s16 w400"
                                            type="text"
                                            placeholder="Cognome"
                                            onChange={(e) => {
                                                setFamilyName(e.target.value)
                                                setPersonalDataSubmitButtonDisabled(false)
                                            }}
                                            value={familyName}
                                        />
                                    </div>
                                </div>
                                <div className="vertical_flex_group margin_top_20 margin_bottom_10">
                                    <label className="s12 w600 uppercase">Indirizzo e-mail</label>
                                    <div className="vertical_flex_group black_border_bottom align_items_end">
                                        <input
                                            className="input_field s16 w400"
                                            type="text"
                                            placeholder="nome@esempio.com"
                                            onChange={() => console.log()}
                                            value={email}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="vertical_flex_group margin_top_20 margin_bottom_10">
                                    <label className="s12 w600 uppercase">Codice Fiscale</label>
                                    <div className="vertical_flex_group black_border_bottom align_items_end">
                                        <input
                                            className="personal-data-input-field s16 w400"
                                            type="text"
                                            placeholder="Inserisci il tuo codice fiscale"
                                            onChange={(e) => {
                                                setTaxNumber(e.target.value)
                                                setPersonalDataSubmitButtonDisabled(false)
                                            }}
                                            value={taxNumber}
                                        />
                                    </div>
                                </div>
                                <div className="vertical_flex_group margin_top_20 margin_bottom_10">
                                    <label className="s12 w600 uppercase">Numero di telefono</label>
                                    <div className="vertical_flex_group black_border_bottom align_items_end">
                                        <input
                                            className="input_field s16 w400"
                                            type="text"
                                            placeholder="Inserisci il tuo numero di telefono"
                                            onChange={(e) => {
                                                setMobileNumber(e.target.value)
                                                setPersonalDataSubmitButtonDisabled(false)
                                            }}
                                            value={mobileNumber}
                                        />
                                    </div>
                                </div>
                                <button
                                    className={`width_100_percent height_55 uppercase w700 s12 br5 blue_background no_border white_text margin_top_20 margin_bottom_20 center ${(!personalDataSubmitButtonDisabled) ? "pointer" : ""}`}
                                    disabled={personalDataSubmitButtonDisabled}
                                >
                                    Conferma
                                </button>
                            </form>
                            {/* ************************************ */}
                            <div className="w700 s20 lh40">Modifica Password</div>
                            <form className="personal-data-form" onSubmit={handleChangePasswordSubmit}>
                                <div className="vertical_flex_group margin_top_20 margin_bottom_20">
                                    <label className="s12 w600 uppercase">Password Attuale</label>
                                    <div
                                        className={`horizontal_flex_group align_items_end ${true ? "black_border_bottom" : "light_gray_border_bottom"}`}
                                    >
                                        <input
                                            className={`ls1 input_field s16 w400 ${true ? "black_text" : "light_gray_text"}`}
                                            type={currentPasswordType}
                                            placeholder="Password Attuale"
                                            onChange={currentPasswordInputChangeHandler}
                                            disabled={!true}
                                            value={currentPassword}
                                        />
                                        <span
                                            className={`lh0 self_center ${currentPassword !== "" ? "pointer" : ""}`}
                                            onClick={onCurrentPasswordIconClick}
                                        >
                                            <img src={currentPasswordIcon} />
                                        </span>
                                    </div>
                                    <label className="s12 w600 lh17 error_text margin_top_10">{currentPasswordErrorText}</label>
                                </div>
                                <div className="s14 w600 lh22 margin_top_10 margin_bottom_20 width_100_percent blue_text">
                                    Una password valida è composta da almeno 8 caratteri di cui: almeno una Maiuscola, una minuscola, un numero e un simbolo speciale (!$%& ...)
                                </div>
                                <div className="vertical_flex_group margin_top_10 margin_bottom_20">
                                    <label className="s12 w600 uppercase">Conferma Password</label>
                                    <div
                                        className={`horizontal_flex_group align_items_end margin_top_10 ${currentPassword !== "" ? "black_border_bottom" : "light_gray_border_bottom"}`}
                                    >
                                        <input
                                            className={`ls1 input_field s16 w400 ${currentPassword !== "" ? "black_text" : "light_gray_text"}`}
                                            type={newPasswordType}
                                            placeholder="Nuova password"
                                            onChange={newPasswordInputChangeHandler}
                                            disabled={currentPassword === ""}
                                            value={newPassword}
                                        />
                                        <span
                                            className={`lh0 self_center ${newPassword !== "" ? "pointer" : ""}`}
                                            onClick={onNewPasswordIconClick}
                                        >
                                            <img src={newPasswordIcon} />
                                        </span>
                                    </div>
                                    <label className="s12 w600 lh17 error_text margin_top_10">{newPasswordErrorText}</label>
                                </div>
                                <button
                                    className={`width_100_percent height_55 uppercase w700 s12 br5 blue_background no_border white_text margin_top_20 margin_bottom_20 center ${!modifyPasswordButtonDisabled ? "pointer" : ""}`}
                                    disabled={modifyPasswordButtonDisabled}
                                >
                                    Modifica Password
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default PersonalData
