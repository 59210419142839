import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useUserData } from './UserDataContext';

interface AuthState {
    idToken: string | null;
    userId: string | null;
    accessToken: string | null;
}

// Define the shape of the authentication context
interface AuthContextType {
    auth: AuthState;
    login: (idToken: string, userId: string, accessToken: string) => void;
    logout: () => void;
}

// Create the authentication context with default values
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a provider component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    // Initialize state with data from localStorage
    const [auth, setAuth] = useState<AuthState>({
        idToken: localStorage.getItem('idToken'),
        userId: localStorage.getItem('userId'),
        accessToken: localStorage.getItem('accessToken')
    });

    const { clearUserData } = useUserData();

    // Effect to update localStorage when auth state changes
    useEffect(() => {
        if (auth.idToken) {
            localStorage.setItem('idToken', auth.idToken);
        } else {
            localStorage.removeItem('idToken');
        }
        
        if (auth.userId) {
            localStorage.setItem('userId', auth.userId);
        } else {
            localStorage.removeItem('userId');
        }

        if (auth.accessToken) {
            localStorage.setItem('accessToken', auth.accessToken)
        } else {
            localStorage.removeItem('accessToken')
        }
    }, [auth]);

    // Function to log in
    const login = (idToken: string, userId: string, accessToken: string) => {
        setAuth({ idToken: idToken, userId: userId, accessToken: accessToken });
    };

    // Function to log out
    const logout = () => {
        setAuth({ idToken: null, userId: null, accessToken: null });
        clearUserData()
    };

    // Provide context value to children
    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the authentication context
export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
