import { User } from "../api-objects/User";
import { BASE_URL, requestOptionsGET } from "../Constants";

/**
 * Type for the callback function invoked upon successful retrieval of user information.
 * @param user - The user object containing the retrieved user data.
 */
type GetUserDataSuccessCallback = (user: User) => void;

/**
 * Type for the callback function invoked upon an error during the retrieval of user information.
 * @param error - A string describing the error that occurred.
 */
type GetUserDataErrorCallback = (error: string) => void;

/**
 * Fetches user information based on the provided user ID and ID token.
 * 
 * @param userId - The unique identifier of the user whose information is to be retrieved.
 * @param idToken - The ID token used for authorization in the API request.
 * @param onSuccess - Callback function to be called if the user information is successfully retrieved.
 * @param onError - Callback function to be called if an error occurs during the retrieval.
 * 
 * @returns A promise that resolves when the user data is successfully fetched and updated.
 */
export async function getUserInfoApi(
    userId: string,
    idToken: string,
    onSuccess: GetUserDataSuccessCallback,
    onError: GetUserDataErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const user = await response.json()
            onSuccess(user)
        } else {
            const errorMessage = response.statusText || "Failed to fetch user info";
            onError(errorMessage);
        }
    } catch (error) {
        console.error("Get user info failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}
