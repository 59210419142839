import "./css/AddPaymentMethodCard.css"

interface Props {
    onClick: () => void,
}

const AddPaymentMethodCard: React.FC<Props> = ({
    onClick,
}) => {
    return (
        <div
            className="payment-method-add-new-method-external-container"
            onClick={onClick}
        >
            <div className="margin-left-3-percent margin-right-2-percent width_30">
                <img src="/images/add-icon.svg" />
            </div>
            <span className="w600 s20 lh26">Aggiungi metodo di pagamento</span>
        </div>
    )
}

export default AddPaymentMethodCard
