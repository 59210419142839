import "./css/DropCard.css"

interface Props {
    onDropFileClick: () => void,
}

/**
 * @returns A box for dropping exams, available in Blood exams and Other exams sections
 */
const DropCard: React.FC<Props> = ({
    onDropFileClick,
}) => {
    return (
        <div className="drop-area-container-desktop">
            <div className="drop-area-internal-container-desktop">
                <div
                    className="drop-area-link-container"
                    onClick={onDropFileClick}
                >
                    Cerca tra i tuoi file
                </div>
            </div>
        </div>
    )
}

export default DropCard
