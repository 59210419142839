import "./css/WhiteButtonBorder.css"

interface Props {
    text: string,
    onClick: () => void,
    pointer: boolean,
}

const WhiteButtonBorder: React.FC<Props> = ({
    text,
    onClick,
    pointer,
}) => {
    return (
        <button
            className={`white-button-border ${pointer ? "pointer" : ""}`}
            onClick={onClick}
        >
            {text}
        </button>
    )
}

export default WhiteButtonBorder
