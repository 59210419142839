import "./css/UploadFileDialogMobile.css"

interface Props {
    fileUploaded: boolean,
    onDismissUploadDialogButtonClick: () => void,
    uploadDialogRadioChoice: string,
    setUploadDialogRadioChoice: (choice: string) => void,
    onFileInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    uploadedFilename: string,
    uploadedFileSize: string,
    uploadedFileExtension: string,
    onUploadedFileRemove: () => void,
    onConfirmUploadButtonClick: () => void,
    onUndoUploadClick: () => void,
    uploadButtonDisabled: boolean,
    customFilename: string,
    setCustomFilename: (e: React.ChangeEvent<HTMLInputElement>) => void,
    customDate: string,
    setCustomDate: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

const UploadFileDialogMobile: React.FC<Props> = ({
    fileUploaded,
    onDismissUploadDialogButtonClick,
    uploadDialogRadioChoice,
    setUploadDialogRadioChoice,
    onFileInputChange,
    uploadedFilename,
    uploadedFileSize,
    uploadedFileExtension,
    onUploadedFileRemove,
    onConfirmUploadButtonClick,
    onUndoUploadClick,
    uploadButtonDisabled,
    customFilename,
    setCustomFilename,
    customDate,
    setCustomDate,
}) => {
    return (
        <div className={`upload-file-dialog-container-mobile ${fileUploaded ? "height_90_percent" : "height_70_percent"} center-div`}>
            <div className={`vertical_flex_group justify_content_center width_100_percent ${fileUploaded ? "height_30_percent" : "height_50_percent"}`}>
                <div className={`height_80_percent width_100_percent`}>
                    <div className={`vertical_flex_group justify_content_space_around height_50_percent`}>
                        <div className={`horizontal_flex_group justify_content_space_between align_items_center margin-left-7-percent margin-right-7-percent`}>
                            <div className="w700 s20 lh40">Carica esami</div>
                            <div
                                className="pointer"
                                onClick={onDismissUploadDialogButtonClick}
                            >
                                <img src="/images/x.svg" />
                            </div>
                        </div>
                        <div className="margin-left-7-percent margin-right-7-percent w400 s16 lh40">
                            Che esami stai caricando?
                        </div>
                    </div>
                    <div className={`margin-left-7-percent margin-right-7-percent vertical_flex_group justify_content_space_evenly height_50_percent`}>
                        <div className="horizontal_flex_group align_items_center">
                            <input
                                key="Esami del sangue"
                                className="margin_bottom_5 pointer margin_right_10"
                                type="radio"
                                value="Esami del sangue"
                                id="blood"
                                checked={uploadDialogRadioChoice === "Esami del sangue"}
                                onClick={() => setUploadDialogRadioChoice("Esami del sangue")}
                                readOnly
                            />
                            <div className="margin-right-7-percent">
                                <div
                                    className="horizontal_flex_group justify_content_center align_items_center upload-dialog-mobile-radio-container"
                                    onClick={() => setUploadDialogRadioChoice("Esami del sangue")}
                                >
                                    <div className={`upload-dialog-mobile-radio-internal-element ${uploadDialogRadioChoice === "Esami del sangue" ? "" : "invisible"}`}>
                                    </div>
                                </div>
                            </div>
                            <label htmlFor="blood" className="w700 s16 lh40">Esami del sangue</label>
                        </div>
                        <div className="horizontal_flex_group align_items_center">
                            <input
                                key="Altri esami"
                                className="margin_top_5 margin_bottom_5 pointer margin_right_10"
                                type="radio"
                                value="Altri esami"
                                id="other"
                                checked={uploadDialogRadioChoice === "Altri esami"}
                                onClick={() => setUploadDialogRadioChoice("Altri esami")}
                                readOnly
                            />
                            <div className="margin-right-7-percent">
                                <div
                                    className="horizontal_flex_group justify_content_center align_items_center upload-dialog-mobile-radio-container"
                                    onClick={() => setUploadDialogRadioChoice("Altri esami")}
                                >
                                    <div className={`upload-dialog-radio-internal-element ${uploadDialogRadioChoice === "Altri esami" ? "" : "invisible"}`}>

                                    </div>
                                </div>
                            </div>
                            <label htmlFor="other" className="w700 s16 lh40">Altri esami</label>
                        </div>
                    </div>
                </div>
            </div>
            {
                !fileUploaded ?
                    <div className="vertical_flex_group justify_content_center width_100_percent height_50_percent">
                        <div className="self_center width_86_percent height_80_percent black-border-dashed br20">
                            <form
                                action=""
                                className="file-input-form"
                            >
                                <div className="height_10_percent"></div>
                                <div className="height_40_percent w600 s20 lh26 width_50_percent center"><span onClick={() => (document.querySelector(".file-input-box") as HTMLElement).click()} className="primary_action_color_text underline_text pointer">Carica un file PDF, JPG o JPEG.</span></div>
                                <input
                                    type="file"
                                    accept="application/pdf image/jpg image/jpeg"
                                    className="file-input-box"
                                    hidden
                                    onChange={(e) => onFileInputChange(e)}
                                />
                            </form>
                        </div>
                    </div>
                    :
                    <div className="vertical_flex_group justify_content_space_evenly width_86_percent height_70_percent center self_center">
                        <div className="horizontal_flex_group justify_content_space_between align_items_center height_25_percent br10 black_border">
                            <div className="vertical_flex_group justify_content_space_around margin-left-5-percent align_items_start width_60_percent height_90_percent">
                                <div className="w700 s16 lh40 left">{uploadedFilename}</div>
                                <div className="w400 s16 lh40 left">{uploadedFileSize} kb</div>
                            </div>
                            <div className="horizontal_flex_group justify_content_space_evenly align_items_center width_30_percent margin-right-5-percent height_90_percent">
                                <div className="vertical_flex_group justify_content_center margin-right-7-percent width_70_percent height_100_percent black_border br9 w700 s16 lh40 gray-background">
                                    {uploadedFileExtension}
                                </div>
                                <div
                                    className="pointer"
                                    onClick={onUploadedFileRemove}
                                >
                                    <img src="/images/x.svg" />
                                </div>
                            </div>
                        </div>
                        <div className="vertical_flex_group justify_content_space_between height_30_percent">
                            <label className="self_start s12 w600 uppercase">Nome esame</label>
                            <div className="vertical_flex_group black_border_bottom align_items_end margin_bottom_10">
                                <input
                                    className="upload-dialog-mobile-input-field s16 w400"
                                    type="text"
                                    placeholder="Es. Check-up sangue, Esame urine, ecc..."
                                    value={customFilename}
                                    onChange={(e) => setCustomFilename(e)}
                                />
                            </div>
                            <label className="self_start s12 w600 uppercase margin_top_10">Data esecuzione</label>
                            <div className="horizontal_flex_group align_items_end black_border_bottom align_items_end">
                                <input
                                    className="margin_left_5 upload-dialog-mobile-input-field s16 w400"
                                    type="text"
                                    placeholder="Data esecuzione"
                                    value={customDate}
                                    onChange={(e) => setCustomDate(e)}
                                />
                                <span
                                    className={`lh0 self_center pointer`}
                                ><img src="/images/calendar-icon.svg"></img>
                                </span>
                            </div>
                        </div>
                        <div className="vertical_flex_group height_25_percent justify_content_space_between">
                            <button
                                className={`height_40_percent primary_action_color_background no_border br5 uppercase white_text w700 s14 lh17 ${uploadButtonDisabled ? "" : "pointer"}`}
                                onClick={onConfirmUploadButtonClick}
                                disabled={uploadButtonDisabled}
                            >
                                Carica
                            </button>
                            <button
                                className="height_40_percent pointer white_background black_border br5 uppercase w700 s14 lh17"
                                onClick={onUndoUploadClick}
                            >
                                Annulla
                            </button>
                        </div>
                    </div>
            }
        </div>
    )
}

export default UploadFileDialogMobile
