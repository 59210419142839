export const enum WaterAnswer {
    LessOneLiter = "Meno di un litro",
    OneLiterOneLiterHalf = "Tra un litro e un litro e mezzo",
    OneLiterHalfTwoLiters = "Tra un litro e mezzo e due litri",
    MoreTwoLiters = "Oltre due litri",
}

export const enum TrainingAnswer {
    Everyday = "Ogni giorno",
    Frequently = "3-4 volte alla settimana",
    Sometimes = "1-2 volte alla settimana",
    Never = "Raramente o mai",
}

export const enum StressAnswer {
    Stressed = "Sì, spesso",
    Medium = "Qualche volta, dipende dal periodo",
    NoStress = "Raramente o mai",
}

export const enum SleepAnswer {
    Great = "Ottima, mi sveglio riposata/o e rigenerata/o ogni giorno",
    Good = "Buona, di solito mi sveglio riposata/o, ma talvolta ho notato dei disturbi nel sonno",
    NotGood = "Non ottimale, mi sveglio spesso durante la notte o non mi sento completamente riposata/o al mattino",
    Bad = "Bassa, ho difficoltà a dormire e mi sveglio frequentemente durante la notte, mi sento stanca/o al risveglio",
}

export const enum EnergyAnswer {
    Active = "No, mi sento energica/o e riposata/o la maggior parte del tempo",
    Medium = "Occasionalmente, soprattutto quando sono molto impegnata/o o stressata/o",
    Low = "Sì, spesso mi sento stanca/o anche dopo aver dormito a sufficienza",
}