import "./css/ParameterWidget.css"

interface Props {
    name: string,
    image: string,
    value: string,
}

const ParameterWidget: React.FC<Props> = ({
    name,
    image,
    value,
}) => {
  return (
    <div className="parameter-widget-container">
      <div className="parameter-widget-internal-container">
        <div className="parameter-widget-heading">
            {name}
        </div>
        <div className="parameter-widget-image">
            <img src={image}/>
        </div>
        <div className="parameter-widget-value">
            {value}
        </div>
      </div>
    </div>
  )
}

export default ParameterWidget
