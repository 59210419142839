import { consultStates } from "../../../utils/Constants"
import { handlePlanTrackerWidgetSubtitle } from "../../../utils/Functions"
import { ConsultStep } from "../../../utils/enums/ConsultStep"
import { TrackerWidgetDotType } from "../../../utils/enums/TrackerWidgetDotType"
import { TrackerWidgetTextType } from "../../../utils/enums/TrackerWidgetTextType"
import PlanTrackerWidgetSingleElement from "../components/PlanTrackerWidgetSingleElement"
import "./css/PlanTrackerWidget.css"

interface Props {
    consultStep: ConsultStep,
    onLinkClick: () => void,
    dnaTestPurchased: boolean,
}

const PlanTrackerWidget: React.FC<Props> = ({
    consultStep,
    onLinkClick,
    dnaTestPurchased,
}) => {

    const handleProgressBar = () => {
        switch (consultStep) {
            case ConsultStep.DnaOrPurchase:
                return "width_0"
            case ConsultStep.FirstConsultBooked:
                return "width_30_percent"
            case ConsultStep.FirstConsultDone:
                return "width_55_percent"
            case ConsultStep.PlanPublished:
                return "width_80_percent"
            case ConsultStep.SecondConsultDone:
                return "width_100_percent"
        }
    }

    return (
        <div className="plan-tracker-widget-container">
            <div className="plan-tracker-widget-internal-container">
                <div className="plan-tracker-widget-heading">
                    Il tuo percorso
                </div>
                <div className="plan-tracker-widget-path-container">
                    <div className="plan-tracker-widget-path-internal-container">
                        <div className="plan-tracker-widget-path-elements-container">
                            <PlanTrackerWidgetSingleElement
                                dotType={TrackerWidgetDotType.Filled}
                                textType={consultStep === ConsultStep.DnaOrPurchase ? TrackerWidgetTextType.Colored : TrackerWidgetTextType.Black}
                                text={dnaTestPurchased ? "Test DNA" : "Percorso\\nacquistato"}
                            />
                            <PlanTrackerWidgetSingleElement
                                dotType={consultStates.indexOf(consultStep) >= 1 ? TrackerWidgetDotType.Filled : TrackerWidgetDotType.Empty}
                                textType={consultStep === ConsultStep.FirstConsultBooked ? TrackerWidgetTextType.Colored : TrackerWidgetTextType.Black}
                                text="Prenota primo consulto\nnutrizionista"
                            />
                            <PlanTrackerWidgetSingleElement
                                dotType={consultStates.indexOf(consultStep) >= 2 ? TrackerWidgetDotType.Filled : TrackerWidgetDotType.Empty}
                                textType={consultStep === ConsultStep.FirstConsultDone ? TrackerWidgetTextType.Colored : TrackerWidgetTextType.Black}
                                text="Primo consulto"
                            />
                            <PlanTrackerWidgetSingleElement
                                dotType={consultStates.indexOf(consultStep) >= 3 ? TrackerWidgetDotType.Filled : TrackerWidgetDotType.Empty}
                                textType={consultStep === ConsultStep.PlanPublished ? TrackerWidgetTextType.Colored : TrackerWidgetTextType.Black}
                                text="Percorso alimentare \n personalizzato"
                            />
                            <PlanTrackerWidgetSingleElement
                                dotType={consultStates.indexOf(consultStep) >= 4 ? TrackerWidgetDotType.Filled : TrackerWidgetDotType.Empty}
                                textType={consultStep === ConsultStep.SecondConsultDone ? TrackerWidgetTextType.Colored : TrackerWidgetTextType.Black}
                                text="Secondo consulto"
                            />
                        </div>
                        <div className="plan-tracker-widget-progression-bar-empty">
                            <div className={`plan-tracker-widget-progression-bar-filled ${handleProgressBar()}`} />
                        </div>
                    </div>
                </div>
                <div className="plan-tracker-widget-title-two">
                    {handlePlanTrackerWidgetSubtitle(consultStep, onLinkClick)}
                </div>
            </div>
        </div>
    )
}

export default PlanTrackerWidget
