import "./css/PaywallProductContent.css"

interface Props {
    text: string,
    image: string
}

const PaywallProductContent: React.FC<Props> = ({
    text,
    image,
}) => {
  return (
    <div className="paywall-product-content-container">
        <div className="paywall-product-content-image-container">
            <img src={image}/>
        </div>
        <div className="paywall-product-content-text">{text}</div>
    </div>
  )
}

export default PaywallProductContent
