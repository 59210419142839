import { useState } from "react"
import { useNavigate } from "react-router-dom"
import BackButton from "../../../components/BackButton"
import LoadingSpinner from "../../../components/LoadingSpinner"
import SecondaryButton12 from "../../../components/buttons/SecondaryButton12"
import { loginApi } from "../../../utils/apis/auth-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { useRegistrationData } from "../../../utils/context/RegistrationContext"
import '../../styles.css'
import ChangePasswordDialog from "./components/ChangePasswordDialog"
import LoginBox from "./components/LoginBox"
import "./css/LoginPage.css"


const LoginPage = () => {

    const navigate = useNavigate();
    const { login } = useAuth();
    const { registrationData } = useRegistrationData();

    const [isLoading, setIsLoading] = useState(false)
    const [passwordErrorText, setPasswordErrorText] = useState("")
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false)

    const registrationPending = registrationData.registrationEmail !== null && registrationData.password !== null

    const onBackButtonClick = () => {
        window.open("https://www.holifya.com/", "_self")
    }

    const onCreateAccountClick = () => {
        if (registrationPending) {
            navigate("/email-registration-code")
        } else {
            navigate("/email-registration")
        }
    }

    const handleLoginInputsSubmit = (inputData: { [key: string]: string }) => {
        const email = inputData.email
        const password = inputData.password
        callLogin(email, password)
    }

    const callLogin = (email: string, password: string) => {
        setIsLoading(true)
        setPasswordErrorText("")
        loginApi(
            email,
            password,
            (result) => {
                login(result.idToken, result.userId, result.accessToken);
                navigate("/login-success");
                setIsLoading(false)
            },
            (error, showChangePasswordDialog) => {
                setPasswordErrorText(error)
                if (showChangePasswordDialog) {
                    setShowChangePasswordDialog(true);
                }
                setIsLoading(false)
            }
        )
    }

    // ******************************************************************************************************************

    return (
        <div className={`App relative`}>
            <div className={`login-page-buttons-row ${(isLoading || showChangePasswordDialog) ? "visible_30_percent inactive" : ""}`}>
                <BackButton onClick={onBackButtonClick} />
                <div className="login-page-create-account-button">
                    <SecondaryButton12
                        text="Crea un Account"
                        onClick={onCreateAccountClick}
                        pointer={true}
                    />
                </div>
            </div>
            <div className={`login-page-full-container ${(isLoading || showChangePasswordDialog) ? "visible_30_percent inactive" : ""}`}>
                <div className={`login-page-main-container`}>
                    <LoginBox
                        onSubmit={handleLoginInputsSubmit}
                        passwordError={passwordErrorText}
                    />
                </div>
            </div>
            {
                isLoading && <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div>
            }
            {
                showChangePasswordDialog && <ChangePasswordDialog onClick={() => setShowChangePasswordDialog(false)} />
            }
        </div>
    )
}

export default LoginPage
