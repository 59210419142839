import { useEffect, useState } from "react"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import HolifyaFullLogo from "../../../components/HolifyaFullLogo"
import TextInputElement from "../../../components/input/TextInputElement"
import { CONFIRMATION_NUMBER_MINIMUM_DIGITS } from "../../../utils/Constants"
import "./css/RedeemCodeBox.css"

interface Props {
    onSubmit: (inputData: { [key: string]: string }) => void;
    errorText: string,
}

const RedeemCodeBox: React.FC<Props> = ({
    onSubmit,
    errorText,
}) => {
    const [error, setError] = useState(errorText)

    const [inputs, setInputs] = useState<{ [key: string]: string }>({
        confirmationCode: '',
    });

    const submitButtonDisabled = inputs.confirmationCode.length < CONFIRMATION_NUMBER_MINIMUM_DIGITS

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setError("")

        setInputs((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        onSubmit(inputs);
    };

    useEffect(() => {
        setError(errorText)
    }, [errorText])

// ********************************************************************************

    return (
        <div className="redeem-code-box-full-container">
            <div className="redeem-code-box-logo-desktop">
                <HolifyaFullLogo />
            </div>
            <div className="redeem-code-box-logo-container">
                <img src="/images/check-mail.svg" />
            </div>
            <div className="redeem-code-box-heading">
                <span className="w700">Fantastico!</span><br></br>Controlla la tua e-mail.
            </div>
            <div className="redeem-code-box-copy">
                Al momento dell'acquisto ti abbiamo inviato una mail contenente un <span className="w700">codice acquisto</span>.
                <br></br>Copialo e incollalo qui sotto per sbloccare la tua esperienza Holifya!
            </div>
            <form
                className="redeem-code-box-form"
                onSubmit={handleSubmit}
            >
                <TextInputElement
                    name="confirmationCode"
                    label="Codice Acquisto"
                    placeholder="Es: 98GH4ASR4"
                    errorText={error}
                    inputValue={inputs.confirmationCode}
                    onChange={handleInputChange}
                />
                <div className="redeem-code-box-form-submit-button">
                    <PrimaryButton
                        text="Conferma codice"
                        pointer={!submitButtonDisabled}
                        disabled={submitButtonDisabled}
                    />
                </div>
            </form>
        </div>
    )
}

export default RedeemCodeBox
