import Expert from "../../../components/Expert"
import { ShopifyProduct } from "../../../utils/enums/ShopifyProduct"
import "./css/MonetizationSingleProductCard.css"
import MonetizationBundleCard from "./internal/MonetizationBundleCard"
import MonetizationNutritionistCardWithSkills from "./internal/MonetizationNutritionistCardWithSkills"
import MonetizationNutritionistConsultCard from "./internal/MonetizationNutritionistConsultCard"

interface Props {
    nutritionist: Expert,
    product: ShopifyProduct,
    onInfoClick: () => void,
}

/** The full card of a wall page, with all the products and nutritionist info. */
const MonetizationSingleProductCard: React.FC<Props> = ({
    nutritionist,
    product,
    onInfoClick,
}) => {

    const isANutritionistConsult = product === ShopifyProduct.NutritionistConsult
    const isABundle = product === ShopifyProduct.OneMonthBundle || product === ShopifyProduct.ThreeMonthsBundle || product === ShopifyProduct.SixMonthsBundle
    const isASub = product === ShopifyProduct.OneMonthSub || product === ShopifyProduct.ThreeMonthsSub || product === ShopifyProduct.SixMonthsSub

    return (
        <div className={`${isABundle ? "monetization-single-product-card-container-big" : "monetization-single-product-card-container-small"}`}>
            <div className={`${isABundle ? "monetization-single-product-card-internal-container-big" : "monetization-single-product-card-internal-container-small"}`}>
                <div className="monetization-single-product-card-nutritionist-container">
                    <MonetizationNutritionistCardWithSkills
                        nutritionist={nutritionist}
                        onInfoClick={onInfoClick}
                    />
                </div>
                <div className={`${isABundle ? "monetization-single-product-card-product-container-big" : "monetization-single-product-card-product-container-small"}`}>
                    {
                        isANutritionistConsult ?
                            <div className="monetization-single-product-card-nutritionist-consult-container">
                                <MonetizationNutritionistConsultCard />
                            </div> : null
                    }
                    {
                        isABundle ?
                            <div className="monetization-single-product-card-bundle-container">
                                <MonetizationBundleCard
                                    product={product}
                                />
                            </div> : null
                    }
                    {
                        isASub ?
                            <div>{product}</div> : null
                    }
                </div>
            </div>
        </div>
    )
}

export default MonetizationSingleProductCard
