import DownloadIconText from "../../../../../components/DownloadIconText"
import { handleDate } from "../../../../../utils/Functions"
import "./css/TestExamCard.css"

interface Props {
    image?: string,
    uploadedFromUser?: boolean,
    onDeleteClick?: () => void,
    name?: string,
    url?: string,
    date?: string,
    filename?: string,
}

/**
 * 
 * @param image the image / first page of the file
 * @param uploadedFromUser a boolean to check if the user uploaded the file
 * @param onDeleteClick a function to handle the delete button behaviour
 * @param name the name of the user
 * @param url the url of the file
 * @param date date of upload of the file
 * @param filename custom name, provided by the user
 * @returns a card with a downloadable exam / test
 */
const TestExamCard: React.FC<Props> = ({
    image,
    uploadedFromUser,
    onDeleteClick,
    name,
    url,
    date,
    filename,
}) => {
    return (
        <div className="text-exam-card-external-container">
            {
                uploadedFromUser ?
                    <div className="text-exam-card-user-uploader-banner">
                        <div className="text-exam-card-user-name-label-container">
                            Caricati da {name}
                        </div>
                        <div
                            className="margin_right_10 pointer"
                            onClick={onDeleteClick}
                        >
                            <img src="/images/delete-icon.svg" />
                        </div>
                    </div> : null
            }
            <div
                className="text-exam-card-clickable-banner"
                onClick={() => window.open(url, "_blank")}
            />
            <div
                className={`text-exam-card-pdf-container`}
            >
                {
                    image !== undefined ?
                        <iframe
                            className="text-exam-card-iframe"
                            src={image}
                        /> : null
                }
            </div>
            <div className="text-exam-card-bottom-label">
                {/* <div className="horizontal_flex_group justify_content_space_between align_items_center width_100_percent height_20_percent br10-bottom"> */}
                <div className="text-exam-card-bottom-label-internal">
                    <div className="text-exam-card-bottom-label-left-element">
                        {
                            date !== undefined ?
                                <div className="text-exam-card-date">
                                    {handleDate(date)}
                                </div> : "Una data a caso"
                        }
                        {
                            filename !== undefined ?
                                <div className="text-exam-card-name">
                                    {filename}
                                </div> : "filename"
                        }
                    </div>
                    <DownloadIconText
                        onClick={() => window.open(url, "_blank")}
                        noMargin={true}
                    />
                    {/* <a
                        className="text-exam-card-hyperlink"
                        href={url}
                        download={true}
                    /> */}
                </div>
            </div>
        </div>
    )
}

export default TestExamCard
