import CloseButton from "../../../components/CloseButton"
import { PAYWALL_PRICE_INFO_ONE_MONTHS_BUNDLE, PAYWALL_PRICE_INFO_SIX_MONTHS_BUNDLE, PAYWALL_PRICE_INFO_SIX_MONTHS_SUB, PAYWALL_PRICE_INFO_THREE_MONTHS_BUNDLE, PAYWALL_PRICE_INFO_THREE_MONTHS_SUB } from "../../../utils/Constants"
import { ShopifyProduct } from "../../../utils/enums/ShopifyProduct"
import "./css/PaymentInfoDialog.css"

interface Props {
  onCloseClick: () => void,
  product: ShopifyProduct,
}

const PaymentInfoDialog: React.FC<Props> = ({
  onCloseClick,
  product,
}) => {

  const handlePriceInfoText = () => {
    switch (product) {
      case ShopifyProduct.OneMonthBundle:
        return PAYWALL_PRICE_INFO_ONE_MONTHS_BUNDLE
      case ShopifyProduct.ThreeMonthsBundle:
        return PAYWALL_PRICE_INFO_THREE_MONTHS_BUNDLE
      case ShopifyProduct.ThreeMonthsSub:
        return PAYWALL_PRICE_INFO_THREE_MONTHS_SUB
      case ShopifyProduct.SixMonthsBundle:
        return PAYWALL_PRICE_INFO_SIX_MONTHS_BUNDLE
      case ShopifyProduct.SixMonthsSub:
        return PAYWALL_PRICE_INFO_SIX_MONTHS_SUB
      default:
        return ""
    }
  }

  return (
    <div className="payment-info-dialog-full-container center-div">
      <div className="payment-info-dialog-internal-container">
        <div className="payment-info-dialog-heading-container">
          <div className="payment-info-dialog-heading">
            Come è composto il prezzo?
          </div>
          <CloseButton
            onClick={onCloseClick}
            noFlexEnd={true}
          />
        </div>
        <div className="payment-info-dialog-copy">
          {
            handlePriceInfoText()
          }
        </div>
      </div>
    </div>
  )
}

export default PaymentInfoDialog
