import { useState } from "react"
import { useNavigate } from "react-router-dom"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { confirmCodeApi, resendCodeApi } from "../../../utils/apis/auth-service"
import { useRegistrationData } from "../../../utils/context/RegistrationContext"
import RegistrationCodeBox from "./components/RegistrationCodeBox"
import "./css/EmailRegistrationInsertCode.css"

const EmailRegistrationInsertCode = () => {

    const navigate = useNavigate();
    const { registrationData } = useRegistrationData()
    const email = registrationData.registrationEmail

    const [codeErrorText, setCodeErrorText] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const handleResendCode = () => {
        email !== null && resendCodeApi(
            email,
            () => {},
            () => {}
        )
    }

    const handleInputSubmit = (inputData: { [key: string]: string }) => {
        setCodeErrorText("")
        const code = inputData.code
        setIsLoading(true)
        email !== null && confirmCodeApi(
            email,
            code,
            () => {
                setIsLoading(false)
                navigate("/registration-success")
            },
            (error) => {
                setIsLoading(false)
                console.log(error)
                setCodeErrorText(error)
            }
        )
    }

    // ********************************************************************************

    return (
        <div className="App">
            <div className={`email-registration-insert-code-container ${isLoading && "visible_30_percent inactive"}`}>
                <div className="email-registration-insert-code-content-container">
                    <RegistrationCodeBox
                        onSubmit={handleInputSubmit}
                        onResendCodeClick={handleResendCode}
                        errorText={codeErrorText}
                    />
                </div>
            </div>
            {
                isLoading && <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div>
            }
        </div>
    )
}

export default EmailRegistrationInsertCode
