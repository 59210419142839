interface Props {
    onClick?: () => void;
}

const DeleteButton: React.FC<Props> = ({
    onClick,
}) => {
    return (
        <div
            className={`horizontal_flex_group align_items_center justify_content_space_around transparent_background uppercase s14 lh17 w700 width_109 br5 pointer height_55`}
            onClick={onClick}
        >
            <img src="/images/x.svg" />
            <div className="no_decoration_text">
                Elimina
            </div>
        </div>
    )
}

export default DeleteButton
