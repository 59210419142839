import { BASE_URL } from "../Constants";

/**
 * Type for the callback function invoked upon successful login.
 * @param result - An object containing login details.
 * @param result.idToken - The ID token received from the server.
 * @param result.accessToken - The access token received from the server.
 * @param result.userId - The unique identifier of the user.
 */
type LoginSuccessCallback = (result: { idToken: string, accessToken: string, userId: string }) => void;

/**
 * Type for the callback function invoked upon login error.
 * @param error - A string describing the error that occurred.
 * @param showChangePasswordDialog - Optional flag to indicate if the change password dialog should be shown.
 */
type LoginErrorCallback = (error: string, showChangePasswordDialog?: boolean) => void;

/**
 * Logs in a user with the provided email and password.
 * 
 * @param email - The user's email address.
 * @param password - The user's password.
 * @param onSuccess - Callback function to be called if the login is successful.
 * @param onError - Callback function to be called if an error occurs during login.
 * 
 * @returns A promise that resolves to an object containing the success status and an error message if an error occurs.
 * 
 * @throws An error if the fetch operation fails.
 */
export async function loginApi(
    email: string,
    password: string,
    onSuccess: LoginSuccessCallback,
    onError: LoginErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                    password: password,
                }
            ),
        })
        if (!response.ok) {
            const responseJson = await response.json()
            if (responseJson.detail === "Password reset required") {
                onError("Cambio password necessario", true)
            } else {
                onError("Indirizzo e-mail o password errata")
            }
        } else {
            const responseJson = await response.json()
            onSuccess({
                idToken: responseJson.id_token,
                accessToken: responseJson.access_token,
                userId: responseJson.id
            });
        }
    } catch (error) {
        console.error("Login failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

/**
 * Type for the callback function invoked upon successful registration.
 */
type RegisterSuccessCallback = () => void;

/**
 * Type for the callback function invoked upon registration error.
 * @param error - A string describing the error that occurred.
 * @param showAlert - Optional flag to indicate if an alert should be shown.
 */
type RegisterErrorCallback = (error: string, showAlert?: boolean) => void;

/**
 * Registers a new user with the provided details.
 * 
 * @param email - The user's email address.
 * @param password - The user's password.
 * @param username - The user's chosen username.
 * @param familyName - The user's family name.
 * @param phoneNumber - The user's phone number, which will be prefixed with country code if not already present.
 * @param newsletterAccepted - Indicates whether the user has accepted the newsletter policy.
 * @param onSuccess - Callback function to be called if the registration is successful.
 * @param onError - Callback function to be called if an error occurs during registration.
 * 
 * @returns A promise that resolves to an object containing the success status and an error message if an error occurs.
 * 
 * @throws An error if the fetch operation fails.
 */
export async function registerApi(
    email: string,
    password: string,
    username: string,
    familyName: string,
    phoneNumber: string,
    newsletterAccepted: boolean,
    onSuccess: RegisterSuccessCallback,
    onError: RegisterErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/auth/register`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                    password: password,
                    name: username,
                    family_name: familyName,
                    mobile_phone: phoneNumber[0] === "+" ? phoneNumber : `+39${phoneNumber}`,
                    newsletter_policy: newsletterAccepted,
                }
            ),
        })
        if (!response.ok) {
            const responseJson = await response.json()
            if (response.status === 422) {
                onError("Errore di compilazione", true)
            } else if (responseJson.detail === "An account with the given email already exists.") {
                onError("Email già presente, accedi cliccando il pulsante in alto.");
            } else {
                onError("Errore, riprova");
            }
        } else {
            onSuccess()
        }
    } catch (error) {
        console.error("Registration failed:", error)
        return { success: false, error: "An unexpected error occurred" };
    }
}

/**
 * Type for the callback function invoked upon successful registration.
 */
type ConfirmCodeSuccessCallback = () => void;

/**
 * Type for the callback function invoked upon registration error.
 * @param error - A string describing the error that occurred.
 */
type ConfirmCodeErrorCallback = (error: string) => void;

export async function confirmCodeApi(
    email: string,
    confirmationCode: string,
    onSuccess: ConfirmCodeSuccessCallback,
    onError: ConfirmCodeErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/auth/confirm`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                    code: confirmationCode,
                }
            ),
        })
        if (!response.ok) {
            onError("Codice non valido")
        } else {
            onSuccess()
        }
    } catch (error) {
        console.error("Code confirmation failed:", error)
        return { success: false, error: "An unexpected error occurred" };
    }
}

/**
 * Type for the callback function invoked upon successful forgot password.
 */
type ForgotPasswordSuccessCallback = () => void;

/**
 * Type for the callback function invoked upon forgot password error.
 * @param error - A string describing the error that occurred.
 */
type ForgotPasswordErrorCallback = (error: string) => void;

export async function forgotPasswordApi(
    email: string,
    onSuccess: ForgotPasswordSuccessCallback,
    onError: ForgotPasswordErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/auth/forgot-password`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                }
            ),
        })
        if (!response.ok) {
            onError("Indirizzo e-mail non trovato")
        } else {
            onSuccess()
        }
    } catch (error) {
        console.error("Forgot password error:", error)
        return { success: false, error: "An unexpected error occurred" };
    }
}

/**
 * Type for the callback function invoked upon successful confirm forgot password.
 */
type ConfirmForgotPasswordSuccessCallback = () => void;

/**
 * Type for the callback function invoked upon confirm forgot password error.
 * @param error - A string describing the error that occurred.
 */
type ConfirmForgotPasswordErrorCallback = (error: string) => void;

export async function confirmForgotPasswordApi(
    email: string,
    password: string,
    code: string,
    onSuccess: ConfirmForgotPasswordSuccessCallback,
    onError: ConfirmForgotPasswordErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/auth/confirm-forgot-password`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                    code: code,
                    password: password,
                }
            ),
        })
        if (!response.ok) {
            onError("Qualcosa è andato storto, torna alla pagina precedente e riprova")
        } else {
            onSuccess()
        }
    } catch (error) {
        console.error("Confirm forgot password error:", error)
        return { success: false, error: "An unexpected error occurred" };
    }
}

/**
 * Type for the callback function invoked upon successful resend code.
 */
type ResendCodeSuccessCallback = () => void;

/**
 * Type for the callback function invoked upon resend code error.
 */
type ResendCodeErrorCallback = () => void;

export async function resendCodeApi(
    email: string,
    onSuccess: ResendCodeSuccessCallback,
    onError: ResendCodeErrorCallback,
) {
    try {
        const respose = await fetch(`${BASE_URL}/auth/resend-code`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                }
            ),
        })
        if (!respose.ok) {
            onError()
        } else {
            onSuccess()
        }
    } catch (error) {
        console.error("Resend code error:", error)
        return { success: false, error: "An unexpected error occurred" };
    }
}

/**
 * Type for the callback function invoked upon successful confirm forgot password.
 */
type LogoutSuccessCallback = (success: string) => void;

/**
 * Type for the callback function invoked upon confirm forgot password error.
 * @param error - A string describing the error that occurred.
 */
type LogoutErrorCallback = (error: string) => void;

export async function logoutAPI(
    email: string,
    onSuccess: LogoutSuccessCallback,
    onError: LogoutErrorCallback,
) {
    const response = await fetch(`${BASE_URL}/auth/logout`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                email: email,
            }
        ),
    })
    if (response.status === 302) {
        const result = await response.json();
        onSuccess(result)
    } else {
        const result = await response.json();
        onError(result)
    }
}
