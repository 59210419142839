import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseButton from "../../../../components/CloseButton";
import HorizontalLogoText from "../../../../components/HorizontalLogoText";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET } from "../../../../utils/Constants";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem";
import MenuPopup from "../../components/MenuPopup";
import CheckoutForm2 from "./components/CheckoutForm2";
import "./css/PaymentMethodAddNewPaymentMethod.css";

const PaymentMethodAddNewPaymentMethod = () => {

    // Navigation parameters
    const navigate = useNavigate();

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId!

    const { userData } = useUserData()
    const username = userData.name!
    const familyName = userData.familyName!
    const email = userData.email!

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
    }, [])

    // *******************************************************************************************************************

    const handleRadioButtonsContainerHeight = () => {
        switch (paymentMethodChoice) {
            case "Credit card":
                return "platform-payment-method-add-new-method-card-chosen-height"
            case "Paypal":
                return "platform-payment-method-add-new-method-paypal-chosen-height"
            default:
                return "platform-payment-method-add-new-method-standard-height"
        }
    }

    const [paymentMethodChoice, setPaymentMethodChoice] = useState("")

    const [cardNumber, setCardNumber] = useState("")
    const [expiryDate, setExpiryDate] = useState("")
    const [cvc, setCvc] = useState("")
    const [country, setCountry] = useState("")

    const [secondPageNameSurname, setSecondPageNameSurname] = useState("")
    const [secondPageCity, setSecondPageCity] = useState("")
    const [secondPageAddress, setSecondPageAddress] = useState("")
    const [secondPageProvincia, setSecondPageProvincia] = useState("")
    const [secondPageCap, setSecondPageCap] = useState("")
    const [secondPageTaxNumber, setSecondPageTaxNumber] = useState("")

    const [showCreditCardSecondPage, setShowCreditCardSecondPage] = useState(false)

    const onAddButtonClick = () => {
        if (paymentMethodChoice === "Credit card" && !showCreditCardSecondPage) {
            setShowCreditCardSecondPage(true)
        } else {
            console.log("add")
        }
    }
    const addButtonDisabled = paymentMethodChoice === "" || (paymentMethodChoice === "Credit card" && !showCreditCardSecondPage && !(cardNumber !== "" && expiryDate !== "" && cvc !== "" && country !== "")) || (paymentMethodChoice === "Credit card" && showCreditCardSecondPage && !(secondPageNameSurname !== "" && secondPageCity !== "" && secondPageAddress !== "" && secondPageProvincia !== "" && secondPageCap !== "" && secondPageTaxNumber !== ""))

    return (
        <div className="App Quiz justify_content_center relative">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        currentPage={PlatformMenuItem.PaymentMethod}
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-payment-method-add-new-method-full-container ${focusOnMenu ? "visible_30_percent inactive" : ""}`}>
                    <div className="height_10_percent width_89_percent horizontal_flex_group justify_content_space_between align_items_start">
                        <HorizontalLogoText
                            image={"/images/add-big-icon.svg"}
                            text="Aggiungi metodo di pagamento"
                            isInOwnView={true}
                        />
                        <CloseButton
                            noFlexEnd={true}
                            onClick={() => navigate("/platform/payment-method")}
                        />
                    </div>
                    <div className="vertical-scroll-group-90 width_100_percent">
                        <div className="platform-final-element-container">
                            {/* {
                                !showCreditCardSecondPage ?
                                    <div className="width_100_percent height_100_percent">
                                        <div className={`platform-payment-method-add-new-method-radio-buttons-container ${handleRadioButtonsContainerHeight()}`}>
                                            <div className="horizontal_flex_group align_items_center">
                                                <input
                                                    key="Credit card"
                                                    className=""
                                                    type="radio"
                                                    value="Credit card"
                                                    id="card"
                                                    checked={true}
                                                    onClick={() => console.log("clicked")}
                                                    readOnly
                                                />
                                                <div className="margin_right_20">
                                                    <div
                                                        className="horizontal_flex_group justify_content_center align_items_center platform-payment-method-add-new-method-radio-container"
                                                        onClick={() => setPaymentMethodChoice("Credit card")}
                                                    >
                                                        <div className={`platform-payment-method-add-new-method-radio-internal-element ${paymentMethodChoice === "Credit card" ? "" : "invisible"}`}>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="lh0 width_40">
                                                    <img src="/images/payment-method-choice-card-icon.svg" />
                                                </div>
                                                <div className="margin_left_15 w700 s20 lh40">
                                                    Carta di credito
                                                </div>
                                            </div>
                                            {
                                                paymentMethodChoice === "Credit card" ?
                                                    <div className="platform-payment-method-add-new-method-credit-card-form">
                                                        <div className="platform-payment-method-add-new-method-credit-card-form-single-line">
                                                            <div className="vertical_flex_group justify_content_center height_100_percent">
                                                                <label className="w600 s12 lh14">
                                                                    Numero della carta
                                                                </label>
                                                                <div className="horizontal_flex_group width_100_percent black_border_bottom align_items_center justify_content_flex_end">
                                                                    <input
                                                                        className="platform-payment-method-add-new-method-input-field"
                                                                        placeholder="Numero carta"
                                                                        onChange={(e) => setCardNumber(e.target.value)}
                                                                        type="text"
                                                                    />
                                                                    <img
                                                                        src="/images/payment-circuits-screen.svg"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="platform-payment-method-add-new-method-credit-card-form-single-line">
                                                            <div className="horizontal_flex_group justify_content_space_between height_100_percent">
                                                                <div className="vertical_flex_group justify_content_center height_100_percent width-408">
                                                                    <label className="w600 s12 lh14">
                                                                        Scadenza
                                                                    </label>
                                                                    <div className="horizontal_flex_group width_100_percent black_border_bottom align_items_center justify_content_flex_end height-48">
                                                                        <input
                                                                            className="platform-payment-method-add-new-method-input-field"
                                                                            placeholder="MM/AA"
                                                                            onChange={(e) => setExpiryDate(e.target.value)}
                                                                            type="text"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="vertical_flex_group justify_content_center height_100_percent width-198">
                                                                    <label className="w600 s12 lh14">
                                                                        CVC
                                                                    </label>
                                                                    <div className="horizontal_flex_group width_100_percent black_border_bottom align_items_center justify_content_flex_end height-48">
                                                                        <input
                                                                            className="platform-payment-method-add-new-method-input-field"
                                                                            placeholder="CVC"
                                                                            onChange={(e) => setCvc(e.target.value)}
                                                                            type="text"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="platform-payment-method-add-new-method-credit-card-form-single-line">
                                                            <div className="vertical_flex_group justify_content_center height_100_percent">
                                                                <label className="w600 s12 lh14">
                                                                    Paese
                                                                </label>
                                                                <div className="horizontal_flex_group width_100_percent black_border_bottom align_items_center justify_content_flex_end height-48">
                                                                    <input
                                                                        className="platform-payment-method-add-new-method-input-field"
                                                                        placeholder="Italia"
                                                                        onChange={(e) => setCountry(e.target.value)}
                                                                        type="text"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    null
                                            }
                                            <div className="horizontal_flex_group align_items_center">
                                                <input
                                                    key="Paypal"
                                                    className=""
                                                    type="radio"
                                                    value="Paypal"
                                                    id="paypal"
                                                    checked={true}
                                                    onClick={() => console.log("clicked")}
                                                    readOnly
                                                />
                                                <div className="margin_right_20">
                                                    <div
                                                        className="horizontal_flex_group justify_content_center align_items_center platform-payment-method-add-new-method-radio-container"
                                                        onClick={() => setPaymentMethodChoice("Paypal")}
                                                    >
                                                        <div className={`platform-payment-method-add-new-method-radio-internal-element ${paymentMethodChoice === "Paypal" ? "" : "invisible"}`}>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="lh0 width_40">
                                                    <img src="/images/payment-method-choice-paypal-icon.svg" />
                                                </div>
                                                <div className="margin_left_15 w700 s20 lh40">
                                                    Paypal
                                                </div>
                                            </div>
                                            {
                                                paymentMethodChoice === "Paypal" ?
                                                    <div className="platform-payment-method-add-new-method-paypal-chosen-container">
                                                        <div className="platform-payment-method-add-new-method-paypal-small-view">Aggiungi qualcosa su Paypal</div>
                                                        <div className="platform-payment-method-add-new-method-paypal-second-small-view">Powered by STRIPE</div>
                                                    </div> :
                                                    null
                                            }
                                            <div className="horizontal_flex_group align_items_center">
                                                <input
                                                    key="Apple Pay"
                                                    className=""
                                                    type="radio"
                                                    value="Apple Pay"
                                                    id="apple"
                                                    checked={true}
                                                    onClick={() => console.log("clicked")}
                                                    readOnly
                                                />
                                                <div className="margin_right_20">
                                                    <div
                                                        className="horizontal_flex_group justify_content_center align_items_center platform-payment-method-add-new-method-radio-container"
                                                        onClick={() => setPaymentMethodChoice("Apple Pay")}
                                                    >
                                                        <div className={`platform-payment-method-add-new-method-radio-internal-element ${paymentMethodChoice === "Apple Pay" ? "" : "invisible"}`}>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="lh0 width_40">
                                                    <img src="/images/payment-method-choice-apple-pay-icon.svg" />
                                                </div>
                                                <div className="margin_left_15 w700 s20 lh40">
                                                    Apple Pay
                                                </div>
                                            </div>
                                            <div className="horizontal_flex_group align_items_center">
                                                <input
                                                    key="Google Pay"
                                                    className=""
                                                    type="radio"
                                                    value="Google Pay"
                                                    id="google"
                                                    checked={true}
                                                    onClick={() => console.log("clicked")}
                                                    readOnly
                                                />
                                                <div className="margin_right_20">
                                                    <div
                                                        className="horizontal_flex_group justify_content_center align_items_center platform-payment-method-add-new-method-radio-container"
                                                        onClick={() => setPaymentMethodChoice("Google Pay")}
                                                    >
                                                        <div className={`platform-payment-method-add-new-method-radio-internal-element ${paymentMethodChoice === "Google Pay" ? "" : "invisible"}`}>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="lh0 width_40">
                                                    <img src="/images/payment-method-choice-google-pay-icon.svg" />
                                                </div>
                                                <div className="margin_left_15 w700 s20 lh40">
                                                    Google Pay
                                                </div>
                                            </div>
                                        </div>
                                        <div className="height_55" />
                                        <div className="platform-payment-method-add-new-method-info-container">
                                            <div className="margin_left_10 margin_top_5 margin_bottom_5 w700 s14 lh17">
                                                Come funzionerà il pagamento?
                                            </div>
                                            <div className="margin_left_10 margin_top_5 margin_bottom_5 margin_right_5 w400 s14 lh24">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In condimentum eu augue eu condimentum. Aliquam vel enim id ligula sollicitudin rutrum. Cras nec tortor hendrerit, lobortis nulla eget, porttitor elit. Cras ullamcorper mauris sit amet ex blandit mattis. Suspendisse pretium porttitor sapien, ac tempus odio tristique vitae. Ut dapibus risus nec lacus viverra, ac euismod turpis feugiat. Pellentesque quis finibus ligula
                                            </div>
                                        </div>
                                        <div className="height_20" />
                                        <button
                                            className={`platform-payment-method-add-new-method-button primary_action_color_background ${addButtonDisabled ? "" : "pointer"}`}
                                            onClick={onAddButtonClick}
                                            disabled={addButtonDisabled}
                                        >
                                            Aggiungi
                                        </button>
                                    </div> :
                                    <div className="width_100_percent height_100_percent">
                                        <BackButton
                                            onClick={() => setShowCreditCardSecondPage(false)}
                                        />
                                        <div className="w700 s20 lh40">
                                            Inserisci i tuoi dati
                                        </div>
                                        <div className="height_20" />
                                        <div className="platform-payment-method-add-new-method-credit-card-second-page">
                                            <div className="margin_left_10 w400 s14 lh24">
                                                Useremo questi dati per fatturare i servizi che acquisterai con questo metodo di pagamento.
                                            </div>
                                        </div>
                                        <div className="height_20" />
                                        <div className="platform-payment-method-add-new-method-credit-card-second-page-form">
                                            <div className="platform-payment-method-add-new-method-credit-card-form-single-line">
                                                <div className="vertical_flex_group justify_content_center height_100_percent">
                                                    <label className="w600 s12 lh14">
                                                        Nome e Cognome
                                                    </label>
                                                    <div className="horizontal_flex_group width_100_percent black_border_bottom align_items_center justify_content_flex_end height-48">
                                                        <input
                                                            className="platform-payment-method-add-new-method-input-field"
                                                            placeholder="Nome e Cognome"
                                                            onChange={(e) => setSecondPageNameSurname(e.target.value)}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="platform-payment-method-add-new-method-credit-card-form-single-line">
                                                <div className="vertical_flex_group justify_content_center height_100_percent">
                                                    <label className="w600 s12 lh14">
                                                        Città
                                                    </label>
                                                    <div className="horizontal_flex_group width_100_percent black_border_bottom align_items_center justify_content_flex_end height-48">
                                                        <input
                                                            className="platform-payment-method-add-new-method-input-field"
                                                            placeholder="Città"
                                                            onChange={(e) => setSecondPageCity(e.target.value)}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="platform-payment-method-add-new-method-credit-card-form-single-line">
                                                <div className="vertical_flex_group justify_content_center height_100_percent">
                                                    <label className="w600 s12 lh14">
                                                        Indirizzo
                                                    </label>
                                                    <div className="horizontal_flex_group width_100_percent black_border_bottom align_items_center justify_content_flex_end height-48">
                                                        <input
                                                            className="platform-payment-method-add-new-method-input-field"
                                                            placeholder="Via, Strada, Viale, ecc.."
                                                            onChange={(e) => setSecondPageAddress(e.target.value)}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="platform-payment-method-add-new-method-credit-card-form-single-line">
                                                <div className="vertical_flex_group justify_content_center height_100_percent">
                                                    <label className="w600 s12 lh14">
                                                        Provincia
                                                    </label>
                                                    <div className="horizontal_flex_group width_100_percent black_border_bottom align_items_center justify_content_flex_end height-48">
                                                        <input
                                                            className="platform-payment-method-add-new-method-input-field"
                                                            placeholder="Provincia"
                                                            onChange={(e) => setSecondPageProvincia(e.target.value)}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="platform-payment-method-add-new-method-credit-card-form-single-line">
                                                <div className="vertical_flex_group justify_content_center height_100_percent">
                                                    <label className="w600 s12 lh14">
                                                        CAP
                                                    </label>
                                                    <div className="horizontal_flex_group width_100_percent black_border_bottom align_items_center justify_content_flex_end height-48">
                                                        <input
                                                            className="platform-payment-method-add-new-method-input-field"
                                                            placeholder="00000"
                                                            onChange={(e) => setSecondPageCap(e.target.value)}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="platform-payment-method-add-new-method-credit-card-form-single-line">
                                                <div className="vertical_flex_group justify_content_center height_100_percent">
                                                    <label className="w600 s12 lh14">
                                                        Codice Fiscale
                                                    </label>
                                                    <div className="horizontal_flex_group width_100_percent black_border_bottom align_items_center justify_content_flex_end height-48">
                                                        <input
                                                            className="platform-payment-method-add-new-method-input-field"
                                                            placeholder="Codice Fiscale"
                                                            onChange={(e) => setSecondPageTaxNumber(e.target.value)}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="height_20" />
                                        <button
                                            className={`platform-payment-method-add-new-method-button primary_action_color_background ${addButtonDisabled ? "" : "pointer"}`}
                                            onClick={onAddButtonClick}
                                            disabled={addButtonDisabled}
                                        >
                                            Aggiungi
                                        </button>
                                    </div>
                            } */}
                            <CheckoutForm2
                                idToken={idToken}
                                userId={userId}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default PaymentMethodAddNewPaymentMethod
