import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CloseButton from "../../../../components/CloseButton";
import HorizontalLogoText from "../../../../components/HorizontalLogoText";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import ShopifyItem from "../../../../components/ShopifyItem";
import { BASE_URL, CONFIRMATION_NUMBER_MINIMUM_DIGITS, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET } from "../../../../utils/Constants";
import { isANutritionistConsult, isAShopifySubbedUser } from "../../../../utils/Functions";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { NavigationToQuizQ } from "../../../../utils/enums/NavigationToQuizQ";
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem";
import { QuizType } from "../../../../utils/enums/QuizType";
import { ShopifyItemName, ShopifyItemSku } from "../../../../utils/enums/ShopifyItemType";
import MenuPopup from "../../components/MenuPopup";
import "./css/MyPurchasesRedeem.css";

const MyPurchasesRedeem = () => {

    // Navigation parameters
    const navigate = useNavigate();
    const location = useLocation();
    const isADNAUser = location.state.isADNAUser

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const { userData } = useUserData()
    const username = userData.name!
    const familyName = userData.familyName!
    const email = userData.email!

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
        callGetQuizQApi()
    }, [])

    // *******************************************************************************************************************

    const [confirmationNumber, setConfirmationNumber] = useState("")
    const [errorText, setErrorText] = useState("")

    const submitButtonDisabled = confirmationNumber.length < CONFIRMATION_NUMBER_MINIMUM_DIGITS

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        callGetOrderByConfirmationCodeAPI()
    }

    const [quizQTaken, setQuizQTaken] = useState(false)
    const [surveyATaken, setSurveyATaken] = useState(false)

    // GET Quiz Q by user id
    async function callGetQuizQApi() {
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/survey/${QuizType.QuizQ}`, requestOptionsGET(idToken))
        if (response.status === 404) {
        } else {
            setQuizQTaken(true)
            callGetSurveyAApi()
        }
    }

    // GET Survey A by user id
    async function callGetSurveyAApi() {
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/survey/${QuizType.SurveyA}`, requestOptionsGET(idToken))
        if (response.status === 404) {
        } else {
            setSurveyATaken(true)
        }
    }

    // GET orders by confirmation_number
    async function callGetOrderByConfirmationCodeAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/orders/find/${confirmationNumber}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            if (responseJson.items.length === 1) {
                const item = responseJson.items[0]
                if ((item.type === ShopifyItemSku.DnaTest || item.type === ShopifyItemSku.DnaTestOld) && isADNAUser) {
                    setErrorText("Non puoi riscattare un ulteriore test del DNA")
                } else {
                    const shopifyItem: ShopifyItem = {
                        id: item.id,
                        name: item.name,
                        price: item.price,
                        type: item.type,
                        confirmationNumber: responseJson.confirmation_number,
                        shopifyId: responseJson.shopify_id,
                        createdAt: responseJson.created_at,
                    }
                    const sku = responseJson.items[0].type
                    const productName = responseJson.items[0].name
                    const toSurveys = isANutritionistConsult(sku, productName) || isAShopifySubbedUser(sku, productName)
                    await callSaveOrderByUserIdAPI(shopifyItem, toSurveys);
                }
            } else {
                var itemIndex = 0
                const shopifyItems: ShopifyItem[] = []
                while (responseJson.items[itemIndex] !== undefined) {
                    shopifyItems.push(
                        {
                            id: responseJson.items[itemIndex].id,
                            name: responseJson.items[itemIndex].name,
                            price: responseJson.items[itemIndex].price,
                            type: responseJson.items[itemIndex].type,
                            confirmationNumber: responseJson.confirmation_number,
                            shopifyId: responseJson.shopify_id,
                            createdAt: responseJson.created_at,
                        }
                    )
                    itemIndex = itemIndex + 1
                }
                navigate("/platform/my-purchases/redeem/choose", { state: { shopifyItems } })
            }
        } else if (response.status === 401) {
            setErrorText("Errore di recupero dati (token)")
        } else if (response.status === 400) {
            setErrorText("Ordine già riscattato")
        } else if (response.status === 404) {
            setErrorText("Ordine non trovato")
        } else {
            setErrorText("Errore recupero dati")
        }
    }

    // SAVE order by user_id
    async function callSaveOrderByUserIdAPI(shopifyItem: ShopifyItem, toSurveys: boolean) {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    shopify_id: shopifyItem.shopifyId,
                    confirmation_number: shopifyItem.confirmationNumber,
                    items: [
                        {
                            id: shopifyItem.id,
                            name: shopifyItem.name,
                            price: shopifyItem.price,
                            type: shopifyItem.type,
                        }
                    ],
                })
        })
        setIsLoading(false)
        if (!response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
        } else {
            if (toSurveys) {
                if (!quizQTaken) {
                    if (shopifyItem.name === ShopifyItemName.NutritionistConsult) {
                        navigate("/quiz-q-home", { state: { fromWhere: NavigationToQuizQ.PlatformRedeem } })
                    } else {
                        navigate("/quiz-t-home")
                    }
                } else if (!surveyATaken) {
                    navigate("/survey-a-home")
                } else {
                    navigate("/platform/my-purchases")
                }
            } else {
                navigate("/platform/my-purchases")
            }
        }
    }

    // ********************************************************************************

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        currentPage={PlatformMenuItem.MyPurchases}
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-my-purchases-redeem-full-container ${focusOnMenu ? "visible_30_percent inactive" : ""}`}>
                    <div className="height_10_percent width_89_percent horizontal_flex_group justify_content_space_between align_items_start">
                        <div className="width_100_percent">
                            <HorizontalLogoText
                                image={"/images/redeem-purchase-big-icon.svg"}
                                text="Riscatta un nuovo acquisto"
                                isInOwnView={true}
                            />
                        </div>
                        <CloseButton
                            noFlexEnd={true}
                            onClick={() => navigate("/platform/my-purchases")}
                        />
                    </div>
                    <div className="width_100_percent height_90_percent">
                        <div className="vertical-scroll-group-100 width_100_percent">
                            <div className="platform-final-element-container">
                                <div className="my-purchases-redeem-copy-container s18 lh24 margin-bottom-4-percent">
                                    Al momento dell'acquisto ti abbiamo inviato una mail contenente un <span className="w700">codice acquisto</span>.
                                    <br></br>Copialo e incollalo qui sotto per sbloccare la tua esperienza Holifya!
                                </div>
                                <form
                                    className="my-purchases-redeem-form-container"
                                    onSubmit={onSubmit}
                                >
                                    <div className="vertical_flex_group margin_bottom_10">
                                        <label className="s12 w600 uppercase">Codice acquisto</label>
                                        <div className="vertical_flex_group black_border_bottom align_items_end">
                                            <input
                                                className="my-purchases-redeem-input-field ls1"
                                                type="text"
                                                placeholder="Es: 98GH4ASR4"
                                                onChange={(e) => {
                                                    setConfirmationNumber(e.target.value)
                                                    setErrorText("")
                                                }}
                                                value={confirmationNumber}
                                            />
                                        </div>
                                        <label className="s12 w600 lh17 error_text margin_top_10">{errorText}</label>
                                    </div>
                                    <button
                                        className={`width_100_percent height_55 uppercase w700 s14 br5 blue_background no_border white_text center margin_top_10 ${!submitButtonDisabled ? "pointer" : ""}`}
                                        disabled={submitButtonDisabled}
                                    >
                                        Conferma codice
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default MyPurchasesRedeem
