import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import CloseButton from "../../../../components/CloseButton"
import HorizontalLogoText from "../../../../components/HorizontalLogoText"
import HorizontalNavBar from "../../../../components/HorizontalNavBar"
import LoadingSpinner from "../../../../components/LoadingSpinner"
import NavBar from "../../../../components/NavBar"
import ShopifyItem from "../../../../components/ShopifyItem"
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET } from "../../../../utils/Constants"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem"
import MenuPopup from "../../components/MenuPopup"
import MyProduct from "./components/MyProduct"
import "./css/MyPurchasesRedeemChoose.css"

const MyPurchasesRedeemChoose = () => {

    // Navigation parameters
    const navigate = useNavigate();
    const location = useLocation();

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const { userData } = useUserData()
    const username = userData.name!
    const familyName = userData.familyName!
    const email = userData.email!

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        setConfirmationNumber(shopifyItems[0].confirmationNumber)
        callAPIUserInfo()
        const length = shopifyItems.length
        for (let i = 0; i < length; i++) {
            if (!items.some(el => el.name === shopifyItems[i].name)) {
                items.push(
                    {
                        id: shopifyItems[i].id,
                        name: shopifyItems[i].name,
                        price: shopifyItems[i].price,
                        type: shopifyItems[i].type,
                        shopifyId: shopifyItems[i].shopifyId,
                        orderId: shopifyItems[i].orderId,
                        confirmationNumber: shopifyItems[i].confirmationNumber,
                    }
                )
            }
            setItems(items)
        }
    }, [])

    // *******************************************************************************************************************

    const [confirmationNumber, setConfirmationNumber] = useState("")
    const shopifyItems = location.state.shopifyItems
    const [items, setItems] = useState<ShopifyItem[]>([])

    const [choice, setChoice] = useState("")

    const onSubmit = () => {
        items?.map(product => (
            product.name === choice ? callSaveOrderByUserIdAPI(product) : null
        ))
    }

    // SAVE order by user_id
    async function callSaveOrderByUserIdAPI(shopifyItem: ShopifyItem) {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    shopify_id: shopifyItem.shopifyId,
                    confirmation_number: shopifyItem.confirmationNumber,
                    items: [
                        {
                            id: shopifyItem.id,
                            name: shopifyItem.name,
                            price: shopifyItem.price,
                            type: shopifyItem.type,
                        }
                    ],
                })
        })
        setIsLoading(false)
        if (!response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
        } else {
            navigate("/platform/my-purchases")
        }
    }

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        currentPage={PlatformMenuItem.MyPurchases}
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-my-purchases-redeem-choose-full-container ${focusOnMenu ? "visible_30_percent inactive" : ""}`}>
                    <div className="height_10_percent width_89_percent horizontal_flex_group justify_content_space_between align_items_center">
                        <div className="width_100_percent">
                            <HorizontalLogoText
                                image={"/images/redeem-purchase-big-icon.svg"}
                                text="Riscatta un nuovo acquisto"
                                isInOwnView={true}
                            />
                        </div>
                        <CloseButton
                            noFlexEnd={true}
                            onClick={() => navigate("/platform/my-purchases/redeem")}
                        />
                    </div>
                    <div className="width_100_percent height_90_percent">
                        <div className="height_10_percent width_100_percent">
                            <div className="margin_top_50 s18 lh24 margin_bottom_50">
                                Hai inserito un <span className="w700">codice acquisto</span> con più prodotti al suo interno.
                                <br></br>Scegli ora il prodotto da associare al tuo account!
                            </div>
                        </div>
                        <div className="vertical-scroll-group-90 width_100_percent">
                            <div className="platform-final-element-container">
                                <div className="uppercase w600 s14 lh26 margin_bottom_10">Codice Acquisto: {confirmationNumber}</div>
                                {items.map(product => (
                                    <MyProduct
                                        key={product.name + product.confirmationNumber}
                                        product={product}
                                        infoText=""
                                        onCardClick={() => setChoice(product.name)}
                                        selected={choice === product.name}
                                        isClickable={true}
                                        isChooseProductView={true}
                                    />
                                ))
                                }
                                <button
                                    className={`my-purchases-redeem-choose-confirm-button blue_background no_border white_text center margin_top_10 ${choice !== "" ? "pointer" : ""}`}
                                    disabled={choice === ""}
                                    onClick={onSubmit}
                                >
                                    Associa
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default MyPurchasesRedeemChoose
