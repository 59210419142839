import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import HorizontalLogoText from "../../../../components/HorizontalLogoText"
import HorizontalNavBar from "../../../../components/HorizontalNavBar"
import LoadingSpinner from "../../../../components/LoadingSpinner"
import NavBar from "../../../../components/NavBar"
import ShopifyItem from "../../../../components/ShopifyItem"
import { SubscriptionItem, SubscriptionItemCard } from "../../../../components/SubscriptionItem"
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET } from "../../../../utils/Constants"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem"
import { ShopifyItemName, ShopifyItemSku } from "../../../../utils/enums/ShopifyItemType"
import TrackingStatus from "../../../../utils/enums/TrackingStatus"
import MenuPopup from "../../components/MenuPopup"
import CancelSubDialog from "./components/CancelSubDialog"
import HandleSubDialog from "./components/HandleSubDialog"
import MyProduct from "./components/MyProduct"
import "./css/MyPurchases.css"

const MyPurchases = () => {

    // Navigation parameters
    const navigate = useNavigate();

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const { userData } = useUserData()
    const username = userData.name!
    const familyName = userData.familyName!
    const email = userData.email!

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
        callAPIOrdersRecovery()
        callGetSubscriptionsApi()
        getTrackingByUserIdAPI()
    }, [])

    // *******************************************************************************************************************

    // This state determines the position on the progression bar, and also the direction of the links
    const [trackingStatus, setTrackingStatus] = useState<TrackingStatus>(TrackingStatus.Purchased)
    // This state determines the position on the progression bar, and also the direction of the links
    const [trackedOrderId, setTrackedOrderId] = useState("")

    // Items already purchased by the user
    const [userProducts, setUserProducts] = useState<ShopifyItem[]>()

    const [subscription, setSubscription] = useState<SubscriptionItem>()

    const [isADNAUser, setIsADNAUser] = useState(false)

    // GET orders by user_id
    async function callAPIOrdersRecovery() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            const shopifyItems: ShopifyItem[] = []
            for (const order of responseJson) {
                if (order.items[0].name !== ShopifyItemName.OneMonthSub && order.items[0].name !== ShopifyItemName.ThreeMonthsSub && order.items[0].name !== ShopifyItemName.SixMonthsSub) {
                    shopifyItems.push(
                        {
                            id: order.id,
                            name: order.items[0].name,
                            price: order.items[0].price,
                            type: order.items[0].type,
                            confirmationNumber: order.confirmation_number,
                            shopifyId: order.shopify_id,
                            createdAt: order.created_at,
                        }
                    )
                    const sku = order.items[0].type
                    if (sku === ShopifyItemSku.DnaTest || sku === ShopifyItemSku.DnaTestOld) {
                        setIsADNAUser(true)
                    }
                }
            }
            shopifyItems.reverse()
            setUserProducts(shopifyItems)
        } else if (response.status === 401) {
            // call again callAPISurveyRecovery
        }
    }

    // GET User subscriptions by user_id
    async function callGetSubscriptionsApi() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/payment/subscription/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            if (responseJson.status === "active" || responseJson.status === "trial") {
                const subscription: SubscriptionItem = {
                    createdAt: responseJson.created_at,
                    type: responseJson.type,
                }
                setSubscription(subscription)
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    async function getTrackingByUserIdAPI() {
        setIsLoading(true)
        const responseTracking = await fetch(`${BASE_URL}/api/track/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseTracking.ok) {
            const responseTrackingJson = await responseTracking.json()
            if (responseTrackingJson.length !== 0) {
                setTrackingStatus(responseTrackingJson[0].tracking_status)
                setTrackedOrderId(responseTrackingJson[0].order_id)
            }
        } else if (responseTracking.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseTracking.json()
            console.log(responseJson)
        }
    }

    const onRedeemPurchaseClick = () => {
        navigate("/platform/my-purchases/redeem", { state: { isADNAUser } })
    }

    const [arrowIcon, setArrowIcon] = useState("/images/down-arrow-blue.svg")
    const [showDeliveryBox, setShowDeliveryBox] = useState(false)

    const onArrowClick = () => {
        if (arrowIcon === "/images/down-arrow-blue.svg") {
            setArrowIcon("/images/up-arrow-blue.svg")
            setShowDeliveryBox(true)
        } else {
            setArrowIcon("/images/down-arrow-blue.svg")
            setShowDeliveryBox(false)
        }
    }

    const [showHandleSubDialog, setShowHandleSubDialog] = useState(false)
    const [showCancelSubDialog, setShowCancelSubDialog] = useState(false)

    // **********************************************************************

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container relative ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        currentPage={PlatformMenuItem.MyPurchases}
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-my-purchases-full-container ${(focusOnMenu || showHandleSubDialog || showCancelSubDialog) ? "visible_30_percent inactive" : ""}`}>
                    <div className="height_10_percent width_100_percent">
                        <HorizontalLogoText
                            image={"/images/purchases-big-icon.svg"}
                            text="Acquisti"
                            isInOwnView={true}
                        />
                    </div>
                    <div className="vertical-scroll-group-90 width_100_percent">
                        <div className="platform-final-element-container">
                            <div
                                className={`my-purchases-redeem-new-product-card`}
                                onClick={onRedeemPurchaseClick}
                            >
                                <div className="margin-left-3-percent margin-right-2-percent width_30">
                                    <img src="/images/redeem-purchase-icon.svg" />
                                </div>
                                <span className="w600 s20 lh26">Riscatta un nuovo acquisto</span>
                            </div>
                            <div className="my-purchases-my-purchases-container">
                                <div className="w700 s20 lh40">
                                    Prodotti Associati
                                </div>
                                {userProducts?.map(product => (
                                    <div key={product.name + product.confirmationNumber}>
                                        <MyProduct
                                            product={product}
                                            infoText=""
                                            isClickable={false}
                                            onHandleClick={() => setShowHandleSubDialog(true)}
                                        />
                                        {
                                            (trackedOrderId === product.id && trackingStatus === TrackingStatus.Purchased) ?
                                                <div>
                                                    <div className="horizontal_flex_group align_items_center">
                                                        <div className="margin_left_10 w600 s14 lh26">
                                                            Kit in consegna. <span className="underline_text primary_action_color_text">Traccia la spedizione</span>
                                                        </div>
                                                        <div
                                                            className="pointer margin_left_10 lh0"
                                                            onClick={onArrowClick}
                                                        >
                                                            <img src={arrowIcon} />
                                                        </div>
                                                    </div>
                                                    <div className="height_10" />
                                                    {
                                                        showDeliveryBox ?
                                                            <div className="my-purchases-delivery-box">
                                                                <div className="margin_left_10 margin_top_10 margin_bottom_10">
                                                                    <img src="/images/delivery-track-icon.svg" />
                                                                </div>
                                                                <div className="margin_left_10 margin_bottom_10">
                                                                    TNT si sta occupando della consegna del tuo kit.
                                                                </div>
                                                                <div className="margin_left_10 w400">
                                                                    Codice tracking: <span className="w700">CODICEACASO</span>.
                                                                </div>
                                                                <div className="margin_left_10 margin_bottom_10 w400">
                                                                    Accedi al tracking di TNT con questo link: <span className="pointer primary_action_color_text underline_text" onClick={() => window.open("https://www.tnt.it/tracking/Tracking.do", "_blank")}>https://www.tnt.it/tracking/Tracking.do</span>.
                                                                </div>
                                                            </div> : null
                                                    }
                                                </div> : null
                                        }
                                        {
                                            (trackedOrderId === product.id && trackingStatus === TrackingStatus.Delivered) ?
                                                <div>
                                                    Kit consegnato
                                                </div> : null
                                        }
                                    </div>
                                ))}
                                {
                                    subscription !== undefined ?
                                        <div>
                                            <SubscriptionItemCard
                                                subscription={subscription}
                                            />
                                        </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showHandleSubDialog ?
                        <HandleSubDialog
                            onEditPaymentMethodClick={() => navigate("/platform/payment-method")}
                            onCancelSubClick={() => {
                                setShowHandleSubDialog(false)
                                setShowCancelSubDialog(true)
                            }}
                            onDismissClick={() => setShowHandleSubDialog(false)}
                        /> : null
                }
                {
                    showCancelSubDialog ?
                        <CancelSubDialog
                            onDismissClick={() => setShowCancelSubDialog(false)}
                            onBackClick={() => {
                                setShowCancelSubDialog(false)
                                setShowHandleSubDialog(true)
                            }}
                        /> : null
                }
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default MyPurchases
