import { ShopifyProduct } from "../../../utils/enums/ShopifyProduct"
import PaywallProductContent from "./PaywallProductContent"
import "./css/PaywallProductContents.css"

interface Props {
  product: ShopifyProduct,
  testIncluded: boolean,
  isDismissible?: boolean,
}

const PaywallProductContents: React.FC<Props> = ({
  product,
  testIncluded,
  isDismissible,
}) => {

  const handleConsultsNumber = () => {
    switch (product) {
      case ShopifyProduct.OneMonthBundle:
      case ShopifyProduct.OneMonthSub:
      case ShopifyProduct.NutritionistConsult:
        return "1 consulto"
      case ShopifyProduct.ThreeMonthsBundle:
      case ShopifyProduct.ThreeMonthsSub:
        return "3 consulti"
      case ShopifyProduct.SixMonthsBundle:
      case ShopifyProduct.SixMonthsSub:
        return "6 consulti"
    }
  }

  const handlePlansNumber = () => {
    switch (product) {
      case ShopifyProduct.OneMonthBundle:
      case ShopifyProduct.OneMonthSub:
        return "x1"
      case ShopifyProduct.ThreeMonthsBundle:
      case ShopifyProduct.ThreeMonthsSub:
        return "x3"
      case ShopifyProduct.SixMonthsBundle:
      case ShopifyProduct.SixMonthsSub:
        return "x6"
    }
  }

  return (
    <div className={`${product === ShopifyProduct.NutritionistConsult ? "paywall-product-contents-small-container" : (isDismissible ? "paywall-product-contents-container-dismissible" : "paywall-product-contents-container")}`}>
      {
        (!isDismissible && product !== ShopifyProduct.NutritionistConsult) ?
          <PaywallProductContent
            text="Report nutrigenetico"
            image={testIncluded ? "/images/paywall/paywall-tick.svg" : "/images/paywall/paywall-tick-no.svg"}
          /> : null
      }
      {
        (!isDismissible && product !== ShopifyProduct.NutritionistConsult) ?
          <PaywallProductContent
            text="Mappa alimentare genetica"
            image={testIncluded ? "/images/paywall/paywall-tick.svg" : "/images/paywall/paywall-tick-no.svg"}
          /> : null
      }
      <PaywallProductContent
        text={`Nutrizionista dedicato (${handleConsultsNumber()})`}
        image={"/images/paywall/paywall-tick.svg"}
      />
      {product !== ShopifyProduct.NutritionistConsult ?
        <PaywallProductContent
          text={`Piano alimentare aggiornato mensilmente (${handlePlansNumber()})`}
          image={"/images/paywall/paywall-tick.svg"}
        /> : null
      }
      {
        (!isDismissible && product !== ShopifyProduct.NutritionistConsult) ?
          <PaywallProductContent
            text="Piano alimentare DNA based"
            image={isDismissible ? "invisible" : (testIncluded ? "/images/paywall/paywall-tick.svg" : "/images/paywall/paywall-tick-no.svg")}
          /> : null
      }
      {
        product === ShopifyProduct.NutritionistConsult ?
          <PaywallProductContent
            text="1 Piano alimentare"
            image="/images/paywall/paywall-tick.svg"
          /> : null
      }
    </div>
  )
}

export default PaywallProductContents
