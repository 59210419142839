/** All the possible ways to reach Welcome Survey */
export enum NavigationToQuizQ {
    /** During the login checks */
    Login = "login",
    /** Post redeem */
    RedeemSuccess = "redeem-success",
    /** From the onboarding fork */
    OnboardingFork = "onboarding-fork",
    /** From dashboard / homepage section in platform */
    PlatformDashboard = "platform",
    /** From plan section in platform */
    PlatformPlan = "platform-plan",
    /** From experts section in platform */
    PlatformExperts = "platform-experts",
    /** In platform after redeem*/
    PlatformRedeem = "platform-redeem",
}