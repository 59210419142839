import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CloseButton from "../../../../components/CloseButton";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import Survey from "../../../../components/Survey";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET } from "../../../../utils/Constants";
import { handleSurveyName, handleSurveyOrderPosition } from "../../../../utils/Functions";
import { SurveyResponse } from "../../../../utils/api-objects/SurveyResponse";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem";
import { QuizType } from "../../../../utils/enums/QuizType";
import MenuPopup from "../../components/MenuPopup";
import MyAnswersSingleAnswerCard from "./components/MyAnswersSingleAnswerCard";
import "./css/MyAnswersSurveyType.css";

const MyAnswersSurveyType = () => {

    // Navigation parameters
    const navigate = useNavigate();
    const location = useLocation()

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const { userData } = useUserData()
    const username = userData.name!
    const familyName = userData.familyName!
    const email = userData.email!

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // Surveys already answered by the user
    const [surveyToModify, setSurveyToModify] = useState<Survey>()

    const [answerChanged, setAnswerChanged] = useState(location.state.answerChanged)
    const newAnswer = location.state.correctAnswer

    const { surveyType } = useParams()
    const typedSurveyType = surveyType as QuizType

    const onConfirmUpdateSurveyClick = () => {
        callAPISaveSurveyByType()
    }

    const onEditAnswerClick = (surveyType: string, question: string, correctAnswer: string) => {
        navigate(`/platform/my-answers/${surveyType}/edit-single-question`, { state: { surveyType, question, correctAnswer } })
    }

    const updateAnswer = (surveys: Survey[]) => {
        const surveyToModify = surveys.filter(survey => survey.type === surveyType)[0]
        const questionToModify = surveyToModify.qAndAs.filter(qAndA => qAndA.q === location.state.questionTitle)
        questionToModify[0].a = [newAnswer]
    }

    // *******************************************************************************************************************

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            callAPIAllSurveysRecovery();
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    // GET all surveys survey by user_id
    async function callAPIAllSurveysRecovery() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/surveys`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (response.ok) {
            const responseJson: SurveyResponse[] = await response.json()
            const surveys: Survey[] = []
            for (const survey of responseJson) {
                surveys.push(
                    {
                        type: survey.type,
                        qAndAs: survey.data,
                        createdAt: survey.created_at,
                        orderPosition: handleSurveyOrderPosition(survey.type)
                    }
                )
            }
            if (answerChanged) {
                updateAnswer(surveys)
            }
            setSurveyToModify(surveys.filter(survey => survey.type === surveyType)[0])
        } else if (response.status === 401) {
            navigate("/login")
        } else {
        }
    }

    async function callAPISaveSurveyByType() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/users/${userId}/survey`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    type: surveyType,
                    data: surveyToModify?.qAndAs,
                })
        })
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
            setAnswerChanged(false)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
    }, [])

    // ********************************************************************************

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        currentPage={PlatformMenuItem.MyAnswers}
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-my-answers-survey-type-full-container ${focusOnMenu ? "visible_30_percent inactive" : ""}`}>
                    <div className="my-answers-survey-type-list-header">
                        <div className="my-answers-survey-type-heading-row">
                            <div className="my-answers-survey-type-heading">
                                {handleSurveyName(typedSurveyType)}
                            </div>
                            <CloseButton
                                onClick={() => navigate("/platform/my-answers")}
                                noFlexEnd={true}
                            />
                        </div>
                        <div className="my-answers-survey-type-header-copy">
                            Dopo aver modificato una risposta devi confermare le modifiche in fondo alla pagina.
                        </div>
                    </div>
                    <div className="my-answers-survey-type-answers-container">
                        <div className="platform-final-element-container">
                            {
                                surveyToModify?.qAndAs.map(qAndA => (
                                    <MyAnswersSingleAnswerCard
                                        key={surveyToModify.qAndAs.indexOf(qAndA)}
                                        question={qAndA.q}
                                        answer={qAndA.a[0]}
                                        onEditClick={() => onEditAnswerClick(typedSurveyType, qAndA.q, qAndA.a[0])}
                                    />
                                ))
                            }
                        </div>
                    </div>
                    <div className="my-answers-survey-type-button-row">
                        <div className="my-answers-survey-type-confirm-button">
                            <PrimaryButton
                                text="Conferma"
                                pointer={answerChanged}
                                disabled={!answerChanged}
                                onClick={onConfirmUpdateSurveyClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading && <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div>
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default MyAnswersSurveyType
