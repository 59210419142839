import { ShopifyProduct } from "../../../utils/enums/ShopifyProduct"
import PaywallProductContents from "./PaywallProductContents"
import PaywallProductTag from "./PaywallProductTag"
import "./css/PaywallMultipleChoiceNoTestSingleProduct.css"

interface Props {
    product: ShopifyProduct,
    onPaymentInfoClick: (product: ShopifyProduct) => void,
    onPurchaseClick: (product: ShopifyProduct) => void,
}

const PaywallMultipleChoiceNoTestSingleProduct: React.FC<Props> = ({
    product,
    onPaymentInfoClick,
    onPurchaseClick,
}) => {

    const handleTagName = () => {
        switch (product) {
            case ShopifyProduct.OneMonthSub:
                return "Piano di 1 mese"
            case ShopifyProduct.ThreeMonthsSub:
                return "Piano di 3 mesi"
            case ShopifyProduct.SixMonthsSub:
                return "Piano di 6 mesi"
            default:
                return ""
        }
    }

    const handleMonthlyPrice = () => {
        switch (product) {
            case ShopifyProduct.OneMonthSub:
                return "89€/mese"
            case ShopifyProduct.ThreeMonthsSub:
                return "79€/mese"
            case ShopifyProduct.SixMonthsSub:
                return "68€/mese"
            default:
                return ""
        }
    }

    const handleCurrentPrice = () => {
        switch (product) {
            case ShopifyProduct.OneMonthSub:
                return ""
            case ShopifyProduct.ThreeMonthsSub:
                return "Per 3 mesi di percorso: 237€"
            case ShopifyProduct.SixMonthsSub:
                return "Per 6 mesi di percorso: 408€"
            default:
                return ""
        }
    }

    // ********************************************************************************

    return (
        <div className={`paywall-multiple-choice-no-test-single-product-container ${product === ShopifyProduct.ThreeMonthsSub ? "mobile-margins" : ""}`}>
            <div className="paywall-multiple-choice-no-test-single-product-internal-container">
                <PaywallProductTag
                    text={handleTagName()}
                />
                <div className="paywall-multiple-choice-no-test-single-product-offer-container">
                    <PaywallProductContents
                        product={product}
                        testIncluded={false}
                        isDismissible={true}
                    />
                </div>
                <div className="paywall-multiple-choice-no-test-single-product-blank-space-container"></div>
                <div className="paywall-multiple-choice-no-test-single-product-price-container">
                    <div className="paywall-multiple-choice-no-test-single-product-monthly-price">
                        <span className="paywall-multiple-choice-no-test-single-product-blue-color">{handleMonthlyPrice()}</span> {product !== ShopifyProduct.OneMonthSub ? <span onClick={() => onPaymentInfoClick(product)} className="pointer margin_left_20"><img src="/images/paywall-info-logo.svg" /></span> : null}
                    </div>
                    <div className="paywall-multiple-choice-no-test-single-product-current-price">
                        {handleCurrentPrice()}
                    </div>
                </div>
                <button
                    className="paywall-multiple-choice-no-test-single-product-confirm-button primary_action_color_background"
                    onClick={() => onPurchaseClick(product)}
                >
                    Acquista
                </button>
            </div>
        </div>
    )
}

export default PaywallMultipleChoiceNoTestSingleProduct
