import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HolifyaFullLogo from "../../../components/HolifyaFullLogo";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { RELATIVE_PATH_TO_PLATFORM } from "../../../utils/Constants";

const SurveyASuccess = () => {

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    {
      setTimeout(() => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
      }, 2000)
    }
  }, [])

  // ********************************************************************************

  return (
    <div className="App Quiz justify_content_center ">
      <div className="vertical_flex_group white_background width_392 height_50_percent align_items_center justify_content_space_between">
        <div className="">
          <HolifyaFullLogo />
        </div>
        <div>
          <div className="s30 w700 lh38 self_center margin_top_50 margin_bottom_20 center">
            Perfetto!
          </div>
          <div className="s18 w400 lh26 margin_top_20 margin_bottom_20 center self_center">
            Stai per accedere alla tua area riservata!
          </div>
        </div>
        <div>
          <LoadingSpinner />
        </div>
      </div>
    </div>
  )
}

export default SurveyASuccess
