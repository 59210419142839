interface Props {
  image: string,
  text: string,
  onClick: () => void,
}

const LogoWithText: React.FC<Props> = ({
  image,
  text,
  onClick,
}) => {
  return (
    <div className="vertical_flex_group justify_content_space_between align_items_center width_100_percent height_100_percent">
      <div className="vertical_flex_group justify_content_flex_end height_50_percent">
        <img className="pointer" src={image} onClick={onClick} />
      </div>
      <div className="vertical_flex_group justify_content_flex_start height_45_percent white_text w500 s14 lh20 center">
        {text}
      </div>
    </div>
  )
}

export default LogoWithText
