import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import WhiteButtonBorder from "../../../components/buttons/WhiteButtonBorder";
import "./css/ForgotEmail.css";

const ForgotEmail = () => {

    const navigate = useNavigate();

    const onBackButtonClick = () => {
        navigate("/forgot-password")
    }

    const onWriteToUsClick = () => {
        window.open("mailto:support@holifya.com", "_blank");
    }

    const onToHomepageClick = () => {
        navigate("/login")
    }

    // ********************************************************************************

    return (
        <div className="App">
            <div className='forgot-email-buttons-row'>
                <BackButton onClick={onBackButtonClick} />
            </div>
            <div className="forgot-email-full-container">
                <div className="forgot-email-main-container">
                    <div className="forgot-email-heading">
                        E-mail dimenticata
                    </div>
                    <div className="forgot-email-copy">
                        Se sei sicuro di avere un account attivo ma non ricordi la tua mail di accesso scrivici a <span className="blue_text">support@holifya.com</span>.
                    </div>
                    <div className="forgot-email-copy">
                        Il nostro team cercherà di aiutarti a recuperarla.
                    </div>
                    <div className="forgot-email-write-to-us-button">
                        <PrimaryButton
                            text="Scrivici"
                            pointer={true}
                            disabled={false}
                            onClick={onWriteToUsClick}
                        />
                    </div>
                    <div className="forgot-email-to-homepage-button">
                        <WhiteButtonBorder
                            text="Vai alla Homepage"
                            onClick={onToHomepageClick}
                            pointer={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotEmail
