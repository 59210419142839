import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../../components/BackButton";
import DeleteFileDialog from "../../../../components/DeleteFileDialog";
import HorizontalLogoText from "../../../../components/HorizontalLogoText";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import UploadFileSuccessDialog from "../../../../components/UploadFileSuccessDialog";
import UploadedExam from "../../../../components/UploadedExam";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET, uploadedExamsCriticalNumber } from "../../../../utils/Constants";
import { handleExtensionFromType, handleFolderFromChoice } from "../../../../utils/Functions";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { ExamsAndTestNavigation } from "../../../../utils/enums/ExamsAndTestNavigation";
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem";
import MenuPopup from "../../components/MenuPopup";
import DropCard from "./components/DropCard";
import DropCardMobile from "./components/DropCardMobile";
import TestExamCard from "./components/TestExamCard";
import UploadFileDialog from "./components/UploadFileDialog";
import UploadFileDialogMobile from "./components/UploadFileDialogMobile";
import "./css/ExamsAndTestsSpecificExamsPage.css";

const ExamsAndTestsSpecificExamsPage = () => {
    const navigate = useNavigate();

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const { userData } = useUserData()
    const username = userData.name!
    const familyName = userData.familyName!
    const email = userData.email!

    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
        callGetAllPdfAPI(handleFolderFromChoice(uploadDialogRadioChoice))
        handleTitleAndLogo()
    }, [])

    // *******************************************************************************************************************

    // navigation variable
    const { examType } = useParams()

    // Boolean to show the upload dialog
    const [showUploadDialog, setShowUploadDialog] = useState(false)
    // Boolean to show the upload success dialog
    const [showUploadSuccessDialog, setShowUploadSuccessDialog] = useState(false)
    // Boolean to show the delete exam dialog
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    // When the user click on the link to upload a file
    const onDropFileClick = () => {
        setShowUploadDialog(true)
    }

    // Choice for the exam type, when uploading
    var originalChoice = ""
    switch (examType) {
        case ExamsAndTestNavigation.Blood: {
            originalChoice = "Esami del sangue"
            break;
        }
        case ExamsAndTestNavigation.Other: {
            originalChoice = "Altri esami"
            break;
        }
        case ExamsAndTestNavigation.Dna: {
            originalChoice = "Test del DNA"
            break;
        }
    }
    const [uploadDialogRadioChoice, setUploadDialogRadioChoice] = useState(originalChoice)

    // When user dismisses the upload dialog
    const onDismissUploadDialogButtonClick = () => {
        setShowUploadDialog(false)
        resetAnswers()
    }

    // States for elements automatically detected while uploading
    const [uploadedFileName, setUploadedFileName] = useState("")
    const [uploadedFileSize, setUploadedFileSize] = useState("")
    const [uploadedFileExtension, setUploadedFileExtension] = useState("")
    const [uploadedFile, setUploadedFile] = useState<File>()

    // Custom filename for the uploaded file
    const [customFilename, setCustomFilename] = useState("")
    // Custom upload date
    const [customDate, setCustomDate] = useState("")

    // Boolean checking if the file has been uploaded and ready to be saved
    const fileUploaded = uploadedFile !== undefined

    // Already uploaded exams, recovered from s3
    const [examsUploaded, setExamsUploaded] = useState<UploadedExam[]>([])

    const [currentFilename, setCurrentFilename] = useState("")

    const [shouldPutAPlaceholder, setShouldPutAPlaceholder] = useState(false)

    // What happens when the file is uploaded
    const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files
        console.log(files)
        const newName = files![0].name
        const newSize = files![0].size.toString()
        const newExtension = handleExtensionFromType(files![0].type)
        setUploadedFileName(newName)
        setUploadedFileSize(newSize)
        setUploadedFile(files![0])
        setUploadedFileExtension(newExtension)
    }

    // When the users removes the uploaded file from the dialog
    const onUploadedFileRemove = () => {
        setUploadedFile(undefined)
        setCustomFilename("")
        setCustomDate("")
    }

    // When the user confirms the upload
    const onConfirmUploadButtonClick = () => {
        callGetUrlApi(handleFolderFromChoice(uploadDialogRadioChoice))
    }

    // When the user clicks on undo from the upload dialog
    const onUndoUploadClick = () => {
        setShowUploadDialog(false)
        resetAnswers()
    }

    // Resets all the custom infos
    const resetAnswers = () => {
        setUploadedFile(undefined)
        setUploadDialogRadioChoice(originalChoice)
        setCustomFilename("")
        setCustomDate("")
    }

    const onUploadSuccessDismissButtonClick = () => {
        setShowUploadSuccessDialog(false)
        resetAnswers()
    }

    const onDeleteCardClick = (filename: string) => {
        setShowDeleteDialog(true)
        setCurrentFilename(filename)
    }

    const onConfirmDeleteExam = () => {
        var folder = ""
        switch (examType) {
            case "blood-tests": {
                folder = "blood_exam"
                break;
            }
            case "other-tests": {
                folder = "other_exam"
                break;
            }
        }
        callDeletePdfAPI(folder, currentFilename)
    }

    const onDismissDeleteExamDialog = () => {
        setShowDeleteDialog(false)
        setCurrentFilename("")
    }

    const [pageTitle, setPageTitle] = useState("")
    const [pageLogo, setPageLogo] = useState("")

    const handleTitleAndLogo = () => {
        switch (examType) {
            case ExamsAndTestNavigation.Dna: {
                setPageTitle("Test del DNA")
                setPageLogo("/images/dna-test-big-icon.svg")
                break;
            }
            case ExamsAndTestNavigation.Blood: {
                setPageTitle("Esami del sangue")
                setPageLogo("/images/blood-exams-big-icon.svg")
                break;
            }
            case ExamsAndTestNavigation.Other: {
                setPageTitle("Altri esami")
                setPageLogo("/images/other-exams-big-icon.svg")
                break;
            }
        }
    }

    // Boolean handling the confirm upload button activation
    const uploadButtonDisabled = !(uploadDialogRadioChoice !== "" && customFilename !== "" && customDate !== "")

    // GET an url to call
    async function callGetUrlApi(type: string) {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/exam-url/${type}/upload?filename=${customFilename}-${customDate}${uploadedFileExtension}`, requestOptionsGET(idToken))
        if (response.ok) {
            const urlResponse = await response.json()
            console.log(urlResponse)
            const formData = new FormData()
            Object.entries(urlResponse.fields).forEach(([k, v]) => {
                formData.append(k, v as unknown as string)
            })
            formData.append('file', uploadedFile!)
            const s3response = await axios.post(urlResponse.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setIsLoading(false)
            if (s3response.status === 204) {
                console.log("File uploaded successfully")
                setShowUploadDialog(false)
                setShowUploadSuccessDialog(true)
                callGetAllPdfAPI(type)
            } else {
                console.log("File not uploaded")
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // DELETE a PDF
    async function callDeletePdfAPI(type: string, filename: string) {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/exam-url/${type}/${filename}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
        })
        setIsLoading(false)
        if (!response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
        } else {
            callGetAllPdfAPI(type)
            setShowDeleteDialog(false)
        }
    }

    // GET a response to with all the urls
    async function callGetAllPdfAPI(type: string) {
        console.log(type)
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/exams-urls/${type}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            if (uploadedExamsCriticalNumber.some(number => responseJson.length === number)) {
                setShouldPutAPlaceholder(true)
            } else {
                setShouldPutAPlaceholder(false)
            }
            const exams: UploadedExam[] = []
            for (const exam of responseJson) {
                const url = exam.url as string
                const uploadedAt = exam.uploaded_at as string
                const filename = exam.filename as string
                const newExam: UploadedExam = { url, uploadedAt, filename }
                exams.push(newExam)
            }
            setExamsUploaded(exams)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
            // console.log(responseQuizTaken)
        }
    }

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container relative ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        currentPage={PlatformMenuItem.ExamsAndTests}
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-exams-and-tests-specific-exam-full-container ${(focusOnMenu || showUploadDialog || showUploadSuccessDialog || showDeleteDialog) ? "visible_30_percent inactive" : ""}`}>
                    <div className="platform-exams-and-tests-specific-exam-heading-row">
                        <BackButton
                            onClick={() => navigate("/platform/exams-and-tests")}
                        />
                        <HorizontalLogoText
                            image={pageLogo}
                            text={pageTitle}
                            isInOwnView={true}
                        />
                    </div>
                    <div className="platform-exams-and-tests-specific-exam-content-container">
                        <div className="platform-final-element-container">
                            <div className="height_20" />
                            <div className={`platform-exams-and-tests-specific-exam-cards-container`}>
                                {
                                    examType !== ExamsAndTestNavigation.Dna ?
                                        <DropCard
                                            onDropFileClick={onDropFileClick}
                                        /> : null
                                }
                                {
                                    examType !== ExamsAndTestNavigation.Dna ?
                                        <DropCardMobile
                                            onDropFileClick={onDropFileClick}
                                        /> : null
                                }
                                {
                                    examsUploaded.map(exam => (
                                        <TestExamCard
                                            key={exam.url}
                                            image={exam.url + "#toolbar=0"}
                                            uploadedFromUser={examType !== ExamsAndTestNavigation.Dna}
                                            name={username}
                                            onDeleteClick={() => onDeleteCardClick(exam.filename)}
                                            url={exam.url}
                                            date={exam.uploadedAt}
                                            filename={exam.filename}
                                        />
                                    ))
                                }
                                {
                                    shouldPutAPlaceholder ?
                                        <div className="platform-exams-and-tests-specific-exam-placeholder" /> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showUploadDialog ?
                        <UploadFileDialog
                            fileUploaded={fileUploaded}
                            onDismissUploadDialogButtonClick={onDismissUploadDialogButtonClick}
                            uploadDialogRadioChoice={uploadDialogRadioChoice}
                            setUploadDialogRadioChoice={setUploadDialogRadioChoice}
                            onFileInputChange={onFileInputChange}
                            uploadedFilename={uploadedFileName}
                            uploadedFileSize={uploadedFileSize}
                            uploadedFileExtension={uploadedFileExtension}
                            onUploadedFileRemove={onUploadedFileRemove}
                            onConfirmUploadButtonClick={onConfirmUploadButtonClick}
                            onUndoUploadClick={onUndoUploadClick}
                            uploadButtonDisabled={uploadButtonDisabled}
                            customFilename={customFilename}
                            setCustomFilename={(e) => setCustomFilename(e.target.value)}
                            customDate={customDate}
                            setCustomDate={(e) => setCustomDate(e.target.value)}
                        />
                        : null
                }
                {
                    showUploadDialog ?
                        <UploadFileDialogMobile
                            fileUploaded={fileUploaded}
                            onDismissUploadDialogButtonClick={onDismissUploadDialogButtonClick}
                            uploadDialogRadioChoice={uploadDialogRadioChoice}
                            setUploadDialogRadioChoice={setUploadDialogRadioChoice}
                            onFileInputChange={onFileInputChange}
                            uploadedFilename={uploadedFileName}
                            uploadedFileSize={uploadedFileSize}
                            uploadedFileExtension={uploadedFileExtension}
                            onUploadedFileRemove={onUploadedFileRemove}
                            onConfirmUploadButtonClick={onConfirmUploadButtonClick}
                            onUndoUploadClick={onUndoUploadClick}
                            uploadButtonDisabled={uploadButtonDisabled}
                            customFilename={customFilename}
                            setCustomFilename={(e) => setCustomFilename(e.target.value)}
                            customDate={customDate}
                            setCustomDate={(e) => setCustomDate(e.target.value)}
                        />
                        : null
                }
                {
                    showUploadSuccessDialog ?
                        <UploadFileSuccessDialog
                            onUploadSuccessDismissButtonClick={onUploadSuccessDismissButtonClick}
                            folder={uploadDialogRadioChoice}
                        />
                        : null
                }
                {
                    showDeleteDialog ?
                        <DeleteFileDialog
                            currentFilename={currentFilename}
                            onConfirmDeleteExam={onConfirmDeleteExam}
                            onDismissDeleteExamDialog={onDismissDeleteExamDialog}
                        /> : null
                }
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default ExamsAndTestsSpecificExamsPage
