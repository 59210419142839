import Expert from "../../../../components/Expert"
import PaywallExpertTagCard from "../../components/PaywallExpertTagCard"
import "./css/MonetizationNutritionistCardWithSkills.css"

interface Props {
  nutritionist: Expert,
  onInfoClick: () => void,
}

/** Nutritionist card with the skill list also in desktop view */
const MonetizationNutritionistCardWithSkills: React.FC<Props> = ({
  nutritionist,
  onInfoClick,
}) => {
  return (
    <div className="monetization-nutritionist-card-with-skills-full-container">
      <div className="monetization-nutritionist-card-with-skills-heading">
        Il tuo Nutrizionista
      </div>
      <div className="monetization-nutritionist-card-with-skills-expert-container">
        <div className="monetization-nutritionist-card-with-skills-expert-internal-container">
          <div className="monetization-nutritionist-card-with-skills-expert-left-element-container">
            <div className="monetization-nutritionist-card-with-skills-image-container">
              <img className="monetization-nutritionist-card-with-skills-image" src={nutritionist.avatar} />
            </div>
          </div>
          <div className="monetization-nutritionist-card-with-skills-expert-right-element-container">
            <div className="monetization-nutritionist-card-with-skills-nutritionist-info-container">
              <div className="monetization-nutritionist-card-with-skills-nutritionist-info-upper-element-container">
                <div>
                  <div className="monetization-nutritionist-card-with-skills-nutritionist-position">{nutritionist.position}</div>
                  <div className="monetization-nutritionist-card-with-skills-nutritionist-name">{nutritionist.name} {nutritionist.familyName}</div>
                </div>
                <div className="monetization-nutritionist-card-with-skills-nutritionist-role">{nutritionist.role}</div>
              </div>
            </div>
            <div className="monetization-nutritionist-card-with-skills-nutritionist-info-skills-container">
              {
                nutritionist.skills.map(skill => (
                  <PaywallExpertTagCard
                    key={nutritionist.skills.indexOf(skill)}
                    skill={skill}
                  />
                ))
              }
            </div>
            <button
              className="monetization-nutritionist-card-with-skills-nutritionist-info-bottom-button"
              onClick={onInfoClick}
            >
              Conosci meglio {nutritionist.name}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MonetizationNutritionistCardWithSkills
