import { useNavigate } from "react-router-dom"
import MenuPopupCategory from "../../../components/MenuPopupCategory"
import { logoutAPI } from "../../../utils/apis/auth-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { PlatformMenuItem } from "../../../utils/enums/PlatformMenuItem"
import "./css/MenuPopup.css"

interface Props {
    onEscapeClick: () => void,
    currentPage?: PlatformMenuItem,
}

const MenuPopup: React.FC<Props> = ({
    onEscapeClick,
    currentPage,
}) => {
    const { logout } = useAuth()
    const { userData } = useUserData()
    const navigate = useNavigate()
    
    const name = userData.name
    const familyName = userData.familyName
    const email = userData.email

    const handleLogout = () => {
        logoutAPI(
            userData.email!,
            (result) => {
                console.log(result)
                navigate("/login")
            },
            (error) => console.log(error),
        )
        logout()
    }

    const handlePersonalDataClick = () => {
        navigate("/platform/personal-data")
        currentPage === PlatformMenuItem.PersonalData && onEscapeClick()
    }

    const handleExamsAndTestsClick = () => {
        navigate("/platform/exams-and-tests")
        currentPage === PlatformMenuItem.ExamsAndTests && onEscapeClick()
    }

    const handleMyAnswersClick = () => {
        navigate("/platform/my-answers")
        currentPage === PlatformMenuItem.MyAnswers && onEscapeClick()
    }

    const handleMyAppointmentsClick = () => {
        navigate("/platform/my-apointments")
        currentPage === PlatformMenuItem.MyAppointments && onEscapeClick()
    }

    const handlePaymentMethodClick = () => {
        navigate("/platform/payment-method")
        currentPage === PlatformMenuItem.PaymentMethod && onEscapeClick()
    }

    const handleMyPurchasesClick = () => {
        navigate("/platform/my-purchases")
        currentPage === PlatformMenuItem.MyPurchases && onEscapeClick()
    }

    // ********************************************************************************

    return (
        <div className="menu-view">
            <div className="menu-popup">
                <div className="menu-popup-internal">
                    <div className="menu-popup-user-info-section">
                        <div className="menu-popup-username">
                            <div>{name} {familyName}</div>
                            <div
                                className="menu-popup-x-button"
                                onClick={onEscapeClick}
                            >
                                <img src="/images/x.svg"/>
                            </div>
                        </div>
                        <div className="menu-popup-email">{email}</div>
                    </div>
                    <div className="menu-popup-category">
                        <div className="menu-popup-title-text">Menu</div>
                        <MenuPopupCategory image={"/images/personal-data-icon.svg"} text="I miei dati personali" onClick={handlePersonalDataClick} />
                        <MenuPopupCategory image={"/images/exams-test-icon.svg"} text="Esami e test" onClick={handleExamsAndTestsClick} />
                        <MenuPopupCategory image={"/images/my-answers-icon.svg"} text="Le mie risposte" onClick={handleMyAnswersClick} />
                        <MenuPopupCategory image={"/images/my-apointments-icon.svg"} text="I miei appuntamenti" onClick={handleMyAppointmentsClick} />
                        <MenuPopupCategory image={"/images/payment-method-icon.svg"} text="Metodo di pagamento" onClick={handlePaymentMethodClick} />
                        <MenuPopupCategory image={"/images/purchases-icon.svg"} text="Acquisti" onClick={handleMyPurchasesClick} />
                    </div>
                    <div className="menu-popup-category">
                        <div className="menu-popup-title-text">Assistenza</div>
                        <MenuPopupCategory image={"/images/frequent-questions-icon.svg"} text="Domande frequenti" onClick={() => window.open("https://www.holifya.com/pages/faqs", "_blank")}/>
                        <MenuPopupCategory image={"/images/write-to-us-icon.svg"} text="Scrivici"/>
                    </div>
                    <div className="menu-popup-category">
                        <div className="menu-popup-title-text">Note legali</div>
                        <MenuPopupCategory image={"/images/t&c-pp-icon.svg"} text="Termini e condizioni"/>
                        <MenuPopupCategory image={"/images/t&c-pp-icon.svg"} text="Privacy policy"/>
                    </div>
                    <div className="menu-popup-logout-container">
                        <MenuPopupCategory image={"/images/exit-icon.svg"} text="Esci" onClick={handleLogout} />
                    </div>
                </div>
            </div>
            <div
                className="menu-popup-blank-screen"
                onClick={onEscapeClick}
            >
            </div>
        </div>
    )
}

export default MenuPopup
