import CloseButton from "../../../../../components/CloseButton"
import "./css/HandleSubDialog.css"

interface Props {
    onEditPaymentMethodClick: () => void,
    onCancelSubClick: () => void,
    onDismissClick: () => void,
}

const HandleSubDialog: React.FC<Props> = ({
    onEditPaymentMethodClick,
    onCancelSubClick,
    onDismissClick,
}) => {
    return (
        <div className="my-purchases-handle-sub-dialog-container center-div">
            <div className="my-purchases-handle-sub-dialog-internal-container">
                <div className="horizontal_flex_group justify_content_space_between align_items_center">
                    <div className="my-purchases-handle-sub-dialog-heading">
                        Gestisci abbonamento
                    </div>
                    <div
                        className="pointer"
                        onClick={onDismissClick}
                    >
                        <img src="/images/x.svg"/>
                    </div>
                </div>
                <div className="my-purchases-handle-sub-dialog-buttons-container">
                    <button
                        className="my-purchases-handle-sub-dialog-update-payment-method-button primary_action_color_background"
                        onClick={onEditPaymentMethodClick}
                    >
                        Modifica metodo di pagamento
                    </button>
                    <button
                        className="my-purchases-handle-sub-dialog-cancel-sub-button"
                        onClick={onCancelSubClick}
                    >
                        Disdici abbonamento
                    </button>
                    <button
                        className="my-purchases-handle-sub-dialog-close-dialog-button"
                        onClick={onDismissClick}
                    >
                        Chiudi
                    </button>
                </div>
            </div>
        </div>
    )
}

export default HandleSubDialog
