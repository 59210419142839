import "./css/DropCardMobile.css"

interface Props {
    onDropFileClick: () => void,
}

/**
 * @returns A box for dropping exams, available in Blood exams and Other exams sections
 */
const DropCardMobile: React.FC<Props> = ({
    onDropFileClick,
}) => {
    return (
        <div className="drop-area-container-mobile">
            <div
                className="drop-area-internal-container-mobile"
                onClick={onDropFileClick}
            >
                Carica un file PDF, JPG o JPEG+
            </div>
        </div>
    )
}

export default DropCardMobile
