import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HorizontalLogoText from "../../../../components/HorizontalLogoText";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import UploadFileSuccessDialog from "../../../../components/UploadFileSuccessDialog";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET } from "../../../../utils/Constants";
import { handleExtensionFromType, handleFolderFromChoice } from "../../../../utils/Functions";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import ExamType from "../../../../utils/enums/ExamType";
import { ExamsAndTestNavigation } from "../../../../utils/enums/ExamsAndTestNavigation";
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem";
import TrackingStatus from "../../../../utils/enums/TrackingStatus";
import MenuPopup from "../../components/MenuPopup";
import TestCard from "./components/TestCard";
import UploadFileDialog from "./components/UploadFileDialog";
import UploadFileDialogMobile from "./components/UploadFileDialogMobile";
import "./css/ExamsAndTests.css";

const ExamsAndTests = () => {

    // Navigation parameters
    const navigate = useNavigate();

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const { userData } = useUserData()
    const username = userData.name!
    const familyName = userData.familyName!
    const email = userData.email!

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
        callGetAllPdfAPI(handleFolderFromChoice(ExamType.DNATest))
        callFindTrackerByUserIdAPI()
    }, [])

    // *******************************************************************************************************************

    // Boolean to show the upload dialog
    const [showUploadDialog, setShowUploadDialog] = useState(false)
    // Boolean to show the upload success dialog
    const [showUploadSuccessDialog, setShowUploadSuccessDialog] = useState(false)

    // Choice for the exam type, when uploading
    const [uploadDialogRadioChoice, setUploadDialogRadioChoice] = useState("")

    // When user clicks on the upload button
    const onUploadFileButtonClick = () => {
        setShowUploadDialog(true)
    }

    // When user dismisses the upload dialog
    const onDismissUploadDialogButtonClick = () => {
        setShowUploadDialog(false)
        resetAnswers()
    }

    // States for elements automatically detected while uploading
    const [uploadedFileName, setUploadedFileName] = useState("")
    const [uploadedFileSize, setUploadedFileSize] = useState("")
    const [uploadedFileExtension, setUploadedFileExtension] = useState("")
    const [uploadedFile, setUploadedFile] = useState<File>()

    // Custom filename for the uploaded file
    const [customFilename, setCustomFilename] = useState("")
    // Custom upload date
    const [customDate, setCustomDate] = useState("")

    // Boolean checking if the file has been uploaded and ready to be saved
    const fileUploaded = uploadedFile !== undefined

    // What happens when the file is uploaded
    const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files
        const newName = files![0].name
        const newSize = files![0].size.toString()
        const newExtension = handleExtensionFromType(files![0].type)
        setUploadedFileName(newName)
        setUploadedFileSize(newSize)
        setUploadedFile(files![0])
        setUploadedFileExtension(newExtension)
    }

    // When the users removes the uploaded file from the dialog
    const onUploadedFileRemove = () => {
        setUploadedFile(undefined)
        setCustomFilename("")
        setCustomDate("")
    }

    // When the user confirms the upload
    const onConfirmUploadButtonClick = () => {
        callGetUrlApi(handleFolderFromChoice(uploadDialogRadioChoice))
    }

    // When the user clicks on undo from the upload dialog
    const onUndoUploadClick = () => {
        setShowUploadDialog(false)
        resetAnswers()
    }

    // Resets all the custom infos
    const resetAnswers = () => {
        setUploadedFile(undefined)
        setUploadDialogRadioChoice("")
        setCustomFilename("")
        setCustomDate("")
    }

    // When the user dimisses the success dialog
    const onUploadSuccessDismissButtonClick = () => {
        setShowUploadSuccessDialog(false)
        resetAnswers()
    }

    // Boolean handling the confirm upload button activation
    const uploadButtonDisabled = !(uploadDialogRadioChoice !== "" && customFilename !== "" && customDate !== "")

    // GET an url to call
    async function callGetUrlApi(type: string) {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/exam-url/${type}/upload?filename=${customFilename}-${customDate}${uploadedFileExtension}`, requestOptionsGET(idToken))
        if (response.ok) {
            const urlResponse = await response.json()
            const formData = new FormData()
            Object.entries(urlResponse.fields).forEach(([k, v]) => {
                formData.append(k, v as unknown as string)
            })
            formData.append('file', uploadedFile!)
            const s3response = await axios.post(urlResponse.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setIsLoading(false)
            if (s3response.status === 204) {
                console.log("File uploaded successfully")
                setShowUploadDialog(false)
                setShowUploadSuccessDialog(true)
            } else {
                console.log("File not uploaded")
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET a response to with all the urls
    async function callGetAllPdfAPI(type: string) {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/exams-urls/${type}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (response.ok) {
            const urlsResponse = await response.json()
            if (urlsResponse.length >= 1) {
                setDnaExamAvailable(true)
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
            // console.log(responseQuizTaken)
        }
    }

    // GET tracking by user ID
    async function callFindTrackerByUserIdAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/track/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            if (responseJson.length !== 0) {
                setTrackingStatus(responseJson[0].tracking_status)
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    const onDNATestCardClick = () => {
        dnaExamAvailable ?
            navigate(`/platform/exams-and-tests/${ExamsAndTestNavigation.Dna}`) :
            navigate("/platform/exams-and-tests/collecting")
    }

    const [dnaExamAvailable, setDnaExamAvailable] = useState(false)

    const [trackingStatus, setTrackingStatus] = useState<TrackingStatus>(TrackingStatus.Purchased)

    return (
        <div className="App Quiz justify_content_center relative">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        currentPage={PlatformMenuItem.ExamsAndTests}
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-exams-and-tests-full-container ${(focusOnMenu || showUploadDialog || showUploadSuccessDialog) ? "visible_30_percent inactive" : ""}`}>
                    <div className="height_10_percent width_100_percent">
                        <HorizontalLogoText
                            image={"/images/exams-test-big-logo.svg"}
                            text="Esami e test"
                            isInOwnView={true}
                        />
                    </div>
                    <div className="vertical-scroll-group-90 width_100_percent">
                        <div className="platform-final-element-container">
                            <div className="exams-and-tests-cards-external-container">
                                <div className="exams-and-tests-cards-final-container">
                                    <TestCard
                                        icon="/images/dna-test-icon.svg"
                                        testName="Test del DNA"
                                        onClick={onDNATestCardClick}
                                    />
                                    <TestCard
                                        icon="/images/blood-exams-icon.svg"
                                        testName="Esami del sangue"
                                        onClick={() => navigate(`/platform/exams-and-tests/${ExamsAndTestNavigation.Blood}`)}
                                    />
                                    <TestCard
                                        icon="/images/other-exams-icon.svg"
                                        testName="Altri esami"
                                        onClick={() => navigate(`/platform/exams-and-tests/${ExamsAndTestNavigation.Other}`)}
                                    />
                                </div>
                            </div>
                            <div className="height_35" />
                            <div className="exams-and-tests-drop-box-external-container">
                                <div className="exams-and-tests-drop-box-internal-container">
                                    <div className="exams-and-tests-drop-box-info-alert-container">
                                        <div className="margin_left_20 w700 s20 lh40">Carica Esami</div>
                                        <div className="margin_left_20 width_80_percent w400 s16 lh24">Se hai degli esami che vuoi condividere con il tuo professionista puoi farlo qui!</div>
                                    </div>
                                    <div className="exams-and-tests-drop-box-button-container">
                                        <button
                                            className="pointer width_200 height_55 br5 black_border s14 lh17 w700 uppercase white_background margin_right_20 margin_bottom_20"
                                            onClick={onUploadFileButtonClick}
                                        >
                                            Carica
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="height_35" />
                            {/* <div className="exams-and-tests-documents-and-files-external-container">
                                <div className="exams-and-tests-documents-and-files-internal-container">
                                    <div className="horizontal_flex_group align_items_center w700 s20 lh40 height_30_percent">
                                        Documenti e file dal tuo professionista Holifya
                                    </div>
                                    <div className="exams-and-tests-documents-and-files-card-row">
                                        <DocumentsAndFilesCard date="16/03/2024" testName="Prescrizione esami ematochimici" />
                                        <DocumentsAndFilesCard date="25/02/2024" testName="Prescrizione esami specifici" />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {
                    showUploadDialog ?
                        <UploadFileDialog
                            fileUploaded={fileUploaded}
                            onDismissUploadDialogButtonClick={onDismissUploadDialogButtonClick}
                            uploadDialogRadioChoice={uploadDialogRadioChoice}
                            setUploadDialogRadioChoice={setUploadDialogRadioChoice}
                            onFileInputChange={onFileInputChange}
                            uploadedFilename={uploadedFileName}
                            uploadedFileSize={uploadedFileSize}
                            uploadedFileExtension={uploadedFileExtension}
                            onUploadedFileRemove={onUploadedFileRemove}
                            onConfirmUploadButtonClick={onConfirmUploadButtonClick}
                            onUndoUploadClick={onUndoUploadClick}
                            uploadButtonDisabled={uploadButtonDisabled}
                            customFilename={customFilename}
                            setCustomFilename={(e) => setCustomFilename(e.target.value)}
                            customDate={customDate}
                            setCustomDate={(e) => setCustomDate(e.target.value)}
                        />
                        :
                        null
                }
                {
                    showUploadDialog ?
                        <UploadFileDialogMobile
                            fileUploaded={fileUploaded}
                            onDismissUploadDialogButtonClick={onDismissUploadDialogButtonClick}
                            uploadDialogRadioChoice={uploadDialogRadioChoice}
                            setUploadDialogRadioChoice={setUploadDialogRadioChoice}
                            onFileInputChange={onFileInputChange}
                            uploadedFilename={uploadedFileName}
                            uploadedFileSize={uploadedFileSize}
                            uploadedFileExtension={uploadedFileExtension}
                            onUploadedFileRemove={onUploadedFileRemove}
                            onConfirmUploadButtonClick={onConfirmUploadButtonClick}
                            onUndoUploadClick={onUndoUploadClick}
                            uploadButtonDisabled={uploadButtonDisabled}
                            customFilename={customFilename}
                            setCustomFilename={(e) => setCustomFilename(e.target.value)}
                            customDate={customDate}
                            setCustomDate={(e) => setCustomDate(e.target.value)}
                        />
                        :
                        null
                }
                {
                    showUploadSuccessDialog ?
                        <UploadFileSuccessDialog
                            onUploadSuccessDismissButtonClick={onUploadSuccessDismissButtonClick}
                            folder={uploadDialogRadioChoice}
                        />
                        : null
                }
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default ExamsAndTests
