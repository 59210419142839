import { useLocation } from "react-router-dom"
import CheckoutForm from "./CheckoutForm"

const Checkout = () => {

    const location = useLocation()
    const mode = location.state.mode
    const bundleType = location.state.bundleType

    return (
        <div>
            <CheckoutForm
                mode={mode}
                bundleType={bundleType}
            />
        </div>
    )
}

export default Checkout
