import { Answer } from "./Answer";

export enum NewQuestionType {
    Radio = "radio",
    Multiselect = "multiselect",
    Input = "input",
    BreakPage = "break",
}

export interface NewQuestion {
  id: number,
  question?: string,
  comment?: string,
  type: NewQuestionType,
  answers: Answer[],
  icon?: string,
  blueText?: string,
  blackText?: string,
  copy?: string,
  progressionBarWidth?: string,
  hasSecondary?: boolean,
  isSecondary?: boolean,
}
