import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { StripeEmbeddedCheckoutOptions, loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { BASE_URL, STRIPE_KEY } from "../../../../../utils/Constants";
import PaymentMethod from "../PaymentMethod";
import { useNavigate } from "react-router-dom";
import TempSuccess from "./TempSuccess";

const stripePromise = loadStripe(STRIPE_KEY);

interface Props {
    idToken: string,
    userId: string,
}

const CheckoutForm2: React.FC<Props> = ({
    idToken,
    userId,
}) => {

    useEffect(() => {
        fetchClientSecret()
        if (isComplete) {
            console.log("ora")
        }
    }, [])

    const navigate = useNavigate()

    const [isComplete, setIsComplete] = useState(false)

    async function fetchClientSecret() {
        const response = await fetch(`${BASE_URL}/api/payment/create-checkout-session`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken
            },
            body: JSON.stringify(
                {
                    mode: "setup",
                    user_id: userId,
                }
            )
        })
        const data = await response.json()
        return data.client_secret
    }

    const handleIsComplete = () => setIsComplete(true)
    const options: StripeEmbeddedCheckoutOptions = { fetchClientSecret, onComplete: handleIsComplete };

    return (
        isComplete ? <TempSuccess userId={userId} idToken={idToken}/> :
        <div id="checkout">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    )
}

export default CheckoutForm2