import { ShopifyProduct } from "../../../utils/enums/ShopifyProduct"
import PaywallProductContents from "./PaywallProductContents"
import PaywallProductTag from "./PaywallProductTag"
import "./css/PaywallMultipleChoiceSingleProduct.css"

interface Props {
    isPushed: boolean,
    product: ShopifyProduct,
    onPaymentInfoClick: (product: ShopifyProduct) => void,
    onPurchaseClick: (product: ShopifyProduct) => void,
    testIncluded: boolean,
}

const PaywallMultipleChoiceSingleProduct: React.FC<Props> = ({
    isPushed,
    product,
    onPaymentInfoClick,
    onPurchaseClick,
    testIncluded,
}) => {

    const handleTagName = () => {
        switch (product) {
            case ShopifyProduct.OneMonthBundle:
                return "Piano di 1 mese + DNA Test"
            case ShopifyProduct.OneMonthSub:
                return "Piano di 1 mese"
            case ShopifyProduct.ThreeMonthsBundle:
                return "Piano di 3 mesi + DNA Test"
            case ShopifyProduct.ThreeMonthsSub:
                return "Piano di 3 mesi"
            case ShopifyProduct.SixMonthsBundle:
                return "Piano di 6 mesi + DNA Test"
            case ShopifyProduct.SixMonthsSub:
                return "Piano di 6 mesi"
            default:
                return ""
        }
    }

    const handleProductCurrentPrice = () => {
        switch (product) {
            case ShopifyProduct.OneMonthBundle:
                return <span className="paywall-pushed-element-section-first-price-color">Oggi paghi <span className="w400 s35 paywall-pushed-element-section-first-price-color">419€</span><span className="margin_left_10 pointer"
                    onClick={() => onPaymentInfoClick(product)}
                >
                    <img src="/images/paywall-info-logo.svg" /></span></span>
            case ShopifyProduct.OneMonthSub:
                return <span className="paywall-pushed-element-section-first-price-color"><span className="w400 s30 paywall-pushed-element-section-first-price-color">89€/mese</span></span>
            case ShopifyProduct.ThreeMonthsBundle:
                return <span className="paywall-pushed-element-section-first-price-color">Oggi paghi <span className="w400 s35 paywall-pushed-element-section-first-price-color">487€</span><span className="margin_left_10 pointer"
                onClick={() => onPaymentInfoClick(product)}
            >
                <img src="/images/paywall-info-logo.svg" /></span></span>
            case ShopifyProduct.ThreeMonthsSub:
                return <span className="paywall-pushed-element-section-first-price-color"><span className="w400 s30 paywall-pushed-element-section-first-price-color">79€/mese</span><span className="margin_left_10 pointer"
                onClick={() => onPaymentInfoClick(product)}
            >
                <img src="/images/paywall-info-logo.svg" /></span></span>
            case ShopifyProduct.SixMonthsSub:
                return <span className="paywall-pushed-element-section-first-price-color"><span className="w400 s30 paywall-pushed-element-section-first-price-color">68€/mese</span><span className="margin_left_10 pointer"
                onClick={() => onPaymentInfoClick(product)}
            >
                <img src="/images/paywall-info-logo.svg" /></span></span>
            case ShopifyProduct.SixMonthsBundle:
                return <span className="paywall-pushed-element-section-first-price-color">Oggi paghi <span className="w400 s35 paywall-pushed-element-section-first-price-color">608€</span><span className="margin_left_10 pointer"
                onClick={() => onPaymentInfoClick(product)}
            >
                <img src="/images/paywall-info-logo.svg" /></span></span>
            default:
                return ""
        }
    }

    const handleProductMonthlyPrice = () => {
        switch (product) {
            case ShopifyProduct.OneMonthSub:
                return <div className="invisible">Per un mese di percorso</div>
            case ShopifyProduct.ThreeMonthsSub:
                return "Per 3 mesi di percorso: 237€"
            case ShopifyProduct.SixMonthsSub:
                return "Per 6 mesi di percorso: 408€"
            default:
                return ""
        }
    }

    return (
        <div className={`${isPushed ? "paywall-multiple-choice-single-product-full-container-pushed margin_left_20 margin_right_20" : "paywall-multiple-choice-single-product-full-container"} ${product === ShopifyProduct.ThreeMonthsSub ? "margin_left_20 margin_right_20" : ""}`}>
            <div className={`${isPushed ? "paywall-multiple-choice-single-product-internal-container-pushed" : "paywall-multiple-choice-single-product-internal-container"}`}>
                <div className="paywall-multiple-choice-single-product-tag-container">
                    <PaywallProductTag text={handleTagName()} />
                    {isPushed ? <div className="paywall-multiple-choice-single-product-psuhed-text">Consigliato</div> : null}
                </div>
                <div className="paywall-multiple-choice-single-product-contents-container">
                    <PaywallProductContents
                        product={product}
                        testIncluded={testIncluded}
                    />
                </div>
                <div className="paywall-multiple-choice-single-product-price">
                    <div className="paywall-multiple-choice-single-product-current-price-internal">
                        {/* {
                            testIncluded ?
                                <div className="paywall-multiple-choice-single-product-first-price-margin">
                                    {handleProductCurrentPrice()}
                                </div> :
                                <div>
                                    <div>
                                        {handleProductCurrentPrice()}
                                    </div>
                                    <div>
                                        {handleProductMonthlyPrice()}
                                    </div>
                                </div>
                        }
                        {
                            product !== ShopifyProduct.OneMonthSub ?
                                <div
                                    className={`pointer ${!testIncluded ? "" : "self_end"}`}
                                    onClick={() => onPaymentInfoClick(product)}
                                >
                                    <img src="/images/paywall-info-logo.svg" />
                                </div> : null
                        } */}
                        <div className={`${testIncluded ? "height_100_percent" : ""} horizontal_flex_group paywall-multiple-choice-single-product-current-price`}>
                            <div className={`${testIncluded ? "height_100_percent" : ""} horizontal_flex_group align_items_center`}>{handleProductCurrentPrice()}</div>
                        </div>
                        {
                            !testIncluded ?
                                <div className="paywall-multiple-choice-single-product-monthly-price">{handleProductMonthlyPrice()}</div> : null
                        }
                    </div>
                </div>
                <button
                    className="paywall-multiple-choice-single-product-purchase-button primary_action_color_background"
                    onClick={() => onPurchaseClick(product)}
                >
                    Acquista
                </button>
            </div>
        </div>
    )
}

export default PaywallMultipleChoiceSingleProduct
