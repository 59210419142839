import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Expert from "../../../components/Expert";
import HorizontalLogoText from "../../../components/HorizontalLogoText";
import HorizontalNavBar from "../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../components/LoadingSpinner";
import NavBar from "../../../components/NavBar";
import { BASE_URL, EXPERTS_LOGO_FOCUS, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, NUTRITIONIST_APPOINTMENT_LINK, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, SPECIALIST_APPOINTMENT_LINK, requestOptionsGET } from "../../../utils/Constants";
import { eleonora, elvira, fakeExpert } from "../../../utils/Experts";
import { handleDate, handleDaysDifference, handleExpertRole, handleHoursDifference, handleMinutesDifference } from "../../../utils/Functions";
import { useAuth } from "../../../utils/context/AuthContext";
import { useUserData } from "../../../utils/context/UserDataContext";
import { AppointmentStatus } from "../../../utils/enums/AppointmentStatus";
import { ExpertRole } from "../../../utils/enums/ExpertRole";
import { NavigationToDismissiblePaywall } from "../../../utils/enums/NavigationToDismissiblePaywall";
import { NavigationToQuizQ } from "../../../utils/enums/NavigationToQuizQ";
import { ShopifyItemSku } from "../../../utils/enums/ShopifyItemType";
import TrackingStatus from "../../../utils/enums/TrackingStatus";
import { Appointment } from "../components/Appointment";
import MenuPopup from "../components/MenuPopup";
import "../css/Platform.css";
import BookApointmentDialog from "../dashboard/components/BookApointmentDialog";
import BookConsultancyWidget from "../widgets/BookConsultancyWidget";
import DismissiblePaywallWidget from "../widgets/DismissiblePaywallWidget";
import NextAppointmentWidget from "../widgets/NextAppointmentWidget";
import QuizQWidget from "../widgets/QuizQWidget";
import ExpertCard from "./components/ExpertCard";
import ExpertDetailsDialog from "./components/ExpertDetailsDialog";
import "./css/Experts.css";

const Experts = () => {

    // Navigation parameters
    const navigate = useNavigate();

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const { userData } = useUserData()
    const username = userData.name!
    const familyName = userData.familyName!
    const email = userData.email!

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        // setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        // setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            callAPIRetrieveMedicalUserInfo(responseJson.email)
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        setIsLoading(true)
        callAPIUserInfo()
        callGetTrackingByUserIdAPI()
        callGetOrdersRetrievedByUserIdApi()
        callGetSubscriptionsApi()
        callAPIUserEmr()
        callGetSpecialistApi()
        setTimeout(() => {
            setIsLoading(false)
        }, 750)
    }, [])

    // GET User orders retrieved by user_id
    async function callGetOrdersRetrievedByUserIdApi() {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, requestOptionsGET(idToken))
        // setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            if (responseJson.length !== 0) {
                for (const order of responseJson) {
                    if (order.items[0].type === ShopifyItemSku.NutritionistConsult) {
                        setUserHasNutriConsult(true)
                    }
                }
            } else {
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET user emr by user_id
    async function callAPIUserEmr() {
        // setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/emr/${userId}`, requestOptionsGET(idToken))
        // setIsLoading(false)
        if (responseUserinfo.ok) {
            setQuizQdone(true)
            const responseJson = await responseUserinfo.json()
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    // GET User subscriptions by user_id
    async function callGetSubscriptionsApi() {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/payment/subscription/${userId}`, requestOptionsGET(idToken))
        // setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            if (responseJson.status === "active" || responseJson.status === "trial") {
                setUserhasSubs(true)
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    const [userHasNutriConsult, setUserHasNutriConsult] = useState(false)
    const [userHasSubs, setUserhasSubs] = useState(false)
    const [quizQdone, setQuizQdone] = useState(false)

    // *******************************************************************************************************************

    // API for getting the tracking by the user id
    async function callGetTrackingByUserIdAPI() {
        // setIsLoading(true)
        const responseTracking = await fetch(`${BASE_URL}/api/track/${userId}`, requestOptionsGET(idToken))
        // setIsLoading(false)
        if (responseTracking.ok) {
            const responseTrackingJson = await responseTracking.json()
            if (responseTrackingJson.length !== 0) {
                setTrackingStatus(responseTrackingJson[0].tracking_status)
            }
        } else if (responseTracking.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseTracking.json()
            console.log(responseJson)
        }
    }

    const [trackingStatus, setTrackingStatus] = useState<TrackingStatus>(TrackingStatus.Purchased)

    const [showFullyDetailedScreen, setShowFullyDetailedScreen] = useState(false)
    const [expertToShow, setExpertToShow] = useState(fakeExpert)

    const onExpertsInfoClick = (expert: Expert) => {
        setShowFullyDetailedScreen(true)
        setExpertToShow(expert)
    }

    const onPurchaseClicked = () => {
        window.open(NUTRITIONIST_APPOINTMENT_LINK, "_blank")
    }

    const onSpacialistPurchaseClicked = () => {
        window.open(SPECIALIST_APPOINTMENT_LINK, "_blank")
    }

    // GET medical user info by patient
    async function callAPIRetrieveMedicalUserInfo(email: string) {
        // setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/patient/${userId}`, requestOptionsGET(idToken))
        // setIsLoading(false)
        if (responseUserinfo.ok) {
            const nutritionist = await responseUserinfo.json()
            const newExpert: Expert = {
                id: nutritionist.id,
                position: nutritionist.data.sex === "M" ? "Dott." : (nutritionist.data.sex === "F" ? "Dott.ssa" : "Doctor"),
                name: nutritionist.data.name,
                familyName: nutritionist.data.family_name,
                role: handleExpertRole(nutritionist.role),
                skills: nutritionist.tags,
                summary: nutritionist.data.bio,
                avatar: nutritionist.calendly_data.calendly_user.avatar_url,
                schedulingUrl: nutritionist.calendly_data.calendly_user.scheduling_url,
            }
            setNutritionist(newExpert)
            callAPIRetrieveBookingInfo(email, newExpert)
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    // GET booking info by email
    async function callAPIRetrieveBookingInfo(email: string, nutritionist: Expert) {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/booking/appointments/${email}`, requestOptionsGET(idToken))
        // setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            const appointmentsArray: Appointment[] = []
            for (const appointment of responseJson) {
                appointmentsArray.push({
                    date: appointment.payload.scheduled_event.start_time,
                    editUrl: appointment.payload.reschedule_url,
                    connectUrl: appointment.payload.scheduled_event.location.join_url,
                    remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
                    remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
                    remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
                    expert: appointment.organizer_email === "elvira.pistolesi@holifya.com" ? medicoRefertante : nutritionist,
                    status: appointment.payload.status,
                })
            }
            setAppointments(appointmentsArray.filter(appointement => appointement.status === AppointmentStatus.Active))
            setAllAppointments(appointmentsArray)
        } else if (response.status === 401) {
            navigate("/login")
        } else if (response.status === 404) {
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET medical user info by patient
    async function callGetSpecialistApi() {
        // setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/role/${ExpertRole.Specialist}`, requestOptionsGET(idToken))
        // setIsLoading(false)
        if (responseUserinfo.ok) {
            const medico = await responseUserinfo.json()
            const medicoRefertante = medico[0]
            const newExpert: Expert = {
                id: medicoRefertante.id,
                position: medicoRefertante.data.sex === "M" ? "Dott." : "Dott.ssa",
                name: medicoRefertante.data.name,
                familyName: medicoRefertante.data.family_name,
                role: handleExpertRole(medicoRefertante.role),
                skills: medicoRefertante.tags,
                summary: medicoRefertante.data.bio,
                avatar: medicoRefertante.calendly_data.calendly_user.avatar_url,
                schedulingUrl: medicoRefertante.calendly_data.calendly_user.scheduling_url,
            }
            setMedicoRefertante(newExpert)
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    const [nutritionist, setNutritionist] = useState<Expert>()
    const [medicoRefertante, setMedicoRefertante] = useState<Expert>()

    // This boolean opens a dialog useful to book the next apointment
    const [showBookApointmentDialog, setShowBookApointmentDialog] = useState(false)

    const [appointments, setAppointments] = useState<Appointment[]>([])
    const [allAppointments, setAllAppointments] = useState<Appointment[]>([])

    const isQuizQWidgetVisible = !quizQdone
    const isRewardPageWidgetVisible = quizQdone && !userHasSubs
    const isExpertSectionVisible = nutritionist !== undefined && (userHasSubs || userHasNutriConsult)
    const isNextAppointmentWidgetVisible = appointments.length > 0 && appointments[appointments.length - 1].remainingMinutes >= -30

    // *******************************************************************************************************************

    return (
        <div className="App Quiz justify_content_center relative">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO_FOCUS}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container relative ${isLoading ? "visible-0-percent inactive" : ""}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-experts-full-container relative ${(focusOnMenu || showFullyDetailedScreen || showBookApointmentDialog) ? "visible_30_percent inactive" : ""}`}>
                    <div className="height_10_percent width_100_percent">
                        <HorizontalLogoText
                            image={"/images/experts-big-logo.svg"}
                            text="Professionisti"
                            isInOwnView={true}
                        />
                    </div>
                    <div className="vertical-scroll-group-90 width_100_percent">
                        <div className="platform-final-element-container">
                            <div className="experts-cards-container">
                                {nutritionist !== undefined ?
                                    <ExpertCard
                                        expert={nutritionist}
                                        onInfoClicked={onExpertsInfoClick}
                                        onPurchaseClicked={onPurchaseClicked}
                                    /> : null
                                }
                                <ExpertCard
                                    expert={medicoRefertante !== undefined ? medicoRefertante : elvira}
                                    onInfoClicked={onExpertsInfoClick}
                                    onPurchaseClicked={onSpacialistPurchaseClicked}
                                />
                            </div>
                            {
                                isQuizQWidgetVisible ?
                                    <div>
                                        <div className="height_35" />
                                        <QuizQWidget
                                            onClick={() => navigate("/quiz-q-home", { state: { fromPlatform: true, fromWhere: NavigationToQuizQ.PlatformExperts } })}
                                        />
                                    </div> : null
                            }
                            {
                                isRewardPageWidgetVisible ?
                                    <div>
                                        <div className="height_35" />
                                        <DismissiblePaywallWidget
                                            onClick={() => navigate("/dismissible-paywall-multiple-choice", { state: { fromWhere: NavigationToDismissiblePaywall.PlatformExperts } })}
                                        />
                                    </div> : null
                            }
                            {
                                isExpertSectionVisible ?
                                    isNextAppointmentWidgetVisible ?
                                        <div className="experts-next-apointments">
                                            <div className="w700 s20 lh40">
                                                Prossimi appuntamenti
                                            </div>
                                            {
                                                appointments.map(appointment => (
                                                    appointment.remainingMinutes >= -30 ?
                                                        <div key={appointments.indexOf(appointment)}>
                                                            <div className="height_35" />
                                                            <NextAppointmentWidget
                                                                appointment={appointment}
                                                                isUrgent={appointment.remainingDays <= 1}
                                                            />
                                                        </div> :
                                                        null
                                                ))
                                            }
                                            <div className="height_35"></div>
                                        </div>
                                        :
                                        <div className="experts-next-apointments">
                                            <div className="w700 s20 lh40">
                                                Prenota il tuo prossimo videoconsulto
                                            </div>
                                            <div className="height_20" />
                                            <BookConsultancyWidget
                                                name={nutritionist !== undefined ? nutritionist.name : eleonora.name}
                                                onClick={() => setShowBookApointmentDialog(true)}
                                                isFirst={appointments.length === 0}
                                                lastAppointmentDate={appointments.length !== 0 ? handleDate(appointments[appointments.length - 1].date) : ""}
                                            />
                                        </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                {
                    showFullyDetailedScreen ?
                        <ExpertDetailsDialog
                            onCloseClick={() => setShowFullyDetailedScreen(false)}
                            expert={expertToShow}
                        /> : null
                }
                {
                    showBookApointmentDialog ?
                        <BookApointmentDialog
                            onCloseClick={() => setShowBookApointmentDialog(false)}
                            email={email}
                            onBookClick={() => {
                                setShowBookApointmentDialog(false)
                                window.open(nutritionist?.schedulingUrl, "_blank")
                            }}
                        /> : null
                }
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO_FOCUS}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default Experts
