import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { loginApi } from "../../../utils/apis/auth-service";
import { useAuth } from "../../../utils/context/AuthContext";
import { useRegistrationData } from "../../../utils/context/RegistrationContext";
import "./css/RegistrationSuccess.css";

const RegistrationSuccess = () => {

    const navigate = useNavigate();
    const { login } = useAuth();
    const { registrationData, clearData } = useRegistrationData()
    const email = registrationData.registrationEmail
    const password = registrationData.password

    useEffect(() => {
        email !== null && password !== null && loginApi(
            email,
            password,
            (result) => {
                login(result.idToken, result.userId, result.accessToken);
                clearData();
                navigate("/login-success")
            },
            () => {}
        )
    }, [])

    // ********************************************************************************

    return (
        <div className="App">
            <div className="registration-success-full-container">
                <div className="registration-success-container">
                    <div className="registration-success-heading">
                        Account creato correttamente
                    </div>
                    <div className="registration-success-copy">
                        Stai per essere reindirizzato alla tua Area Personale
                    </div>
                    <LoadingSpinner />
                </div>
            </div>
        </div>
    )
}

export default RegistrationSuccess
