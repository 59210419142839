import "./css/NoPaymentMethodsCard.css"

const NoPaymentMethodsCard = () => {
    return (
        <div className="payment-method-no-payment-methods-external-container">
            <div className="margin-left-3-percent margin-right-2-percent width_30">
                <img src="/images/no-payment-methods-icon.svg" />
            </div>
            <span className="w600 s20 lh26">Nessun metodo di pagamento</span>
        </div>
    )
}

export default NoPaymentMethodsCard
