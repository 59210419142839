interface Props {
    name: string
    isLast: boolean
}

const ExpertsTagCard: React.FC<Props> = ({
    name,
    isLast,
}) => {
  return (
    <div className={`experts-tag-container ${!isLast ? "margin_right_10" : ""}`}>
      {name}
    </div>
  )
}

export default ExpertsTagCard
