import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Expert from "../../../../components/Expert";
import HorizontalLogoText from "../../../../components/HorizontalLogoText";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET } from "../../../../utils/Constants";
import { handleDaysDifference, handleExpertRole, handleHoursDifference, handleMinutesDifference } from "../../../../utils/Functions";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { AppointmentStatus } from "../../../../utils/enums/AppointmentStatus";
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem";
import { Appointment } from "../../components/Appointment";
import MenuPopup from "../../components/MenuPopup";
import NextAppointmentWidget from "../../widgets/NextAppointmentWidget";
import OldAppointmentWidget from "../../widgets/OldAppointmentWidget";
import "./css/MyApointments.css";

const MyApointments = () => {

  // Navigation parameters
  const navigate = useNavigate();

  const { auth } = useAuth()
  const idToken = auth.idToken!
  const userId = auth.userId

  const { userData } = useUserData()
  const username = userData.name!
  const familyName = userData.familyName!
  const email = userData.email!

  // Booleans to display either menu popup or loading spinner
  const [isLoading, setIsLoading] = useState(false)
  const [focusOnMenu, setFocusOnMenu] = useState(false)

  // Actions to perform when clicking on Navbar logos and Holifya icon
  const onHolifyaLogoClick = () => {
    navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
  }
  const onHomeIconClick = () => {
    navigate(RELATIVE_PATH_TO_PLATFORM)
  }
  const onPlanIconClick = () => {
    navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
  }
  const onExpertsIconClick = () => {
    navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
  }
  const onMenuIconClick = () => {
    setFocusOnMenu(!focusOnMenu)
  }

  // GET users info by user_id
  async function callAPIUserInfo() {
    setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
    // setIsLoading(false)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
      callAPIRetrieveMedicalUserInfo(responseJson.email)
    } else if (responseUserinfo.status === 401) {
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  useEffect(() => {
    callAPIUserInfo()
  }, [])

  // GET medical user info by patient
  async function callAPIRetrieveMedicalUserInfo(email: string) {
    // setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/patient/${userId}`, requestOptionsGET(idToken))
    setIsLoading(false)
    if (responseUserinfo.ok) {
      const nutritionist = await responseUserinfo.json()
      console.log(nutritionist)
      const newExpert: Expert = {
        id: nutritionist.id,
        position: nutritionist.data.sex === "M" ? "Dott." : (nutritionist.data.sex === "F" ? "Dott.ssa" : "Doctor"),
        name: nutritionist.data.name,
        familyName: nutritionist.data.family_name,
        role: handleExpertRole(nutritionist.role),
        skills: nutritionist.tags,
        summary: nutritionist.data.bio,
        avatar: nutritionist.calendly_data.calendly_user.avatar_url,
        schedulingUrl: nutritionist.calendly_data.calendly_user.scheduling_url,
      }
      setNutritionist(newExpert)
      callAPIRetrieveBookingInfo(email, newExpert)
    } else if (responseUserinfo.status === 401) {
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  // GET booking info by email
  async function callAPIRetrieveBookingInfo(email: string, nutritionist: Expert) {
    setIsLoading(true)
    const response = await fetch(`${BASE_URL}/api/booking/appointments/${email}`, requestOptionsGET(idToken))
    setIsLoading(false)
    if (response.ok) {
      const responseJson = await response.json()
      console.log(responseJson)
      const appointmentsArray: Appointment[] = []
      for (const appointment of responseJson) {
        appointmentsArray.push({
          date: appointment.payload.scheduled_event.start_time,
          editUrl: appointment.payload.reschedule_url,
          connectUrl: appointment.payload.scheduled_event.location.join_url,
          remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
          remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
          remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
          expert: nutritionist,
          status: appointment.payload.status,
        })
      }
      const reverseAppointments = appointmentsArray.reverse()
      setAppointments(reverseAppointments.filter(appointment => appointment.status === AppointmentStatus.Active))
      setAllAppointments(reverseAppointments)
    } else if (response.status === 401) {
      navigate("/login")
    } else if (response.status === 404) {
    } else {
      const responseJson = await response.json()
      console.log(responseJson)
    }
  }

  const [nutritionist, setNutritionist] = useState<Expert>()

  const [appointments, setAppointments] = useState<Appointment[]>([])
  const [allAppointments, setAllAppointments] = useState<Appointment[]>([])

  // *******************************************************************************************************************
  return (
    <div className="App Quiz justify_content_center">
      <NavBar
        onHolifyaLogoClick={onHolifyaLogoClick}
        homeLogo={HOME_LOGO}
        onHomeIconClick={onHomeIconClick}
        planLogo={PLAN_LOGO}
        onPlanIconClick={onPlanIconClick}
        expertsLogo={EXPERTS_LOGO}
        onExpertsIconClick={onExpertsIconClick}
        menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
        onMenuIconClick={onMenuIconClick}
        isLoading={isLoading}
      />
      <div
        className={`platform-full-screen-no-navbar-container ${isLoading ? "visible-0-percent inactive" : ""}`}
      >
        {
          focusOnMenu &&
          <MenuPopup
            currentPage={PlatformMenuItem.MyAppointments}
            onEscapeClick={onMenuIconClick}
          />
        }
        <div className={`platform-my-apointments-full-container ${focusOnMenu ? "visible_30_percent inactive" : ""}`}>
          <div className="height_10_percent width_100_percent">
            <HorizontalLogoText
              image={"/images/my-apointments-big-icon.svg"}
              text="I miei appuntamenti"
              isInOwnView={true}
            />
          </div>
          <div className="vertical-scroll-group-90 width_100_percent">
            <div className="platform-final-element-container">
              <div className="platform-my-apointments-heading">
                Prossimi appuntamenti
              </div>
              {
                nutritionist !== undefined ?
                  appointments.map(appointment =>
                    appointment.remainingMinutes >= -30 ?
                      <div key={appointments.indexOf(appointment)}>
                        <div className="height_35" />
                        <NextAppointmentWidget
                          appointment={appointment}
                          isUrgent={appointment.remainingDays <= 1}
                        />
                      </div>
                      : null
                  ) : null
              }
              <div className="height_35" />
              <div className="platform-my-apointments-heading">Appuntamenti passati</div>
              {
                nutritionist !== undefined ?
                  appointments.map(appointment =>
                    (appointment.remainingMinutes < -30) ?
                      <div key={appointments.indexOf(appointment)}>
                        <div className="height_35" />
                        <OldAppointmentWidget
                          appointment={appointment}
                        />
                      </div>
                      : null
                  ) : null
              }
            </div>
          </div>
        </div>
      </div>
      {
        isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
      }
      <HorizontalNavBar
        homeLogo={HOME_LOGO}
        onHomeIconClick={onHomeIconClick}
        planLogo={PLAN_LOGO}
        onPlanIconClick={onPlanIconClick}
        expertsLogo={EXPERTS_LOGO}
        onExpertsIconClick={onExpertsIconClick}
        menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
        onMenuIconClick={onMenuIconClick}
      />
    </div>
  )
}

export default MyApointments
