import { NewQuestion, NewQuestionType } from "../pages/surveys/components/NewQuestion";

export const answerAltro = "Altro"

const question1: NewQuestion = {
    id: 1,
    question: "Qual è la tua altezza in cm?",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "cm",
        }
    ],
    progressionBarWidth: "width_8_percent",
}

const question2: NewQuestion = {
    id: 2,
    question: "Quanto pesi in kg?",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "kg",
        }
    ],
    progressionBarWidth: "width_16_percent",
}

const question4: NewQuestion = {
    id: 3,
    question: "Hai allergie alimentari?",
    comment: "(es. crostacei, uova, frutta secca, ecc...)",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "",
            text: "Sì",
        },
        {
            icon: "",
            text: "No",
        },
    ],
    hasSecondary: true,
    progressionBarWidth: "width_24_percent",
}

const question4bis: NewQuestion = {
    id: 3.1,
    question: "Quali allergie?",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "Quali allergie",
        }
    ],
    isSecondary: true,
    progressionBarWidth: "width_24_percent",
}

const question5: NewQuestion = {
    id: 4,
    question: "Hai intolleranze alimentari già diagnosticate?",
    comment: "(es. lattosio, glutine, ecc...)",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "",
            text: "Sì",
        },
        {
            icon: "",
            text: "No",
        },
    ],
    hasSecondary: true,
    progressionBarWidth: "width_32_percent",
}

const question5bis: NewQuestion = {
    id: 4.1,
    question: "Quali Intolleranze?",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "Quali intolleranze",
        }
    ],
    isSecondary: true,
    progressionBarWidth: "width_32_percent",
}

const question6: NewQuestion = {
    id: 5,
    question: "Hai allergie ad alcuni farmaci?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "",
            text: "Sì",
        },
        {
            icon: "",
            text: "No",
        },
    ],
    hasSecondary: true,
    progressionBarWidth: "width_40_percent",
}

const question6bis: NewQuestion = {
    id: 5.1,
    question: "Allergie a quali farmaci?",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "Allergie a quali farmaci?",
        }
    ],
    isSecondary: true,
    progressionBarWidth: "width_40_percent",
}

const question7: NewQuestion = {
    id: 6,
    question: "Assumi farmaci quotidianamente?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "",
            text: "Sì",
        },
        {
            icon: "",
            text: "No",
        },
    ],
    hasSecondary: true,
    progressionBarWidth: "width_48_percent",
}

const question7bis: NewQuestion = {
    id: 6.1,
    question: "Quali farmaci?",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "Quali farmaci?",
        }
    ],
    isSecondary: true,
    progressionBarWidth: "width_48_percent",
}

const question8: NewQuestion = {
    id: 7,
    question: "Assumi integratori?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "",
            text: "Sì",
        },
        {
            icon: "",
            text: "No",
        },
    ],
    hasSecondary: true,
    progressionBarWidth: "width_56_percent",
}

const question8bis: NewQuestion = {
    id: 7.1,
    question: "Quali integratori?",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "Quali integratori?",
        }
    ],
    isSecondary: true,
    progressionBarWidth: "width_56_percent",
}

const question9: NewQuestion = {
    id: 8,
    question: "Fumi?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "",
            text: "Sì",
        },
        {
            icon: "",
            text: "No",
        },
    ],
    progressionBarWidth: "width_64_percent",
}

const question10: NewQuestion = {
    id: 9,
    question: "Quali di queste condizioni manifesti spesso?",
    comment: "Puoi selezionare più risposte",
    type: NewQuestionType.Multiselect,
    answers: [
        {
            icon: "",
            text: "Problemi digestivi / gonfiore",
        },
        {
            icon: "",
            text: "Stitichezza",
        },
        {
            icon: "",
            text: "Diarrea",
        },
        {
            icon: "",
            text: "Gastrite / bruciori di stomaco",
        },
        {
            icon: "",
            text: "Nessuna delle precedenti",
        },
    ],
    progressionBarWidth: "width_72_percent",
}

const question11: NewQuestion = {
    id: 10,
    question: "Hai patologie croniche diagnosticate?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "",
            text: "Sì",
        },
        {
            icon: "",
            text: "No",
        },
    ],
    hasSecondary: true,
    progressionBarWidth: "width_80_percent",
}

const question11bis: NewQuestion = {
    id: 10.1,
    question: "Quali patologie?",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "Quali patologie?",
        }
    ],
    isSecondary: true,
    progressionBarWidth: "width_88_percent",
}

const question12: NewQuestion = {
    id: 11,
    question: "Hai avuto casi in famiglia delle seguenti (o altre) patologie?",
    comment: "Puoi selezionare più risposte",
    type: NewQuestionType.Multiselect,
    answers: [
        {
            icon: "",
            text: "Diabete",
        },
        {
            icon: "",
            text: "Patologie cardiovascolari",
        },
        {
            icon: "",
            text: "Patologie neurodegenerative",
        },
        {
            icon: "",
            text: "Tumori",
        },
        {
            icon: "",
            text: "Nessuna delle precedenti",
        },
        {
            icon: "",
            text: answerAltro,
        },
    ],
    progressionBarWidth: "width_96_percent",
}

const question13: NewQuestion = {
    id: 12,
    question: "Vuoi aggiungere altre informazioni?",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "Altre informazioni...",
        }
    ],
    progressionBarWidth: "width_100_percent",
}

export const question14: NewQuestion = {
    id: 13,
    question: "Seleziona la condizione che più si adatta a te in questo momento",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "",
            text: "Gravidanza",
        },
        {
            icon: "",
            text: "Post-gravidanza",
        },
        {
            icon: "",
            text: "Allattamento",
        },
        {
            icon: "",
            text: "Menopausa",
        },
        {
            icon: "",
            text: "Nessuna delle precedenti",
        },
    ],
    progressionBarWidth: "width_100_percent",
}

export const fakeQuestion: NewQuestion = {
    id: 999,
    question: "This is a fake Question",
    type: NewQuestionType.Radio,
    answers: [],
}

export const surveyAQuestions = [
    question1,
    fakeQuestion,
    question2,
    fakeQuestion,
    question4,
    question4bis,
    question5,
    question5bis,
    question6,
    question6bis,
    question7,
    question7bis,
    question8,
    question8bis,
    question9,
    fakeQuestion,
    question10,
    fakeQuestion,
    question11,
    question11bis,
    question12,
    fakeQuestion,
    question13,
]
