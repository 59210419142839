import { useEffect, useRef } from "react"
import Expert from "../../../components/Expert"
import { ShopifyProduct } from "../../../utils/enums/ShopifyProduct"
import MonetizationNutritionistCardWithSkills from "../cards/internal/MonetizationNutritionistCardWithSkills"
import PaywallMultipleChoiceNutritionistCard from "./PaywallMultipleChoiceNutritionistCard"
import PaywallMultipleChoiceSingleProduct from "./PaywallMultipleChoiceSingleProduct"
import PaywallOtherOptionsSection from "./PaywallOtherOptionsSection"
import "./css/PaywallMultipleChoiceCard.css"

interface Props {
  nutritionist: Expert,
  onPaymentInfoClick: (product: ShopifyProduct) => void,
  onKnowBetterNutritionistButtonClick: () => void,
  testIncluded: boolean,
  onTestIncludedClick: () => void,
  onPurchaseClick: (product: ShopifyProduct) => void,
  onInsertProductCodeClicked: () => void,
}

const PaywallMultipleChoiceCard: React.FC<Props> = ({
  nutritionist,
  onPaymentInfoClick,
  onKnowBetterNutritionistButtonClick,
  testIncluded,
  onTestIncludedClick,
  onPurchaseClick,
  onInsertProductCodeClicked,
}) => {

  const pushedElement = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      pushedElement.current?.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  }, [])

  return (
    <div className="paywall-multiple-choice-card-container">
      <div className="paywall-multiple-choice-card-content-container">
        <div className="paywall-multiple-choice-card-content-internal-container">
          <div className="paywall-multiple-choice-card-nutritionist-container">
            <div className="paywall-multiple-choice-card-nutritionist-heading">
              Il tuo nutrizionista
            </div>
            <PaywallMultipleChoiceNutritionistCard
              nutritionist={nutritionist}
              onKnowBetterNutritionistButtonClick={onKnowBetterNutritionistButtonClick}
            />
          </div>
          <div className="paywall-multiple-choice-card-nutritionist-container-mobile">
            <MonetizationNutritionistCardWithSkills
              nutritionist={nutritionist}
              onInfoClick={onKnowBetterNutritionistButtonClick}
            />
          </div>
          <div className="paywall-multiple-choice-card-cards-container">
            <div ref={pushedElement} className="paywall-multiple-choice-card-cards-heading">
              Scegli la durata del tuo percorso
            </div>
            <div className="mobile-only-paywall">
              <div className="paywall-multiple-choice-card-cards-internal-container">
                <PaywallMultipleChoiceSingleProduct
                  isPushed={false}
                  product={testIncluded ? ShopifyProduct.OneMonthBundle : ShopifyProduct.OneMonthSub}
                  onPaymentInfoClick={onPaymentInfoClick}
                  onPurchaseClick={onPurchaseClick}
                  testIncluded={testIncluded}
                />
                <PaywallMultipleChoiceSingleProduct
                  isPushed={testIncluded ? true : false}
                  product={testIncluded ? ShopifyProduct.ThreeMonthsBundle : ShopifyProduct.ThreeMonthsSub}
                  onPaymentInfoClick={onPaymentInfoClick}
                  onPurchaseClick={onPurchaseClick}
                  testIncluded={testIncluded}
                />
                <PaywallMultipleChoiceSingleProduct
                  isPushed={false}
                  product={testIncluded ? ShopifyProduct.SixMonthsBundle : ShopifyProduct.SixMonthsSub}
                  onPaymentInfoClick={onPaymentInfoClick}
                  onPurchaseClick={onPurchaseClick}
                  testIncluded={testIncluded}
                />
              </div>
            </div>
            <div className="desktop-only-paywall">
              <div className="paywall-multiple-choice-card-cards-internal-container">
                <PaywallMultipleChoiceSingleProduct
                  isPushed={false}
                  product={testIncluded ? ShopifyProduct.OneMonthBundle : ShopifyProduct.OneMonthSub}
                  onPaymentInfoClick={onPaymentInfoClick}
                  onPurchaseClick={onPurchaseClick}
                  testIncluded={testIncluded}
                />
                <PaywallMultipleChoiceSingleProduct
                  isPushed={testIncluded ? true : false}
                  product={testIncluded ? ShopifyProduct.ThreeMonthsBundle : ShopifyProduct.ThreeMonthsSub}
                  onPaymentInfoClick={onPaymentInfoClick}
                  onPurchaseClick={onPurchaseClick}
                  testIncluded={testIncluded}
                />
                <PaywallMultipleChoiceSingleProduct
                  isPushed={false}
                  product={testIncluded ? ShopifyProduct.SixMonthsBundle : ShopifyProduct.SixMonthsSub}
                  onPaymentInfoClick={onPaymentInfoClick}
                  onPurchaseClick={onPurchaseClick}
                  testIncluded={testIncluded}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="paywall-multiple-choice-card-other-options-container">
        <PaywallOtherOptionsSection
          pushedProduct={ShopifyProduct.Choices}
          testIncluded={testIncluded}
          onTestIncludedClick={onTestIncludedClick}
          onInsertProductCodeClicked={onInsertProductCodeClicked}
        />
      </div>
    </div>
  )
}

export default PaywallMultipleChoiceCard
