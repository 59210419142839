import "./css/BookConsultancyWidget.css"

interface Props {
    name: string,
    onClick: () => void,
    isFirst: boolean,
    lastAppointmentDate: string,
}

const BookConsultancyWidget: React.FC<Props> = ({
    name,
    onClick,
    isFirst,
    lastAppointmentDate,
}) => {
    return (
        <div className="book-consultancy-widget-container">
            <div className={`book-consultancy-widget-left-element-container`}>
                <div className={`book-consultancy-widget-left-element-internal-container`}>
                    <div className="book-consultancy-widget-heading">
                        {isFirst ? "Primo videoconsulto con Nutrizionista" : "Prenota il prossimo video consulto"}
                    </div>
                    <div className="book-consultancy-widget-copy">
                        Prenota la tua {isFirst ? "prima" : "prossima"} visita con <span className="w700 experts-blue-text">{name}</span>.<br></br>
                        {isFirst ? "Dopo la visita riceverai il tuo piano e potrai iniziare il tuo percorso!" : `Ultimo appuntamento: ${lastAppointmentDate}`}
                    </div>
                    <button
                        className={`book-consultancy-widget-answer-button-desktop primary_action_color_background`}
                        onClick={onClick}
                    >
                        Prenota subito
                    </button>
                </div>
            </div>
            <div className={`book-consultancy-widget-right-element-container`}>
                <div className="book-consultancy-widget-right-element-internal-container">
                    <button
                        className={`book-consultancy-widget-answer-button-mobile primary_action_color_background`}
                        onClick={onClick}
                    >
                        Prenota subito
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BookConsultancyWidget
