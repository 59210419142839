import CloseButton from "../../../../components/CloseButton"
import "./css/BookApointmentDialog.css"

interface Props {
    onCloseClick: () => void,
    email: string,
    onBookClick: () => void,
}

const BookApointmentDialog: React.FC<Props> = ({
    onCloseClick,
    email,
    onBookClick,
}) => {
    return (
        <div className="book-apointment-dialog-container center-div">
            <div className="book-apointment-dialog-internal-container">
                <div className="book-apointment-dialog-title-close-row">
                    <div className="book-apointment-dialog-heading">Usa lo stesso indirizzo e-mail!</div>
                    <CloseButton onClick={onCloseClick} />
                </div>
                <div className="book-apointment-dialog-copy">
                    Stai per prenotare il tuo video consulto sulla piattaforma Calendly.<br></br>
                    È importante che tu utilizzi la stessa mail con la quale hai effettuato la
                    registrazione su Holifya.
                </div>
                <div className="book-apointment-dialog-email-row">
                    <div className="book-apointment-dialog-email">
                        {email}
                    </div>
                    <div>
                        <div
                            className="horizontal_flex_group pointer"
                            onClick={() => { 
                                alert("Mail copiata negli appunti")
                                navigator.clipboard.writeText(email) }
                            }
                        >
                            <img src="/images/copy-icon.svg" />
                            <div className="book-apointment-dialog-copy-text">Copia</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="book-apointment-dialog-button-copy">
                        Copia la tua mail e prosegui!
                    </div>
                    <button
                        className="book-apointment-dialog-book-button primary_action_color_background"
                        onClick={onBookClick}
                    >
                        Prenota subito
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BookApointmentDialog
