import "./css/OnboardingForkCard.css"

interface Props {
    onClick: () => void,
    copy: string,
}

const OnboardingForkCard: React.FC<Props> = ({
    onClick,
    copy,
}) => {
    return (
        <div
            className="onboarding-fork-card-container"
            onClick={onClick}
        >
            <div className="onboarding-fork-card-internal-container">
                <div className="onboarding-fork-card-copy-container">
                    {copy}
                </div>
            </div>
        </div>
    )
}

export default OnboardingForkCard
