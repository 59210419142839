import { useNavigate } from "react-router-dom"
import HolifyaFullLogo from "../../../components/HolifyaFullLogo"
import "./css/UnderTheAgePage.css"

const UnderTheAgePage = () => {

    const navigate = useNavigate()

    return (
        <div className="App Quiz justify_content_center">
            <div className="under-the-age-page-element-container">
                <div>
                    <HolifyaFullLogo />
                </div>
                <div className="under-the-age-page-copy-container">
                    <div className="under-the-age-page-copy-title">
                        Che peccato! {String.fromCodePoint(0x1F615)}
                    </div>
                    <div className="under-the-age-page-copy">
                        Purtroppo non puoi continuare a compilare questo questionario se sei minorenne. Per legge, abbiamo bisogno del consenso dei tuoi genitori.
                    </div>
                    <div className="under-the-age-page-copy">
                        Scrivi al nostro servizio di assistenza che sarà a tua disposizione per trovare la soluzione più adatta a te!
                    </div>
                </div>
                <div className="under-the-age-page-buttons-container">
                    <button
                        className="under-the-age-page-write-to-us-button primary_action_color_background"
                    >
                        <a
                            href="mailto:support@holifya.com"
                            style={
                                {
                                    textDecoration: "none",
                                    color: "white"
                                }
                            }>
                            Scrivici
                        </a>
                    </button>
                    <button
                        className="under-the-age-page-to-the-homepage-button"
                        onClick={() => window.open("https://www.holifya.com/", "_self")}
                    >
                        Vai alla homepage
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UnderTheAgePage
