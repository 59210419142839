import "./css/SecondaryButton12.css"

interface Props {
  text: string,
  onClick: () => void,
  pointer: boolean,
}

const SecondaryButton12: React.FC<Props> = ({
  text,
  onClick,
  pointer,
}) => {
  return (
    <button
      className={`secondary-button-12 ${pointer ? "pointer" : ""} secondary_action_color_background`}
      onClick={onClick}
    >
      {text}
    </button>
  )
}

export default SecondaryButton12
