import HolifyaProduct from "../components/HolifyaProduct";
import { ConsultStep } from "./enums/ConsultStep";
import TrackingStatus from "./enums/TrackingStatus";

// export const BASE_URL = "https://ayvk7yg741.execute-api.eu-central-1.amazonaws.com";

export const BASE_URL = process.env.REACT_APP_BASE_URL || "https://ayvk7yg741.execute-api.eu-central-1.amazonaws.com";
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || "pk_test_51OvcMNGSmREaeHuCNLRUpxY0PRR4TIuTINaLa8ygKp0bH2lpcazLgZ5FLy7XgVnCiETX4YS5cTGGeXyAtDy0Sxlr00qbGg5iYH";

export const requestOptionsGET = (idToken: string) => ({
    method: 'GET',
    headers: {
        'Authorization': idToken
    }
});

export const PUBLIC_BUCKET = "frontendstack-frontenddeploymentstaticobjectbucket-pcr1urhast1r"

export const PHARMACIES_CONFIRMATION_NUMBER = "FARMA6794LH"

export const NUTRITIONIST_APPOINTMENT_LINK = "https://www.holifya.com/pages/consulto"
export const SPECIALIST_APPOINTMENT_LINK = "https://www.holifya.com/pages/consulto-nutrigenetico"

export const YOUTUBE_VIDEO_TUTORIAL = "https://www.youtube.com/embed/lhD2OlmiETI?si=URjszY5cHx-xstf7"

export const CONFIRMATION_NUMBER_MINIMUM_DIGITS = 8
export const SWAB_CODE_MINIMUM_DIGITS = 12

export const ONE_MONTHS_BUNDLE_PRICE = "419€"
export const THREE_MONTHS_BUNDLE_PRICE = "487€"
export const SIX_MONTHS_BUNDLE_PRICE = "608€"
export const ONE_MONTHS_SUB_PRICE = "89€"
export const THREE_MONTHS_SUB_PRICE = "237€"
export const SIX_MONTHS_SUB_PRICE = "408€"

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.,:;\-_?!$€£&%/()='"*])[^\s]{8,16}$/

export const INVALID_EMAIL_ERROR_TEXT = "Inserisci un indirizzo e-mail valido"

export const SHOW_PASSWORD_ENABLED_ICON = "/images/show-password-enabled.svg"
export const SHOW_PASSWORD_DISABLED_ICON = "/images/show-password-disabled.svg"
export const HIDE_PASSWORD_ENABLED_ICON = "/images/hide-password-enabled.svg"
export const HIDE_PASSWORD_DISABLED_ICON = "/images/hide-password-disabled.svg"

export const trackingStates = [
    TrackingStatus.Purchased,
    TrackingStatus.Delivered,
    TrackingStatus.TestDone,
    TrackingStatus.SampleRegistered,
    TrackingStatus.PickupRequested,
    TrackingStatus.Departed,
    TrackingStatus.DeliveredToLaboratory,
    TrackingStatus.StartingToExtract,
    TrackingStatus.StartingAnalysis,
    TrackingStatus.AnalysisDone,
    TrackingStatus.ReportGenerated,
]

export const consultStates = [
    ConsultStep.DnaOrPurchase,
    ConsultStep.FirstConsultBooked,
    ConsultStep.FirstConsultDone,
    ConsultStep.PlanPublished,
    ConsultStep.SecondConsultDone,
]

export const holifyaDNATest: HolifyaProduct = {
    value: "test",
    name: "Test del DNA Holifya",
    bundleContents: [
        "1 test del DNA Holifya",
        "Accesso alla piattaforma Holifya con possibilità di acquisto consulti e abbonamenti",
    ],
    icon: "/images/product-choice-dna-test.svg",
}

export const holifyaNutritionistConsultancy: HolifyaProduct = {
    value: "nutritionist-consultancy",
    name: "Consulto Nutrizionista",
    bundleContents: [
        "1 consulto con specialista",
        "Accesso alla piattaforma Holifya",
    ],
    icon: "/images/product-choice-consult.svg",
}

export const holifyaSpecialistConsultancy: HolifyaProduct = {
    value: "spaceilist-consultancy",
    name: "Consulto Medico genetista",
    bundleContents: [
        "1 consulto con specialista",
        "Accesso alla piattaforma Holifya",
    ],
    icon: "/images/product-choice-consult.svg",
}

export const holifyaOneMonthSub: HolifyaProduct = {
    value: "one-month-sub",
    name: "Membership 1 mese",
    bundleContents: [
        "1 consulto con il tuo nutrizionista",
        "1 mese di piano nutrizionale personalizzato",
        "Accesso alla piattaforma Holifya ",
    ],
    icon: "/images/bundle-test-sub-icon.svg",
}

export const holifyaOneMonthBundle: HolifyaProduct = {
    value: "one-month-bundle",
    name: "Membership 1 mese + DNA Test",
    bundleContents: [
        "1 test del DNA Holifya",
        "1 consulto con il tuo nutrizionista",
        "1 mese di piano nutrizionale personalizzato",
        "Accesso alla piattaforma Holifya ",
    ],
    icon: "/images/bundle-sub-test-icon.svg",
}

export const holifyaThreeMonthsSub: HolifyaProduct = {
    value: "three-months-sub",
    name: "Membership 3 mesi",
    bundleContents: [
        "3 consulti con il tuo nutrizionista",
        "3 mesi di piano nutrizionale personalizzato",
        "3 aggiornamenti del piano",
        "Accesso alla piattaforma Holifya ",
    ],
    icon: "/images/bundle-test-sub-icon.svg",
}

export const holifyaThreeMonthsBundle: HolifyaProduct = {
    value: "three-months-bundle",
    name: "Membership 3 mesi + DNA Test",
    bundleContents: [
        "1 test del DNA Holifya",
        "3 consulti con il tuo nutrizionista",
        "3 mesi di piano nutrizionale personalizzato (3 aggiornamenti)",
        "Accesso alla piattaforma Holifya ",
    ],
    icon: "/images/bundle-sub-test-icon.svg",
}

export const holifyaSixMonthsSub: HolifyaProduct = {
    value: "six-months-sub",
    name: "Membership 6 mesi",
    bundleContents: [
        "6 consulti con il tuo nutrizionista",
        "6 mesi di piano nutrizionale personalizzato",
        "6 aggiornamenti del piano",
        "Accesso alla piattaforma Holifya ",
    ],
    icon: "/images/bundle-test-sub-icon.svg",
}

export const holifyaSixMonthsBundle: HolifyaProduct = {
    value: "six-months-bundle",
    name: "Membership 6 mesi + DNA Test",
    bundleContents: [
        "1 test del DNA Holifya",
        "6 consulti con il tuo nutrizionista",
        "6 mesi di piano nutrizionale personalizzato (6 aggiornamenti)",
        "Accesso alla piattaforma Holifya ",
    ],
    icon: "/images/bundle-sub-test-icon.svg",
}

export const uploadedExamsCriticalNumber = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37, 40]

export const HOME_LOGO = "/images/home-logo.svg"
export const HOME_LOGO_FOCUS = "/images/home-logo-focus.svg"
export const PLAN_LOGO = "/images/plan-logo.svg"
export const PLAN_LOGO_FOCUS = "/images/plan-logo-focus.svg"
export const EXPERTS_LOGO = "/images/experts-logo.svg"
export const EXPERTS_LOGO_FOCUS = "/images/experts-logo-focus.svg"
export const MENU_LOGO = "/images/menu-logo.svg"
export const MENU_LOGO_FOCUS = "/images/menu-logo-x.svg"

export const RELATIVE_PATH_TO_PLATFORM_HOME = "/platform/home"
export const RELATIVE_PATH_TO_PLATFORM = "/platform"
export const RELATIVE_PATH_TO_PLATFORM_PLAN = "/platform/plan"
export const RELATIVE_PATH_TO_PLATFORM_EXPERTS = "/platform/experts"

export const PAYWALL_PRICE_INFO_ONE_MONTHS_BUNDLE =
    <div>
        <div className="w700">Piano di 1 mese + DNA Test</div>
        <div className="height_20" />
        <div>Il prezzo del Piano di 1 mese + DNA Test è composto da due voci:</div>
        <div className="height_20" />
        <ul>
            <li className="margin_left_20">
                <span className="w700">Piano di 1 mese</span> = 89€
                <ul>
                    <li className="margin_left_20">1 x Videoconsulto con Nutrizionista Holifya selezionato</li>
                    <li className="margin_left_20">1 x Piano alimentare personalizzato</li>
                </ul>
                <div className="height_20" />
            </li>
            <li className="margin_left_20"><span className="w700">Test del DNA</span> = 330€</li>
        </ul>
        <div className="height_20" />
        <div className="w700">Per un totale di 419€.</div>
        <div className="height_20" />
        <div><span className="w700">Nota:</span> una volta terminato il mese, potrai decidere di interrompere il tuo piano, continuare a usufruirne mantenendo la tariffa di 89€/mese o abbassare la tariffa mensile acquistando un piano da 3 o 6 mesi.</div>
    </div>

export const PAYWALL_PRICE_INFO_THREE_MONTHS_BUNDLE =
    <div>
        <div className="w700">Piano di 3 mesi + DNA Test</div>
        <div className="height_20" />
        <div>Il prezzo del Piano di 3 mesi + DNA Test è composto da due voci:</div>
        <div className="height_20" />
        <ul>
            <li className="margin_left_20">
                <span className="w700">Piano di 3 mesi</span> = 237€ | <span className="italic_text">Corrispondente a <span className="w700">79€/mese</span> x 3 mesi (al posto di 89€/mese)</span>
                <ul>
                    <li className="margin_left_20">3 x Videoconsulti con Nutrizionista Holifya selezionato</li>
                    <li className="margin_left_20">3 x Piano alimentare personalizzato (1 piano + 2 aggiornamenti)</li>
                </ul>
                <div className="height_20" />
            </li>
            <li className="margin_left_20"><span className="w700">Test del DNA</span> = 250€ | <span className="italic_text">Al posto di 330€</span></li>
        </ul>
        <div className="height_20" />
        <div className="w700">Per un totale di 487€.</div>
        <div className="height_20" />
        <div><span className="w700">Nota:</span> una volta terminato il periodo di tre mesi, potrai decidere di interrompere il tuo piano, continuare a usufruirne mantenendo la tariffa agevolata di 79€/mese o abbassare la tariffa mensile acquistando un piano da 6 mesi.</div>
    </div>

export const PAYWALL_PRICE_INFO_THREE_MONTHS_SUB =
<div>
    <div className="w700">Piano di 3 mesi</div>
    <div className="height_20" />
    <div>Il prezzo del Piano di 3 mesi è composto da:</div>
    <div className="height_20" />
    <ul>
        <li className="margin_left_20">
            <span className="w700">Piano di 3 mesi</span> = 237€ | <span className="italic_text">Corrispondente a <span className="w700">79€/mese</span> x 3 mesi (al posto di 89€/mese)</span>
            <ul>
                <li className="margin_left_20">3 x Videoconsulti con Nutrizionista Holifya selezionato</li>
                <li className="margin_left_20">3 x Piano alimentare personalizzato (1 piano + 2 aggiornamenti)</li>
            </ul>
            <div className="height_20" />
        </li>
    </ul>
    <div className="height_20" />
    <div className="w700">Per un totale di 237€.</div>
    <div className="height_20" />
    <div><span className="w700">Nota:</span> una volta terminato il periodo di tre mesi, potrai decidere di interrompere il tuo piano, continuare a usufruirne mantenendo la tariffa agevolata di 79€/mese o abbassare la tariffa mensile acquistando un piano da 6 mesi.</div>
</div>

export const PAYWALL_PRICE_INFO_SIX_MONTHS_BUNDLE =
    <div>
        <div className="w700">Piano di 6 mesi + DNA Test</div>
        <div className="height_20" />
        <div>Il prezzo del Piano di 6 mesi + DNA Test è composto da due voci:</div>
        <div className="height_20" />
        <ul>
            <li className="margin_left_20">
                <span className="w700">Piano di 6 mesi</span> = 408€ | <span className="italic_text">Corrispondente a <span className="w700">68€/mese</span> x 3 mesi (al posto di 89€/mese)</span>
                <ul>
                    <li className="margin_left_20">6 x Videoconsulti con Nutrizionista Holifya selezionato</li>
                    <li className="margin_left_20">6 x Piano alimentare personalizzato (1 piano + 5 aggiornamenti)</li>
                </ul>
                <div className="height_20" />
            </li>
            <li className="margin_left_20"><span className="w700">Test del DNA</span> = 200€ | <span className="italic_text">Al posto di 330€</span></li>
        </ul>
        <div className="height_20" />
        <div className="w700">Per un totale di 608€.</div>
        <div className="height_20" />
        <div><span className="w700">Nota:</span> una volta terminato il periodo di sei mesi, potrai decidere di interrompere il tuo piano o continuare a usufruirne mantenendo la tariffa agevolata di 68€/mese.</div>
    </div>

export const PAYWALL_PRICE_INFO_SIX_MONTHS_SUB =
<div>
    <div className="w700">Piano di 6 mesi</div>
    <div className="height_20" />
    <div>Il prezzo del Piano di 6 mesi è composto da:</div>
    <div className="height_20" />
    <ul>
        <li className="margin_left_20">
            <span className="w700">Piano di 6 mesi</span> = 408€ | <span className="italic_text">Corrispondente a <span className="w700">68€/mese</span> x 6 mesi (al posto di 89€/mese)</span>
            <ul>
                <li className="margin_left_20">6 x Videoconsulti con Nutrizionista Holifya selezionato</li>
                <li className="margin_left_20">6 x Piano alimentare personalizzato (1 piano + 5 aggiornamenti)</li>
            </ul>
            <div className="height_20" />
        </li>
    </ul>
    <div className="height_20" />
    <div className="w700">Per un totale di 408€.</div>
    <div className="height_20" />
    <div><span className="w700">Nota:</span> una volta terminato il periodo di sei mesi, potrai decidere di interrompere il tuo piano o continuare a usufruirne mantenendo la tariffa agevolata di 68€/mese.</div>
</div>