interface Props {
    currentFilename: string,
    onConfirmDeleteExam: () => void,
    onDismissDeleteExamDialog: () => void,
}

const DeleteFileDialog: React.FC<Props> = ({
    currentFilename,
    onConfirmDeleteExam,
    onDismissDeleteExamDialog,
}) => {
    return (
        <div className="delete-file-dialog gray_border drop_shadow white_background br20 absolute center-div">
            <div className="margin-left-7-percent w700 s20 lh40 self_start">Elimina file</div>
            <div className="margin-left-7-percent w400 s16 lh40 self_start">Vuoi veramente eliminare <span className="w700">{currentFilename}</span>?</div>
            <button className="width_86_percent height_20_percent br5 white_text error-color-background no_border w700 s14 lh17 uppercase pointer" onClick={onConfirmDeleteExam}>Si, elimina definitivamente</button>
            <button className="width_86_percent height_20_percent br5 white_background black_border w700 s14 lh17 uppercase pointer" onClick={onDismissDeleteExamDialog}>Annulla</button>
        </div>
    )
}

export default DeleteFileDialog
