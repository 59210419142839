import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseButton from "../../../../components/CloseButton";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET } from "../../../../utils/Constants";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { PickupChoice } from "../../../../utils/enums/PickupChoice";
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem";
import TrackingStatus from "../../../../utils/enums/TrackingStatus";
import MenuPopup from "../../components/MenuPopup";
import RadioAnswerCardPickup from "./components/RadioAnswerCardPickup";
import WarningBox from "./components/WarningBox";
import "./css/ExamsAndTestsCollectingRequestPickup.css";

const ExamsAndTestsCollectingRequestPickup = () => {

    const navigate = useNavigate();

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const { userData } = useUserData()
    const username = userData.name!
    const familyName = userData.familyName!
    const email = userData.email!

    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
        callFindTrackerByUserIdAPI()
    }, [])

    // *******************************************************************************************************************

    // This state determines the position on the progression bar, and also the direction of the links
    const [trackingStatus, setTrackingStatus] = useState<TrackingStatus>(TrackingStatus.Purchased)
    const [orderId, setOrderId] = useState("")

    // GET tracking by user ID
    async function callFindTrackerByUserIdAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/track/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            setTrackingStatus(responseJson[0].tracking_status)
            setOrderId(responseJson[0].order_id)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    const [pickupChoice, setPickupChoice] = useState("")

    // UPDATE track item
    async function callUpdateTrackerAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/track/order/${orderId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    tracking_status: TrackingStatus.PickupRequested,
                }
            )
        })
        setIsLoading(false)
        const responseJson = await response.json()
        if (response.ok) {
            console.log(responseJson)
        } else {
            console.log(responseJson)
        }
    }

    const onGoToTNTWebsiteClick = () => {
        switch (pickupChoice) {
            case PickupChoice.AtHome:
                window.open("https://www.tnt.it/booking/Booking.do", "_blank", "noreferrer")
                break;
            case PickupChoice.TNTPoint:
                window.open("https://www.tnt.it/contents/trova-fedex.html", "_blank", "noreferrer")
                break;
            default:
                break;
        }
        callUpdateTrackerAPI()
        navigate("/platform/exams-and-tests/collecting")
    }

    const onPickupCardClick = (choice: string) => {
        setPickupChoice(choice)
        setTimeout(() => {
            warningDiv.current?.scrollIntoView({ behavior: 'smooth' })
        }, 100)
    }

    const warningDiv = useRef<null | HTMLDivElement>(null);

    // *******************************************************************************

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        currentPage={PlatformMenuItem.ExamsAndTests}
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-exams-and-tests-collecting-request-pickup-full-container ${(focusOnMenu) ? "visible_30_percent inactive" : ""}`}>
                    <div className="exams-and-tests-request-pickup-title-button-row">
                        <div className="w700 s30 lh40">
                            Richiedi il ritiro
                        </div>
                        <CloseButton
                            noFlexEnd={true}
                            onClick={() => navigate("/platform/exams-and-tests/collecting", { state: { sampleState: TrackingStatus.SampleRegistered } })}
                        />
                    </div>
                    <div className="vertical-scroll-group-90 width_100_percent">
                        <div className="platform-final-element-container mobile-scroll-100">
                            <div className={`exams-and-tests-request-pickup-content-container`}>
                                <div className="exams-and-tests-request-pickup-content-internal-container">
                                    <div className="">
                                        <div className="exams-and-tests-request-pickup-heading">
                                            Inserisci la lettera di vettura nella tasca trasparente posizionata sul lato della busta del corriere.
                                        </div>
                                        <div>
                                            Ora tutto è pronto! Ti basta selezionare uno dei due metodi di ritiro qui sotto.
                                        </div>
                                    </div>
                                    <div className="height_35" />
                                    <div className="exams-and-tests-request-pickup-heading">
                                        Ritiro a domicilio
                                    </div>
                                    <div>
                                        Se qui sotto selezioni “Ritiro a domicilio” verrai reindirizzato sul sito di FedEx TNT per inserire i tuoi dati di ritiro:
                                        <ul>
                                            <li>- seleziona l'opzione <span className="exams-and-tests-request-pickup-heading">“Mittente convenzionato”</span> e lascia la spunta su <span className="exams-and-tests-request-pickup-heading">“Merce destinata in Italia”</span> e prosegui;</li>
                                            <li>- inserisci i tuoi dati relativi a dove ritirare il tuo campione e prosegui;</li>
                                            <li>- inserisci le tue preferenze su quando ritirare il tuo campione e prosegui;</li>
                                            <li>- inserisci la tua mail e prosegui;</li>
                                            <li>- inserisci le dimensioni e il peso (c.ca 0,5 kg e 15cm x 15cm x 5cm) e prosegui;</li>
                                            <li>- controlla e conferma.</li>
                                        </ul>
                                        Il pacchetto contenente il tuo test del DNA, con la lettera di vettura allegata, verrà ritirato secondo le istruzioni da te fornite e consegnato in pochi giorni al nostro laboratorio.
                                    </div>
                                    <div className="height_35" />
                                    <div className="exams-and-tests-request-pickup-heading">
                                        Consegna in FedEx TNT Point
                                    </div>
                                    <div>
                                        Se qui sotto selezioni “Consegna in FedEx TNT Point”, dopo la conferma verrai reindirizzato al sito di FedEx TNT per trovare il FedEx TNT Point più vicino a te.
                                        Porta il pacchetto contenente il tuo test del DNA, con la lettera di vettura allegata, al FedEx TNT Point che preferisci. La lettera di vettura allegata permetterà al tuo campione di arrivare in pochi giorni al nostro laboratorio.
                                    </div>
                                    <div className="height_35" />
                                    <div className="exams-and-tests-request-pickup-heading italic_text">
                                        Cos'è una lettera di vettura?
                                    </div>
                                    <div className="italic_text">
                                        La lettera di vettura è un foglio allegato ad ogni Kit Holifya, che troverete all'interno del pacchetto di spedizione.
                                    </div>
                                    <div className="italic_text">
                                        La lettera di vettura è essenziale per rispedire il campione al nostro laboratorio e andrà consegnata assieme alla busta TNT.
                                    </div>
                                    <div className="height_35" />
                                    <div className="exams-and-tests-request-pickup-radio-choices-container">
                                        <RadioAnswerCardPickup
                                            answer={PickupChoice.AtHome}
                                            onCardClick={onPickupCardClick}
                                            choice={pickupChoice}
                                        />
                                        <RadioAnswerCardPickup
                                            answer={PickupChoice.TNTPoint}
                                            onCardClick={onPickupCardClick}
                                            choice={pickupChoice}
                                        />
                                    </div>
                                    <div className="height_35" />
                                    {
                                        pickupChoice !== "" ?
                                            <div ref={warningDiv}>
                                                <WarningBox pickupChoice={pickupChoice} />
                                                <div className="height_35" />
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="exams-and-tests-request-pickup-buttons-container">
                                <button
                                    className={`dna-test-pickup-request-back-button transparent_background s12 w700`}
                                    onClick={() => navigate("/platform/exams-and-tests/collecting/packing")}
                                    type="button"
                                >
                                    <img src="/images/back-arrow.svg" />
                                    <div className="no_decoration_text">
                                        Indietro
                                    </div>
                                </button>
                                <button
                                    className={`dna-test-pickup-request-confirm-button tnt-color-background w700 s14 lh17`}
                                    onClick={onGoToTNTWebsiteClick}
                                    disabled={pickupChoice === ""}
                                >
                                    Conferma
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default ExamsAndTestsCollectingRequestPickup
