import { handleProductTagName } from "../../../../utils/Functions"
import { ShopifyProduct } from "../../../../utils/enums/ShopifyProduct"
import PaywallProductContent from "../../components/PaywallProductContent"
import PaywallProductTag from "../../components/PaywallProductTag"
import "./css/MonetizationBundleCard.css"

interface Props {
    product: ShopifyProduct,
}

/** In a wall page, this card shows the contents of a bundle product */
const MonetizationBundleCard: React.FC<Props> = ({
    product,
}) => {
    return (
        <div className="monetization-bundle-card-full-container">
            <div className="monetization-bundle-card-heading">
                Il tuo percorso
            </div>
            <div className="monetization-bundle-card-content-container">
                <div className="monetization-bundle-card-content-internal-container">
                    <div className="monetization-bundle-card-left-element-container">
                        <div className="monetization-bundle-card-image-container">
                            <img
                                className="monetization-bundle-card-image"
                                src="/images/monetization/bundle-icon.svg"
                            />
                        </div>
                    </div>
                    <div className="monetization-bundle-card-right-element-container">
                        <PaywallProductTag text={handleProductTagName(product)} />
                        <div className="monetization-bundle-card-product-contents-container">
                            <PaywallProductContent
                                text={`Report nutrigenetico`}
                                image={"/images/paywall/paywall-tick.svg"}
                            />
                            <PaywallProductContent
                                text={`Mappa alimentare genetica`}
                                image={"/images/paywall/paywall-tick.svg"}
                            />
                            <PaywallProductContent
                                text={`Nutrizionista dedicato (${product === ShopifyProduct.OneMonthBundle ? "1 consulto" : (product === ShopifyProduct.ThreeMonthsBundle ? "3 consulti" : "6 consulti")})`}
                                image={"/images/paywall/paywall-tick.svg"}
                            />
                            <PaywallProductContent
                                text={`Piano alimentare aggiornato mensilmente (${product === ShopifyProduct.OneMonthBundle ? "x1" : (product === ShopifyProduct.ThreeMonthsBundle ? "x3" : "x6")})`}
                                image={"/images/paywall/paywall-tick.svg"}
                            />
                            <PaywallProductContent
                                text={`Piano alimentare DNA based`}
                                image={"/images/paywall/paywall-tick.svg"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MonetizationBundleCard
