import "./css/MonetizationContinueButtonSection.css"

interface Props {
    onClick: () => void,
}

/** The area with continue button in a monetization page */
const MonetizationContinueButtonSection: React.FC<Props> = ({
    onClick,
}) => {
    return (
        <div className="monetization-continue-button-section-full-container">
            <button
                className="monetization-continue-button primary_action_color_background"
                onClick={onClick}
            >
                Continua
            </button>
        </div>
    )
}

export default MonetizationContinueButtonSection
