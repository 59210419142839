import { NewQuestion, NewQuestionType } from "../pages/surveys/components/NewQuestion"

export const quizTQuestion1: NewQuestion = {
    id: 3,
    question: "Qual è la tua data di nascita?",
    comment: "",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "GG / MM / AAAA",
        },
    ],
    progressionBarWidth: "width_50_percent",
}

const quizTQuestion2: NewQuestion = {
    id: 4,
    question: "Qual è il tuo sesso biologico?",
    comment: "",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/quizQ/donna.svg",
            text: "Donna",
        },
        {
            icon: "/images/quizQ/uomo.svg",
            text: "Uomo",
        },
        {
            icon: "/images/quizQ/no-gender.svg",
            text: "Preferisco non rispondere",
        },
    ],
    progressionBarWidth: "width_100_percent",
}

export const quizTQuestions = [quizTQuestion1, quizTQuestion2]