import "./css/RadioAnswerCardPickup.css"

interface Props {
    answer: string,
    onCardClick: (text: string) => void,
    choice: string,
}

const RadioAnswerCardPickup: React.FC<Props> = ({
    answer,
    onCardClick,
    choice,
}) => {
    return (
        <div
            className={`${(choice !== answer || choice === "") ? "radio-answer-card-pickup-container" : ("radio-answer-card-pickup-container-filled")}`}
            onClick={() => onCardClick(answer)}
        >
            <div className="radio-answer-card-pickup-internal-container">
            <div>
                <div
                    className="horizontal_flex_group justify_content_center align_items_center radio-answer-card-pickup-external-circle"
                >
                    <div className={`radio_internal_element ${choice === answer ? "" : "invisible"}`}>

                    </div>
                </div>
            </div>
            <div className="w600 s20 lh26">
                {answer}
            </div>
            </div>
        </div>
    )
}

export default RadioAnswerCardPickup
