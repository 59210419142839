/**
 * All possible ways to reach the dismissible paywall
 */
export enum NavigationToDismissiblePaywall {
    /** From the dashboard / homepage */
    Platform = "platform",
    /** From the plan section of the platform */
    PlatformPlan = "platform-plan",
    /** From the experts section of the platform */
    PlatformExperts = "platform-experts",
}