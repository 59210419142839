import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HorizontalLogoText from "../../../../components/HorizontalLogoText";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import Survey from "../../../../components/Survey";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET } from "../../../../utils/Constants";
import { handleSurveyOrderPosition } from "../../../../utils/Functions";
import { SurveyResponse } from "../../../../utils/api-objects/SurveyResponse";
import { User } from "../../../../utils/api-objects/User";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem";
import { QuizType } from "../../../../utils/enums/QuizType";
import MenuPopup from "../../components/MenuPopup";
import MyAnswersSurveyCard from "./components/MyAnswersSurveyCard";
import "./css/MyAnswers.css";

const MyAnswers = () => {

    // Navigation parameters
    const navigate = useNavigate();

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const { userData } = useUserData()
    const username = userData.name!
    const familyName = userData.familyName!
    const email = userData.email!

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(true)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Surveys already answered by the user
    const [surveysCompleted, setSurveysCompleted] = useState<Survey[]>([])

    // Placeholder-wise
    const [isSurveyAMissing, setIsSurveyAMissing] = useState(false)
    const [isSurveyBMissing, setIsSurveyBMissing] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    const onSurveyCardClick = (type: QuizType) => {
        navigate(`/platform/my-answers/${type}`, { state: { answerChanged: false } })
    }

    // *******************************************************************************************************************

    // GET users info by user_id
    async function callAPIUserInfo() {
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        if (responseUserinfo.ok) {
            const responseJson: User = await responseUserinfo.json()
            callAPIAllSurveysRecovery()
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    // GET all surveys survey by user_id
    async function callAPIAllSurveysRecovery() {
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/surveys`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (response.ok) {
            const responseJson: SurveyResponse[] = await response.json()
            const surveys: Survey[] = []
            for (const survey of responseJson) {
                surveys.push(
                    {
                        type: survey.type,
                        qAndAs: survey.data,
                        createdAt: survey.created_at,
                        orderPosition: handleSurveyOrderPosition(survey.type),
                    }
                )
            }
            surveys.sort((a: Survey, b: Survey) => a.orderPosition - b.orderPosition)
            setSurveysCompleted(surveys)
            if (surveys.length < 3) {
                setIsSurveyBMissing(true)
            }
            if (surveys.length < 2) {
                setIsSurveyAMissing(true)
            }
        } else if (response.status === 401) {
            navigate("/login")
        } else {
        }
    }

    useEffect(() => {
        callAPIUserInfo()
    }, [])

    // ********************************************************************************

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible-0-percent inactive" : ""}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        currentPage={PlatformMenuItem.MyAnswers}
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-my-answers-full-container ${focusOnMenu ? "visible_30_percent inactive" : ""}`}>
                    <div className="platform-my-answers-title-row">
                        <HorizontalLogoText
                            image={"/images/my-answers-big-icon.svg"}
                            text="Le mie risposte"
                            isInOwnView={true}
                        />
                    </div>
                    <div className="platform-my-answers-content-container">
                        <div className="platform-final-element-container">
                            <div className="platform-my-answers-copy">
                                Qui trovi tutte le risposte che hai fornito in fase di completamento dei questionari. Puoi modificarle ricercandole tra i vari questionari compilati nel tempo.
                            </div>
                            <div className="platform-my-answers-heading-two">
                                Questionari Iniziali
                            </div>
                            <div className="platform-my-answers-cards-container">
                                {
                                    surveysCompleted.map(survey => (
                                        <MyAnswersSurveyCard
                                            key={survey.type}
                                            onCardClick={onSurveyCardClick}
                                            surveyType={survey.type}
                                            createdAt={survey.createdAt}
                                        />
                                    ))
                                }
                                {
                                    isSurveyAMissing && <div className="platform-my-answers-survey-placeholder" />
                                }
                                {
                                    isSurveyBMissing && <div className="platform-my-answers-survey-placeholder" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading && <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div>
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default MyAnswers
