import Expert from "../../../components/Expert"
import { ShopifyProduct } from "../../../utils/enums/ShopifyProduct"
import MonetizationNutritionistCardWithSkills from "../cards/internal/MonetizationNutritionistCardWithSkills"
import PaywallMultipleChoiceNoTestSingleProduct from "./PaywallMultipleChoiceNoTestSingleProduct"
import PaywallMultipleChoiceNutritionistCard from "./PaywallMultipleChoiceNutritionistCard"
import PaywallOtherOptionsSection from "./PaywallOtherOptionsSection"
import "./css/PaywallMultipleChoiceNoTestCard.css"

interface Props {
    nutritionist: Expert,
    onKnowBetterNutritionistButtonClick: () => void,
    onPaymentInfoClick: (product: ShopifyProduct) => void,
    onInsertProductCodeClick: () => void,
    onPurchaseClick: (product: ShopifyProduct) => void,
}

const PaywallMultipleChoiceNoTestCard: React.FC<Props> = ({
    nutritionist,
    onKnowBetterNutritionistButtonClick,
    onPaymentInfoClick,
    onInsertProductCodeClick,
    onPurchaseClick,
}) => {
    return (
        <div className="paywall-multiple-choice-no-test-card-container">
            <div className="paywall-multiple-choice-no-test-card-content-container">
                <div className="paywall-multiple-choice-no-test-card-content-internal-container">
                    <div className="paywall-multiple-choice-no-test-card-nutritionist-area-container">
                        <div className="paywall-multiple-choice-no-test-card-nutritionist-heading">
                            Il tuo nutrizionista
                        </div>
                        <PaywallMultipleChoiceNutritionistCard
                            nutritionist={nutritionist}
                            onKnowBetterNutritionistButtonClick={onKnowBetterNutritionistButtonClick}
                        />
                    </div>
                    <div className="paywall-multiple-choice-no-test-card-nutritionist-area-container-mobile">
                        <MonetizationNutritionistCardWithSkills
                            nutritionist={nutritionist}
                            onInfoClick={onKnowBetterNutritionistButtonClick}
                        />
                    </div>
                    <div className="paywall-multiple-choice-no-test-card-products-container">
                        <div className="paywall-multiple-choice-no-test-card-products-heading">
                            Scegli la durata del tuo percorso
                        </div>
                        <div className="desktop-only">
                            <div className="paywall-multiple-choice-no-test-card-products">
                                <PaywallMultipleChoiceNoTestSingleProduct
                                    product={ShopifyProduct.OneMonthSub}
                                    onPaymentInfoClick={onPaymentInfoClick}
                                    onPurchaseClick={onPurchaseClick}
                                />
                                <PaywallMultipleChoiceNoTestSingleProduct
                                    product={ShopifyProduct.ThreeMonthsSub}
                                    onPaymentInfoClick={onPaymentInfoClick}
                                    onPurchaseClick={onPurchaseClick}
                                />
                                <PaywallMultipleChoiceNoTestSingleProduct
                                    product={ShopifyProduct.SixMonthsSub}
                                    onPaymentInfoClick={onPaymentInfoClick}
                                    onPurchaseClick={onPurchaseClick}
                                />
                            </div>
                        </div>
                        <div className="mobile-only">
                            <div className="paywall-multiple-choice-no-test-card-products">
                                <PaywallMultipleChoiceNoTestSingleProduct
                                    product={ShopifyProduct.OneMonthSub}
                                    onPaymentInfoClick={onPaymentInfoClick}
                                    onPurchaseClick={onPurchaseClick}
                                />
                                <PaywallMultipleChoiceNoTestSingleProduct
                                    product={ShopifyProduct.ThreeMonthsSub}
                                    onPaymentInfoClick={onPaymentInfoClick}
                                    onPurchaseClick={onPurchaseClick}
                                />
                                <PaywallMultipleChoiceNoTestSingleProduct
                                    product={ShopifyProduct.SixMonthsSub}
                                    onPaymentInfoClick={onPaymentInfoClick}
                                    onPurchaseClick={onPurchaseClick}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="paywall-multiple-choice-no-test-card-other-options-container">
                <PaywallOtherOptionsSection
                    pushedProduct={ShopifyProduct.Choices}
                    onInsertProductCodeClicked={onInsertProductCodeClick}
                    isDismissible={true}
                />
            </div>
        </div>
    )
}

export default PaywallMultipleChoiceNoTestCard
