import { format, parseISO } from 'date-fns';
import Expert from "../components/Expert";
import { Appointment } from "../pages/platform/components/Appointment";
import { ONE_MONTHS_BUNDLE_PRICE, ONE_MONTHS_SUB_PRICE, SIX_MONTHS_BUNDLE_PRICE, SIX_MONTHS_SUB_PRICE, THREE_MONTHS_BUNDLE_PRICE, THREE_MONTHS_SUB_PRICE } from "./Constants";
import { CalendlyExpert } from "./api-objects/CalendlyExpert";
import { BucketFolder } from "./enums/BucketFolder";
import { ConsultStep } from "./enums/ConsultStep";
import { ExpertRole } from "./enums/ExpertRole";
import { ExpertGender, ExpertGenderRole } from "./enums/ExpertsGender";
import { KeyParameter } from "./enums/KeyParameter";
import { QuizType } from "./enums/QuizType";
import { ShopifyItemName, ShopifyItemSku } from "./enums/ShopifyItemType";
import { ShopifyProduct } from "./enums/ShopifyProduct";
import { SubscriptionStatus } from "./enums/SubscriptionStatus";
import { EnergyAnswer, SleepAnswer, StressAnswer, TrainingAnswer, WaterAnswer } from "./enums/SurveysAnswers";
import TrackingStatus from "./enums/TrackingStatus";
import { UserId } from "./enums/UserId";

export const separateStrings = (s: string, separator: string) => {
    const arr = s.split(separator)
    return arr;
}

export const handleDate = (date: string): string => {
    const parsedDate = parseISO(date);
    return format(parsedDate, 'dd/MM/yyyy');
}

export const handleExtensionFromType = (type: string) => {
    switch (type) {
        case "application/pdf":
            return ".pdf"
        case "image/jpg":
            return ".jpg"
        case "image/jpeg":
            return ".jpeg"
        default:
            return "";
    }
}

export const handleFolderFromChoice = (choice: string) => {
    switch (choice) {
        case "Esami del sangue":
            return BucketFolder.BloodExams
        case "Altri esami":
            return BucketFolder.OtherExams
        case "Test del DNA":
            return BucketFolder.DnaExams
        default:
            return ""
    }
}

export const handleSurveyName = (survey: QuizType) => {
    switch (survey) {
        case QuizType.QuizQ:
            return "Welcome survey";
        case QuizType.SurveyA:
            return "Anamnesi clinica";
        case QuizType.SurveyB:
            return "Anamnesi alimentare";
    }
}

export const handleSurveyContents = (survey: QuizType) => {
    switch (survey) {
        case QuizType.QuizQ:
            return "Questo è il primo questionario a cui ti abbiamo sottoposto. Domande ampie su obiettivi e lifestyle per inquadrare il tuo primo passo in Holifya";
        case QuizType.SurveyA:
            return "Questo è il secondo questionario a cui ti abbiamo sottoposto. Domande sulle tue informazioni anamnestiche";
        case QuizType.SurveyB:
            return "Questo è il terzo questionario a cui ti abbiamo sottoposto, disponibile solo per chi acquista un piano nutrizionale. Le tue abitudini di vita / sport / alimentazione";
    }
}

export const handleQuestionId = (id: number) => {
    if (id >= 10) {
        return id + ". "
    } else {
        return "0" + id + ". "
    }
}

export const retrieveQuestionId = (question: string) => {
    const id = question.slice(0, 2)
    const idNumber = parseInt(id, 10)
    return idNumber
}

export const handleExpertRole = (role: string) => {
    switch (role) {
        case ExpertRole.Nutritionist:
            return "Nutrizionista"
        case ExpertRole.Specialist:
            return "Medico Refertante"
        default:
            return ""
    }
}

export const handleMonth = (monthNumber: string) => {
    switch (monthNumber) {
        case "01":
            return "gen"
        case "02":
            return "feb"
        case "03":
            return "mar"
        case "04":
            return "apr"
        case "05":
            return "mag"
        case "06":
            return "giu"
        case "07":
            return "lug"
        case "08":
            return "ago"
        case "09":
            return "set"
        case "10":
            return "ott"
        case "11":
            return "nov"
        case "02":
            return "dic"
        default:
            return "error"
    }
}

export const handleDaySpan = (start: string, end: string) => {
    const startYear = start.substring(0, 4)
    const startMonth = handleStringToNumber(start.substring(5, 7))
    const startDay = handleStringToNumber(start.substring(8, 10))
    const endYear = end.substring(0, 4)
    const endMonth = handleStringToNumber(end.substring(5, 7))
    const endDay = handleStringToNumber(end.substring(8, 10))
    const leepYears = ["2024", "2028", "2032", "2036", "2040", "2044", "2048"]
    const januaryDays = 31
    const februaryDays = leepYears.some(year => startYear === year) ? 29 : 28
    const marchDays = 31
    const aprilDays = 30
    const mayDays = 31
    const juneDays = 30
    const julyDays = 31
    const augustDays = 31
    const septemberDays = 30
    const octoberDays = 31
    const novemberDays = 30
    const decemberDays = 31
    const monthDays = [
        januaryDays,
        januaryDays + februaryDays,
        januaryDays + februaryDays + marchDays,
        januaryDays + februaryDays + marchDays + aprilDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays + juneDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays + juneDays + julyDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays + juneDays + julyDays + augustDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays + juneDays + julyDays + augustDays + septemberDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays + juneDays + julyDays + augustDays + septemberDays + octoberDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays + juneDays + julyDays + augustDays + septemberDays + octoberDays + novemberDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays + juneDays + julyDays + augustDays + septemberDays + octoberDays + novemberDays + decemberDays,
    ]
    if (startYear === endYear) {
        const startDays = monthDays[startMonth - 2] + startDay
        const endDays = monthDays[endMonth - 2] + endDay
        return endDays - startDays
    } else {
        return 0
    }
}

const handleStringToNumber = (number: string) => {
    switch (number) {
        case "01":
            return 1
        case "02":
            return 2
        case "03":
            return 3
        case "04":
            return 4
        case "05":
            return 5
        case "06":
            return 6
        case "07":
            return 7
        case "08":
            return 8
        case "09":
            return 9
        case "10":
            return 10
        case "11":
            return 11
        case "12":
            return 12
        case "13":
            return 13
        case "14":
            return 14
        case "15":
            return 15
        case "16":
            return 16
        case "17":
            return 17
        case "18":
            return 18
        case "19":
            return 19
        case "20":
            return 20
        case "21":
            return 21
        case "22":
            return 22
        case "23":
            return 23
        case "24":
            return 24
        case "25":
            return 25
        case "26":
            return 26
        case "27":
            return 27
        case "28":
            return 28
        case "29":
            return 29
        case "30":
            return 30
        case "31":
            return 31
        default:
            return 0
    }
}

export const handleWaterIntake = (waterIntake: WaterAnswer) => {
    switch (waterIntake) {
        case WaterAnswer.LessOneLiter:
            return "< 1 lt/g"
        case WaterAnswer.OneLiterOneLiterHalf:
            return "1-1,5 lt/g"
        case WaterAnswer.OneLiterHalfTwoLiters:
            return "1,5-2 lt/g"
        case WaterAnswer.MoreTwoLiters:
            return "> 2 lt/g"
    }
}

export const handleEnergy = (energy: EnergyAnswer) => {
    switch (energy) {
        case EnergyAnswer.Active:
            return "Energico"
        case EnergyAnswer.Medium:
            return "A tratti scarico"
        case EnergyAnswer.Low:
            return "Scarico"
    }
}

export const handleSleepQuality = (sleepQuality: SleepAnswer) => {
    switch (sleepQuality) {
        case SleepAnswer.Great:
            return "Ottimo"
        case SleepAnswer.Good:
            return "Regolare"
        case SleepAnswer.NotGood:
            return "Non ottimale"
        case SleepAnswer.Bad:
            return "Scarso"
    }
}

export const handleStressLevel = (stressLevel: StressAnswer) => {
    switch (stressLevel) {
        case StressAnswer.Stressed:
            return "Stressato"
        case StressAnswer.Medium:
            return "A tratti stressato"
        case StressAnswer.NoStress:
            return "Sereno"
    }
}

export const handleTraining = (training: TrainingAnswer) => {
    switch (training) {
        case TrainingAnswer.Everyday:
            return "Ogni giorno"
        case TrainingAnswer.Frequently:
            return "3-4 / settimana"
        case TrainingAnswer.Sometimes:
            return "1-2 / settimana"
        case TrainingAnswer.Never:
            return "Raramente / mai"
    }
}

/**
 * Returns an Expert object to be used in the platform
 * 
 * @param expert coming from get expert API
 */
export const handleExpert = (expert: CalendlyExpert) => {
    const newExpert: Expert = {
        id: expert.id,
        position: expert.data.sex === ExpertGender.Male ? ExpertGenderRole.Dott : ExpertGenderRole.Dottssa,
        name: expert.data.name,
        familyName: expert.data.family_name,
        role: handleExpertRole(expert.role),
        skills: expert.tags,
        summary: expert.data.bio,
        avatar: expert.calendly_data.calendly_user.avatar_url,
        schedulingUrl: expert.calendly_data.calendly_user.scheduling_url,
    }
    return newExpert
}

/**
 * Function giving the days from now to the input date
 * 
 * @param date 
 * @returns number of days rounded
 */
export const handleDaysDifference = (date: string) => {
    const today = new Date()
    const futureDate = new Date(date)
    const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 3600 * 24))
    return differenceInDays
}

/**
 * Function giving the hours from now to the input date
 * 
 * @param date 
 * @returns number of hours rounded
 */
export const handleHoursDifference = (date: string) => {
    const today = new Date()
    const futureDate = new Date(date)
    const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 3600))
    return differenceInDays
}

/**
 * Function giving the minutes from now to the input date
 * 
 * @param date 
 * @returns number of minutes rounded
 */
export const handleMinutesDifference = (date: string) => {
    const today = new Date()
    const futureDate = new Date(date)
    const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 60))
    return differenceInDays
}

/**
 * Function giving the day of the month of the input date
 * 
 * @param date 
 * @returns the number of the day of month
 */
export const handleDayOfMonth = (date: string) => {
    const realDate = new Date(date)
    const day = realDate.getDate()
    return day
}

/**
 * Function giving a 3 letters string of the input date month
 * 
 * @param date 
 * @returns the string representing the month
 */
export const handleMonthString = (date: string) => {
    const realDate = new Date(date)
    const day = realDate.getMonth() + 1
    switch (day) {
        case 1:
            return "gen"
        case 2:
            return "feb"
        case 3:
            return "mar"
        case 4:
            return "apr"
        case 5:
            return "mag"
        case 6:
            return "giu"
        case 7:
            return "lug"
        case 8:
            return "ago"
        case 9:
            return "set"
        case 10:
            return "ott"
        case 11:
            return "nov"
        case 12:
            return "dic"
        default:
            return ""
    }
}

/**
 * Function giving the hour of the input date
 * 
 * @param date 
 * @returns string representing the hout of the input
 */
export const handleHourAndMinutes = (date: string) => {
    const realDate = new Date(date)
    const hour = realDate.getHours()
    const minutes = realDate.getMinutes()
    return `${hour < 10 ? `0${hour.toString()}` : hour}:${minutes < 10 ? `0${minutes.toString()}` : minutes}`
}

export const handleParamenterName = (parameter: KeyParameter) => {
    switch (parameter) {
        case KeyParameter.Water:
            return "Acqua"
        case KeyParameter.Weight:
            return "Peso"
        case KeyParameter.Energy:
            return "Livello di Energia"
        case KeyParameter.Sleep:
            return "Qualità del sonno"
        case KeyParameter.WaistLine:
            return "Circ. vita"
        case KeyParameter.HipsLine:
            return "Circ. fianchi"
        case KeyParameter.ArmCircunference:
            return "Circ. braccio"
        case KeyParameter.Training:
            return "Allenamento"
        case KeyParameter.StressLevel:
            return "Livello di stress"
    }
}

export const handleParamenterImage = (parameter: KeyParameter, energy: string, waterIntake: string, sleepQuality: string, stressLevel: string, trainingAnswer: string) => {
    switch (parameter) {
        case KeyParameter.Energy:
            return handleEnergyImage(energy)
        case KeyParameter.Sleep:
            return handleSleepImage(sleepQuality)
        case KeyParameter.WaistLine:
            return "/images/keyParameters/girovita-icon.svg"
        case KeyParameter.Water:
            return handleWaterImage(waterIntake)
        case KeyParameter.Weight:
            return "/images/keyParameters/weight-icon.svg"
        case KeyParameter.HipsLine:
            return "/images/keyParameters/hips-icon.svg"
        case KeyParameter.ArmCircunference:
            return "/images/keyParameters/arm-icon.svg"
        case KeyParameter.Training:
            return handleTrainingImage(trainingAnswer)
        case KeyParameter.StressLevel:
            return handleStressImage(stressLevel)
    }
}

const handleEnergyImage = (energyAnswer: string) => {
    switch (energyAnswer) {
        case EnergyAnswer.Active:
            return "/images/keyParameters/energy-icon-1.svg"
        case EnergyAnswer.Medium:
            return "/images/keyParameters/energy-icon-2.svg"
        case EnergyAnswer.Low:
            return "/images/keyParameters/energy-icon-3.svg"
        default:
            return ""
    }
}

const handleSleepImage = (sleepAnswer: string) => {
    switch (sleepAnswer) {
        case SleepAnswer.Great:
        case SleepAnswer.Good:
            return "/images/keyParameters/sleep-icon-1.svg"
        case SleepAnswer.NotGood:
        case SleepAnswer.Bad:
            return "/images/keyParameters/sleep-icon-2.svg"
        default:
            return ""
    }
}

const handleWaterImage = (waterAnswer: string) => {
    switch (waterAnswer) {
        case WaterAnswer.LessOneLiter:
            return "/images/keyParameters/water-icon-1.svg"
        case WaterAnswer.OneLiterOneLiterHalf:
            return "/images/keyParameters/water-icon-2.svg"
        case WaterAnswer.OneLiterHalfTwoLiters:
            return "/images/keyParameters/water-icon-3.svg"
        case WaterAnswer.MoreTwoLiters:
            return "/images/keyParameters/water-icon-4.svg"
        default:
            return ""
    }
}

const handleStressImage = (stressAnswer: string) => {
    switch (stressAnswer) {
        case StressAnswer.NoStress:
            return "/images/keyParameters/stress-icon-1.svg"
        case StressAnswer.Medium:
            return "/images/keyParameters/stress-icon-2.svg"
        case StressAnswer.Stressed:
            return "/images/keyParameters/stress-icon-3.svg"
        default:
            return ""
    }
}

const handleTrainingImage = (trainingAnswer: string) => {
    switch (trainingAnswer) {
        case TrainingAnswer.Everyday:
            return "/images/keyParameters/training-icon-1.svg"
        case TrainingAnswer.Frequently:
            return "/images/keyParameters/training-icon-2.svg"
        case TrainingAnswer.Sometimes:
            return "/images/keyParameters/training-icon-3.svg"
        case TrainingAnswer.Never:
            return "/images/keyParameters/training-icon-4.svg"
        default:
            return ""
    }
}

// export const handleParameterValue = (parameter: KeyParameter) => {
//     switch (parameter) {
//         case KeyParameter.Energy:
//             return energy
//         case KeyParameter.Sleep:
//             return sleep
//         case KeyParameter.WaistLine:
//             return waistline + " cm"
//         case KeyParameter.Water:
//             return waterIntake
//         case KeyParameter.Weight:
//             return weight + " kg"
//         case KeyParameter.HipsLine:
//             return ""
//         case KeyParameter.ArmCircunference:
//             return ""
//         case KeyParameter.Training:
//             return ""
//         case KeyParameter.StressLevel:
//             return ""
//     }
// }

export const handleSubscriptionType = (name: ShopifyItemName) => {
    switch (name) {
        case ShopifyItemName.OneMonthSub:
        case ShopifyItemName.OneMonthBundle:
            return "diet_subscription"
        case ShopifyItemName.ThreeMonthsSub:
        case ShopifyItemName.ThreeMonthsBundle:
        case ShopifyItemName.Nutrition:
            return "3_months_subscription"
        case ShopifyItemName.SixMonthsSub:
        case ShopifyItemName.SixMonthsBundle:
            return "6_months_subscription"
        default:
            return ""
    }
}

export const handleSurveyOrderPosition = (type: QuizType) => {
    switch (type) {
        case QuizType.QuizQ:
            return 1
        case QuizType.SurveyA:
            return 2
        case QuizType.SurveyB:
            return 3
    }
}

export const isADnaTest = (sku: ShopifyItemSku, name: ShopifyItemName) => {
    return ((sku === ShopifyItemSku.DnaTest && (name === ShopifyItemName.DnaTest || name === ShopifyItemName.DnaTestOld || name === ShopifyItemName.DnaTestOldBis || name === ShopifyItemName.DNAIntolerance || name === ShopifyItemName.DnaTestComplete)) || sku === ShopifyItemSku.DnaTestOld)
}

export const isAnExpertConsult = (sku: ShopifyItemSku, name: ShopifyItemName) => {
    return sku === ShopifyItemSku.ExpertConsult && name === ShopifyItemName.ExpertConsult
}

export const isANutritionBoost = (sku: ShopifyItemSku, name: ShopifyItemName) => {
    return sku === ShopifyItemSku.DnaTest && name === ShopifyItemName.NutritionBoost
}

export const isANutritionistConsult = (sku: ShopifyItemSku, name: ShopifyItemName) => {
    return sku === ShopifyItemSku.NutritionistConsult && name === ShopifyItemName.NutritionistConsult
}

export const isASubbedUser = (status: SubscriptionStatus) => {
    return status !== SubscriptionStatus.Inactive
}

export const isAShopifySubbedUser = (sku: ShopifyItemSku, name: ShopifyItemName) => {
    return (sku === ShopifyItemSku.Membership && (name === ShopifyItemName.OneMonthSub || name === ShopifyItemName.ThreeMonthsSub || name === ShopifyItemName.SixMonthsSub)) || (sku === ShopifyItemSku.DnaTest && (name === ShopifyItemName.OneMonthBundle || name === ShopifyItemName.ThreeMonthsBundle || name === ShopifyItemName.SixMonthsBundle || name === ShopifyItemName.Nutrition))
}

export const filterNutritionist = (nutritionists: CalendlyExpert[], userId: string) => {
    switch (userId) {
        // Se martina socrate le associamo fabiana
        case UserId.MartinaSocrate:
            return nutritionists.filter(nutritionist => nutritionist.email === "fabiana.nutrizionista@gmail.com")
        default:
            return nutritionists.filter(nutritionist => nutritionist.email !== "matteodellaporta92@gmail.com")
    }
}

export const handleDNATestTrackerWidgetSubtitle = (trackingStatus: TrackingStatus, trackingCode: string, onSubtitleClick: () => void) => {
    switch (trackingStatus) {
        case TrackingStatus.Purchased:
            return <div className="vertical_flex_group justify_content_space_between">
                <div>TNT si sta occupando della consegna del tuo kit.</div>
                <div className="w400">Accedi al tracking di TNT con <span className="pointer primary_action_color_text underline_text" onClick={() => window.open(trackingCode, "_blank")}>questo link</span></div>
            </div>
        case TrackingStatus.Delivered:
            return <span>Il tuo campione è arrivato! <span onClick={onSubtitleClick} className="primary_action_color_text w400 underline_text pointer">Effettua l'autoprelievo adesso</span></span>
        case TrackingStatus.TestDone:
            return <span onClick={onSubtitleClick} className="primary_action_color_text w400 underline_text pointer">Registra il tuo campione adesso!</span>
        case TrackingStatus.SampleRegistered:
            return <span>Decidi come far arrivare il tuo campione in laboratorio. <span onClick={onSubtitleClick} className="primary_action_color_text w400 underline_text pointer">TNT Point o ritiro al domicilio?</span></span>
        case TrackingStatus.PickupRequested:
            return <span>Hai cambiato i tuoi piani? <span onClick={onSubtitleClick} className="primary_action_color_text w400 underline_text pointer">Richiedi un ritiro con informazioni differenti</span></span>
        case TrackingStatus.Departed:
            return <span>Il tuo campione è in viaggio verso il laboratorio.</span>
        case TrackingStatus.DeliveredToLaboratory:
            return <span>Il tuo campione è in laboratorio in fase di accettazione. Questa fase può durare qualche giorno.</span>
        case TrackingStatus.StartingToExtract:
            return <span>La fase di estrazione del DNA è iniziata.</span>
        case TrackingStatus.StartingAnalysis:
            return <span>Il tuo DNA è in analisi. Manca sempre meno! <span onClick={onSubtitleClick} className="primary_action_color_text w400 underline_text pointer">Come avviene l'analisi?</span></span>
        case TrackingStatus.AnalysisDone:
            return "Il tuo risultato è arrivato! Visualizzalo in Home o nell'area “Esami e test” all'interno del menu."
    }
}

export const handlePlanTrackerWidgetSubtitle = (consultancyStatus: ConsultStep, onLinkClick: () => void) => {
    switch (consultancyStatus) {
        case ConsultStep.DnaOrPurchase:
            return <div><span className="underline_text primary_action_color_text pointer" onClick={onLinkClick}>Prenota subito</span> il tuo primo videoconsulto per proseguire nel tuo percorso!</div>
        case ConsultStep.FirstConsultBooked:
            return <div>Stai per partecipare al tuo primo consulto con il tuo Nutrizionista. Il tuo percorso sta per cominciare!</div>
        case ConsultStep.FirstConsultDone:
            return <div>Il tuo piano alimentare è quasi pronto! Potrai trovarlo qui, ma ti avviseremo anche con una notifica alla tua casella e-mail.</div>
        case ConsultStep.PlanPublished:
            return <div>Il tuo piano alimentare è pronto! Lo puoi trovare qui sotto e nella sezione "Piano".</div>
        case ConsultStep.SecondConsultDone:
            return <div></div>
    }
}

export const handleProductPrice = (product: ShopifyProduct) => {
    switch (product) {
        case ShopifyProduct.OneMonthSub:
            return ONE_MONTHS_SUB_PRICE
        case ShopifyProduct.OneMonthBundle:
            return ONE_MONTHS_BUNDLE_PRICE
        case ShopifyProduct.ThreeMonthsSub:
            return THREE_MONTHS_SUB_PRICE
        case ShopifyProduct.ThreeMonthsBundle:
            return THREE_MONTHS_BUNDLE_PRICE
        case ShopifyProduct.SixMonthsSub:
            return SIX_MONTHS_SUB_PRICE
        case ShopifyProduct.SixMonthsBundle:
            return SIX_MONTHS_BUNDLE_PRICE
        default:
            return ""
    }
}

export const handleProductTagName = (product: ShopifyProduct) => {
    switch (product) {
        case ShopifyProduct.OneMonthBundle:
            return "Percorso di 1 mese + DNA Test"
        case ShopifyProduct.ThreeMonthsBundle:
            return "Percorso di 3 mesi + DNA Test"
        case ShopifyProduct.SixMonthsBundle:
            return "Percorso di 6 mesi + DNA Test"
        default:
            return ""
    }
}

export const handleConsultStep = (appointments: Appointment[]) => {
    switch (appointments.length) {
        case 0:
            return ConsultStep.DnaOrPurchase
        case 1: {
            if (appointments[0].remainingMinutes > -30) {
                return ConsultStep.FirstConsultBooked
            } else {
                return ConsultStep.FirstConsultDone
            }
        }
        case 2: {
            if (appointments[1].remainingMinutes > -30) {
                return ConsultStep.FirstConsultDone
            } else if (appointments[1].remainingMinutes > -1440) {
                return ConsultStep.SecondConsultDone
            } else {
                return ConsultStep.SecondConsult24HoursAgo
            }
        }
        default:
            return ConsultStep.SecondConsult24HoursAgo
    }
}
