import "./css/DismissiblePaywallWidget.css"

interface Props {
    onClick: () => void,
}

const DismissiblePaywallWidget: React.FC<Props> = ({
    onClick,
}) => {
    return (
        <div className={`dismissible-paywall-widget-container`}>
            <div className={`dismissible-paywall-widget-left-element-container`}>
                <div className={`dismissible-paywall-widget-left-element-internal-container`}>
                    <div className="dismissible-paywall-widget-heading">Inizia il tuo percorso</div>
                    <div className="dismissible-paywall-widget-copy">
                        Inizia il tuo programma di nutrizione iper personalizzata costruito ad hoc per te, le tue esigenze, e il tuo DNA!
                    </div>
                    <button
                        className={`dismissible-paywall-widget-answer-button`}
                        onClick={onClick}
                    >
                        Acquista ora
                    </button>
                </div>
            </div>
            <div className={`dismissible-paywall-widget-right-element-container`}>
            </div>
        </div>
    )
}

export default DismissiblePaywallWidget
