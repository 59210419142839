import "./css/YourReportWidget.css"

interface Props {
    onClick: () => void,
}

const YourReportWidget: React.FC<Props> = ({
    onClick,
}) => {
    return (
        <div className="your-report-widget-container">
            <div className="your-report-widget-upper-element-container">
                <div className="your-report-widget-upper-element-internal-container">
                    <div className="your-report-widget-upper-element-heading">Test del DNA</div>
                    <div className="your-report-widget-upper-element-copy">
                        Consulta il tuo report nutrigenetico
                    </div>
                </div>
            </div>
            <div className="your-report-widget-bottom-element-container">
                <button
                    className="your-report-widget-bottom-element-button"
                    onClick={onClick}
                >
                    Apri
                </button>
            </div>
        </div>
    )
}

export default YourReportWidget
