import { NewQuestion, NewQuestionType } from "../pages/surveys/components/NewQuestion";

export const answerAltro = "Altro"

const sectionPage1: NewQuestion = {
    id: 0,
    question: "Lifestyle",
    type: NewQuestionType.BreakPage,
    answers: [],
    icon: "/images/lifestyle-section-icon.svg",
    comment: "1",
}

const question1: NewQuestion = {
    id: 1,
    question: "Come descriveresti la qualità del tuo sonno?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/good-sleep.svg",
            text: "Ottima, mi sveglio riposata/o e rigenerata/o ogni giorno",
        },
        {
            icon: "/images/surveyB/good-sleep.svg",
            text: "Buona, di solito mi sveglio riposata/o, ma talvolta ho notato dei disturbi nel sonno",
        },
        {
            icon: "/images/surveyB/bad-sleep.svg",
            text: "Non ottimale, mi sveglio spesso durante la notte o non mi sento completamente riposata/o al mattino",
        },
        {
            icon: "/images/surveyB/bad-sleep.svg",
            text: "Bassa, ho difficoltà a dormire e mi sveglio frequentemente durante la notte, mi sento stanca/o al risveglio",
        },
    ],
    progressionBarWidth: "width_20_percent",
}

const question2: NewQuestion = {
    id: 2,
    question: "Ti senti spesso stanca/o?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/full-battery.svg",
            text: "No, mi sento energica/o e riposata/o la maggior parte del tempo",
        },
        {
            icon: "/images/surveyB/half-battery.svg",
            text: "Occasionalmente, soprattutto quando sono molto impegnata/o o stressata/o",
        },
        {
            icon: "/images/surveyB/low-battery.svg",
            text: "Sì, spesso mi sento stanca/o anche dopo aver dormito a sufficienza",
        },

    ],
    progressionBarWidth: "width_40_percent",
}

const question3: NewQuestion = {
    id: 3,
    question: "Conduci una vita attiva o sedentaria?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/active-life.svg",
            text: "Attiva, faccio regolarmente attività fisica e mi muovo molto durante il giorno",
        },
        {
            icon: "/images/surveyB/half-active-life.svg",
            text: "Moderatamente attiva, faccio un po' di attività fisica, ma potrei essere più costante",
        },
        {
            icon: "/images/surveyB/quiet-life.svg",
            text: "Sedentaria, trascorro la maggior parte del mio tempo seduta/o o inattiva/o",
        },
    ],
    progressionBarWidth: "width_60_percent",
}

const question4: NewQuestion = {
    id: 4,
    question: "Tendi spesso a sentirti stressata/o, triste, nervosa/o, o in uno stato di ansia generale?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/stress-emoji.svg",
            text: "Sì, spesso",
        },
        {
            icon: "/images/surveyB/half-stress-emoji.svg",
            text: "Qualche volta, dipende dal periodo",
        },
        {
            icon: "/images/surveyB/no-stress-emoji.svg",
            text: "Raramente o mai",
        },
    ],
    progressionBarWidth: "width_80_percent",
}

const question5: NewQuestion = {
    id: 5,
    question: "Seleziona le opzioni che più ti rappresentano",
    type: NewQuestionType.Multiselect,
    answers: [
        {
            icon: "/images/surveyB/stressive-work.svg",
            text: "Faccio un lavoro molto stressante",
        },
        {
            icon: "/images/surveyB/lot-of-travels.svg",
            text: "Viaggio spesso",
        },
        {
            icon: "/images/surveyB/no-sleep.svg",
            text: "Dormo meno di 6 ore a notte",
        },
        {
            icon: "/images/surveyB/frequently-tired.svg",
            text: "Mi sento spesso stanca/o e spossata/o",
        },
        {
            icon: "/images/surveyB/very-sportive.svg",
            text: "Faccio sport molto impegnativi fisicamente / sono un atleta professionista",
        },
        {
            icon: "/images/surveyB/very-busy.svg",
            text: "Ho una vita molto frenetica e piena di impegni",
        },
        {
            icon: "/images/surveyB/chilly-weekends.svg",
            text: "Nel weekend sono molto stanca/o e dormo molto",
        },
        {
            icon: "/images/surveyB/frequently-sick.svg",
            text: "Mi ammalo spesso, sono spesso vittima di virus stagionali (raffreddori,influenze,etc...)",
        },
    ],
    progressionBarWidth: "width_100_percent",
}

const sectionPage2: NewQuestion = {
    id: 0,
    question: "Attività fisica",
    type: NewQuestionType.BreakPage,
    answers: [],
    icon: "/images/sport-activities-section-icon.svg",
    comment: "2",
}

const question6: NewQuestion = {
    id: 6,
    question: "Quanto spesso pratichi attività fisica?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/workout-everyday.svg",
            text: "Ogni giorno",
        },
        {
            icon: "/images/surveyB/workout-often.svg",
            text: "3-4 volte alla settimana",
        },
        {
            icon: "/images/surveyB/workout-sometimes.svg",
            text: "1-2 volte alla settimana",
        },
        {
            icon: "/images/surveyB/workout-never.svg",
            text: "Raramente o mai",
        },
    ],
    progressionBarWidth: "width_17_percent",
}

const question7: NewQuestion = {
    id: 7,
    question: "Quali tipi di attività fisica preferisci?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/cardio-workout.svg",
            text: "Cardio (corsa, nuoto, ciclismo)",
        },
        {
            icon: "/images/surveyB/pesi-workout.svg",
            text: "Allenamento con i pesi",
        },
        {
            icon: "/images/surveyB/yoga-pilates-workout.svg",
            text: "Yoga o pilates",
        },
        {
            icon: "/images/quizQ/altro.svg",
            text: answerAltro,
        },
    ],
    progressionBarWidth: "width_33_percent",
}

const question8: NewQuestion = {
    id: 8,
    question: "Quanto tempo dedichi all'attività fisica durante una sessione?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/less-than-30.svg",
            text: "Meno di 30 minuti",
        },
        {
            icon: "/images/surveyB/30-60-minutes.svg",
            text: "30-60 minuti",
        },
        {
            icon: "/images/surveyB/more-than-60.svg",
            text: "Più di 60 minuti",
        },
    ],
    progressionBarWidth: "width_51_percent",
}

const question9: NewQuestion = {
    id: 9,
    question: "Come definiresti il tuo livello di attività fisica complessivo?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/physical-activity-active.svg",
            text: "Attivo e regolare",
        },
        {
            icon: "/images/surveyB/physical-activity-medium.svg",
            text: "Moderatamente attivo",
        },
        {
            icon: "/images/surveyB/physical-activity-low.svg",
            text: "Poco attivo",
        },
    ],
    progressionBarWidth: "width_68_percent",
}

const question10: NewQuestion = {
    id: 10,
    question: "Hai degli obiettivi specifici legati all'attività fisica?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/increase-resistence.svg",
            text: "Migliorare la resistenza",
        },
        {
            icon: "/images/surveyB/increase-strength.svg",
            text: "Aumentare la forza muscolare",
        },
        {
            icon: "/images/quizQ/bilancia.svg",
            text: "Perdere peso",
        },
        {
            icon: "/images/surveyB/increase-flexibility.svg",
            text: "Migliorare la flessibilità e il bilanciamento",
        },
        {
            icon: "/images/quizQ/altro.svg",
            text: answerAltro,
        },
    ],
    progressionBarWidth: "width_75_percent",
}

const question11: NewQuestion = {
    id: 11,
    question: "Qual è il tuo atteggiamento nei confronti dell'attività fisica?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/love-emoji.svg",
            text: "Mi piace e la faccio con piacere",
        },
        {
            icon: "/images/surveyB/no-stress-emoji.svg",
            text: "La trovo importante, ma talvolta mi manca la motivazione",
        },
        {
            icon: "/images/surveyB/half-stress-emoji.svg",
            text: "Non mi piace particolarmente, ma lo faccio per mantenermi in forma",
        },
        {
            icon: "/images/surveyB/stress-emoji.svg",
            text: "Non mi piace e infatti la pratico raramente",
        },
    ],
    progressionBarWidth: "width_100_percent",
}

const sectionPage3: NewQuestion = {
    id: 0,
    question: "Alimentazione",
    type: NewQuestionType.BreakPage,
    answers: [],
    icon: "/images/nutrition-section-icon.svg",
    comment: "3",
}

const question12: NewQuestion = {
    id: 12,
    question: "Qual è il tuo approccio all'alimentazione?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/quizQ/piatto.svg",
            text: "Cerco di stare attenta/o all'apporto di calorie, evito cibi poco salutari, grassi e/o processati",
        },
        {
            icon: "/images/surveyB/carrot.svg",
            text: "Seguo un regime alimentare molto rigido per mantenere la mia forma fisica al meglio",
        },
        {
            icon: "/images/surveyB/hamburger.svg",
            text: "Non sono molto attenta/o a quello che mangio",
        },
    ],
    progressionBarWidth: "width_5_percent",
}

const question13: NewQuestion = {
    id: 13,
    question: "Che tipo di alimentazione segui?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/mediterrean-diet.svg",
            text: "Mediterranea",
        },
        {
            icon: "/images/surveyB/veggie-diet.svg",
            text: "Vegetariana",
        },
        {
            icon: "/images/surveyB/vegan-diet.svg",
            text: "Vegana",
        },
        {
            icon: "/images/surveyB/dieta-onnivora.svg",
            text: "Onnivora",
        },
        {
            icon: "/images/quizQ/altro.svg",
            text: answerAltro,
        },
    ],
    progressionBarWidth: "width_11_percent",
}

const question14: NewQuestion = {
    id: 14,
    question: "Quante volte mangi al giorno?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/small-number.svg",
            text: "Meno di 2",
        },
        {
            icon: "/images/surveyB/medium-number.svg",
            text: "3-5 (compresi gli spuntini)",
        },
        {
            icon: "/images/surveyB/big-number.svg",
            text: "Più di 5",
        },
    ],
    progressionBarWidth: "width_16_percent",
}

const question15: NewQuestion = {
    id: 15,
    question: "Ti capita spesso di saltare i pasti? Se si, quali?",
    type: NewQuestionType.Multiselect,
    answers: [
        {
            icon: "/images/surveyB/no-breakfast.svg",
            text: "Colazione",
        },
        {
            icon: "/images/surveyB/no-lunch.svg",
            text: "Pranzo",
        },
        {
            icon: "/images/surveyB/no-dinner.svg",
            text: "Cena",
        },
        {
            icon: "/images/surveyB/all-meals.svg",
            text: "Non salto mai i pasti",
        },
    ],
    progressionBarWidth: "width_21_percent",
}

const question16: NewQuestion = {
    id: 16,
    question: "Ti capita spesso di mangiare fuori dai pasti?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/morning.svg",
            text: "Si, prima del pranzo",
        },
        {
            icon: "/images/surveyB/afternoon.svg",
            text: "Si, nel pomeriggio",
        },
        {
            icon: "/images/surveyB/sunset.svg",
            text: "Si, prima di cena",
        },
        {
            icon: "/images/surveyB/evening.svg",
            text: "Si, dopo cena",
        },
        {
            icon: "/images/surveyB/never.svg",
            text: "Mai",
        },
    ],
    progressionBarWidth: "width_26_percent",
}

const question17: NewQuestion = {
    id: 17,
    question: "Quante volte a settimana mangi fuori casa?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/everyday.svg",
            text: "Tutti i giorni",
        },
        {
            icon: "/images/surveyB/3-4-per-week.svg",
            text: "3-4 volte a settimana",
        },
        {
            icon: "/images/surveyB/1-2-per-week.svg",
            text: "1-2 volte a settimana",
        },
        {
            icon: "/images/surveyB/always-home.svg",
            text: "Raramente o mai",
        },
    ],
    progressionBarWidth: "width_32_percent",
}

const question18: NewQuestion = {
    id: 18,
    question: "Tendi a cucinare i tuoi pasti o consumare prodotti già pronti?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/always-cooking.svg",
            text: "Cucino sempre io",
        },
        {
            icon: "/images/surveyB/usually-cooking.svg",
            text: "Cucino quasi sempre, ogni tanto compro piatti già pronti",
        },
        {
            icon: "/images/surveyB/sometimes-cooking.svg",
            text: "Consumo principalmente piatti già pronti, ma ogni tanto cucino io",
        },
        {
            icon: "/images/surveyB/only-ready-dishes.svg",
            text: "Consumo solo piatti già pronti",
        },
    ],
    progressionBarWidth: "width_38_percent",
}

const question19: NewQuestion = {
    id: 19,
    question: "Solitamente, quanto tempo dedichi alla preparazione dei pasti?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/surveyB/less-than-30.svg",
            text: "Meno di 30 minuti",
        },
        {
            icon: "images/surveyB/30-60-minutes.svg",
            text: "Tra i 30 e i 60 minuti",
        },
        {
            icon: "images/surveyB/more-than-60.svg",
            text: "Più di 60 minuti",
        },
        {
            icon: "images/surveyB/never-cooking.svg",
            text: "Non cucino mai",
        },
    ],
    progressionBarWidth: "width_43_percent",
}

const question20: NewQuestion = {
    id: 20,
    question: "Con quale frequenza consumi verdura?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "images/surveyB/lots-of-vegetables.svg",
            text: "A ogni pasto",
        },
        {
            icon: "images/surveyB/some-vegetables.svg",
            text: "Almeno una volta al giorno",
        },
        {
            icon: "images/surveyB/vegan-diet.svg",
            text: "Quasi tutti i giorni",
        },
        {
            icon: "images/surveyB/carrot.svg",
            text: "Pochi giorni a settimana",
        },
    ],
    progressionBarWidth: "width_49_percent",
}

const question21: NewQuestion = {
    id: 21,
    question: "Con quale frequenza consumi carne?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "images/surveyB/always-meat.svg",
            text: "A ogni pasto",
        },
        {
            icon: "images/surveyB/usually-meat.svg",
            text: "Almeno una volta al giorno",
        },
        {
            icon: "images/surveyB/sometimes-meat.svg",
            text: "Quasi tutti i giorni",
        },
        {
            icon: "images/surveyB/few-meat.svg",
            text: "Pochi giorni a settimana",
        },
        {
            icon: "images/surveyB/i-am-vegan.svg",
            text: "Mai, sono vegano/vegetariano",
        },
    ],
    progressionBarWidth: "width_54_percent",
}

const question22: NewQuestion = {
    id: 22,
    question: "Con quale frequenza consumi pesce?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "images/surveyB/always-fish.svg",
            text: "A ogni pasto",
        },
        {
            icon: "images/surveyB/usually-fish.svg",
            text: "Almeno una volta al giorno",
        },
        {
            icon: "images/surveyB/sometimes-fish.svg",
            text: "Quasi tutti i giorni",
        },
        {
            icon: "images/surveyB/few-fish.svg",
            text: "Pochi giorni a settimana",
        },
        {
            icon: "images/surveyB/i-am-vegan.svg",
            text: "Mai, sono vegano/vegetariano",
        },
    ],
    progressionBarWidth: "width_60_percent",
}

const question23: NewQuestion = {
    id: 23,
    question: "Con quale frequenza consumi formaggi e latticini?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "images/surveyB/always-cheese.svg",
            text: "A ogni pasto",
        },
        {
            icon: "images/surveyB/usually-cheese.svg",
            text: "Almeno una volta al giorno",
        },
        {
            icon: "images/surveyB/sometimes-cheese.svg",
            text: "Quasi tutti i giorni",
        },
        {
            icon: "images/surveyB/few-cheese.svg",
            text: "Pochi giorni a settimana",
        },
        {
            icon: "images/surveyB/i-am-vegan.svg",
            text: "Mai, sono vegano/vegetariano",
        },
    ],
    progressionBarWidth: "width_67_percent",
}

const question24: NewQuestion = {
    id: 24,
    question: "Quanta acqua bevi al giorno?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "images/surveyB/less-than-half-liter.svg",
            text: "Meno di un litro",
        },
        {
            icon: "images/surveyB/1-liter-1-liter-half.svg",
            text: "Tra un litro e un litro e mezzo",
        },
        {
            icon: "images/surveyB/1-liter-half-2-liters.svg",
            text: "Tra un litro e mezzo e due litri",
        },
        {
            icon: "images/surveyB/more-than-2-liters.svg",
            text: "Oltre due litri",
        },
    ],
    progressionBarWidth: "width_72_percent",
}

const question25: NewQuestion = {
    id: 25,
    question: "Quante volte alla settimana mangi cibi ricchi di zuccheri aggiunti?",
    comment: "Dolci, biscotti, bibite gassate, ...",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "images/surveyB/always-sweets.svg",
            text: "Ogni giorno",
        },
        {
            icon: "images/surveyB/usually-sweets.svg",
            text: "3-4 volte alla settimana",
        },
        {
            icon: "images/surveyB/sometimes-sweets.svg",
            text: "1-2 volte alla settimana",
        },
        {
            icon: "images/surveyB/never-sweets.svg",
            text: "Raramente o mai",
        },
    ],
    progressionBarWidth: "width_77_percent",
}

const question26: NewQuestion = {
    id: 26,
    question: "Con quale frequenza consumi bevande alcoliche?",
    comment: "Vino, birra, superalcolici, ...",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "images/surveyB/always-alcohol.svg",
            text: "Ogni giorno",
        },
        {
            icon: "images/surveyB/usually-alcohol.svg",
            text: "3-4 volte alla settimana",
        },
        {
            icon: "images/surveyB/sometimes-alcohol.svg",
            text: "1-2 volte alla settimana",
        },
        {
            icon: "images/surveyB/never-alcohol.svg",
            text: "Raramente o mai",
        },
    ],
    progressionBarWidth: "width_83_percent",
}

const question27: NewQuestion = {
    id: 27,
    question: "In generale, come valuteresti la tua forma fisica?",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "",
            text: "Sono sovrappeso di almeno 5 kg rispetto al mio peso forma",
        },
        {
            icon: "",
            text: "Sento di avere qualche chilo di troppo e ho grandi difficoltà a perdere peso, anche se mangio poco",
        },
        {
            icon: "",
            text: "Ho una buona forma fisica, non sento il bisogno di dimagrire",
        },
        {
            icon: "",
            text: "Mi sento leggermente sottopeso, vorrei costruire massa muscolare e guadagnare qualche chilo nel modo più sano e corretto possibile",
        },
        {
            icon: "",
            text: "Sono soddisfatta/o della mia forma fisica, il mio obiettivo è incorporare una dieta e uno stile di vita sani nella mia routine quotidiana",
        },
        {
            icon: "",
            text: answerAltro,
        },
    ],
    progressionBarWidth: "width_88_percent",
}

const question28: NewQuestion = {
    id: 28,
    question: "Seleziona le opzioni che più ti rappresentano",
    type: NewQuestionType.Multiselect,
    answers: [
        {
            icon: "/images/surveyB/salt.svg",
            text: "Mi piace molto il cibo salato",
        },
        {
            icon: "/images/surveyB/sometimes-meat.svg",
            text: "Amo la carne, mangio moltissima carne rossa, salumi e formaggi",
        },
        {
            icon: "/images/surveyB/pasta.svg",
            text: "Mi piacciono molto i carboidrati (pasta, pane, riso, etc...",
        },
        {
            icon: "/images/surveyB/empty-dish.svg",
            text: "Mi sembra di avere sempre fame",
        },
        {
            icon: "/images/surveyB/no-sleep-2.svg",
            text: "Mangiare la sera tardi mi disturba il sonno",
        },
        {
            icon: "/images/quizQ/intestino.svg",
            text: "Ho problemi di digestione, gonfiore, pesantezza e acidità di stomaco",
        },
        {
            icon: "/images/surveyB/intestino.svg",
            text: "Ho problemi di regolarità intestinale (e.g., stitichezza)",
        },
        {
            icon: "/images/surveyB/clock-dish.svg",
            text: "Mi è capitato di provare programmi di digiuno intermittente",
        },
        {
            icon: "/images/surveyB/never.svg",
            text: "Nessuna delle precedenti",
        },
    ],
    progressionBarWidth: "width_95_percent",
}

const question29: NewQuestion = {
    id: 29,
    question: "Vorresti condividere con noi altre informazioni? Qualsiasi cosa credi possa essere utile per realizzare al meglio un piano costruito ad hoc per te",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "Aggiungi qualcosa",
        },
    ],
    progressionBarWidth: "width_100_percent",
}

const sectionPage4: NewQuestion = {
    id: 0,
    question: "Misure",
    type: NewQuestionType.BreakPage,
    answers: [],
    icon: "/images/measures-section-icon.svg",
    comment: "4",
}

const question30: NewQuestion = {
    id: 30,
    question: "Prendi le tue misure con un metro, dovrai prenderle stando in piedi e con i piedi uniti",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "/images/surveyB/circonferenza-vita.svg",
            text: "Circonferenza vita (cm)",
        },
        {
            icon: "/images/surveyB/circonferenza-fianchi.svg",
            text: "Circonferenza fianchi (cm)",
        },
        {
            icon: "/images/surveyB/circonferenza-braccio.svg",
            text: "Circonferenza braccio (cm)",
        },
    ],
    progressionBarWidth: "width_100_percent",
}

export const surveyBQuestions = [
    sectionPage1,
    question1,
    question2,
    question3,
    question4,
    question5,
    sectionPage2,
    question6,
    question7,
    question8,
    question9,
    question10,
    question11,
    sectionPage3,
    question12,
    question13,
    question14,
    question15,
    question16,
    question17,
    question18,
    question19,
    question20,
    question21,
    question22,
    question23,
    question24,
    question25,
    question26,
    question27,
    question28,
    question29,
    sectionPage4,
    question30,
]