import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

interface Props {
    userId: string,
    idToken: string,
}

const TempSuccess: React.FC<Props> = ({
    userId,
    idToken,
}) => {

    const navigate = useNavigate()

    useEffect(() => {
        navigate("/platform/payment-method")
    }, [])

    return (
        <div>
            Stai per essere mandato alla tua area pagamenti
        </div>
    )
}

export default TempSuccess
