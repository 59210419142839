import React from "react"
import Expert from "../../../../components/Expert"
import ExpertsTagCard from "../../../../components/ExpertsTagCard"
import "./css/ExpertCard.css"

interface Props {
    expert: Expert
    onInfoClicked: (expert: Expert) => void,
    onPurchaseClicked: () => void,
}

/**
 * Expert card in the expert area of the platform
 * @param expert the expert object to handle
 * @param onInfoClicked the action to perform when clicking on the info button 
 * @returns 
 */
const ExpertCard: React.FC<Props> = ({
    expert,
    onInfoClicked,
    onPurchaseClicked,
}) => {
    return (
        <div className='expert-card-container'>
            <div className="expert-card-internal-container">
                <div className="expert-card-photo-container">
                    <img className="expert-card-photo" src={expert.avatar}/>
                </div>
                <div className="expert-card-info-container">
                    <div className="expert-card-info-internal-container">
                        <div className="width_100_percent">
                            <div className="w700 s12 lh20">
                                {expert.position}
                            </div>
                            <div className="horizontal_flex_group justify_content_space_between align_items_center">
                                <div className="w700 s20 lh20">
                                    {expert.name} {expert.familyName}
                                </div>
                                <div className="w700 s14 lh20 experts-blue-text">
                                    {expert.role}
                                </div>
                            </div>
                        </div>
                        <div className="experts-card-tags-row">
                            {
                                expert.skills.map(skill => (
                                    <ExpertsTagCard
                                        key={expert.skills.indexOf(skill)}
                                        name={skill}
                                        isLast={expert.skills.indexOf(skill) === expert.skills.length - 1}
                                    />
                                ))
                            }
                        </div>
                        <div className="experts-card-buttons-row">
                            <button
                                className="experts-card-info-button ternary_action_color_background w700 lh17"
                                onClick={() => onInfoClicked(expert)}
                            >
                                Informazioni
                            </button>
                            <button
                                className="experts-purchase-button primary_action_color_background w700 lh17"
                                onClick={onPurchaseClicked}
                            >
                                Acquista un consulto
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpertCard
