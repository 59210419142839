interface Props {
    image: string,
    text: string,
    onClick?: () => void,
    isInOwnView?: boolean,
}

const HorizontalLogoText: React.FC<Props> = ({
    image,
    text,
    onClick,
    isInOwnView,
}) => {
    return (
        <div
            className={`horizontal_flex_group align_items_center ${isInOwnView ? "" : "margin_bottom_20 pointer"}`}
            onClick={onClick}
        >
            <div>
                <img className="margin_right_10" src={image} />
            </div>
            <div className={`${isInOwnView ? "single-view-title-text" : "menu-popup-element-text"}`}>{text}</div>
        </div>
    )
}

export default HorizontalLogoText
