import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import HolifyaFullLogo from "../../../components/HolifyaFullLogo"
import { KlaviyoProperties } from "../../../components/KlaviyoProperties"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { QuestionAndAnswer } from "../../../components/QuestionAndAnswer"
import { BASE_URL, requestOptionsGET } from "../../../utils/Constants"
import { handleQuestionId, separateStrings } from "../../../utils/Functions"
import { answerAltro, question1Answers, quizQNewQuestion3, quizQQuestions } from "../../../utils/QuizQQuestions"
import { useAuth } from "../../../utils/context/AuthContext"
import { NavigationToQuizQ } from "../../../utils/enums/NavigationToQuizQ"
import InputAnswerCard from "../components/InputAnswerCard"
import MultiSelectAnswerCard from "../components/MultiSelectAnswerCard"
import { NewQuestionType } from "../components/NewQuestion"
import RadioAnswerCard from "../components/RadioAnswerCard"
import "../css/SurveysStyles.css"
import "./css/QuizQNew.css"

const QuizQNew = () => {

    // Needed to navigate to another page
    const navigate = useNavigate()
    const location = useLocation()
    const fromWhere = location.state.fromWhere

    const { auth } = useAuth();
    const userId = auth.userId
    const idToken = auth.idToken!

    // Boolean to display the loading spinner
    const [isLoading, setIsLoading] = useState(false)

    // Values to be filled during the survey
    // const [familyName, setFamilyName] = useState("")
    // const [email, setEmail] = useState("")

    const [customAnswer, setCustomAnswer] = useState("")

    const handleBackToHomePage = () => {
        switch (fromWhere) {
            case NavigationToQuizQ.Login:
            case NavigationToQuizQ.OnboardingFork:
            case NavigationToQuizQ.RedeemSuccess:
            case NavigationToQuizQ.PlatformDashboard:
            case NavigationToQuizQ.PlatformExperts:
            case NavigationToQuizQ.PlatformPlan:
            case NavigationToQuizQ.PlatformRedeem:
                return navigate("/quiz-q-home", { state: { fromWhere } })
            default:
                return ""
        }
    }

    // When the user clicks on back button
    const onBackButtonClick = () => {
        if (currentQuestion.id === 1) {
            handleBackToHomePage()
        } else {
            var previousIndex = -1
            if (currentQuestionIndex === 2 && (question1AnswersSelected.length === 0)) {
                previousIndex = currentQuestionIndex - 2
            } else {
                previousIndex = currentQuestionIndex - 1
            }
            const previousQuestion = questions[previousIndex]
            if (previousQuestion.type === NewQuestionType.Multiselect) {
                setMultiselectAnswersSelected(separateStrings(savedAnswers[previousIndex], "; "))
            } else if (previousQuestion.type === NewQuestionType.Radio) {
                setRadioAnswer(savedAnswers[previousIndex])
            }
            setCurrentQuestionIndex(previousIndex)
        }
    }

    // When the user clicks on confirm button
    const onConfirmButtonClick = () => {
        if (currentQuestion.question === quizQNewQuestion3.question) {
            handleAge()
        }
        saveAnswer()
        refreshOrSend()
    }

    const onNewInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAnswer = e.target.value
        setCustomAnswer(newAnswer)
    }

    const swapAnswers = (oldAnswer: string, newAnswer: string, arr: string[]) => {
        const index = arr.indexOf(oldAnswer)
        arr[index] = newAnswer
    }

    // Save the answer of the current question on the array of the saved answers
    const saveAnswer = () => {
        // Se la domanda è una multiselect, bisogna prendere l'array di risposte selezionate e creare un'unica stringa da inserire nell'array di risposte salvate
        if (currentQuestion.type === NewQuestionType.Multiselect) {

            // Saving the answers to question 1, needed for question 2
            if (isFirstQuestion) {
                if (multiselectAnswersSelected.length >= 2 || multiselectAnswersSelected.includes(answerAltro) || isAnswer1Custom(multiselectAnswersSelected[0])) {
                    setQuestion1AnswersSelected(multiselectAnswersSelected)
                } else {
                    setQuestion1AnswersSelected([])
                }
            }

            // Saving the answers to question 1, needed for question 2
            // if (isFirstQuestion && multiselectAnswersSelected.length === 1) {
            //     if (multiselectAnswersSelected[0] === "Pesoforma" || multiselectAnswersSelected[0] === "Migliore alimentazione in generale") {
            //         setShowQuestion5a(true)
            //     } else if (multiselectAnswersSelected[0] === "Salute gastrointestinale (gonfiore, digestione…)" || multiselectAnswersSelected[0] === "Scoprire e agire su intolleranze") {
            //         setShowQuestion5b(true)
            //     }
            // }

            if (multiselectAnswersSelected.includes(answerAltro) && customAnswer !== "") {
                swapAnswers(answerAltro, customAnswer, multiselectAnswersSelected)
            }

            // Inizia il processo di creazione di un'unica stringa
            const answersString = multiselectAnswersSelected.join("; ")
            // Processo di creazione di un'unica stringa finito

            // Inserisco la stringa nell'array delle risposte, mettendo in coda oppure sostituendo
            if (savedAnswers.length === currentQuestionIndex) {
                savedAnswers.push(answersString)
            } else {
                savedAnswers[currentQuestionIndex] = answersString
            }
        } else if (currentQuestion.type === NewQuestionType.Input) {

            // Inizia il processo di creazione di un'unica stringa
            var answersString = ""
            switch (currentQuestion.id) {
                // case 2:
                //     answersString = question2InputAnswers.join("; ")
                //     break;
                case 3:
                    answersString = question3InputAnswers.join("; ")
                    break;
                // case 5.2:
                //     answersString = question5InputAnswers.join("; ")
                //     break;
                case 9:
                    answersString = question9InputAnswers.join("; ")
                    break;
                // case 13:
                //     answersString = question13InputAnswers.join("; ")
                //     break;
                default:
                    answersString = inputAnswersFilled.join("; ")
            }
            // Processo di creazione di un'unica stringa finito

            // if (currentQuestionIndex === 2) {
            //     if (showQuestion5a) {
            //         questions.push(quizQNewQuestion5a)
            //         const newQuestions = questions.concat(quizQNewQuestionsPart2)
            //         setQuestions(newQuestions)
            //     } else if (showQuestion5b) {
            //         questions.push(quizQNewQuestion5b)
            //         questions.push(quizQNewQuestion5bis)
            //         questions.push(quizQNewQuestion5tris)
            //         const newQuestions = questions.concat(quizQNewQuestionsPart2)
            //         setQuestions(newQuestions)
            //     } else {
            //         const newQuestions = questions.concat(quizQNewQuestionsPart2)
            //         setQuestions(newQuestions)
            //     }
            // }

            // if (currentQuestion.question === "Come ti chiami?") {
            //     setName(question2InputAnswers[0])
            //     setFamilyName(question2InputAnswers[1])
            // }

            // Inserisco la stringa nell'array delle risposte, mettendo in coda oppure sostituendo
            if (savedAnswers.length === currentQuestionIndex) {
                savedAnswers.push(answersString)
            } else {
                savedAnswers[currentQuestionIndex] = answersString
            }

            // if (currentQuestion.question === "Quale è il tuo indirizzo e-mail?") {
            //     setEmail(question13InputAnswers[0])
            //     handleTempAnswers()
            //     callKlaviyoApi(question13InputAnswers[0])
            // }
        } else if (currentQuestion.type === NewQuestionType.Radio) {
            // if (currentQuestionIndex === 1) {
            //     if (radioAnswer === "Pesoforma" || radioAnswer === "Migliore alimentazione in generale") {
            //         setShowQuestion5a(true)
            //     } else if (radioAnswer === "Salute gastrointestinale (gonfiore, digestione…)" || radioAnswer === "Scoprire e agire su intolleranze") {
            //         setShowQuestion5b(true)
            //     }
            // }

            if (radioAnswer === answerAltro && customAnswer !== "") {
                if (savedAnswers.length === currentQuestionIndex) {
                    savedAnswers.push(customAnswer)
                } else {
                    savedAnswers[currentQuestionIndex] = customAnswer
                }
            } else {
                if (savedAnswers.length === currentQuestionIndex) {
                    savedAnswers.push(radioAnswer)
                } else {
                    savedAnswers[currentQuestionIndex] = radioAnswer
                }
            }
        }
        setSavedAnswers(savedAnswers)
        switch (currentQuestion.type) {
            case NewQuestionType.Input:
                setInputAnswersFilled([])
                break;
            case NewQuestionType.Multiselect:
                setMultiselectAnswersSelected([])
                break;
            case NewQuestionType.Radio:
                setRadioAnswer("")
                break;
            default:
                break;
        }
    }

    const isAnswer1Custom = (firstAnswer: string) => {
        for (const answer of question1Answers) {
            if (answer === firstAnswer) {
                return false
            }
        }
        return true
    }

    // Refresh the page with a new question or send the questions and navigate to the next step
    const refreshOrSend = () => {
        // In case of last question we have to save the answers and redirect to the reward page
        if (isLastQuestion) {
            handleAnswers()
            // if (location.state === null) {
            //     callSaveTempSurveyApi()
            // } else {
            callSaveSurveyApi()
            // }
        } else if (isFirstQuestion) {
            if (multiselectAnswersSelected.length >= 2 || isAnswer1Custom(multiselectAnswersSelected[0])) {
                const nextIndex = currentQuestionIndex + 1
                setCurrentQuestionIndex(nextIndex)
            } else {
                const nextIndex = currentQuestionIndex + 2
                setCurrentQuestionIndex(nextIndex)
            }
        } else {
            var nextIndex = -1
            if (currentQuestion.id === 5.1 && radioAnswer === "No") {
                nextIndex = currentQuestionIndex + 2
            } else {
                nextIndex = currentQuestionIndex + 1
            }
            setCurrentQuestionIndex(nextIndex)
        }
    }

    // Before saving all the answers, we need to save them in the correct object
    const handleAnswers = () => {
        questions.forEach(q => {
            if (q.question !== undefined) {
                const index = questions.indexOf(q)
                if (savedAnswers[index] !== undefined) {
                    questionsAndAnswers.push({
                        // q: q.id !== 5.2 ? (handleQuestionId(q.id) + q.question) : (handleQuestionId(q.id) + handleQuestion5tris()),
                        q: handleQuestionId(q.id) + q.question,
                        a: [savedAnswers[index]]
                    })
                }
            }
        })
    }

    // const handleTempAnswers = () => {
    //     questions.forEach(q => {
    //         if (q.question !== undefined) {
    //             const index = questions.indexOf(q)
    //             if (savedAnswers[index] !== undefined) {
    //                 // tempQuestionsAndAnswers[q.id !== 5.2 ? (handleQuestionId(q.id) + q.question) : (handleQuestionId(q.id) + handleQuestion5tris())] = savedAnswers[index]
    //                 tempQuestionsAndAnswers[handleQuestionId(q.id) + q.question] = savedAnswers[index]
    //             }
    //         }
    //     })
    // }

    // Array of saved answers
    const [savedAnswers, setSavedAnswers] = useState<string[]>([])

    // Questions of this survey
    // const [questions, setQuestions] = useState(quizQNewQuestionsPart1)
    const questions = quizQQuestions
    // Index of the current question to display
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    // Current question displayed on screen
    const currentQuestion = questions[currentQuestionIndex]

    // In a multiselect question, this is the array of the selected answers
    const [multiselectAnswersSelected, setMultiselectAnswersSelected] = useState<string[]>([]);
    // In an input question, this is the array of the filled answers
    const [inputAnswersFilled, setInputAnswersFilled] = useState<string[]>([""]);
    const [question2InputAnswers, setQuestion2InputAnswers] = useState(["", ""])
    const [question3InputAnswers, setQuestion3InputAnswers] = useState([""])
    const [question5InputAnswers, setQuestion5InputAnswers] = useState([""])
    const [question9InputAnswers, setQuestion9InputAnswers] = useState([""])
    const [question13InputAnswers, setQuestion13InputAnswers] = useState([""])
    // In a radio question, this represents the currrent answer
    const [radioAnswer, setRadioAnswer] = useState("")

    // Answers to question 1, needed for question 1.2
    const [question1AnswersSelected, setQuestion1AnswersSelected] = useState<string[]>([]);

    // Boolean checking if the confirm button is disabled
    const confirmButtonDisabled = () => {
        switch (currentQuestion.type) {
            case NewQuestionType.Multiselect: {
                return multiselectAnswersSelected.length === 0
            }
            case NewQuestionType.Radio: {
                return radioAnswer === ""
            }
            case NewQuestionType.Input: {
                if (currentQuestion.id === 2) {
                    return question2InputAnswers[0] === "" || question2InputAnswers[1] === ""
                } else if (currentQuestion.id === 3) {
                    return question3InputAnswers[0] === ""
                } else if (currentQuestion.id === 5.2) {
                    return question5InputAnswers[0] === ""
                } else if (currentQuestion.id === 9) {
                    return (question9InputAnswers[0] === "")
                } else if (currentQuestion.id === 13) {
                    return (question13InputAnswers[0] === "")
                }
            }
        }
    }
    // Boolean checking if it's the last question of the survey
    const isLastQuestion = currentQuestionIndex === questions.length - 1
    // Boolean checking if it's the first question of the survey
    const isFirstQuestion = currentQuestionIndex === 0

    // When clicking on a multiselect question card
    const onMultiSelectCardClick = (answer: string) => {
        const index = multiselectAnswersSelected.indexOf(answer);
        if (index > -1) {
            setMultiselectAnswersSelected([...multiselectAnswersSelected.slice(0, index), ...multiselectAnswersSelected.slice(index + 1)]);
        } else {
            setMultiselectAnswersSelected([...multiselectAnswersSelected, ...[answer]]);
        }
    }

    const handleInputAnswers = (index: number) => {
        switch (index) {
            case 2:
                return question2InputAnswers
            case 3:
                return question3InputAnswers
            case 5.2:
                return question5InputAnswers
            case 9:
                return question9InputAnswers
            case 13:
                return question13InputAnswers
            default:
                return [""]
        }
    }

    const [tempString, setTempString] = useState("")

    // When the user types in the input field
    const onInputCardChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        if (currentQuestion.id === 2) {
            question2InputAnswers[index] = e.target.value
            setQuestion2InputAnswers(question2InputAnswers)
        } else if (currentQuestion.id === 3) {
            const oldLength = question3InputAnswers[index].length
            var newAnswer = e.target.value
            var newLength = newAnswer.length
            if (oldLength === 1 && newLength === 2) {
                newAnswer = newAnswer + "/"
            }
            if (oldLength === 4 && newAnswer.length === 5) {
                newAnswer = newAnswer + "/"
            }
            question3InputAnswers[index] = newAnswer
            setQuestion3InputAnswers(question3InputAnswers)
        } else if (currentQuestion.id === 5.2) {
            question5InputAnswers[index] = e.target.value
            setQuestion5InputAnswers(question5InputAnswers)
        } else if (currentQuestion.id === 9) {
            question9InputAnswers[index] = e.target.value
            setQuestion9InputAnswers(question9InputAnswers)
        } else if (currentQuestion.id === 13) {
            question13InputAnswers[index] = e.target.value.toLowerCase()
            setQuestion13InputAnswers(question13InputAnswers)
        }
        setTempString(e.target.value)
    }

    // When the user clicks on a radio answer card
    const onRadioCardClick = (choice: string) => {
        setRadioAnswer(choice)
        if (choice !== answerAltro && !isLastQuestion) {
            setTimeout(() => {
                saveRadioAndRefresh(choice)
            }, 250)
        }
    }

    const saveRadioAndRefresh = (radioChoice: string) => {
        saveRadioAnswer(radioChoice)
        refreshOrSendRadio(radioChoice)
    }

    const saveRadioAnswer = (choice: string) => {
        if (currentQuestionIndex === 1) {
            if (choice === "Pesoforma" || choice === "Migliore alimentazione in generale") {
                setShowQuestion5a(true)
            } else if (choice === "Salute gastrointestinale (gonfiore, digestione…)" || choice === "Scoprire e agire su intolleranze") {
                setShowQuestion5b(true)
            }
        }

        if (choice === answerAltro && customAnswer !== "") {
            if (savedAnswers.length === currentQuestionIndex) {
                savedAnswers.push(customAnswer)
            } else {
                savedAnswers[currentQuestionIndex] = customAnswer
            }
        } else {
            if (savedAnswers.length === currentQuestionIndex) {
                savedAnswers.push(choice)
            } else {
                savedAnswers[currentQuestionIndex] = choice
            }
        }
        setSavedAnswers(savedAnswers)
        setRadioAnswer("")
    }

    // Refresh the page with a new question or send the questions and navigate to the next step
    const refreshOrSendRadio = (choice: string) => {
        // In case of last question we have to save the answers and redirect to the reward page
        if (isLastQuestion) {
            handleAnswers()
            // if (location.state === null) {
            //     callSaveTempSurveyApi()
            // } else {
            callSaveSurveyApi()
            // }
        } else {
            var nextIndex = -1
            if (currentQuestion.id === 5.1 && choice === "No") {
                nextIndex = currentQuestionIndex + 2
            } else {
                nextIndex = currentQuestionIndex + 1
            }
            setCurrentQuestionIndex(nextIndex)
        }
    }

    // Array of question and answer, needed to save them all
    const questionsAndAnswers: QuestionAndAnswer[] = []

    // Array of temporary question and answer, used with klaviyo
    const tempQuestionsAndAnswers: KlaviyoProperties = {}

    // Booleans to check if we need to show question 5
    const [showQuestion5a, setShowQuestion5a] = useState(false)
    const [showQuestion5b, setShowQuestion5b] = useState(false)

    // Depending on answer at question 5.1 we have different title on question 5.2
    // const handleQuestion5tris = () => {
    //     // const index = currentQuestionIndex - 1
    //     const index = questions.indexOf(questions.filter(q => q.id === 5.1)[0])
    //     switch (savedAnswers[index]) {
    //         case "Sì, ho fatto un test e sono risultato intollerante...":
    //             return "Specifica quale test hai fatto e a cosa sei risultato intollerante"
    //         case "Sì, ho fatto un test ma non sono risultato intollerante":
    //             return "Specifica quale test hai fatto"
    //         case "Non ho mai fatto un test ma penso di avere intolleranze":
    //             return "A cosa credi di essere intollerante?"
    //         default:
    //             return "Niente"
    //     }
    // }

    // async function callSaveTempSurveyApi() {
    //     setIsLoading(true)
    //     const response = await fetch(`${BASE_URL}/api/pre-register-survey`, {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(
    //             {
    //                 type: QuizType.QuizQ,
    //                 data: questionsAndAnswers,
    //             }
    //         )
    //     })
    //     setIsLoading(false)
    //     if (!response.ok) {
    //         const responseJson = await response.json()
    //         console.log(responseJson)
    //     } else {
    //         const responseJson = await response.json()
    //         navigate("/quiz-q-new/reward-page", { state: { name, familyName, email, tempId: responseJson.id } })
    //     }
    // }

    async function callSaveSurveyApi() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/survey`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    type: "Quiz",
                    data: questionsAndAnswers,
                })
        })
        setIsLoading(false)
        if (response.ok) {
            navigate("/assign-nutritionist", { state: { fromWhere } })
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // Today day month and year, for minimum age policy
    const [minimumDay, setMinimumDay] = useState(0)
    const [minimumMonth, setMinimumMonth] = useState(0)
    const [minimumYear, setMinimumYear] = useState(0)

    // Handles the minimum age to be a Holifya user (18yo)
    const handleDate = () => {
        const today = new Date()
        const todayDay = today.getDate()
        const todayMonth = today.getMonth() + 1
        const todayYear = today.getFullYear()
        setMinimumYear(todayYear - 18)
        setMinimumMonth(todayMonth)
        setMinimumDay(todayDay)
    }

    // Handles the user's age from the input
    const handleAge = () => {
        const userBirthday = question3InputAnswers[0]
        const userDay = parseInt(userBirthday.substring(0, 2))
        const userMonth = parseInt(userBirthday.substring(3, 5))
        const userYear = parseInt(userBirthday.substring(6, 10))
        if (userYear > minimumYear) {
            navigate("under-the-age-page")
        } else if (userYear === minimumYear) {
            if (userMonth > minimumMonth) {
                navigate("under-the-age-page")
            } else if (userMonth === minimumMonth) {
                if (userDay > minimumDay) {
                    navigate("under-the-age-page")
                }
            }
        }
    }

    // GET users info by user_id
    async function callGetUserInfoApi() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setQuestion2InputAnswers([responseJson.data.name, responseJson.data.family_name])
            setQuestion13InputAnswers([responseJson.email])
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    async function callKlaviyoApi(tempEmail: string) {
        const response = await fetch(`${BASE_URL}/api/klaviyo/create`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: tempEmail,
                    properties: tempQuestionsAndAnswers,
                }
            )
        })
        if (response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
            callKlaviyoApiSecondAddToList(tempEmail)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    async function callKlaviyoApiSecondAddToList(tempEmail: string) {
        const response = await fetch(`${BASE_URL}/api/klaviyo`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: tempEmail,
                    type: "quiz_dropped",
                }
            )
        })
        if (response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callGetUserInfoApi()
        handleDate()
    }, [])

    // ****************************************************************************************

    return (
        <div className="App Quiz relative">
            {
                currentQuestion.type !== NewQuestionType.BreakPage ?
                    <div className={`survey-heading-container ${isLoading ? "visible_30_percent inactive" : ""}`}>
                        <div className="survey-question-empty-space" />
                        <div className="survey-bar-logo-container">
                            <div className="survey-empty-bar">
                                <div className={`survey-filled-bar ${currentQuestion.progressionBarWidth}`} />
                            </div>
                            <div className="quiz-q-new-holifya-full-logo">
                                <HolifyaFullLogo />
                            </div>
                            <div className="quiz-q-new-holifya-partial-logo">
                                <img src="/images/quizQ/holifya-spiral.svg" />
                            </div>
                        </div>
                        <div className="survey-question-container">
                            <div className="survey-question">
                                {/* {currentQuestion.id !== 5.2 ? currentQuestion.question : handleQuestion5tris()} */}
                                {currentQuestion.question}
                            </div>
                            <div className="survey-question-title-two">
                                {currentQuestion.comment}
                            </div>
                        </div>
                    </div> :
                    <div className={`quiz-q-new-buttons-row ${isLoading ? "visible_30_percent inactive" : ""}`} />
            }
            <div className={`${isLoading ? "visible_30_percent inactive" : ""} ${currentQuestion.type !== NewQuestionType.BreakPage ? "quiz-q-new-answers-container" : "quiz-q-new-break-page-container"}`}>
                <div className={`${currentQuestion.type !== NewQuestionType.BreakPage ? "quiz-q-new-answers-internal-container" : "quiz-q-new-break-page-internal-container"}`}>
                    {
                        currentQuestion.type === NewQuestionType.Multiselect ?
                            currentQuestion.answers.map(answer => (
                                <MultiSelectAnswerCard
                                    key={answer.text}
                                    answer={answer}
                                    isChecked={multiselectAnswersSelected.includes(answer.text)}
                                    onClick={onMultiSelectCardClick}
                                    isNowInput={answer.text === answerAltro}
                                    onMultiSelectInputChangeHandler={onNewInputChangeHandler}
                                />
                            )) : null
                    }
                    {
                        currentQuestion.type === NewQuestionType.Input ?
                            currentQuestion.answers.map(answer => (
                                <InputAnswerCard
                                    key={answer.text}
                                    placeholder={answer.text}
                                    index={currentQuestion.answers.indexOf(answer)}
                                    onChange={onInputCardChange}
                                    inputAnswers={handleInputAnswers(currentQuestion.id)}
                                    showEmailDisclaimer={currentQuestion.question === "Quale è il tuo indirizzo e-mail?" && location.state === null}
                                />
                            )) : null
                    }
                    {
                        currentQuestion.type === NewQuestionType.Radio ?
                            currentQuestion.answers.map(answer => (
                                (currentQuestionIndex === 1 && question1AnswersSelected.includes(answer.text)) || (currentQuestionIndex === 1 && question1AnswersSelected.length === 1 && isAnswer1Custom(question1AnswersSelected[0])) || currentQuestionIndex !== 1 ?
                                    <RadioAnswerCard
                                        key={answer.text}
                                        answer={answer}
                                        isChecked={radioAnswer === answer.text}
                                        onClick={onRadioCardClick}
                                        isNowInput={answer.text === answerAltro}
                                        onRadioInputChangeHandler={onNewInputChangeHandler}
                                    /> : null
                            )) : null
                    }
                    {
                        currentQuestion.type === NewQuestionType.BreakPage ?
                            <div className="quiz-q-new-break-page-internal-element">
                                <div>
                                    <img src={currentQuestion.icon} />
                                </div>
                                <div className="quiz-q-new-break-page-internal-element-blue-text">
                                    {currentQuestion.blueText}
                                </div>
                                <div className="quiz-q-new-break-page-internal-element-black-text">
                                    {currentQuestion.blackText}
                                </div>
                                <div className="quiz-q-new-break-page-internal-element-copy">
                                    {currentQuestion.copy}
                                </div>
                            </div> : null
                    }
                </div>
            </div>
            <div className={`quiz-q-new-buttons-row ${isLoading ? "visible_30_percent inactive" : ""}`}>
                <div className={`quiz-q-new-internal-buttons-row`}>
                    <div
                        className="quiz-q-new-back-button"
                        onClick={onBackButtonClick}
                    >
                        <div className="quiz-q-new-back-button-arrow">
                            <img src="/images/back-arrow.svg" />
                        </div>
                        <div className="quiz-q-new-back-button-text">
                            Indietro
                        </div>
                    </div>
                    <button
                        className={`quiz-q-new-confirm-button ${isLastQuestion ? "secondary_action_color_background black_text" : "primary_action_color_background white_text"} ${confirmButtonDisabled() ? "" : "pointer"}`}
                        disabled={confirmButtonDisabled()}
                        onClick={onConfirmButtonClick}
                    >
                        {isLastQuestion ? "Concludi" : "Avanti"}
                    </button>
                </div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
        </div>
    )
}

export default QuizQNew
