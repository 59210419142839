import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Expert from "../../components/Expert"
import { BASE_URL, requestOptionsGET } from "../../utils/Constants"
import { handleExpert } from "../../utils/Functions"
import { CalendlyExpert } from "../../utils/api-objects/CalendlyExpert"
import { OrderRetrieved } from "../../utils/api-objects/OrderRetrieved"
import { useAuth } from "../../utils/context/AuthContext"
import { useUserData } from "../../utils/context/UserDataContext"
import { ShopifyItemName } from "../../utils/enums/ShopifyItemType"
import { ShopifyProduct } from "../../utils/enums/ShopifyProduct"
import MonetizationSingleProductCard from "./cards/MonetizationSingleProductCard"
import MonetizationContinueButtonSection from "./components/MonetizationContinueButtonSection"
import NutritionistDetailsDialog from "./components/NutritionistDetailsDialog"
import "./css/RewardPage.css"

/** A wall page with the info of the product redeemed, if needed also with the expert connected */
const RewardPage = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const fromWhere = location.state.fromWhere

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const { userData } = useUserData()
    const username = userData.name

    const [isLoading, setIsLoading] = useState(false)

    const [showNutritionistDetailsDialog, setShowNutritionistDetailsDialog] = useState(false)

    const [orderRetrieved, setOrderRetrieved] = useState<ShopifyProduct>()
    const [nutritionist, setNutritionist] = useState<Expert>()

    const showRewardPage = orderRetrieved !== undefined && nutritionist !== undefined

    async function callGetOrdersByUserIdApi() {
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const responseJson: OrderRetrieved[] = await response.json()
            switch (responseJson[0].items[0].name) {
                case ShopifyItemName.NutritionistConsult:
                    setOrderRetrieved(ShopifyProduct.NutritionistConsult)
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET medical user info by patient
    async function callGetMedicalUserInfoApi() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/medical-user/patient/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (response.ok) {
            const nutritionist: CalendlyExpert = await response.json()
            const expert = handleExpert(nutritionist)
            setNutritionist(expert)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    const onNutritionistInfoButtonClick = () => {
        setShowNutritionistDetailsDialog(true)
    }

    const onNutritionistInfoDismissClick = () => {
        setShowNutritionistDetailsDialog(false)
    }

    const onContinueClick = () => {
        navigate("/survey-a-home")
    }

    useEffect(() => {
        callGetOrdersByUserIdApi()
        callGetMedicalUserInfoApi()
    }, [])

    // ********************************************************************

    return (
        <div className="App Quiz relative">
            <div className={`reward-page-full-container ${showNutritionistDetailsDialog ? "visible_30_percent inactive" : ""}`}>
                <div className="reward-page-logo-container">
                    <img src="/images/full-logo-new.svg" />
                </div>
                {
                    showRewardPage ?
                        <div className="reward-page-content-container">
                            <div className="reward-page-heading reward-page-desktop-only">
                                Complimenti {username}! {String.fromCodePoint(0x1F60E)} <span className="w700">Questo è il tuo acquisto!</span>
                            </div>
                            <div className="reward-page-heading reward-page-mobile-only">
                                <div>Complimenti {username}! {String.fromCodePoint(0x1F60E)}</div>
                                <div className="w700">Questo è il tuo acquisto!</div>
                            </div>
                            <div className="reward-page-full-card-container">
                                <MonetizationSingleProductCard
                                    nutritionist={nutritionist}
                                    product={orderRetrieved}
                                    onInfoClick={onNutritionistInfoButtonClick}
                                />
                                <MonetizationContinueButtonSection
                                    onClick={onContinueClick}
                                />
                            </div>
                        </div> : null
                }
            </div>
            {
                showNutritionistDetailsDialog ?
                    <NutritionistDetailsDialog
                        onCloseClick={onNutritionistInfoDismissClick}
                        nutritionist={nutritionist!}
                    /> : null
            }
        </div>
    )
}

export default RewardPage
