interface Props {
    onClick: () => void
    noMargin?: boolean,
}

/**
 * 
 * @param onClick the behaviour when clicking on the label
 * @returns a clickable label for downloading files
 */
const DownloadIconText: React.FC<Props> = ({
    onClick,
    noMargin,
}) => {
    return (
        <div
            className={`horizontal_flex_group justify_content_center align_items_center pointer ${noMargin ? "" : "margin_right_40"}`}
            onClick={onClick}
        >
            <div className="margin_right_5 lh0">
                <img src="/images/download-icon.svg" />
            </div>
            <div className="margin_left_5 lh0 uppercase w700 s14 lh17">
                Scarica
            </div>
        </div>
    )
}

export default DownloadIconText
