interface Props {
    image: string,
    text: string,
    onClick: () => void,
}

const HorizontalNavBarLogoAndText: React.FC<Props> = ({
    image,
    text,
    onClick,
}) => {
    return (
        <div className="height_100_percent">
            <div className="vertical_flex_group justify_content_flex_end align_items_center width_100_percent height_60_percent">
                <img className="pointer" src={image} onClick={onClick} />
            </div>
            <div className="vertical_flex_group justify_content_center white_text width_100_percent height_40_percent w500 s14 lh20 center">
                {text}
            </div>
        </div>
    )
}

export default HorizontalNavBarLogoAndText
