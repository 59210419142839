import HorizontalNavBarLogoAndText from "./HorizontalNavBarLogoAndText"

interface Props {
    homeLogo: string,
    onHomeIconClick: () => void,
    planLogo: string,
    onPlanIconClick: () => void,
    expertsLogo: string,
    onExpertsIconClick: () => void,
    menuLogo: string,
    onMenuIconClick: () => void,
}

const HorizontalNavBar: React.FC<Props> = ({
    homeLogo,
    onHomeIconClick,
    planLogo,
    onPlanIconClick,
    expertsLogo,
    onExpertsIconClick,
    menuLogo,
    onMenuIconClick,
}) => {
    return (
        <div className="horizontal-navbar">
            <div className="horizontal-navbar-logo-container">
                <HorizontalNavBarLogoAndText
                    image={homeLogo}
                    text="Home"
                    onClick={onHomeIconClick}
                />
            </div>
            <div className="horizontal-navbar-logo-container">
                <HorizontalNavBarLogoAndText
                    image={planLogo}
                    text="Piano"
                    onClick={onPlanIconClick}
                />
            </div>
            <div className="horizontal-navbar-logo-container">
                <HorizontalNavBarLogoAndText
                    image={expertsLogo}
                    text="Professionisti"
                    onClick={onExpertsIconClick}
                />
            </div>
            <div className="horizontal-navbar-logo-container">
                <HorizontalNavBarLogoAndText
                    image={menuLogo}
                    text="Menu"
                    onClick={onMenuIconClick}
                />
            </div>
        </div>
    )
}

export default HorizontalNavBar
