import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Expert from "../../components/Expert"
import { BASE_URL, requestOptionsGET } from "../../utils/Constants"
import { handleExpertRole, handleProductPrice } from "../../utils/Functions"
import { useAuth } from "../../utils/context/AuthContext"
import { useUserData } from "../../utils/context/UserDataContext"
import { CheckoutBundleType, CheckoutMode } from "../../utils/enums/Checkout"
import { ExpertGender, ExpertGenderRole } from "../../utils/enums/ExpertsGender"
import { NavigationToRewardPage } from "../../utils/enums/NavigationToRewardPage"
import { ShopifyProduct } from "../../utils/enums/ShopifyProduct"
import MonetizationSingleProductCard from "./cards/MonetizationSingleProductCard"
import MonetizationPricePurchaseButtonSection from "./components/MonetizationPricePurchaseButtonSection"
import NutritionistDetailsDialog from "./components/NutritionistDetailsDialog"
import PaymentInfoDialog from "./components/PaymentInfoDialog"
import RewardPaywallOtherOptionsSection from "./components/RewardPaywallOtherOptionsSection"
import "./css/PaywallSingleProduct.css"

/** A wall page with info about custom plan, allows to buy or to proceed to a comparison paywall */
const PaywallSingleProduct = () => {

    const { auth } = useAuth();
    const userId = auth.userId
    const idToken = auth.idToken!

    const { userData } = useUserData()
    const username = userData.name

    const navigate = useNavigate()
    const location = useLocation()
    const fromWhere: NavigationToRewardPage = location.state.fromWhere

    const [isLoading, setIsLoading] = useState(false)
    const [goal, setGoal] = useState("")

    const [showNutritionistDetailsDialog, setShowNutritionistDetailsDialog] = useState(false)
    const [showPaymentInfoDialog, setShowPaymentInfoDialog] = useState(false)

    const [nutritionist, setNutritionist] = useState<Expert>()

    const pushedProduct = ShopifyProduct.ThreeMonthsBundle

    // GET medical user info by patient
    async function callGetMedicalUserInfoApi() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/patient/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const nutri = await responseUserinfo.json()
            const newExpert: Expert = {
                id: nutri.id,
                position: nutri.data.sex === ExpertGender.Male ? ExpertGenderRole.Dott : ExpertGenderRole.Dottssa,
                name: nutri.data.name,
                familyName: nutri.data.family_name,
                role: handleExpertRole(nutri.role),
                skills: nutri.tags,
                summary: nutri.data.bio,
                avatar: nutri.calendly_data.calendly_user.avatar_url,
            }
            setNutritionist(newExpert)
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
        }
    }

    // GET user emr by user_id
    async function callAPIUserEmr() {
        const responseUserinfo = await fetch(`${BASE_URL}/api/emr/${userId}`, requestOptionsGET(idToken))
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setGoal(responseJson.data.avatar)
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    const onPurchaseClick = (product: ShopifyProduct) => {
        switch (product) {
            case ShopifyProduct.OneMonthSub:
                return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.DietSubscription } })
            case ShopifyProduct.OneMonthBundle:
                return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.DietBundle } })
            case ShopifyProduct.ThreeMonthsSub:
                return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.ThreeMonthsSubscription } })
            case ShopifyProduct.ThreeMonthsBundle:
                return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.ThreeMonthsBundle } })
            case ShopifyProduct.SixMonthsSub:
                return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.SixMonthsSubscription } })
            case ShopifyProduct.SixMonthsBundle:
                return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.SixMonthsBundle } })
            default:
                return
        }
    }

    const onNutritionistInfoClick = () => {
        setShowNutritionistDetailsDialog(true)
    }

    const onNutritionistInfoDismissClick = () => {
        setShowNutritionistDetailsDialog(false)
    }

    const onPaymentInfoClick = () => {
        setShowPaymentInfoDialog(true)
    }

    const onPaymentInfoDismissClick = () => {
        setShowPaymentInfoDialog(false)
    }

    const onOtherOptionsClick = () => {
        navigate("/paywall-multiple-choice", { state: { nutritionist, fromWhere } })
    }

    useEffect(() => {
        callAPIUserEmr()
        callGetMedicalUserInfoApi()
    }, [])

    // ********************************************************************

    return (
        <div className="App Quiz relative">
            <div className={`paywall-single-product-full-container ${(showNutritionistDetailsDialog || showPaymentInfoDialog) ? "visible_30_percent inactive" : ""}`}>
                <div className="paywall-single-product-logo-container">
                    <img src="/images/full-logo-new.svg" />
                </div>
                {
                    nutritionist !== undefined ?
                        <div className="paywall-single-product-content-container">
                            <div className="paywall-single-product-heading paywall-single-product-desktop-only">
                                Complimenti {username}! {String.fromCodePoint(0x1F60E)} <span className="w700">Questa è la tua soluzione su misura!</span>
                            </div>
                            <div className="paywall-single-product-heading paywall-single-product-mobile-only">
                                <div>Complimenti {username}! {String.fromCodePoint(0x1F60E)}</div>
                                <div className="w700">Questa è la tua soluzione su misura!</div>
                            </div>
                            <div className="paywall-single-product-full-card-container">
                                <MonetizationSingleProductCard
                                    nutritionist={nutritionist}
                                    product={pushedProduct}
                                    onInfoClick={onNutritionistInfoClick}/>
                                <MonetizationPricePurchaseButtonSection
                                    onPurchaseClick={() => onPurchaseClick(pushedProduct)}
                                    price={handleProductPrice(pushedProduct)}
                                    onInfoClick={onPaymentInfoClick}
                                />
                                <RewardPaywallOtherOptionsSection
                                    onClick={onOtherOptionsClick}
                                />
                            </div>
                        </div> : null
                }
            </div>
            {
                showNutritionistDetailsDialog ?
                    <NutritionistDetailsDialog
                        onCloseClick={onNutritionistInfoDismissClick}
                        nutritionist={nutritionist!}
                    /> : null
            }
            {
                showPaymentInfoDialog ?
                    <PaymentInfoDialog
                        onCloseClick={onPaymentInfoDismissClick}
                        product={pushedProduct}
                    /> : null
            }
        </div>
    )
}

export default PaywallSingleProduct
