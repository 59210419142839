import "./css/QuizQWidget.css"

interface Props {
    onClick: () => void,
}
const QuizQWidget: React.FC<Props> = ({
    onClick,
}) => {
    return (
        <div className={`quiz-q-widget-container`}>
            <div className={`quiz-q-widget-left-element-container`}>
                <div className={`quiz-q-widget-left-element-internal-container`}>
                    <div className="quiz-q-widget-heading">Scopri come sfruttare al massimo i risultati del tuo test</div>
                    <div className="quiz-q-widget-copy">Definisci i tuoi obiettivi e lasciati guidare da Holifya lungo l'intero percorso.
                        Inizia il tuo programma di nutrizione iper personalizzata costruito ad hoc per te, le tue esigenze, e il tuo DNA!
                    </div>
                    <button
                        className={`quiz-q-widget-answer-button`}
                        onClick={onClick}
                    >
                        Definisci il tuo obiettivo
                    </button>
                </div>
            </div>
            <div className={`quiz-q-widget-right-element-container`}>
            </div>
        </div>
    )
}

export default QuizQWidget
