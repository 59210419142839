import React from "react"
import HolifyaFullLogo from "./HolifyaFullLogo"
import LogoWithText from "./LogoWithText"

interface Props {
    onHolifyaLogoClick: () => void,
    homeLogo: string,
    onHomeIconClick: () => void,
    planLogo: string,
    onPlanIconClick: () => void,
    expertsLogo: string,
    onExpertsIconClick: () => void,
    menuLogo: string,
    onMenuIconClick: () => void,
    isLoading: boolean,
}

const NavBar: React.FC<Props> = ({
    onHolifyaLogoClick,
    homeLogo,
    onHomeIconClick,
    planLogo,
    onPlanIconClick,
    expertsLogo,
    onExpertsIconClick,
    menuLogo,
    onMenuIconClick,
    isLoading,
}) => {
    return (
        <div
            className={`vertical-navbar`}
        >
            <div
                className="margin_top_60 pointer"
                onClick={onHolifyaLogoClick}
            >
                <HolifyaFullLogo image="/images/full-logo-white.svg" />
            </div>
            <div className="height_450 width_100_percent">
                <div className="height_25_percent width_100_percent">
                    <LogoWithText
                        image={homeLogo}
                        text="Home"
                        onClick={onHomeIconClick}
                    />
                </div>
                <div className="height_25_percent width_100_percent">
                    <LogoWithText
                        image={planLogo}
                        text="Percorso"
                        onClick={onPlanIconClick}
                    />
                </div>
                <div className="height_25_percent width_100_percent">
                    <LogoWithText
                        image={expertsLogo}
                        text="Professionisti"
                        onClick={onExpertsIconClick}
                    />
                </div>
                <div className="height_25_percent width_100_percent">
                    <LogoWithText
                        image={menuLogo}
                        text="Menu"
                        onClick={onMenuIconClick}
                    />
                </div>
            </div>
            <div className="invisible margin_bottom_60">
                <HolifyaFullLogo image="/images/full-logo-white.svg" />
            </div>
        </div>
    )
}

export default NavBar
