import "./css/MyAnswersSingleAnswerCard.css"

interface Props {
    question: string,
    answer: string
    onEditClick: () => void,
}

const MyAnswersSingleAnswerCard: React.FC<Props> = ({
    question,
    answer,
    onEditClick,
}) => {
    return (
        <div className="my-answers-single-answer-card-container">
            <div className="my-answers-single-answer-card-internal-container">
                <div className="my-answers-single-answer-card-left-element-container">
                    <div className="my-answers-single-answer-card-question">
                        {question}
                    </div>
                    <div className="my-answers-single-answer-card-answer">
                        {answer}
                    </div>
                </div>
                <button
                    className="my-answers-single-answer-edit-button"
                    onClick={onEditClick}
                >
                    Modifica
                </button>
            </div>
        </div>
    )
}

export default MyAnswersSingleAnswerCard
