interface Props {
  image?: string,
}

const HolifyaFullLogo: React.FC<Props> = ({
  image,
}) => {
  const logo = image === undefined ? "/images/full-logo-new.svg" : image
  return (
    <img className="margin_5" src={logo} />
  )
}

export default HolifyaFullLogo
