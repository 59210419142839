import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HolifyaFullLogo from "../../components/HolifyaFullLogo";
import { useUserData } from "../../utils/context/UserDataContext";
import { NavigationToInsertCodePage } from "../../utils/enums/NavigationToInsertCodePage";
import { NavigationToQuizQ } from "../../utils/enums/NavigationToQuizQ";
import OnboardingForkCard from "./components/OnboardingForkCard";
import "./css/OnboardingFork.css";

const OnboardingFork = () => {

    const navigate = useNavigate();

    const { userData } = useUserData()
    const username = userData.name

    const handleAlreadyPurchased = () => {
        navigate("/insert-product-code", { state: { fromWhere: NavigationToInsertCodePage.OnboardingFork } })
    }

    const handleNoPurchasesYet = () => {
        navigate("/quiz-q-home", { state: { fromWhere: NavigationToQuizQ.OnboardingFork } })
    }

    useEffect(() => {
    }, [])

    // **********************************************************************

    return (
        <div className="App Quiz">
            <div className="onboarding-fork-container">
                <div className="onboarding-fork-logo-container">
                    <HolifyaFullLogo />
                </div>
                <div className="onboarding-fork-content-container">
                    <div className="onboarding-fork-content-internal-container">
                        <div className="onboarding-fork-text-container">
                            <div>Ciao <span className="w700">{username}</span>! {String.fromCodePoint(0x1F44B)}</div>
                            <div>Felici di vederti qui!</div>
                        </div>
                        <div className="onboarding-fork-cards-container">
                            <OnboardingForkCard
                                onClick={handleAlreadyPurchased}
                                copy={"Ho già effettuato acquisti nello store Holifya!"}
                            />
                            <OnboardingForkCard
                                onClick={handleNoPurchasesYet}
                                copy={"Non ho ancora effettuato acquisti nello store Holifya."}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnboardingFork