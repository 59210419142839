import "./css/PaywallExpertTagCard.css"

interface Props {
    skill: string,
}

const PaywallExpertTagCard: React.FC<Props> = ({
    skill,
}) => {
  return (
    <div className="paywall-expert-tag-card-container">
      {skill}
    </div>
  )
}

export default PaywallExpertTagCard
