import { useEffect, useState } from "react";
import { PasswordInputIcon } from "../../utils/enums/PasswordInputIcon";
import { PasswordInputType } from "../../utils/enums/PasswordInputType";
import "./css/PasswordInputElement.css";

/**
 * Props interface for PasswordInputElement.
 * 
 * @property {string} name - the name of the input field
 * @property {string} label - The label text for the input field.
 * @property {string} placeholder - The placeholder text for the input field.
 * @property {boolean} disabled - Whether the input field is disabled.
 * @property {PasswordInputIcon} image - The image URL for the toggle visibility icon.
 * @property {PasswordInputType} type - The type of the input field (password or text).
 * @property {string} inputValue - The current value of the input field.
 * @property {boolean} toggleTypeEnabled - Whether the toggle visibility icon is enabled.
 * @property {() => void} onIconClick - Callback function to handle icon clicks.
 * @property {(e: React.ChangeEvent<HTMLInputElement>) => void} onChange - Callback function to handle input changes.
 * @property {string} errorText - The error message to display.
 * @property {() => void} [onBlur] - Optional callback function to handle input blur events.
 */
interface Props {
    name?: string,
    label: string,
    placeholder: string,
    disabled: boolean,
    image: PasswordInputIcon,
    type: PasswordInputType,
    inputValue: string,
    toggleTypeEnabled: boolean,
    onIconClick: () => void,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    errorText: string,
    onBlur?: () => void,
}

/**
 * PasswordInputElement Component
 * 
 * A reusable password input component with label, placeholder, error message, and change/blur handlers.
 * 
 * @param {Props} props - The props for the component.
 * @returns {JSX.Element} The rendered password input element.
 */
const PasswordInputElement: React.FC<Props> = ({
    name,
    label,
    placeholder,
    disabled,
    image,
    type,
    inputValue,
    toggleTypeEnabled,
    onIconClick,
    onChange,
    errorText,
    onBlur,
}) => {
    const [inputType, setInputType] = useState<PasswordInputType>(type);
    const [icon, setIcon] = useState<PasswordInputIcon>(image)

    const handleTogglePasswordType = () => {
        setInputType((prevType) =>
            prevType === PasswordInputType.Password ? PasswordInputType.Text : PasswordInputType.Password
        );
        setIcon((prevIcon) => {
            switch (prevIcon) {
                case PasswordInputIcon.ShowDisabled:
                    return PasswordInputIcon.HideDisabled;
                case PasswordInputIcon.HideDisabled:
                    return PasswordInputIcon.ShowDisabled;
                case PasswordInputIcon.ShowEnabled:
                    return PasswordInputIcon.HideEnabled;
                case PasswordInputIcon.HideEnabled:
                    return PasswordInputIcon.ShowEnabled;
            }
        })
    };

    useEffect(() => {
        if (toggleTypeEnabled) {
            if (icon === PasswordInputIcon.ShowDisabled) {
                setIcon(PasswordInputIcon.ShowEnabled)
            } else {
                setIcon(PasswordInputIcon.HideEnabled)
            }
        } else {
            if (icon === PasswordInputIcon.ShowEnabled) {
                setIcon(PasswordInputIcon.ShowDisabled)
            } else if (icon === PasswordInputIcon.HideEnabled) {
                setIcon(PasswordInputIcon.HideDisabled)
            }
        }
    }, [toggleTypeEnabled])

    // ********************************************************************************

    return (
        <div className="password-input-element-container">
            <label
                className="password-input-element-label"
                htmlFor={`password-input-${label}`}
            >
                {label}
            </label>
            <div className={`password-input-element-input-image-container ${disabled ? "password-input-border-bottom-disabled" : "password-input-border-bottom-enabled"}`}>
                <input
                    name={name}
                    id={`password-input-${label}`}
                    className="password-input-element-input-field"
                    placeholder={placeholder}
                    value={inputValue}
                    type={inputType}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <img
                    className={`password-input-element-image ${toggleTypeEnabled && "pointer"}`}
                    onClick={toggleTypeEnabled ? handleTogglePasswordType : undefined}
                    src={icon}
                    alt="Toggle visibility"
                />
            </div>
            {errorText && <div className="password-input-element-error-text" aria-live="polite">
                {errorText}
            </div>}
        </div>
    )
}

export default PasswordInputElement
