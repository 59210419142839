import "./css/UpdateTrackingWidget.css"

interface Props {
    onClick: () => void,
}

const UpdateTrackingWidget: React.FC<Props> = ({
    onClick,
}) => {
    return (
        <div className="update-tracking-widget-full-container">
            <div className="update-tracking-widget-left-element-container">
                <div className="update-tracking-widget-left-element-heading">
                    Il tuo kit è già arrivato?
                </div>
                <div className="update-tracking-widget-left-element-copy">
                    Se il tuo kit è già arrivato a casa e non si è ancora aggiornato il tracking clicca qui!
                </div>
            </div>
            <button
                className="update-tracking-widget-button primary_action_color_background"
                onClick={onClick}
            >
                Ho già ricevuto il kit
            </button>
        </div>
    )
}

export default UpdateTrackingWidget
