import "./css/UploadExamWidget.css"

interface Props {
    onUploadClick : () => void,
}

const UploadExamWidget: React.FC<Props> = ({
    onUploadClick,
}) => {
    return (
        <div className="upload-exam-widget-container">
            <div className="upload-exam-widget-upper-element-container">
                <div className="upload-exam-widget-upper-element-internal-container">
                    <div className="upload-exam-widget-upper-element-heading">Carica esami</div>
                    <div className="upload-exam-widget-upper-element-copy">
                        Se hai degli esami che vuoi condividere con il tuo<br></br>professionista puoi farlo qui!
                    </div>
                </div>
            </div>
            <div className="upload-exam-widget-bottom-element-container">
                <button
                    className="upload-exam-widget-bottom-element-button"
                    onClick={onUploadClick}
                >
                    Carica
                </button>
            </div>
        </div>
    )
}

export default UploadExamWidget
