export enum NavigationToRewardPage {
    OnboardingAfterPurchaseNutritionistConsult = "onboarding-after-purchase-nutritionist-consult",
    OnboardingAfterRedeemSub = "onboarding-after-redeem-sub",
    LoginAfterPurchaseNutritionistConsult = "login-after-purchase-nutritionist-consult",
    LoginAfterPurchaseShopifySub = "login-after-purchase-shopify-sub",
    OnboardingNoPurchase = "onboarding-no-purchase",
    LoginNoPurchase= "login-no-purchase",
    Login = "login",
    LoginWithSub = 'login-with-sub',
    Platform = "platform",
    PlatformPlan = "platform-plan",
    PlatformExperts = "platform-experts",
}