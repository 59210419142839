import CloseButton from "../../../components/CloseButton"
import { Mood } from "../../../utils/enums/Mood"
import "./css/MoodDialog.css"

interface Props {
    mood: Mood,
    moodLog: string,
    onMoodLogChange: (e : React.ChangeEvent<HTMLInputElement>) => void,
    onDismissClick: () => void,
    onDontSendButtonClick: () => void,
    onSendButtonClick: () => void,
}

const MoodDialog: React.FC<Props> = ({
    mood,
    moodLog,
    onMoodLogChange,
    onDismissClick,
    onDontSendButtonClick,
    onSendButtonClick,
}) => {

    const handleImage = () => {
        switch (mood) {
            case Mood.Good:
                return "/images/your-feelings-positive-vote.svg"
            case Mood.Bad:
                return "/images/your-feelings-negative-vote.svg"
            default:
                return ""
        }
    }

    const handleHeading = () => {
        switch (mood) {
            case Mood.Good:
                return "Mi sento bene!"
            case Mood.Bad:
                return "Non troppo bene!"
            default:
                return ""
        }
    }

    const handleCopy = () => {
        switch (mood) {
            case Mood.Good:
                return "Fantastico!"
            case Mood.Bad:
                return "Ti aiutiamo noi!"
            default:
                return ""
        }
    }

    return (
        <div className="mood-dialog-container center-div">
            <div className="mood-dialog-internal-container">
                <div className="horizontal_flex_group justify_content_space_between align_items_start">
                    <div className="horizontal_flex_group align_items_center">
                        <img src={handleImage()} />
                        <div className="mood-dialog-heading">{handleHeading()}</div>
                    </div>
                    <CloseButton
                        onClick={onDismissClick}
                    />
                </div>
                <div className="mood-dialog-copy">
                    <span className="w700">{handleCopy()}</span><br></br>
                    Vuoi condividere con i tuoi professionisti due righe?
                </div>
                {/* <textarea className="mood-dialog-textarea"/> */}
                <div
                    className={`mood-dialog-textarea`}
                >
                    <input
                        className={`mood-dialog-input-field ls1`}
                        type="text"
                        name="choice"
                        onChange={e => onMoodLogChange(e)}
                        value={moodLog}
                        autoComplete="off"
                        autoFocus
                    />
                </div>
                <div className="mood-dialog-buttons-row">
                    <button
                        className="mood-dialog-dont-send-info-button"
                        onClick={onDontSendButtonClick}
                    >
                        No, grazie
                    </button>
                    <button
                        className={`mood-dialog-send-info-button primary_action_color_background ${moodLog === "" ? "" : "pointer"}`}
                        onClick={onSendButtonClick}
                        disabled={moodLog ===  ""}
                    >
                        Invia
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MoodDialog
