interface Props {
    image: string,
    text: string,
    onClick?: () => void,
}

const MenuPopupCategory: React.FC<Props> = ({
    image,
    text,
    onClick,
}) => {
    return (
        <div
            className={`horizontal_flex_group align_items_center margin_bottom_20 pointer width_80_percent height_35 relative`}
            onClick={onClick}
        >
            <div className="width_40">
                <img className="margin_right_10" src={image} />
            </div>
            <div className={`menu-popup-element-text`}>{text}</div>
            {
                (text === "Termini e condizioni" || text === "Privacy policy") ?
                    <a
                        className="width_100_percent height_100_percent absolute"
                        href={`${text === "Privacy policy" ? "/assets/privacy-policy.pdf" : "/assets/personal-data-treatment.pdf"}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    /> : null
            }
            {
                text === "Scrivici" ?
                    <a
                        className="width_100_percent height_100_percent absolute"
                        href="mailto:support@holifya.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    /> : null
            }
        </div>
    )
}

export default MenuPopupCategory
