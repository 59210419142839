import CloseButton from "../../../../components/CloseButton"
import Expert from "../../../../components/Expert"
import ExpertsTagCard from "../../../../components/ExpertsTagCard"
import "./css/ExpertDetailsDialog.css"

interface Props {
    onCloseClick: () => void,
    expert: Expert,
}

const ExpertDetailsDialog: React.FC<Props> = ({
    onCloseClick,
    expert,
}) => {
    return (
        <div className="expert-details-dialog-container center-div">
            <div className="expert-details-dialog-internal-container">
                <div className="expert-details-photo-name-row">
                    <div className="expert-details-dialog-photo-container">
                        <img className="expert-details-dialog-photo" src={expert.avatar}/>
                    </div>
                    <div className="expert-details-dialog-name-button-container">
                        <div className="expert-details-dialog-expert-container">
                            <div className="expert-details-dialog-expert-position">
                                {expert.position}
                            </div>
                            <div className="expert-details-dialog-expert-name">
                                {expert.name} {expert.familyName}
                            </div>
                            <div className="expert-details-dialog-expert-role">
                                {expert.role}
                            </div>
                        </div>
                        <CloseButton
                            onClick={onCloseClick}
                            noFlexEnd={true}
                        />
                    </div>
                </div>
                <div className="experts-details-dialog-tags-row">
                    {
                        expert.skills.map(skill => (
                            <ExpertsTagCard
                                key={expert.skills.indexOf(skill)}
                                name={skill}
                                isLast={expert.skills.indexOf(skill) === expert.skills.length - 1}
                            />
                        ))
                    }
                </div>
                <div className="expert-details-dialog-bio-container">
                    <div className="w700 s12 lh20">
                        Su di me
                    </div>
                    <div className="experts-details-dialog-box">
                        {
                            expert.summary
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpertDetailsDialog
