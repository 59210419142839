import "./css/YourPlanWidget.css"

interface Props {
    onClick: () => void,
}

const YourPlanWidget: React.FC<Props> = ({
    onClick,
}) => {
    return (
        <div className="your-plan-widget-container">
            <div className="your-plan-widget-upper-element-container">
                <div className="your-plan-widget-upper-element-internal-container">
                    <div className="your-plan-widget-upper-element-heading">
                        Il tuo percorso
                    </div>
                    <div className="your-plan-widget-upper-element-copy">
                        Consulta il documento del tuo percorso alimentare
                    </div>
                </div>
            </div>
            <div className="your-plan-widget-bottom-element-container">
                <button
                    className="your-plan-widget-bottom-element-button"
                    onClick={onClick}
                >
                    Apri
                </button>
            </div>
        </div>
    )
}

export default YourPlanWidget
