import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HorizontalLogoText from "../../../../components/HorizontalLogoText";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET } from "../../../../utils/Constants";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem";
import MenuPopup from "../../components/MenuPopup";
import AddPaymentMethodCard from "./components/AddPaymentMethodCard";
import AlertDialog from "./components/AlertDialog";
import NoPaymentMethodsCard from "./components/NoPaymentMethodsCard";
import { PaymentCard } from "./components/PaymentCard";
import PaymentMethodCard from "./components/PaymentMethodCard";
import "./css/PaymentMethod.css";

const PaymentMethod = () => {

  // Navigation parameters
  const navigate = useNavigate();

  const { auth } = useAuth()
  const idToken = auth.idToken!
  const userId = auth.userId

  const { userData } = useUserData()
  const username = userData.name!
  const familyName = userData.familyName!
  const email = userData.email!

  // Booleans to display either menu popup or loading spinner
  const [isLoading, setIsLoading] = useState(false)
  const [focusOnMenu, setFocusOnMenu] = useState(false)

  // Actions to perform when clicking on Navbar logos and Holifya icon
  const onHolifyaLogoClick = () => {
    navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
  }
  const onHomeIconClick = () => {
    navigate(RELATIVE_PATH_TO_PLATFORM)
  }
  const onPlanIconClick = () => {
    navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
  }
  const onExpertsIconClick = () => {
    navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
  }
  const onMenuIconClick = () => {
    setFocusOnMenu(!focusOnMenu)
  }

  // GET users info by user_id
  async function callAPIUserInfo() {
    // setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
    // setIsLoading(false)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
    } else if (responseUserinfo.status === 401) {
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    callAPIUserInfo()
    callAPIGetUserpayments()
    // callApiLoadStripe()
    // TODO: try to understand why it takes more time to refresh
    // setTimeout(() => {
    //   setIsLoading(false)
    // }, 900)
  }, [])

  // *******************************************************************************************************************

  // const [stripeKey, setStripeKey] = useState("")

  // async function callApiLoadStripe() {
  //   const response = await fetch(`${BASE_URL}/api/payment/config`, requestOptionsGET)
  //   const responseJson = await response.json()
  //   if (response.ok) {
  //     setStripeKey(responseJson.stripe_public_key)
  //   }
  // }

  // GET users payments by user_id
  async function callAPIGetUserpayments() {
    // setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/payment/${userId}`, requestOptionsGET(idToken))
    setIsLoading(false)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      if (responseJson.length === 0) {
        console.log(userId)
      } else {
        console.log(responseJson)
        var index = 0
        const cards: PaymentCard[] = []
        while (responseJson[index] !== undefined) {
          cards.push({
            id: responseJson[index].id,
            circuit: responseJson[index].brand,
            expiryMonth: responseJson[index].exp_month,
            expiryYear: responseJson[index].exp_year,
            isDefault: responseJson[index].is_default,
            last4digits: responseJson[index].last4,
          })
          if (responseJson[index].is_default) {
            setDefaultChoice(responseJson[index].id)
          }
          index++
        }
        if (defaultChoice === "") {
          setDefaultChoice(cards[0].id)
        }
        setPaymentCards(cards)
      }
    } else if (responseUserinfo.status === 401) {
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  // DELETE user payment by payment_method_id
  async function callAPIDeleteUserPayment(paymentId: string) {
    setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/payment/${paymentId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': idToken,
      },
    })
    setIsLoading(false)
    if (responseUserinfo.ok) {
      callAPIGetUserpayments()
    } else if (responseUserinfo.status === 401) {
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  // UPDATE user default payment
  async function callApiUpdateDefaultCard() {
    setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/payment`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': idToken,
      },
      body: JSON.stringify(
        {
          user_id: userId,
          payment_method_id: defaultChoice,
        })
    })
    setIsLoading(false)
    if (responseUserinfo.ok) {
      callAPIGetUserpayments()
    } else if (responseUserinfo.status === 401) {
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  // Booleans to display dialogs
  const [showUpdateDefaultDialog, setShowUpdateDefaultDialog] = useState(false)
  const [showCannotRemoveDefaultCardDialog, setShowCannotRemoveDefaultCardDialog] = useState(false)
  const [showCannotRemoveUniqueCardDialog, setShowCannotRemoveUniqueCardDialog] = useState(false)

  // Boolean to display the error when willing to update default method but there is just one
  const [showUpdateDeafultError, setShowUpdateDeafultError] = useState(false)

  // Initialization of payment methods
  const [paymentCards, setPaymentCards] = useState<PaymentCard[]>([
    {
      id: "",
      circuit: "",
      expiryMonth: "",
      expiryYear: "",
      last4digits: "",
      isDefault: false,
    }
  ])

  // Radio choice when changing the default payment
  const [defaultChoice, setDefaultChoice] = useState("")

  // Boolean to check if the update default payment button is enabled
  const updateDefaultCardButtonDisabled = paymentCards.filter(card => card.id === defaultChoice)[0].isDefault

  const onAddNewPaymentMethodClick = () => {
    if (paymentCards.length === 5) {
      window.alert("massimo 5, rimuovine 1")
    } else {
      navigate("add-new-payment-method")
    }
  }

  // When user confirms the new default payment
  const onUpdateDefaultCardClicked = () => {
    callApiUpdateDefaultCard()
    setShowUpdateDefaultDialog(false)
  }

  // When the user clicks on the delete button for a single payment method
  const onDeletePaymentMethodClick = (paymentId: string, isDefault: boolean) => {
    if (paymentCards.length === 1) {
      setShowCannotRemoveUniqueCardDialog(true)
    } else {
      if (isDefault) {
        setShowCannotRemoveDefaultCardDialog(true)
      } else {
        callAPIDeleteUserPayment(paymentId)
      }
    }
  }

  //*********************************UTILITY FUNCTIONS***********************************

  const calculateCardsContainerHeight = () => {
    switch (paymentCards.length) {
      case 1:
        return ""
      case 2:
        return "payment-method-2-cards-container"
      case 3:
        return "payment-method-3-cards-container"
      case 4:
        return "payment-method-4-cards-container"
      case 5:
        return "payment-method-5-cards-container"
    }
  }

  const handleUpdateDefaultContainerHeight = () => {
    switch (paymentCards.length) {
      case 1:
        return ""
      case 2:
        return "payment-method-update-default-dialog-container-2-cards-height"
      case 3:
        return "payment-method-update-default-dialog-container-3-cards-height"
      case 4:
        return "payment-method-update-default-dialog-container-4-cards-height"
      case 5:
        return "payment-method-update-default-dialog-container-5-cards-height"
    }
  }

  const handleRadioButtonsContainer = () => {
    switch (paymentCards.length) {
      case 1:
        return ""
      case 2:
        return "payment-method-update-default-dialog-radio-button-container-2-cards-height"
      case 3:
        return "payment-method-update-default-dialog-radio-button-container-3-cards-height"
      case 4:
        return "payment-method-update-default-dialog-radio-button-container-4-cards-height"
      case 5:
        return "payment-method-update-default-dialog-radio-button-container-5-cards-height"
    }
  }

  return (
    <div className="App Quiz justify_content_center relative">
      <NavBar
        onHolifyaLogoClick={onHolifyaLogoClick}
        homeLogo={HOME_LOGO}
        onHomeIconClick={onHomeIconClick}
        planLogo={PLAN_LOGO}
        onPlanIconClick={onPlanIconClick}
        expertsLogo={EXPERTS_LOGO}
        onExpertsIconClick={onExpertsIconClick}
        menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
        onMenuIconClick={onMenuIconClick}
        isLoading={isLoading}
      />
      <div
        className={`platform-full-screen-no-navbar-container relative ${(isLoading) ? "visible-0-percent inactive" : ""}`}
      >
        {
          focusOnMenu &&
          <MenuPopup
            currentPage={PlatformMenuItem.PaymentMethod}
            onEscapeClick={onMenuIconClick}
          />
        }
        <div className={`platform-payment-method-full-container ${(focusOnMenu || showUpdateDefaultDialog || showCannotRemoveDefaultCardDialog || showCannotRemoveUniqueCardDialog) ? "visible_30_percent inactive" : ""}`}>
          <div className="height_10_percent width_100_percent">
            <HorizontalLogoText
              image={"/images/payment-method-big-logo.svg"}
              text="Il tuo metodo di pagamento"
              isInOwnView={true}
            />
          </div>
          <div className="vertical-scroll-group-90 width_100_percent">
            <div className="platform-final-element-container">
              <AddPaymentMethodCard
                onClick={onAddNewPaymentMethodClick}
              />
              <div className="height_20"></div>
              {
                paymentCards[0].circuit === "" ?
                  <NoPaymentMethodsCard /> :
                  <div className={`payment-method-cards-container ${calculateCardsContainerHeight()}`}>
                    {
                      paymentCards.map(card => (
                        <PaymentMethodCard
                          key={paymentCards.indexOf(card)}
                          onDeleteMethodClick={() => onDeletePaymentMethodClick(card.id, card.isDefault)}
                          showDeleteButton={true}
                          circuit={card.circuit}
                          expiryMonth={card.expiryMonth}
                          expiryYear={card.expiryYear}
                          last4Digits={card.last4digits}
                        />
                      ))
                    }
                  </div>
              }
              {
                paymentCards[0].circuit !== "" ?
                  <div className="">
                    <div className="height_35" />
                    <div className="payment-method-my-payments-container">
                      <div className="horizontal_flex_group justify_content_space_between align_items_center">
                        <div className="w700 s20 lh40">Il tuo abbonamento</div>
                        <div
                          className="w700 s14 lh17 uppercase pointer primary_action_color_text"
                          onClick={() => paymentCards.length === 1 ? setShowUpdateDeafultError(true) : setShowUpdateDefaultDialog(true)}
                        >
                          Modifica
                        </div>
                      </div>
                      <div className="height_20" />
                      {
                        paymentCards.map(card => (
                          card.isDefault ?
                            <PaymentMethodCard
                              key={paymentCards.indexOf(card)}
                              showDeleteButton={false}
                              circuit={card.circuit}
                              expiryMonth={card.expiryMonth}
                              expiryYear={card.expiryYear}
                              last4Digits={card.last4digits}
                            /> :
                            null
                        ))
                      }
                      {
                        showUpdateDeafultError ?
                          <div className="payment-method-update-default-error-message error_color_text">
                            Per modificare metodo di pagamento per il tuo abbonamento devi prima aggiungerne uno nuovo
                          </div>
                          : null
                      }
                    </div>
                    <div className="height_35" />
                  </div> : null
              }
            </div>
          </div>
        </div>
        {
          showUpdateDefaultDialog ?
            <div className={`payment-method-update-default-dialog-container center-div absolute ${handleUpdateDefaultContainerHeight()}`}>
              <div className="payment-method-update-default-dialog-internal-container">
                <div className="horizontal_flex_group justify_content_space_between align_items_center">
                  <div className="w400 s16 lh40">Seleziona il metodo di pagamento per il tuo abbonamento</div>
                  <div
                    className="pointer"
                    onClick={() => setShowUpdateDefaultDialog(false)}
                  >
                    <img src="/images/x.svg" />
                  </div>
                </div>
                <div className={`payment-method-update-default-dialog-radio-button-container ${handleRadioButtonsContainer()}`}>
                  {
                    paymentCards.map(card => (
                      <div
                        key={card.id}
                        className="horizontal_flex_group align_items_center pointer"
                        onClick={() => setDefaultChoice(card.id)}
                      >
                        <input
                          className=""
                          type="radio"
                          value={card.id}
                          id={card.id}
                          checked={true}
                          onClick={() => console.log("clicked")}
                          readOnly
                        />
                        <div className="margin_right_20">
                          <div
                            className="horizontal_flex_group justify_content_center align_items_center platform-payment-method-update-default-dialog-radio-container"
                          >
                            <div className={`platform-payment-method-update-default-dialog-radio-internal-element ${card.id === defaultChoice ? "" : "invisible"}`}>
                            </div>
                          </div>
                        </div>
                        <div>
                          {card.circuit} | **** **** **** {card.last4digits} | {card.expiryMonth}/{card.expiryYear}
                        </div>
                      </div>
                    )
                    )
                  }
                </div>
                <button
                  className={`payment-method-update-default-dialog-confirm-button primary_action_color_background ${updateDefaultCardButtonDisabled ? "" : "pointer"}`}
                  disabled={updateDefaultCardButtonDisabled}
                  onClick={onUpdateDefaultCardClicked}
                >
                  Conferma
                </button>
              </div>
            </div> : null
        }
        {
          showCannotRemoveDefaultCardDialog ?
            <AlertDialog
              text="Non puoi rimuovere una carta associata ad un pagamento, prima cambia il metodo di pagamento automatico"
              onClick={() => setShowCannotRemoveDefaultCardDialog(false)}
            /> : null
        }
        {
          showCannotRemoveUniqueCardDialog ?
            <AlertDialog
              text="Non puoi rimuovere l'unica carta associata, prima aggiungine un'altra"
              onClick={() => setShowCannotRemoveUniqueCardDialog(false)}
            /> : null
        }
      </div>
      {
        isLoading ? <div className="width_100_percent height_100_percent center-div absolute"><LoadingSpinner /></div> : null
      }
      <HorizontalNavBar
        homeLogo={HOME_LOGO}
        onHomeIconClick={onHomeIconClick}
        planLogo={PLAN_LOGO}
        onPlanIconClick={onPlanIconClick}
        expertsLogo={EXPERTS_LOGO}
        onExpertsIconClick={onExpertsIconClick}
        menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
        onMenuIconClick={onMenuIconClick}
      />
    </div>
  )
}

export default PaymentMethod
