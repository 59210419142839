import React, { useState } from "react";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import TextInputElement from "../../../../components/input/TextInputElement";
import "./css/ForgotPasswordInsertCodeBox.css";

interface Props {
    onSubmit: (inputData: { [key: string]: string }) => void;
    onResendCodeClick: () => void,
}

const ForgotPasswordInsertCodeBox: React.FC<Props> = ({
    onSubmit,
    onResendCodeClick,
}) => {

    const [inputs, setInputs] = useState<{ [key: string]: string }>({
        code: '',
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setInputs((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        onSubmit(inputs);
    }

    // ********************************************************************************
    return (
        <div className="forgot-password-insert-code-box-full-container">
            <div className="forgot-password-insert-code-box-heading">
                Inserisci codice
            </div>
            <div className="forgot-password-insert-code-box-copy">
                Hai ricevuto per e-mail un codice a 6 cifre per verificare il tuo indirizzo e-mail. Inseriscilo qui per reimpostare una nuova password.
            </div>
            <form
                className="forgot-password-insert-code-box-form"
                onSubmit={handleSubmit}
            >
                <TextInputElement
                    name="code"
                    label="Codice recupero password"
                    placeholder="000000"
                    errorText={""}
                    inputValue={inputs.code}
                    onChange={handleInputChange}
                />
                <div className="forgot-password-insert-code-box-confirm-button">
                    <PrimaryButton
                        text="Conferma codice"
                        pointer={inputs.code.length === 6}
                        disabled={!(inputs.code.length === 6)}
                    />
                </div>
            </form>
            <div className="forgot-password-insert-code-box-resend-button">
                <WhiteButton
                    text="Invia nuovo codice"
                    onClick={onResendCodeClick}
                    pointer={true}
                />
            </div>
        </div>
    )
}

export default ForgotPasswordInsertCodeBox
