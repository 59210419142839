import { OrderFound } from "../api-objects/OrderFound";
import { OrderRetrieved } from "../api-objects/OrderRetrieved";
import { BASE_URL, requestOptionsGET } from "../Constants";

/**
 * Type for the callback function invoked upon get orders success.
 */
type GetOrdersSuccessCallback = (orders: OrderRetrieved[]) => void;

/**
 * Type for the callback function invoked upon a generic success.
 */
type SuccessCallback = () => void;

/**
 * Type for the callback function invoked upon a generic error.
 */
type ErrorCallback = () => void;

export async function getOrders(
    userId: string,
    idToken: string,
    onSuccess: GetOrdersSuccessCallback,
    onError: ErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const orders: OrderRetrieved[] = await response.json()
            onSuccess(orders)
        } else {
            onError()
        }
    } catch (error) {
        console.error("Get orders failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

/**
 * Type for the callback function invoked upon find orders success.
 */
type FindOrderSuccessCallback = (orders: OrderFound) => void;

/**
 * Type for the callback function invoked upon a find orders error.
 */
type FindOrderErrorCallback = (error: string) => void;

export async function findOrder(
    idToken: string,
    confirmationCode: string,
    onSuccess: FindOrderSuccessCallback,
    onError: FindOrderErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/orders/find/${confirmationCode}`, requestOptionsGET(idToken))
        if (response.ok) {
            const orders = await response.json()
            onSuccess(orders)
        } else {
            switch (response.status) {
                case 400:
                    return onError("Ordine già riscattato")
                case 401:
                    return onError("Sessione scaduta")
                case 404:
                    return onError("Ordine non trovato")
                default:
                    return onError("Errore di recupero datu")
            }
        }
    } catch (error) {
        console.error("Find order failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function saveOrder(
    userId: string,
    idToken: string,
    order: OrderFound,
    onSuccess: SuccessCallback,
    onError: ErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    shopify_id: order.shopify_id,
                    confirmation_number: order.confirmation_number,
                    items: order.items,
                }
            ),
        })
        if (response.ok) {
            onSuccess()
        } else {
            onError()
        }
    } catch(error) {
        console.error("Save order failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}