import CloseButton from "../../../components/CloseButton"
import "./css/InfoDialog.css"

interface Props {
  onCloseClick: () => void,
}

const InfoDialog: React.FC<Props> = ({
  onCloseClick,
}) => {
  return (
    <div className="info-dialog-container">
      <div className="info-dialog-internal-container">
        <div className="info-dialog-title-button-row">
          <div className="info-dialog-title">
            Hai più prodotti associati al tuo codice acquisto!
          </div>
          <CloseButton
            onClick={onCloseClick}
          />
        </div>
        <div className="info-dialog-copy">
          Avendo più prodotti associabili singolarmente a profili differenti in un unico
          codice acquisto ti permette di utilizzare lo stesso codice da utenze diverse,
          una per ogni prodotto riscattabile.
        </div>
      </div>
    </div>
  )
}

export default InfoDialog
