import { ShopifyProduct } from "../../../utils/enums/ShopifyProduct"
import "./css/PaywallOtherOptionsSection.css"

interface Props {
  onOtherOptionsClick?: () => void,
  pushedProduct: ShopifyProduct,
  testIncluded?: boolean,
  onTestIncludedClick?: () => void,
  onInsertProductCodeClicked?: () => void,
  isDismissible?: boolean,
}

const PaywallOtherOptionsSection: React.FC<Props> = ({
  onOtherOptionsClick,
  pushedProduct,
  testIncluded,
  onTestIncludedClick,
  onInsertProductCodeClicked,
  isDismissible,
}) => {
  return (
    <div className="paywall-other-options-section-full-container">
      <div className="paywall-other-options-section-internal-container">
        {
          pushedProduct === ShopifyProduct.Choices ?
            <div className={`paywall-other-options-section-dna-toggle-container ${isDismissible ? "invisible" : ""}`}>
              <div className="margin_right_20">
                Test del DNA incluso nell'offerta
              </div>
              <div
                className="pointer"
                onClick={onTestIncludedClick}
              >
                <img src={`${testIncluded ? "/images/toggle-yes.svg" : "/images/toggle-no.svg"}`} />
              </div>
            </div>
            :
            <div
              className="paywall-other-options-section-other-options-button"
              onClick={onOtherOptionsClick}
            >
              Altre opzioni d'acquisto
            </div>
        }
        {
          pushedProduct === ShopifyProduct.Choices ?
            <div className="paywall-other-options-section-buttons-container">
              <button
                className="paywall-other-options-section-insert-code-button"
                onClick={onInsertProductCodeClicked}
              >
                Ho già un codice acquisto
              </button>
            </div> : null
        }
      </div>
    </div>
  )
}

export default PaywallOtherOptionsSection
