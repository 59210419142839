import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { loginApi } from "../../../utils/apis/auth-service";
import { useAuth } from "../../../utils/context/AuthContext";
import "./css/ChangePasswordSuccess.css";

const ChangePasswordSuccess = () => {

    const { login } = useAuth();

    const navigate = useNavigate();
    const location = useLocation()
    const email = location.state.email
    const password = location.state.password

    useEffect(() => {
        setTimeout(() => { 
            loginApi(
                email,
                password,
                (result) => {
                    login(result.idToken, result.userId, result.accessToken);
                    navigate("/login-success")
                },
                () => {}
            )
        }, 1500)
    }, [])

    // ********************************************************************************

    return (
        <div className={`App`}>
            <div className="change-password-success-full-container">
                <div className="change-password-success-main-container">
                    <div className="change-password-success-heading">
                        Nuova password impostata correttamente
                    </div>
                    <div className="change-password-success-copy">
                        Stai per essere reindirizzato alla tua Area Personale
                    </div>
                    <LoadingSpinner />
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordSuccess
