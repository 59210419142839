import "./css/CancelSubDialog.css"

interface Props {
    onDismissClick: () => void,
    onBackClick: () => void,
}

const CancelSubDialog: React.FC<Props> = ({
    onDismissClick,
    onBackClick,
}) => {
    return (
        <div className="my-purchases-cancel-sub-dialog-container center-div">
            <div className="my-purchases-cancel-sub-dialog-internal-container">
                <div className="horizontal_flex_group justify_content_space_between align_items_center">
                    <div className="my-purchases-handle-sub-dialog-heading">
                        Disdici abbonamento
                    </div>
                    <div
                        className="pointer"
                        onClick={onDismissClick}
                    >
                        <img src="/images/x.svg" />
                    </div>
                </div>
                <div className="my-purchases-cancel-sub-dialog-copy">
                    Che peccato!<br></br>Perché vuoi disdire il tuo abbonamento? Condividilo con noi!
                </div>
                <textarea/>
                <div className="my-purchases-cancel-sub-dialog-buttons-row">
                    <button
                        onClick={onBackClick}
                        className="my-purchases-cancel-sub-dialog-undo-button primary_action_color_background"
                    >
                        Annulla
                    </button>
                    <button
                        className="my-purchases-cancel-sub-dialog-confirm-button"
                    >
                        Conferma disdetta
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CancelSubDialog
