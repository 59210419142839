import "./css/AlertDialog.css"

interface Props {
    text: string,
    onClick: () => void,
}

const AlertDialog: React.FC<Props> = ({
    text,
    onClick,
}) => {
    return (
        <div className="payment-method-alert-dialog-container center-div absolute">
            <div className="payment-method-alert-dialog-internal-container">
                <div className="horizontal_flex_group justify_content_flex_end">
                    <div
                        className="pointer"
                        onClick={onClick}
                    >
                        <img src="/images/x.svg" />
                    </div>
                </div>
                <div className="w400 s16 lh40">
                    {text}
                </div>
            </div>
        </div>
    )
}

export default AlertDialog
