import Expert from "../../../../components/Expert"
import "./css/ExpertsWidget.css"

const ExpertsWidgetNutrizionistaCard = (nutrizionista: Expert) => {
    return (
        <div className="experts-widget-expert-container">
            <div className="experts-widget-expert-internal-container">
                <div className="experts-widget-expert-image-container">
                    <img className="experts-widget-expert-image" src={nutrizionista.avatar} />
                </div>
                <div className="experts-widget-expert-info-container">
                    <div className="experts-widget-expert-info-internal-container">
                        <div className="horizontal_flex_group justify_content_space_between align_items_center">
                            <div className="vertical_flex_group">
                                <div className="experts-widget-expert-position">{nutrizionista.position}</div>
                                <div className="experts-widget-expert-name">{nutrizionista.name} {nutrizionista.familyName}</div>
                            </div>
                            <div className="experts-widget-expert-role">
                                {nutrizionista.role}
                            </div>
                        </div>
                        <div className="experts-widget-expert-description">
                            <span className="w700 experts-blue-text">{nutrizionista.name}</span> si occuperà di creare il tuo piano alimentare sulla base di tutte le informazioni che avrai messo a disposizione, comprese le risposte ai vari questionari e i referti di esami recenti che hai a disposizione.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface MRProps {
    medico: Expert,
    nutrizionista: Expert | undefined,
}

const ExpertsWidgetMedicoRefertanteCard: React.FC<MRProps> = ({
    medico,
    nutrizionista,
}) => {
    return (
        <div className={`${nutrizionista === undefined ? "experts-widget-expert-container-100-percent" : "experts-widget-expert-container"}`}>
            <div className="experts-widget-expert-internal-container">
                <div className="experts-widget-expert-image-container">
                    <img className="experts-widget-expert-image" src={medico.avatar} />
                </div>
                <div className="experts-widget-expert-info-container">
                    <div className="experts-widget-expert-info-internal-container">
                        <div className="horizontal_flex_group justify_content_space_between align_items_center">
                            <div className="vertical_flex_group">
                                <div className="experts-widget-expert-position">{medico.position}</div>
                                <div className="experts-widget-expert-name">{medico.name} {medico.familyName}</div>
                            </div>
                            <div className="experts-widget-expert-role">
                                {medico.role}
                            </div>
                        </div>
                        <div className="experts-widget-expert-description">
                            <span className="w700 experts-blue-text">{medico.name}</span> controllerà e validerà il tuo report nutrigenetico.
                            Per qualsiasi domanda relativa alle tue predisposizioni genetiche e su come sono state analizzate, prenota un consulto con {medico.position === "Dott." ? "lui" : "lei"}. Approfondisci l'appassionante mondo della nutrigenetica.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface Props {
    nutrionista: Expert | undefined,
    medicoRefertante: Expert,
}

const ExpertsWidget: React.FC<Props> = ({
    nutrionista,
    medicoRefertante,
}) => {
    return (
        <div className={`${nutrionista === undefined ? "experts-widget-smaller-container" : "experts-widget-container"}`}>
            <div className="experts-widget-internal-container">
                <div className="experts-widget-heading">
                    I tuoi professionisti dedicati
                </div>
                <div className="experts-widget-experts-container">
                    {
                        nutrionista !== undefined ?
                            <ExpertsWidgetNutrizionistaCard
                                avatar={nutrionista.avatar}
                                position={nutrionista.position}
                                name={nutrionista.name}
                                familyName={nutrionista.familyName}
                                role={nutrionista.role}
                                skills={nutrionista.skills}
                                summary={nutrionista.summary}
                            /> : null
                    }
                    <ExpertsWidgetMedicoRefertanteCard
                        medico={medicoRefertante}
                        nutrizionista={nutrionista}
                    />
                </div>
            </div>
        </div>
    )
}

export default ExpertsWidget
