import "./css/ImportantThingsWidget.css"

interface Props {
    itemsLength: number,
    expertGender: string,
    items: string[],
}

const ImportantThingsWidget: React.FC<Props> = ({
    itemsLength,
    expertGender,
    items,
}) => {
    return (
        <div className="important-things-widget-container">
            <div className="important-things-widget-internal-container">
                <div className="important-things-widget-upper-element">
                    <div className="important-things-widget-title">
                        {itemsLength} cose importanti da tenere a mente
                    </div>
                    <div className="important-things-widget-author">
                        {expertGender === "Dott." ? "Dal tuo nutrizionista" : "Dalla tua nutrizionista"}
                    </div>
                </div>
                <div className="important-things-widget-content-container">
                    <ul className="important-things-widget-content">
                        {
                            items.map(item => (
                                <li
                                    key={items.indexOf(item)}
                                    className="important-things-widget-content-single-item">{item}
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ImportantThingsWidget
