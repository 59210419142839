import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import HolifyaFullLogo from "../../../components/HolifyaFullLogo"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { QuestionAndAnswer } from "../../../components/QuestionAndAnswer"
import { BASE_URL } from "../../../utils/Constants"
import { handleQuestionId, separateStrings } from "../../../utils/Functions"
import { answerAltro, fakeQuestion, question14, surveyAQuestions } from "../../../utils/SurveyAQuestions"
import { useAuth } from "../../../utils/context/AuthContext"
import InputAnswerCard from "../components/InputAnswerCard"
import MultiSelectAnswerCard from "../components/MultiSelectAnswerCard"
import { NewQuestionType } from "../components/NewQuestion"
import RadioAnswerCard from "../components/RadioAnswerCard"
import "./css/SurveyANew.css"

const SurveyANew = () => {

  // Needed to navigate to another page
  const navigate = useNavigate()
  const location = useLocation()
  const sex = location.state.sex

  const { auth } = useAuth()
  const idToken = auth.idToken!
  const userId = auth.userId

  const [isLoading, setIsLoading] = useState(false)

  const [customAnswer, setCustomAnswer] = useState("")

  // Questions of this survey
  const questions = surveyAQuestions

  // Index of the current question to display
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  // Current question displayed on screen
  const currentQuestion = questions[currentQuestionIndex]

  // In a multiselect question, this is the array of the selected answers
  const [multiselectAnswersSelected, setMultiselectAnswersSelected] = useState<string[]>([]);
  // In an input question, this is the array of the filled answers
  const [inputAnswersFilled, setInputAnswersFilled] = useState<string[]>([]);
  const [question1InputAnswers, setQuestion1InputAnswers] = useState([""])
  const [question2InputAnswers, setQuestion2InputAnswers] = useState([""])
  const [question3BisInputAnswers, setQuestion3BisInputAnswers] = useState([""])
  const [question4BisInputAnswers, setQuestion4BisInputAnswers] = useState([""])
  const [question5BisInputAnswers, setQuestion5BisInputAnswers] = useState([""])
  const [question6BisInputAnswers, setQuestion6BisInputAnswers] = useState([""])
  const [question7BisInputAnswers, setQuestion7BisInputAnswers] = useState([""])
  const [question10BisInputAnswers, setQuestion10BisInputAnswers] = useState([""])
  const [question12InputAnswers, setQuestion12InputAnswers] = useState([""])
  // In a radio question, this represents the currrent answer
  const [radioAnswer, setRadioAnswer] = useState("")

  // Array of saved answers
  const [savedAnswers, setSavedAnswers] = useState<string[]>([])

  // When clicking on a multiselect question card
  const onMultiSelectCardClick = (answer: string) => {
    const index = multiselectAnswersSelected.indexOf(answer);
    if (index > -1) {
      setMultiselectAnswersSelected([...multiselectAnswersSelected.slice(0, index), ...multiselectAnswersSelected.slice(index + 1)]);
    } else {
      setMultiselectAnswersSelected([...multiselectAnswersSelected, ...[answer]]);
    }
  }

  const onNewInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAnswer = e.target.value
    setCustomAnswer(newAnswer)
  }

  const handleInputAnswers = (index: number) => {
    switch (index) {
      case 1:
        return question1InputAnswers
      case 2:
        return question2InputAnswers
      case 3.1:
        return question3BisInputAnswers
      case 4.1:
        return question4BisInputAnswers
      case 5.1:
        return question5BisInputAnswers
      case 6.1:
        return question6BisInputAnswers
      case 7.1:
        return question7BisInputAnswers
      case 10.1:
        return question10BisInputAnswers
      case 12:
        return question12InputAnswers
      default:
        return [""]
    }
  }

  const [tempString, setTempString] = useState("")

  // When the user types in the input field
  const onInputCardChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (currentQuestion.id === 1) {
      question1InputAnswers[index] = e.target.value
      setQuestion1InputAnswers(question1InputAnswers)
    } else if (currentQuestion.id === 2) {
      question2InputAnswers[index] = e.target.value
      setQuestion2InputAnswers(question2InputAnswers)
    } else if (currentQuestion.id === 3.1) {
      question3BisInputAnswers[index] = e.target.value
      setQuestion3BisInputAnswers(question3BisInputAnswers)
    }
    else if (currentQuestion.id === 4.1) {
      question4BisInputAnswers[index] = e.target.value
      setQuestion4BisInputAnswers(question4BisInputAnswers)
    }
    else if (currentQuestion.id === 5.1) {
      question5BisInputAnswers[index] = e.target.value
      setQuestion5BisInputAnswers(question5BisInputAnswers)
    }
    else if (currentQuestion.id === 6.1) {
      question6BisInputAnswers[index] = e.target.value
      setQuestion6BisInputAnswers(question6BisInputAnswers)
    }
    else if (currentQuestion.id === 7.1) {
      question7BisInputAnswers[index] = e.target.value
      setQuestion7BisInputAnswers(question7BisInputAnswers)
    }
    else if (currentQuestion.id === 10.1) {
      question10BisInputAnswers[index] = e.target.value
      setQuestion10BisInputAnswers(question10BisInputAnswers)
    }
    else if (currentQuestion.id === 12) {
      question12InputAnswers[index] = e.target.value
      setQuestion12InputAnswers(question12InputAnswers)
    }
    setTempString(e.target.value)
  }

  // When the user clicks on a radio answer card
  const onRadioCardClick = (choice: string) => {
    setRadioAnswer(choice)
    if (choice !== answerAltro && !isLastQuestion) {
      setTimeout(() => {
        saveRadioAndRefresh(choice)
      }, 250)
    }
  }

  const saveRadioAndRefresh = (radioChoice: string) => {
    saveRadioAnswer(radioChoice)
    refreshOrSendRadio(radioChoice)
  }

  const saveRadioAnswer = (choice: string) => {
    if (choice === answerAltro && customAnswer !== "") {
      if (savedAnswers.length === currentQuestionIndex) {
        savedAnswers.push(customAnswer)
      } else {
        savedAnswers[currentQuestionIndex] = customAnswer
      }
    } else {
      if (savedAnswers.length === currentQuestionIndex) {
        savedAnswers.push(choice)
      } else {
        savedAnswers[currentQuestionIndex] = choice
      }
    }

    setSavedAnswers(savedAnswers)
    setRadioAnswer("")
  }

  const refreshOrSendRadio = (choice: string) => {
    if ((currentQuestion.hasSecondary && choice === "Sì" || currentQuestion.isSecondary)) {
      const nextIndex = currentQuestionIndex + 1
      setCurrentQuestionIndex(nextIndex)
    } else {
      const nextIndex = currentQuestionIndex + 2
      setCurrentQuestionIndex(nextIndex)
    }
  }

  // When the user clicks on back button
  const onBackButtonClick = () => {
    if (currentQuestion.id === 1) {
      navigate("/survey-a-home")
    } else {
      var previousIndex = currentQuestionIndex - 1
      const previousQuestion = questions[previousIndex]
      if (previousQuestion.type === NewQuestionType.Multiselect) {
        setMultiselectAnswersSelected(separateStrings(savedAnswers[previousIndex], "; "))
      } else if (previousQuestion.type === NewQuestionType.Radio) {
        setRadioAnswer(savedAnswers[previousIndex])
      }
      setCurrentQuestionIndex(previousIndex)
    }
  }

  // When the user clicks on confirm button
  const onConfirmButtonClick = () => {
    saveAnswer()
    refreshOrSend()
  }

  const swapAnswers = (oldAnswer: string, newAnswer: string, arr: string[]) => {
    const index = arr.indexOf(oldAnswer)
    arr[index] = newAnswer
  }

  // Save the answer of the current question on the array of the saved answers
  const saveAnswer = () => {
    if (currentQuestion.type === NewQuestionType.Multiselect) {

      if (multiselectAnswersSelected.includes(answerAltro) && customAnswer !== "") {
        swapAnswers(answerAltro, customAnswer, multiselectAnswersSelected)
      }

      // Inizia il processo di creazione di un'unica stringa
      const answersString = multiselectAnswersSelected.join("; ")
      // Processo di creazione di un'unica stringa finito

      // Inserisco la stringa nell'array delle risposte, mettendo in coda oppure sostituendo
      if (savedAnswers.length === currentQuestionIndex) {
        savedAnswers.push(answersString)
      } else {
        savedAnswers[currentQuestionIndex] = answersString
      }
    } else if (currentQuestion.type === NewQuestionType.Input) {

      // Inizia il processo di creazione di un'unica stringa
      var answersString = ""
      switch (currentQuestion.id) {
        case 1:
          answersString = question1InputAnswers.join("; ")
          break;
        case 2:
          answersString = question2InputAnswers.join("; ")
          break;
        case 3.1:
          answersString = question3BisInputAnswers.join("; ")
          break;
        case 4.1:
          answersString = question4BisInputAnswers.join("; ")
          break;
        case 5.1:
          answersString = question5BisInputAnswers.join("; ")
          break;
        case 6.1:
          answersString = question6BisInputAnswers.join("; ")
          break;
        case 7.1:
          answersString = question7BisInputAnswers.join("; ")
          break;
        case 10.1:
          answersString = question10BisInputAnswers.join("; ")
          break;
        case 12:
          answersString = question12InputAnswers.join("; ")
          break;
        default:
          answersString = inputAnswersFilled.join("; ")
      }
      // Processo di creazione di un'unica stringa finito

      // Inserisco la stringa nell'array delle risposte, mettendo in coda oppure sostituendo
      if (savedAnswers.length === currentQuestionIndex) {
        savedAnswers.push(answersString)
      } else {
        savedAnswers[currentQuestionIndex] = answersString
      }
    } else if (currentQuestion.type === NewQuestionType.Radio) {
      if (radioAnswer === answerAltro && customAnswer !== "") {
        if (savedAnswers.length === currentQuestionIndex) {
          savedAnswers.push(customAnswer)
        } else {
          savedAnswers[currentQuestionIndex] = customAnswer
        }
      } else {
        if (savedAnswers.length === currentQuestionIndex) {
          savedAnswers.push(radioAnswer)
        } else {
          savedAnswers[currentQuestionIndex] = radioAnswer
        }
      }
    }

    setSavedAnswers(savedAnswers)
    setMultiselectAnswersSelected([])
    setInputAnswersFilled([])
    setRadioAnswer("")
  }

  const refreshOrSend = () => {
    if (isLastQuestion) {
      handleAnswers()
      callSaveSurveyApi()
    } else {
      if ((currentQuestion.hasSecondary && radioAnswer === "Sì" || currentQuestion.isSecondary)) {
        const nextIndex = currentQuestionIndex + 1
        setCurrentQuestionIndex(nextIndex)
      } else {
        const nextIndex = currentQuestionIndex + 2
        setCurrentQuestionIndex(nextIndex)
      }
    }
  }

  // Boolean checking if it's the last question of the survey
  const isLastQuestion = currentQuestionIndex === questions.length - 1

  // Boolean checking if the confirm button is disabled
  const confirmButtonDisabled = currentQuestion.type === NewQuestionType.Multiselect ? multiselectAnswersSelected.length === 0 : (currentQuestion.type === NewQuestionType.Radio ? radioAnswer === "" : false)

  // Array of question and answer, needed to save them all
  const questionsAndAnswers: QuestionAndAnswer[] = []

  const handleAnswers = () => {
    questions.forEach(q => {
      const index = questions.indexOf(q)
      if (q.isSecondary && savedAnswers[index - 1] === "No" && savedAnswers[index] === undefined) {
        savedAnswers[index] = "Hai risposto no alla domanda precedente"
      }
      if (savedAnswers[index] !== undefined && savedAnswers[index] !== "undefined") {
        questionsAndAnswers.push({ q: handleQuestionId(q.id) + q.question, a: [savedAnswers[index]] })
      }
    }
    )
  }

  useEffect(() => {
    if (sex === "Donna" && questions[questions.length - 1].id !== 13) {
      questions.push(fakeQuestion, question14)
    }
  }, [])

  async function callSaveSurveyApi() {
    setIsLoading(true)
    const response = await fetch(`${BASE_URL}/api/emr/${userId}/survey`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': idToken,
      },
      body: JSON.stringify(
        {
          type: "Medical History",
          data: questionsAndAnswers,
        })
    })
    setIsLoading(false)
    if (response.ok) {
      navigate("/survey-a-success")
    } else {
      const responseJson = await response.json()
      console.log(responseJson)
    }
  }

  return (
    <div className="App Quiz relative">
      <div className={`survey-heading-container ${isLoading ? "visible_30_percent inactive" : ""}`}>
        <div className="survey-question-empty-space" />
        <div className="survey-bar-logo-container">
          <div className="survey-empty-bar">
            <div className={`survey-filled-bar ${currentQuestion.progressionBarWidth}`} />
          </div>
          <div className="quiz-q-new-holifya-full-logo">
            <HolifyaFullLogo />
          </div>
          <div className="quiz-q-new-holifya-partial-logo">
            <img src="/images/quizQ/holifya-spiral.svg" />
          </div>
        </div>
        <div className="survey-question-container">
          <div className="survey-question">
            {currentQuestion.question}
          </div>
          <div className="survey-question-title-two">
            {currentQuestion.comment}
          </div>
        </div>
      </div>
      <div className={`${isLoading ? "visible_30_percent inactive" : ""} survey-a-new-answers-container`}>
        <div className={`survey-a-new-answers-internal-container`}>
          {
            currentQuestion.type === NewQuestionType.Multiselect ?
              currentQuestion.answers.map(answer => (
                <MultiSelectAnswerCard
                  key={answer.text}
                  answer={answer}
                  isChecked={multiselectAnswersSelected.includes(answer.text)}
                  onClick={onMultiSelectCardClick}
                  isNowInput={answer.text === answerAltro}
                  onMultiSelectInputChangeHandler={onNewInputChangeHandler}
                />
              )) : null
          }
          {
            currentQuestion.type === NewQuestionType.Input ?
              currentQuestion.answers.map(answer => (
                <InputAnswerCard
                  key={answer.text}
                  placeholder={answer.text}
                  index={currentQuestion.answers.indexOf(answer)}
                  onChange={onInputCardChange}
                  inputAnswers={handleInputAnswers(currentQuestion.id)}
                />
              )) : null
          }
          {
            currentQuestion.type === NewQuestionType.Radio ?
              currentQuestion.answers.map(answer => (
                <RadioAnswerCard
                  key={answer.text}
                  answer={answer}
                  isChecked={radioAnswer === answer.text}
                  onClick={onRadioCardClick}
                  isNowInput={answer.text === answerAltro}
                  onRadioInputChangeHandler={onNewInputChangeHandler}
                />
              )) : null
          }
          {
            currentQuestion.type === NewQuestionType.BreakPage ?
              <div className="quiz-q-new-break-page-internal-element">
                <div>
                  <img src={currentQuestion.icon} />
                </div>
                <div className="quiz-q-new-break-page-internal-element-blue-text">
                  {currentQuestion.blueText}
                </div>
                <div className="quiz-q-new-break-page-internal-element-black-text">
                  {currentQuestion.blackText}
                </div>
                <div className="quiz-q-new-break-page-internal-element-copy">
                  {currentQuestion.copy}
                </div>
              </div> : null
          }
        </div>
      </div>
      <div className={`survey-a-new-buttons-row ${isLoading ? "visible_30_percent inactive" : ""}`}>
        <div className={`survey-a-new-internal-buttons-row`}>
          <div
            className="survey-a-new-back-button"
            onClick={onBackButtonClick}
          >
            <div className="survey-a-new-back-button-arrow">
              <img src="/images/back-arrow.svg" />
            </div>
            <div className="survey-a-new-back-button-text">
              Indietro
            </div>
          </div>
          <button
            className={`survey-a-new-confirm-button ${isLastQuestion ? "secondary_action_color_background" : "primary_action_color_background white_text"} ${confirmButtonDisabled ? "" : "pointer"}`}
            disabled={confirmButtonDisabled}
            onClick={onConfirmButtonClick}
          >
            {isLastQuestion ? "Concludi" : "Avanti"}
          </button>
        </div>
      </div>
      {
        isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
      }
    </div>
  )
}

export default SurveyANew
