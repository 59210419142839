import { useState } from "react"
import { useNavigate } from "react-router-dom"
import HolifyaFullLogo from "../../../components/HolifyaFullLogo"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { QuestionAndAnswer } from "../../../components/QuestionAndAnswer"
import { BASE_URL } from "../../../utils/Constants"
import { handleQuestionId } from "../../../utils/Functions"
import { quizTQuestions } from "../../../utils/QuizTQuestions"
import { useAuth } from "../../../utils/context/AuthContext"
import { QuizType } from "../../../utils/enums/QuizType"
import InputAnswerCard from "../components/InputAnswerCard"
import { NewQuestionType } from "../components/NewQuestion"
import RadioAnswerCard from "../components/RadioAnswerCard"
import "./css/QuizT.css"

const QuizT = () => {

  const navigate = useNavigate()

  const { auth } = useAuth()
  const idToken = auth.idToken!
  const userId = auth.userId

  const questions = quizTQuestions
  // Index of the current question to display
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  // Current question displayed on screen
  const currentQuestion = questions[currentQuestionIndex]

  const [question1InputAnswers, setQuestion1InputAnswers] = useState([""])

  const [radioAnswer, setRadioAnswer] = useState("")

  const [tempString, setTempString] = useState("")

  // Array of saved answers
  const [savedAnswers, setSavedAnswers] = useState<string[]>([])

  // When the user types in the input field
  const onInputCardChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const oldLength = question1InputAnswers[index].length
    var newAnswer = e.target.value
    var newLength = newAnswer.length
    if (oldLength === 1 && newLength === 2) {
      newAnswer = newAnswer + "/"
    }
    if (oldLength === 4 && newAnswer.length === 5) {
      newAnswer = newAnswer + "/"
    }
    question1InputAnswers[index] = newAnswer
    setQuestion1InputAnswers(question1InputAnswers)
    setTempString(e.target.value)
  }

  // When the user clicks on a radio answer card
  const onRadioCardClick = (choice: string) => {
    setRadioAnswer(choice)
  }

  // Array of question and answer, needed to save them all
  const questionsAndAnswers: QuestionAndAnswer[] = []

  const onBackButtonClick = () => {
    if (currentQuestionIndex === 0) {
      navigate("/quiz-t-home")
    } else {
      setCurrentQuestionIndex(0)
    }
  }

  const onContinueClick = () => {
    if (currentQuestionIndex === 0) {
      const answersString = question1InputAnswers.join("; ")
      // Inserisco la stringa nell'array delle risposte, mettendo in coda oppure sostituendo
      if (savedAnswers.length === currentQuestionIndex) {
        savedAnswers.push(answersString)
      } else {
        savedAnswers[currentQuestionIndex] = answersString
      }
      setSavedAnswers(savedAnswers)
      setCurrentQuestionIndex(1)
    } else {
      savedAnswers.push(radioAnswer)
      setSavedAnswers(savedAnswers)
      handleAnswers()
      callSaveSurveyApi()
    }
  }

  // Before saving all the answers, we need to save them in the correct object
  const handleAnswers = () => {
    questions.forEach(q => {
      if (q.question !== undefined) {
        const index = questions.indexOf(q)
        if (savedAnswers[index] !== undefined) {
          questionsAndAnswers.push({
            q: handleQuestionId(q.id) + q.question,
            a: [savedAnswers[index]]
          })
        }
      }
    })
  }

  // Boolean to display the loading spinner
  const [isLoading, setIsLoading] = useState(false)

  async function callSaveSurveyApi() {
    setIsLoading(true)
    const response = await fetch(`${BASE_URL}/api/emr/${userId}/survey`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': idToken,
      },
      body: JSON.stringify(
        {
          type: QuizType.QuizQ,
          data: questionsAndAnswers,
        })
    })
    setIsLoading(false)
    if (response.ok) {
      navigate("/survey-a-home")
    } else {
      const responseJson = await response.json()
      console.log(responseJson)
    }
  }

  const isConfirmButtonDisabled = currentQuestionIndex === 0 ? question1InputAnswers[0] === "" : radioAnswer === ""

  // ********************************************************************************

  return (
    <div className="App Quiz relative">
      <div className={`survey-heading-container ${isLoading ? "visible_30_percent inactive" : ""}`}>
        <div className="survey-question-empty-space" />
        <div className="survey-bar-logo-container">
          <div className="survey-empty-bar">
            <div className={`survey-filled-bar ${currentQuestion.progressionBarWidth}`} />
          </div>
          <div className="quiz-q-new-holifya-full-logo">
            <HolifyaFullLogo />
          </div>
          <div className="quiz-q-new-holifya-partial-logo">
            <img src="/images/quizQ/holifya-spiral.svg" />
          </div>
        </div>
        <div className="survey-question-container">
          <div className="survey-question">
            {currentQuestion.question}
          </div>
        </div>
      </div>
      <div className={`quiz-t-answers-container ${isLoading ? "visible_30_percent inactive" : ""}`}>
        <div className="quiz-t-answers-internal-container">
          {
            currentQuestion.type === NewQuestionType.Input ?
              currentQuestion.answers.map(answer => (
                <InputAnswerCard
                  key={answer.text}
                  placeholder={answer.text}
                  index={currentQuestion.answers.indexOf(answer)}
                  onChange={onInputCardChange}
                  inputAnswers={question1InputAnswers}
                />
              )) : null
          }
          {
            currentQuestion.type === NewQuestionType.Radio ?
              currentQuestion.answers.map(answer => (
                <RadioAnswerCard
                  key={answer.text}
                  answer={answer}
                  isChecked={radioAnswer === answer.text}
                  onClick={onRadioCardClick}
                  isNowInput={false}
                  onRadioInputChangeHandler={() => console.log()}
                />
              )) : null}
        </div>
      </div>
      <div className={`quiz-t-buttons-row ${isLoading ? "visible_30_percent inactive" : ""}`}>
        <div className="quiz-t-buttons-internal-row">
          <button
            className="quiz-t-back-button"
            onClick={onBackButtonClick}
          >
            <div className="quiz-t-back-button-arrow">
              <img src="/images/back-arrow.svg" />
            </div>
            <div className="quiz-t-back-button-text">
              Indietro
            </div>
          </button>
          <button
            className={`quiz-t-confirm-button ${isConfirmButtonDisabled ? "" : "pointer"} ${currentQuestionIndex === 0 ? "white_text primary_action_color_background" : "black_text secondary_action_color_background"}`}
            onClick={onContinueClick}
            disabled={isConfirmButtonDisabled}
          >
            {currentQuestionIndex === 0 ? "Avanti" : "Concludi"}
          </button>
        </div>
      </div>
      {
        isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
      }
    </div>
  )
}

export default QuizT
