import DeleteButton from "../../../../../components/DeleteButton"
import "./css/PaymentMethodCard.css"

interface Props {
    onDeleteMethodClick?: () => void,
    showDeleteButton: boolean,
    circuit: string,
    expiryMonth: string,
    expiryYear: string,
    last4Digits: string,
}

const PaymentMethodCard: React.FC<Props> = ({
    onDeleteMethodClick,
    showDeleteButton,
    circuit,
    expiryMonth,
    expiryYear,
    last4Digits,
}) => {

    const firstCharUpperCase = (word: string) => {
        const newWord = word.charAt(0).toUpperCase() + word.substring(1, word.length)
        return newWord
    }

    return (
        <div className="payment-method-your-method-container">
            <div className="margin-left-3-percent margin-right-2-percent width_30">
                <img src="/images/payment-method-icon.svg" />
            </div>
            <div className="horizontal_flex_group justify_content_space_between width_100_percent margin-right-2-percent">
                <div className="payment-method-your-method-info-container">
                    <div className="horizontal_flex_group justify_content_space_between">
                        <div className="margin_right_20">
                            {firstCharUpperCase(circuit)}
                        </div>
                        <div className="w400">
                            {expiryMonth}/{expiryYear}
                        </div>
                    </div>
                    <div>
                        **** **** **** {last4Digits}
                    </div>
                </div>
                {
                    showDeleteButton ?
                        <div className="vertical_flex_group justify_content_center align_items_center">
                            <DeleteButton
                                onClick={onDeleteMethodClick}
                            />
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

export default PaymentMethodCard
