import { BASE_URL, requestOptionsGET } from "../Constants";
import { QuizType } from "../enums/QuizType";

/**
 * Type for the callback function invoked upon generic success.
 */
type SuccessCallback = () => void;

/**
 * Type for the callback function invoked upon a generic error.
 */
type ErrorCallback = () => void;

export async function getQuizQ(
    userId: string,
    idToken: string,
    onSuccess: SuccessCallback,
    onError: ErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/survey/${QuizType.QuizQ}`, requestOptionsGET(idToken!))
        if (response.status === 404) {
            onError()
        } else {
            onSuccess()
        }
    } catch (error) {
        console.error("Get Welcome Survey failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getSurveyA(
    userId: string,
    idToken: string,
    onSuccess: SuccessCallback,
    onError: ErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/survey/${QuizType.SurveyA}`, requestOptionsGET(idToken!))
        if (response.status === 404) {
            onError()
        } else {
            onSuccess()
        }
    } catch (error) {
        console.error("Get Medical History failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}
