import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import BackButton from "../../../components/BackButton"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { forgotPasswordApi } from "../../../utils/apis/auth-service"
import ForgotPasswordInsertCodeBox from "./components/ForgotPasswordInsertCodeBox"
import "./css/ForgotPasswordInsertCode.css"

const ForgotPasswordInsertCode = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state.email

    const [isLoading, setIsLoading] = useState(false)

    const onBackButtonClick = () => {
        navigate("/forgot-password")
    }

    const handleResendCode = () => {
        forgotPasswordApi(
            email,
            () => {
                navigate("/forgot-password-insert-code", { state: { email } })
            },
            () => {},
        )
    }

    const handleInputSubmit = (inputData: { [key: string]: string }) => {
        const code = inputData.code
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
            navigate("/new-password", { state: { email, code } })
        }, 1000)
    }

    // ********************************************************************************

    return (
        <div className="App relative">
            <div className={`forgot-password-insert-code-buttons-row ${isLoading && "visible_30_percent inactive"}`}>
                <BackButton onClick={onBackButtonClick} />
            </div>
            <div className={`forgot-password-insert-code-full-container ${isLoading && "visible_30_percent inactive"}`}>
                <div className="forgot-password-insert-code-main-container">
                    <ForgotPasswordInsertCodeBox
                        onSubmit={handleInputSubmit}
                        onResendCodeClick={handleResendCode}
                    />
                </div>
            </div>
            {
                isLoading && <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div>
            }
        </div>
    )
}

export default ForgotPasswordInsertCode
