import { useState } from "react"
import { useNavigate } from "react-router-dom"
import BackButton from "../../../components/BackButton"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { forgotPasswordApi } from "../../../utils/apis/auth-service"
import ForgotPasswordBox from "./components/ForgotPasswordBox"
import "./css/ForgotPassword.css"

const ForgotPassword = () => {

    const navigate = useNavigate();
    
    const [emailErrorText, setEmailErrorText] = useState("")
    const [isLoading, setIsLoading] = useState(false)    

    const onBackButtonClick = () => {
        navigate("/login")
    }

    const handleInputSubmit = (inputData: { [key: string]: string }) => {
        const email = inputData.email
        callForgotPassword(email)
    }

    const callForgotPassword = (email: string) => {
        setEmailErrorText("")
        setIsLoading(true)
        forgotPasswordApi(
            email,
            () => {
                setIsLoading(false)
                navigate("/forgot-password-insert-code", { state: { email } })
            },
            (error) => {
                setIsLoading(false)
                setEmailErrorText(error)
            }
        )
    }

    // ********************************************************************************

    return (
        <div className="App relative">
            <div className={`forgot-password-buttons-row ${isLoading && "visible_30_percent inactive"}`}>
                <BackButton onClick={onBackButtonClick} />
            </div>
            <div className={`forgot-password-full-container ${isLoading && "visible_30_percent inactive"}`}>
                <div className="forgot-password-main-container">
                    <ForgotPasswordBox
                        onSubmit={handleInputSubmit}
                        emailErrorText={emailErrorText}
                    />
                </div>
            </div>
            {
                isLoading && <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div>
            }
        </div>
    )
}

export default ForgotPassword
