import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CloseButton from "../../../../components/CloseButton";
import HolifyaFullLogo from "../../../../components/HolifyaFullLogo";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET } from "../../../../utils/Constants";
import { retrieveQuestionId, separateStrings } from "../../../../utils/Functions";
import { answerAltro, quizQAllQuestions } from "../../../../utils/QuizQQuestions";
import { surveyAQuestions } from "../../../../utils/SurveyAQuestions";
import { surveyBQuestions } from "../../../../utils/SurveyBQuestions";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem";
import { QuizType } from "../../../../utils/enums/QuizType";
import InputAnswerCard from "../../../surveys/components/InputAnswerCard";
import MultiSelectAnswerCard from "../../../surveys/components/MultiSelectAnswerCard";
import { NewQuestion, NewQuestionType } from "../../../surveys/components/NewQuestion";
import RadioAnswerCard from "../../../surveys/components/RadioAnswerCard";
import MenuPopup from "../../components/MenuPopup";
import "./css/MyAnswersSurveyTypeEditSingleQuestion.css";

const MyAnswersSurveyTypeEditSingleQuestion = () => {

    // Navigation parameters
    const navigate = useNavigate();
    const location = useLocation();

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const { userData } = useUserData()
    const username = userData.name!
    const familyName = userData.familyName!
    const email = userData.email!

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(true)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // Constants coming from navigation
    const surveyType: QuizType = location.state.surveyType    // type of the syrvey
    const questionTitle = location.state.question  // The question
    const [correctAnswer, setCorrectAnswer] = useState(location.state.correctAnswer)    // old answer, we want to modify it

    // Question displayed to be changed
    const [currentQuestion, setCurrentQuestion] = useState<NewQuestion>()

    const handleQuizQQuestions = () => {
        for (const question of quizQAllQuestions) {
            if (question.question === questionTitle.substring(4)) {
                setCurrentQuestion(question)
            }
        }
    }

    const handleSurveyAQuestions = () => {
        for (const question of surveyAQuestions) {
            if (question.question === questionTitle.substring(4)) {
                setCurrentQuestion(question)
            }
        }
    }

    const handleSurveyBQuestions = () => {
        for (const question of surveyBQuestions) {
            if (question.question === questionTitle.substring(4) && question.id === retrieveQuestionId(questionTitle)) {
                retrieveQuestionId(questionTitle)
                setCurrentQuestion(question)
            }
        }
    }

    // Function which depending on the survey type, sets the current question
    const handleQuestion = () => {
        switch (surveyType) {
            case QuizType.QuizQ: {
                handleQuizQQuestions()
                break;
            }
            case QuizType.SurveyA: {
                handleSurveyAQuestions()
                break;
            }
            case QuizType.SurveyB: {
                handleSurveyBQuestions()
                break;
            }
        }
        setIsLoading(false)
    }

    // Function which depending on the question type, returns the new answer
    const handleAnswer = () => {
        switch (currentQuestion?.type) {
            case NewQuestionType.Radio: {
                return radioChoice;
            }
            case NewQuestionType.Input: {
                console.log(inputAnswers)
                return inputAnswers.join("; ")
            }
            case NewQuestionType.Multiselect: {
                return multiSelectAnswers.join("; ")
            }
        }
    }

    // Answer for radio questions, at the beginning is set as the previous answer
    const [radioChoice, setRadioChoice] = useState(correctAnswer)
    // Array of selectedAnswers for multiselect questions, at the beginning is set as the previous answer
    const [multiSelectAnswers, setMultiSelectAnswers] = useState(separateStrings(correctAnswer, "; "))

    const [inputAnswers, setInputAnswers] = useState(separateStrings(correctAnswer, "; "))

    // Boolean determining if the answer has been changed
    const [answerChanged, setAnswerChanged] = useState(false)

    // Submit button disabled or not
    const submitChangeAnswerButtonDisabled = !answerChanged

    // Handles the click on the radio card
    const onRadioCardClick = (choice: string) => {
        setRadioChoice(choice)
        setAnswerChanged(true)
    }

    // Handles the change on input questions
    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setCorrectAnswer(e.target.value)
        const tempAnswers = inputAnswers
        tempAnswers[index] = e.target.value
        setInputAnswers(tempAnswers)
        setAnswerChanged(true)
    }

    // Handles the click on the multiselect card
    const onMultiSelectCardClick = (choice: string) => {
        const index = multiSelectAnswers.indexOf(choice);
        if (index > -1) {
            setMultiSelectAnswers([...multiSelectAnswers.slice(0, index), ...multiSelectAnswers.slice(index + 1)]);
        } else {
            setMultiSelectAnswers([...multiSelectAnswers, ...[choice]]);
        }
        setAnswerChanged(true)
    }

    const onCloseClick = () => {
        navigate(`/platform/my-answers/${surveyType}`)
    }

    const onConfirmClick = () => {
        navigate(`/platform/my-answers/${surveyType}`, { state: { answerChanged: true, correctAnswer: handleAnswer(), questionTitle } })
    }

    // ********************************************************************************

    // GET users info by user_id
    async function callAPIUserInfo() {
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        if (responseUserinfo.ok) {
            handleQuestion()
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
    }, [])

    // ********************************************************************************

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading && "visible-0-percent inactive"}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        currentPage={PlatformMenuItem.MyAnswers}
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-my-answers-edit-answers-full-container ${focusOnMenu ? "visible_30_percent inactive" : ""}`}>
                    <div className={`platform-my-answers-edit-answers-header`}>
                        <div className="platform-my-answers-edit-answers-empty-space" />
                        <div className="platform-my-answers-edit-answers-logo-container">
                            <HolifyaFullLogo />
                        </div>
                        <div className="platform-my-answers-edit-answers-question-container">
                            {currentQuestion !== undefined && currentQuestion.question}
                        </div>
                        <div className="platform-my-answers-edit-answers-empty-space" />
                    </div>
                    {
                        currentQuestion !== undefined &&
                        <div className="platform-my-answers-edit-answers-answers-card-big-container">
                            <div className={`platform-my-answers-edit-answers-answers-card-container ${currentQuestion.type === NewQuestionType.Input && "platform-my-answers-edit-answers-answers-card-container-vertical"}`}>
                                {
                                    currentQuestion.type === NewQuestionType.Radio &&
                                    currentQuestion.answers?.map(answer => (
                                        <RadioAnswerCard
                                            key={answer.text}
                                            answer={answer}
                                            isChecked={radioChoice === answer.text}
                                            onClick={onRadioCardClick}
                                            isNowInput={false}
                                            onRadioInputChangeHandler={() => console.log()}
                                        />
                                    ))
                                }
                                {
                                    currentQuestion.type === NewQuestionType.Input &&
                                    currentQuestion.answers?.map(answer => (
                                        <InputAnswerCard
                                            key={answer.text}
                                            index={currentQuestion.answers.indexOf(answer)}
                                            onChange={onInputChange}
                                            inputAnswers={inputAnswers}
                                            icon={answer.icon}
                                            placeholder={answer.text}
                                        />
                                    ))
                                }
                                {
                                    currentQuestion.type === NewQuestionType.Multiselect &&
                                    currentQuestion.answers?.map(answerElement => (
                                        <MultiSelectAnswerCard
                                            key={answerElement.text}
                                            answer={answerElement}
                                            isChecked={multiSelectAnswers.includes(answerElement.text)}
                                            onClick={onMultiSelectCardClick}
                                            isNowInput={answerElement.text === answerAltro}
                                            onMultiSelectInputChangeHandler={() => console.log()}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    }
                    <div className="platform-my-answers-edit-answers-buttons-row">
                        <CloseButton
                            onClick={onCloseClick}
                            showBorder={true}
                            noFlexEnd={true}
                        />
                        <div className="platform-my-answers-edit-answers-confirm-button">
                            <PrimaryButton
                                text="Conferma"
                                pointer={!submitChangeAnswerButtonDisabled}
                                disabled={submitChangeAnswerButtonDisabled}
                                onClick={onConfirmClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading && <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div>
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default MyAnswersSurveyTypeEditSingleQuestion
