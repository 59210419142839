import "./css/PrimaryButton12.css"

interface Props {
    text: string,
    onClick?: () => void,
    pointer: boolean,
    disabled: boolean,
}

const PrimaryButton12: React.FC<Props> = ({
    text,
    onClick,
    pointer,
    disabled,
}) => {
  return (
    <button
        className={`primary-button-12 primary_action_color_background ${pointer ? "pointer" : ""}`}
        onClick={onClick}
        disabled={disabled}
    >
      {text}
    </button>
  )
}

export default PrimaryButton12
