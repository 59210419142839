import Expert from "../../../components/Expert"
import "./css/PaywallMultipleChoiceNutritionistCard.css"

interface Props {
    nutritionist: Expert,
    onKnowBetterNutritionistButtonClick: () => void,
}

const PaywallMultipleChoiceNutritionistCard: React.FC<Props> = ({
    nutritionist,
    onKnowBetterNutritionistButtonClick,
}) => {
    return (
        <div className="paywall-multiple-choice-nutritionist-card-container">
            <div className="paywall-multiple-choice-nutritionist-card-internal-container">
                <div className="paywall-multiple-choice-nutritionist-card-image-container">
                    <img className="paywall-multiple-choice-nutritionist-card-image" src={nutritionist.avatar} />
                </div>
                <div className="paywall-multiple-choice-nutritionist-card-info-container">
                    <div className="paywall-multiple-choice-nutritionist-card-left-element-container">
                        <div>
                            <div className="paywall-multiple-choice-nutritionist-card-position">{nutritionist.position}</div>
                            <div className="paywall-multiple-choice-nutritionist-card-name">{nutritionist.name} {nutritionist.familyName}</div>
                        </div>
                        <button
                            className="paywall-multiple-choice-nutritionist-card-button"
                            onClick={onKnowBetterNutritionistButtonClick}
                        >
                                Conosci meglio {nutritionist.name}
                        </button>
                    </div>
                    <div className="paywall-multiple-choice-nutritionist-card-right-element-container">
                        {nutritionist.role}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaywallMultipleChoiceNutritionistCard
