import { handleDayOfMonth, handleHourAndMinutes, handleMonthString } from "../../../utils/Functions"
import { Appointment } from "../components/Appointment"
import "./css/NextAppointmentWidget.css"

interface Props {
    isUrgent: boolean,
    appointment: Appointment,
}

const NextAppointmentWidget: React.FC<Props> = ({
    isUrgent,
    appointment,
}) => {

    const handleMissingTime = () => {
        if (appointment.remainingDays > 1) {
            return `Mancano ${appointment.remainingDays} giorni`
        } else if (appointment.remainingDays === 1) {
            return "Manca 1 giorno"
        } else {
            if (appointment.remainingHours > 1) {
                return `Mancano ${appointment.remainingHours} ore`
            } else if (appointment.remainingHours === 1 && appointment.remainingMinutes >= 60) {
                return "Manca 1 ora"
            }
            else {
                if (appointment.remainingMinutes > 1) {
                    return `Mancano ${appointment.remainingMinutes} minuti`
                } else if (appointment.remainingMinutes === 1) {
                    return `Manca 1 minuto`
                } else {
                    return `Il meeting è iniziato, connettiti al più presto`
                }
            }
        }
    }

    return (
        <div className={`${isUrgent ? "next-appointment-widget-container-urgent" : "next-appointment-widget-container"}`}>
            <div className="next-appointment-widget-internal-container">
                <div className="next-appointment-widget-upper-element-container">
                    <div className="next-appointment-widget-upper-element-heading">
                        Videoconsulto | <span className={`${isUrgent ? "next-appointment-widget-red-text" : "next-appointment-widget-blue-text"}`}>{handleMissingTime()}</span>
                    </div>
                    <div className="next-appointment-widget-upper-element-date">
                        <span>{handleDayOfMonth(appointment.date)} {handleMonthString(appointment.date)} <span className="next-appointment-widget-regular-font">{handleHourAndMinutes(appointment.date)}</span></span>
                    </div>
                </div>
                <div className="next-appointment-widget-bottom-element-container desktop-only">
                    <div className="next-appointment-widget-bottom-left-element">
                        <div className="next-appointment-widget-bottom-element-expert-position">
                            {appointment.expert?.position}
                        </div>
                        <div className="next-appointment-widget-bottom-element-name">
                            {appointment.expert?.name} {appointment.expert?.familyName}
                        </div>
                        <div className="next-appointment-widget-bottom-element-expert-role">
                            {appointment.expert?.role}
                        </div>
                    </div>
                    <div className="next-appointment-widget-bottom-element-buttons-container">
                        <button
                            className={`next-appointment-widget-bottom-element-edit-button ternary_action_color_background ${isUrgent ? "" : "pointer"}`}
                            onClick={() => window.open(appointment.editUrl)}
                            disabled={isUrgent}
                        >
                            Modifica
                        </button>
                        <button
                            className="next-appointment-widget-bottom-element-connect-button primary_action_color_background"
                            onClick={() => window.open(appointment.connectUrl)}
                        >
                            Collegati
                        </button>
                    </div>
                </div>
                <div className="next-appointment-widget-bottom-element-container-mobile-only">
                    <div className="horizontal_flex_group justify_content_space_between">
                        <div>
                            <div className="next-appointment-widget-bottom-element-expert-position">
                                {appointment.expert?.position}
                            </div>
                            <div className="next-appointment-widget-bottom-element-name">
                                {appointment.expert?.name} {appointment.expert?.familyName}
                            </div>
                        </div>
                        <div className="next-appointment-widget-bottom-element-expert-role">
                            {appointment.expert?.role}
                        </div>
                    </div>
                    <div className="next-appointment-widget-bottom-element-buttons-container-mobile">
                    <button
                        className="next-appointment-widget-bottom-element-edit-button-mobile ternary_action_color_background"
                        onClick={() => window.open(appointment.editUrl)}
                    >
                        Modifica
                    </button>
                    <button
                        className="next-appointment-widget-bottom-element-connect-button-mobile primary_action_color_background"
                        onClick={() => window.open(appointment.connectUrl)}
                    >
                        Collegati
                    </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NextAppointmentWidget
