import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import CloseButton from "../../components/CloseButton"
import Expert from "../../components/Expert"
import LoadingSpinner from "../../components/LoadingSpinner"
import { BASE_URL, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET } from "../../utils/Constants"
import { handleExpertRole } from "../../utils/Functions"
import { useAuth } from "../../utils/context/AuthContext"
import { useUserData } from "../../utils/context/UserDataContext"
import { CheckoutBundleType, CheckoutMode } from "../../utils/enums/Checkout"
import { ExpertGender, ExpertGenderRole } from "../../utils/enums/ExpertsGender"
import { NavigationToDismissiblePaywall } from "../../utils/enums/NavigationToDismissiblePaywall"
import { NavigationToInsertCodePage } from "../../utils/enums/NavigationToInsertCodePage"
import { ShopifyProduct } from "../../utils/enums/ShopifyProduct"
import NutritionistDetailsDialog from "./components/NutritionistDetailsDialog"
import PaymentInfoDialog from "./components/PaymentInfoDialog"
import PaywallMultipleChoiceNoTestCard from "./components/PaywallMultipleChoiceNoTestCard"
import "./css/DismissiblePaywallMultipleChoice.css"

const DismissiblePaywallMultipleChoice = () => {

    const location = useLocation()
    const fromWhere: NavigationToDismissiblePaywall = location.state.fromWhere

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const { userData } = useUserData()
    const username = userData.name

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)

    const [nutritionist, setNutritionist] = useState<Expert>()

    const [showNutritionistDetailsDialog, setShowNutritionistDetailsDialog] = useState(false)
    const [showPaymentInfoDialog, setShowPaymentInfoDialog] = useState(false)

    const [chosenProduct, setChosenProduct] = useState(ShopifyProduct.OneMonthSub)

    const onCloseButtonClick = () => {
        switch (fromWhere) {
            case NavigationToDismissiblePaywall.Platform:
                return navigate(RELATIVE_PATH_TO_PLATFORM)
            case NavigationToDismissiblePaywall.PlatformPlan:
                return navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
            case NavigationToDismissiblePaywall.PlatformExperts:
                return navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
        }
    }

    const onInsertProductCodeClick = () => {
        navigate("/insert-product-code", { state: { fromWhere: NavigationToInsertCodePage.DismissiblePaywallMultipleChoice, fromWherePlatform: fromWhere } })
    }

    const onPurchaseClick = (product: ShopifyProduct) => {
        switch (product) {
            case ShopifyProduct.OneMonthSub:
                return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.DietSubscription } })
            case ShopifyProduct.ThreeMonthsSub:
                return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.ThreeMonthsSubscription } })
            case ShopifyProduct.SixMonthsSub:
                return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.SixMonthsSubscription } })
            default:
                return
        }
    }

    // dialogs
    const onKnowBetterNutritionistButtonClick = () => {
        setShowNutritionistDetailsDialog(true)
    }

    const onKnowBetterNutritionistDismissClick = () => {
        setShowNutritionistDetailsDialog(false)
    }

    const onPaymentInfoClick = (product: ShopifyProduct) => {
        setShowPaymentInfoDialog(true)
        setChosenProduct(product)
    }

    const onPaymentInfoDismissClick = () => {
        setShowPaymentInfoDialog(false)
    }

    // **************************************************
    // APIs

    // GET medical user info by patient
    async function callGetMedicalUserInfoApi() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/patient/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const nutri = await responseUserinfo.json()
            const newExpert: Expert = {
                id: nutri.id,
                position: nutri.data.sex === ExpertGender.Male ? ExpertGenderRole.Dott : ExpertGenderRole.Dottssa,
                name: nutri.data.name,
                familyName: nutri.data.family_name,
                role: handleExpertRole(nutri.role),
                skills: nutri.tags,
                summary: nutri.data.bio,
                avatar: nutri.calendly_data.calendly_user.avatar_url,
            }
            setNutritionist(newExpert)
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
        }
    }

    useEffect(() => {
        callGetMedicalUserInfoApi()
    }, [])

    // ********************************************************************************

    return (
        <div className="App Quiz relative">
            <div className={`dismissible-paywall-multiple-choice-full-container ${(isLoading || showPaymentInfoDialog || showNutritionistDetailsDialog) ? "visible_30_percent inactive" : ""}`}>
                <div className="dismissible-paywall-multiple-choice-logo-container">
                    <img src="/images/full-logo-new.svg" />
                </div>
                <div className="dismissible-paywall-multiple-choice-close-button-container">
                    <CloseButton
                        onClick={onCloseButtonClick}
                    />
                </div>
                {
                    nutritionist !== undefined ?
                        <div className="dismissible-paywall-multiple-choice-content-container">
                            <div className="dismissible-paywall-multiple-choice-heading dismissible-paywall-multiple-choice-desktop-only">
                                Complimenti {username}! {String.fromCodePoint(0x1F60E)} <span className="w700">Questa è la tua soluzione su misura!</span>
                            </div>
                            <div className="dismissible-paywall-multiple-choice-heading dismissible-paywall-multiple-choice-mobile-only">
                                <div>Complimenti {username}! {String.fromCodePoint(0x1F60E)}</div>
                                <div className="w700">Questa è la tua soluzione su misura!</div>
                            </div>
                            <PaywallMultipleChoiceNoTestCard
                                nutritionist={nutritionist}
                                onKnowBetterNutritionistButtonClick={onKnowBetterNutritionistButtonClick}
                                onPaymentInfoClick={onPaymentInfoClick}
                                onInsertProductCodeClick={onInsertProductCodeClick}
                                onPurchaseClick={onPurchaseClick}
                            />
                        </div>
                        : null
                }
                <div className="height_20" />
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            {
                showNutritionistDetailsDialog ?
                    <NutritionistDetailsDialog
                        onCloseClick={onKnowBetterNutritionistDismissClick}
                        nutritionist={nutritionist!}
                    /> : null
            }
            {
                showPaymentInfoDialog ?
                    <PaymentInfoDialog
                        onCloseClick={onPaymentInfoDismissClick}
                        product={chosenProduct}
                    /> : null
            }
        </div>
    )
}

export default DismissiblePaywallMultipleChoice
