export enum ShopifyItemSku {
    ExpertConsult = "Expert-consult",
    NutritionistConsult = "Nutritionist-consult",
    DnaTest = "0171002",
    DnaTestOld = "0171003",
    Membership = "",
}

export enum ShopifyItemName {
    ExpertConsult = "Consulto con il Medico",
    NutritionistConsult = "Consulto con il Nutrizionista",
    DnaTestOld = "DNA Complete",
    DnaTestOldBis = "Test del DNA Complete",
    DnaTest = "DNA Test",
    DnaTestComplete = "DNA Test Complete",
    OneMonthSubOld = "Membership 1 mese",
    OneMonthSub = "Percorso di nutrizione personalizzata - Mensile",
    ThreeMonthsSubOld = "Membership 3 mesi",
    ThreeMonthsSub = "Percorso di nutrizione personalizzata - 3 mesi",
    SixMonthsSubOld = "Membership 6 mesi",
    SixMonthsSub = "Percorso di nutrizione personalizzata - 6 mesi",
    OneMonthBundleOld = "Membership 1 mese + DNA Test",
    OneMonthBundle = "Percorso di nutrizione personalizzata + DNA Test - Mensile",
    ThreeMonthsBundleOld = "Membership 3 mesi + DNA Test",
    ThreeMonthsBundle = "Percorso di nutrizione personalizzata + DNA Test - 3 mesi",
    SixMonthsBundleOld = "Membership 6 mesi + DNA Test",
    SixMonthsBundle = "Percorso di nutrizione personalizzata + DNA Test - 6 mesi",
    NutritionBoost = "DNA Nutrition Boost",
    DNAIntolerance = "DNA Intolerance",
    Nutrition = "Percorso Nutrition"
}