import { PickupChoice } from "../../../../../utils/enums/PickupChoice"
import "./css/WarningBox.css"

interface Props {
    pickupChoice: string,
}

const WarningBox: React.FC<Props> = ({
    pickupChoice,
}) => {
    return (
        <div className={`${pickupChoice === PickupChoice.AtHome ? "warning-box-external-container-at-home" : "warning-box-external-container-tnt"}`}>
            <div className="warning-box-internal-container">
                <div className="warning-box-title">
                    <div className="lh0">
                        <img src="/images/alert-icon.svg" />
                    </div>
                    <div className="warning-box-heading">
                        Attenzione
                    </div>
                </div>
                <div>
                    {
                        pickupChoice === PickupChoice.AtHome ?
                            <div className="w400 s18 lh24">
                                Non verrai addebitato di alcun importo per il ritiro del campione. <br></br>
                                è importante che venga selezionata l'opzione <span className="w700">“Mittente convenzionato”</span> e che venga lasciata la spunta su <span className="w700">“Merce destinata in Italia”</span>.
                            </div> :
                            <div className="w400 s18 lh24"><span className="w700">La lettera di vettura</span> in tuo possesso è valida anche per far recuperare il tuo campione in un Fedex TNT Point.<br></br>
                                <span className="w700">Ricordati di allegarla alla busta contenente il campione</span> prima di consegnarla al al Fedex TNT Point più comodo per te. <br></br>
                                Confermando sarai reindirizzato sulla pagina del fornitore di logistica per trovare il punto di raccolta più vicino.
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default WarningBox
