import "./css/TernaryButton.css"

interface Props {
    text: string,
    onClick: () => void,
    pointer: boolean,
  }

const TernaryButton: React.FC<Props> = ({
    text,
    onClick,
    pointer,
}) => {
    return (
        <button
          className={`ternary-button ${pointer ? "pointer" : ""} ternary_action_color_background`}
          onClick={onClick}
        >
          {text}
        </button>
      )
}

export default TernaryButton
