import { Answer } from "./Answer"
import "./css/RadioAnswerCard.css"

interface Props {
    answer: Answer,
    isChecked: boolean,
    onClick: (answer: string) => void,
    isNowInput: boolean,
    onRadioInputChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

const RadioAnswerCard: React.FC<Props> = ({
    answer,
    isChecked,
    onClick,
    isNowInput,
    onRadioInputChangeHandler,
}) => {
    return (
        <div
            className={`${isChecked ? "radio-answer-checked-card-container" : "radio-answer-card-container"}`}
            onClick={() => onClick(answer.text)}
        >
            <div className="radio-answer-card-internal-container">
                <div className="radio-answer-card-radio-container">
                    <div className="radio-answer-card-radio-external-element">
                        <div className={`${isChecked ? "radio-answer-card-radio-internal-element" : ""}`} />
                    </div>
                </div>
                {
                    answer.icon !== "" ?
                        <div className="radio-answer-card-image-container">

                            <img src={answer.icon} />
                        </div> :
                        null
                }
                {
                    !(isNowInput && isChecked) ?
                        <div className="radio-answer-card-text">
                            {answer.text}
                        </div>
                        :
                        <input
                            className="radio-answer-card-input-field"
                            type="text"
                            onChange={onRadioInputChangeHandler}
                            placeholder="Specifica la risposta"
                            autoFocus
                        />
                }
            </div>
        </div>
    )
}

export default RadioAnswerCard
