import HolifyaProduct from "../../../components/HolifyaProduct"
import "./css/ChooseProductCard.css"

interface Props {
    product: HolifyaProduct,
    onCardClick: (product: string) => void,
    choice: string | null,
    icon: string,
}

const ChooseProductCard: React.FC<Props> = ({
    product,
    onCardClick,
    choice,
    icon,
}) => {
    return (
        <div
            className={`${(choice !== product.name || choice === null) ? ("choose-product-card-container") : ("choose-product-card-checked-container")}`}
            onClick={() => onCardClick(product.name)}
        >
            <div className="choose-product-card-internal-container">
                <div className="choose-product-card-upper-element-container">
                    <input
                        className="pointer margin_right_20"
                        type="radio"
                        name="choice"
                        value={product.name}
                        id={product.value}
                        checked={choice === product.name}
                        readOnly
                    />
                    <div className="margin-right-4-percent">
                        <div
                            className="horizontal_flex_group justify_content_center align_items_center choose-product-radio-container"
                        >
                            <div className={`choose-product-radio-internal-element ${choice === product.name ? "" : "invisible"}`}>

                            </div>
                        </div>
                    </div>
                    <div className="margin_right_20">
                        <img src={icon} />
                    </div>
                    <div className="w600 s20 lh26">
                        {product.name}
                    </div>
                </div>
                <div className="choose-product-card-divider"/>
                <div className="choose-product-card-bottom-element-container">
                    <span className="w600">Cosa contiene:</span>
                    <ul>
                        {
                            product.bundleContents.map(item => (
                                <li className="margin_left_20 lh20 margin_top_10 margin_bottom_10" key={item}>{item}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ChooseProductCard
