import ExamType from "../../../../../utils/enums/ExamType"
import "./css/TestCard.css"

interface Props {
    icon: string,
    testName: string,
    onClick: (testName: string) => void,
}

/**
 * 
 * @param icon the icon of the test
 * @param testName the name of the test
 * @param onClick the behaviour of the card when clicking on it
 * @returns a card with a test view to open, available in Exams and Test page
 */
const TestCard: React.FC<Props> = ({
    icon,
    testName,
    onClick,
}) => {

    const handleImage = () => {
        switch (testName) {
            case ExamType.DNATest:
                return "exams-and-tests-exam-card-dna-test-image"
            case ExamType.BloodExams:
                return "exams-and-tests-exam-card-blood-exams-image"
            case ExamType.OtherExams:
                return "exams-and-tests-exam-card-other-exams-image"
            default:
                return ""
        }
    }

    return (
        <div
            className="exams-and-tests-exam-card-container"
            onClick={() => (
                onClick(testName)
            )
            }
        >
            <div className={`exams-and-tests-exam-card-upper-element-container ${handleImage()}`} />
            <div className="exams-and-tests-exam-card-bottom-element-container">
                <div className="horizontal_flex_group margin_left_20 align_items_center">
                    <div className="margin_right_5 lh0">
                        <img src={icon} />
                    </div>
                    <div className="w600 s20 lh26 margin_left_5 lh0">
                        {testName}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestCard
