import { useState } from "react";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import PasswordInputElement from "../../../../components/input/PasswordInputElement";
import { PASSWORD_REGEX } from "../../../../utils/Constants";
import { PasswordInputIcon } from "../../../../utils/enums/PasswordInputIcon";
import { PasswordInputType } from "../../../../utils/enums/PasswordInputType";
import "./css/NewPasswordBox.css";

interface Props {
    onSubmit: (inputData: { [key: string]: string }) => void;
}

const NewPasswordBox: React.FC<Props> = ({
    onSubmit,
}) => {
    const [inputs, setInputs] = useState<{ [key: string]: string }>({
        password: '',
        confirmPassword: '',
    });

    const isPasswordAcceptable =  PASSWORD_REGEX.test(inputs.password)
    const isConfirmButtonEnabled = isPasswordAcceptable && inputs.password === inputs.confirmPassword

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setInputs((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        onSubmit(inputs);
    }

    // ********************************************************************************

    return (
        <div className="new-password-box-full-container">
            <div className="new-password-box-heading">
                Nuova Password
            </div>
            <div className="new-password-box-copy">
                Inserisci e conferma una nuova password per il tuo account Holifya.
            </div>
            <div className="new-password-box-hints">
                Una password valida è composta da almeno 8 caratteri di cui: almeno una Maiuscola, una minuscola, un numero e un simbolo speciale (!$%& ...)
            </div>
            <form
                className="new-password-box-form"
                onSubmit={handleSubmit}
            >
                <PasswordInputElement
                    name="password"
                    label="Nuova password"
                    placeholder="Nuova password"
                    disabled={false}
                    image={PasswordInputIcon.ShowDisabled}
                    type={PasswordInputType.Password}
                    inputValue={inputs.password}
                    toggleTypeEnabled={inputs.password !== ""}
                    onIconClick={() => console.log()}
                    onChange={handleInputChange}
                    errorText={""}
                />
                <PasswordInputElement
                    name="confirmPassword"
                    label="Conferma password"
                    placeholder="Conferma password"
                    disabled={!isPasswordAcceptable}
                    image={PasswordInputIcon.ShowDisabled}
                    type={PasswordInputType.Password}
                    inputValue={inputs.confirmPassword}
                    toggleTypeEnabled={isPasswordAcceptable && inputs.confirmPassword !== ""}
                    onIconClick={() => console.log()}
                    onChange={handleInputChange}
                    errorText={""}
                />
                <div className="new-password-box-confirm-button">
                    <PrimaryButton
                        text="Conferma Password"
                        disabled={!isConfirmButtonEnabled}
                        pointer={isConfirmButtonEnabled}
                    />
                </div>
            </form>
        </div>
    )
}

export default NewPasswordBox
