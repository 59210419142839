import "./css/ExpertCommentWidget.css"

interface Props {
    expertGender: string,
    expertComment: string
}

const ExpertCommentWidget: React.FC<Props> = ({
    expertGender,
    expertComment,
}) => {
    return (
        <div className="expert-comment-widget-container">
            <div className="expert-comment-widget-internal-container">
                <div className="expert-comment-widget-upper-element">
                    <div className="expert-comment-widget-title">
                        Il commento del piano
                    </div>
                    <div className="expert-comment-widget-author">
                        {expertGender === "Dott." ? "Dal tuo nutrizionista" : "Dalla tua nutrizionista"}
                    </div>
                </div>
                <div className="expert-comment-widget-content-container">
                    <div className="expert-comment-widget-comment">
                        {expertComment}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpertCommentWidget
