import "./css/PaywallProductTag.css"

interface Props {
    text: string,
}

const PaywallProductTag: React.FC<Props> = ({
    text,
}) => {
  return (
    <div className="paywall-product-tag-container">
      {text}
    </div>
  )
}

export default PaywallProductTag
