import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import HolifyaFullLogo from "../../../components/HolifyaFullLogo";
import { RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_PLAN } from "../../../utils/Constants";
import { NavigationToQuizQ } from "../../../utils/enums/NavigationToQuizQ";
import "./css/QuizQHomePage.css";

const QuizQHomePage = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const fromWhere = location.state.fromWhere

    const isBackButtonVisible = () => {
        switch (fromWhere) {
            case NavigationToQuizQ.OnboardingFork:
            case NavigationToQuizQ.PlatformDashboard:
            case NavigationToQuizQ.PlatformExperts:
            case NavigationToQuizQ.PlatformPlan:
                return true
            case NavigationToQuizQ.Login:
            case NavigationToQuizQ.RedeemSuccess:
                case NavigationToQuizQ.PlatformRedeem:
            default:
                return false
        }
    }

    const onBackClick = () => {
        switch (fromWhere) {
            case NavigationToQuizQ.OnboardingFork:
                navigate("/onboarding-fork")
                break;
            case NavigationToQuizQ.PlatformDashboard:
                navigate(RELATIVE_PATH_TO_PLATFORM)
                break;
            case NavigationToQuizQ.PlatformExperts:
                navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
                break;
            case NavigationToQuizQ.PlatformPlan:
                navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
                break;
            default:
        }
    }

    const handleConfirm = () => {
        navigate("/quiz-q-new", { state: { fromWhere } })
    }

    useEffect(() => {
    }, [])

    // ****************************************************************************************

    return (
        <div className="App Quiz justify_content_space_between relative">
            <div className={`quiz-q-home-header ${!isBackButtonVisible() ? "invisible" : ""}`}>
                <BackButton
                    onClick={onBackClick}
                />
            </div>
            <div className={`quiz-q-home-container`}>
                <div className="">
                    <HolifyaFullLogo />
                </div>
                <div>
                    <img src="/images/quiz-q-home.svg" />
                </div>
                <div className="quiz-q-home-after-purchase-text">
                    <div className="quiz-q-home-after-purchase-heading">
                        Iniziamo!
                    </div>
                    <div className="quiz-q-home-after-purchase-copy">
                        Rispondi a poche domande e tra 1 minuto saprai chi è il nutrizionista Holifya più adatto per raggiungere i tuoi obiettivi!
                    </div>
                </div>
                <button
                    className={`quiz-q-home-confirm-button primary_action_color_background`}
                    onClick={handleConfirm}
                >
                    Scopri il tuo nutrizionista
                </button>
            </div>
            <div className="quiz-q-home-footer" />
            {/* {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            } */}
        </div>
    )
}

export default QuizQHomePage
