import DownloadIconText from "../../../components/DownloadIconText"
import { handleDate } from "../../../utils/Functions"
import "./css/PlanCardWidget.css"

interface Props {
    planUrl: string,
    planCreatedAt: string,
    planUpdatedAt: string,
}

const PlanCardWidget: React.FC<Props> = ({
    planUrl,
    planCreatedAt,
    planUpdatedAt,
}) => {
    return (
        <div className="plan-card-widget-container">
            <div className="plan-card-widget-image-container">
                <iframe
                    src={planUrl + "#toolbar=0"}
                    width="100%"
                    height="100%"
                />
            </div>
            <div className="plan-card-widget-bottom-container">
                <div className="plan-card-widget-bottom-internal-container">
                    <div className="plan-card-widget-text-info-container">
                        <div className="w700 s16 lh20">
                            {handleDate(planCreatedAt)} - OGGI
                        </div>
                        <div className="w700 s20 lh20">
                            Il tuo piano attuale
                        </div>
                    </div>
                    <DownloadIconText
                        noMargin={true}
                        onClick={() => window.open(planUrl, "_blank")}
                    />
                </div>
            </div>
        </div>
    )
}

export default PlanCardWidget
