import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Expert from "../../components/Expert"
import { useUserData } from "../../utils/context/UserDataContext"
import { CheckoutBundleType, CheckoutMode } from "../../utils/enums/Checkout"
import { NavigationToInsertCodePage } from "../../utils/enums/NavigationToInsertCodePage"
import { ShopifyProduct } from "../../utils/enums/ShopifyProduct"
import NutritionistDetailsDialog from "./components/NutritionistDetailsDialog"
import PaymentInfoDialog from "./components/PaymentInfoDialog"
import PaywallMultipleChoiceCard from "./components/PaywallMultipleChoiceCard"
import "./css/PaywallMultipleChoice.css"

const PaywallMultipleChoice = () => {

  const navigate = useNavigate()

  const location = useLocation()
  const nutritionist: Expert = location.state.nutritionist

  const { userData } = useUserData()
  const username = userData.name

  const [showNutritionistDetailsDialog, setShowNutritionistDetailsDialog] = useState(false)
  const [showPaymentInfoDialog, setShowPaymentInfoDialog] = useState(false)

  const onPurchaseClick = (product: ShopifyProduct) => {
    switch (product) {
      case ShopifyProduct.OneMonthBundle:
        return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.DietBundle } })
      case ShopifyProduct.OneMonthSub:
        return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.DietSubscription } })
      case ShopifyProduct.ThreeMonthsBundle:
        return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.ThreeMonthsBundle } })
      case ShopifyProduct.ThreeMonthsSub:
        return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.ThreeMonthsSubscription } })
      case ShopifyProduct.SixMonthsBundle:
        return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.SixMonthsBundle } })
      case ShopifyProduct.SixMonthsSub:
        return navigate("checkout", { state: { mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.SixMonthsSubscription } })
      default:
        return ""
    }
  }

  const [chosenProduct, setChosenProduct] = useState(ShopifyProduct.OneMonthSub)

  const onKnowBetterNutritionistButtonClick = () => {
    setShowNutritionistDetailsDialog(true)
  }

  const onKnowBetterNutritionistDismissClick = () => {
    setShowNutritionistDetailsDialog(false)
  }

  const onPaymentInfoClick = (product: ShopifyProduct) => {
    setShowPaymentInfoDialog(true)
    setChosenProduct(product)
  }

  const onPaymentInfoDismissClick = () => {
    setShowPaymentInfoDialog(false)
  }

  const onTestIncludedClick = () => {
    setTestIncluded(!testIncluded)
  }

  const [testIncluded, setTestIncluded] = useState(true)

  const onToInsertCodeClicked = () => {
    navigate("/insert-product-code", { state: { fromWhere: NavigationToInsertCodePage.PaywallMultipleChoice, nutritionist} })
  }

  useEffect(() => {
  }, [])

  return (
    <div className="App Quiz relative">
      <div className={`paywall-multiple-choice-full-container ${(showPaymentInfoDialog || showNutritionistDetailsDialog) ? "visible_30_percent inactive" : ""}`}>
        <div className="paywall-multiple-choice-logo-container">
          <img src="/images/full-logo-new.svg" />
        </div>
        <div className="paywall-multiple-choice-content-container">
          <div className="paywall-multiple-choice-heading paywall-multiple-choice-desktop-only">Complimenti {username}! {String.fromCodePoint(0x1F60E)} <span className="w700">Questa è la tua soluzione su misura!</span></div>
          <div className="paywall-multiple-choice-heading paywall-multiple-choice-mobile-only">
            <div>Complimenti {username}! {String.fromCodePoint(0x1F60E)}</div>
            <div className="w700">Questa è la tua soluzione su misura!</div>
          </div>
          <PaywallMultipleChoiceCard
            nutritionist={nutritionist}
            onPaymentInfoClick={onPaymentInfoClick}
            onKnowBetterNutritionistButtonClick={onKnowBetterNutritionistButtonClick}
            testIncluded={testIncluded}
            onTestIncludedClick={onTestIncludedClick}
            onPurchaseClick={onPurchaseClick}
            onInsertProductCodeClicked={onToInsertCodeClicked}
          />
        </div>
        <div className="height_20" />
      </div>
      {
        showNutritionistDetailsDialog ?
          <NutritionistDetailsDialog
            onCloseClick={onKnowBetterNutritionistDismissClick}
            nutritionist={nutritionist}
          /> : null
      }
      {
        showPaymentInfoDialog ?
          <PaymentInfoDialog
            onCloseClick={onPaymentInfoDismissClick}
            product={chosenProduct}
          /> : null
      }
    </div>
  )
}

export default PaywallMultipleChoice
