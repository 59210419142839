import { Answer } from "./Answer"
import "./css/MultiSelectAnswerCard.css"

interface Props {
  answer: Answer,
  isChecked: boolean,
  onClick: (text: string) => void,
  isNowInput: boolean,
  onMultiSelectInputChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

const MultiSelectAnswerCard: React.FC<Props> = ({
  answer,
  isChecked,
  onClick,
  isNowInput,
  onMultiSelectInputChangeHandler,
}) => {
  return (
    <div
      className={`${isChecked ? "multiselect-answer-checked-card-container" : "multiselect-answer-card-container"}`}
      onClick={() => onClick(answer.text)}
    >
      <div className="multiselect-answer-card-internal-container">
        <div className="multiselect-answer-card-checkbox-container">
          <div className="multiselect-answer-card-checkbox-external-element">
            <div className={`${isChecked ? "multiselect-answer-card-checkbox-internal-element" : ""}`} />
          </div>
        </div>
        {
          answer.icon !== "" ?
            <div className="multiselect-answer-card-image-container">
              <img src={answer.icon} />
            </div> :
            null
        }
        {
          !(isNowInput && isChecked) ?
            <div className="multiselect-answer-card-text">
              {answer.text}
            </div> : 
            <input
              className="multiselect-answer-card-input-field"
              type="text"
              onChange={onMultiSelectInputChangeHandler}
              placeholder="Specifica la risposta"
              autoFocus
            />
        }
      </div>
    </div>
  )
}

export default MultiSelectAnswerCard
