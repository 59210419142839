export const enum KeyParameter {
    Water = "water",
    Weight = "weight",
    Energy = "energy",
    WaistLine = "waistline",
    Sleep = "sleep",
    HipsLine = "hipsline",
    ArmCircunference = "arm_circumference",
    Training = "training",
    StressLevel = "stress_level",
}