import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from 'react-gtm-module';
import { BrowserRouter } from "react-router-dom";
import "typeface-roboto";
import App from "./App";
import { AuthProvider } from "./utils/context/AuthContext";
import { RegistrationProvider } from "./utils/context/RegistrationContext";
import { UserDataProvider } from "./utils/context/UserDataContext";

const tagManagerArgs = {
    gtmId: 'GTM-P9DJPS6B'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <UserDataProvider>
                <AuthProvider>
                    <RegistrationProvider>
                        <App />
                    </RegistrationProvider>
                </AuthProvider>
            </UserDataProvider>
        </BrowserRouter>
    </React.StrictMode>
);
