import TrackingStatus from "../../../../utils/enums/TrackingStatus"
import "./css/CollectingActionsWidget.css"

interface Props {
    trackingStatus: TrackingStatus,
    onButtonClick: () => void,
}

const CollectingActionsWidget: React.FC<Props> = ({
    trackingStatus,
    onButtonClick,
}) => {

    const handleLeftElementHeading = () => {
        switch (trackingStatus) {
            case TrackingStatus.Delivered:
                return "Il tuo kit per effettuare il test è arrivato!"
            case TrackingStatus.TestDone:
                return "Registra il tuo codice campione"
            case TrackingStatus.SampleRegistered:
                return "Richiedi il ritiro del tuo campione"
            case TrackingStatus.PickupRequested:
                return "Modifica le informazioni di ritiro del tuo campione"
            default:
                return ""
        }
    }

    const handleLeftElementCopy = () => {
        switch (trackingStatus) {
            case TrackingStatus.Delivered:
                return <ul className="height_100_percent vertical_flex_group justify_content_space_between w400 s16 lh24">
                    <span className="w700 lh16">Cosa bisogna fare ora?</span>
                    <li className="margin_left_20">
                        Guarda il tutorial o segui le istruzioni per l'autoprelievo salivare
                    </li>
                    <li className="margin_left_20">
                        Registra il tuo codice campione
                    </li>
                    <li className="margin_left_20">
                        Impacchetta
                    </li>
                    <li className="margin_left_20">
                        Richiedi il ritiro dove preferisci!
                    </li>
                </ul>
            case TrackingStatus.TestDone:
                return <div className="w400 s16 lh24">
                    Il codice campione permette l'anonimizzazione del tuo campione salivare durante le fasi di logistica e analisi.
                </div>
            case TrackingStatus.SampleRegistered:
                return <div className="w400 s16 lh24">
                    Il ritiro è compreso e sempre a carico nostro. Decidi solo la modalità che prefersci.
                    Invia subito il tuo campione al laboratorio!
                </div>
            case TrackingStatus.PickupRequested:
                return <div className="w400 s16 lh24">
                    Hai cambiato piani? Se il campione non è ancora stato ritirato puoi modificare le informazioni di ritiro inserendo un'altra richiesta.
                </div>
            default:
                return ""
        }
    }

    const handleLeftElementButtonText = () => {
        switch (trackingStatus) {
            case TrackingStatus.Delivered:
                return "Iniziamo"
            case TrackingStatus.TestDone:
                return "Registra"
            case TrackingStatus.SampleRegistered:
                return "Richiedi ritiro"
            case TrackingStatus.PickupRequested:
                return "Modifica"
            default:
                return ""
        }
    }

    const handleImage = () => {
        switch (trackingStatus) {
            case TrackingStatus.Delivered:
                return "collecting-actions-widget-right-element-image-1"
            case TrackingStatus.TestDone:
                return "collecting-actions-widget-right-element-image-2"
            case TrackingStatus.SampleRegistered:
                return "collecting-actions-widget-right-element-image-3"
            case TrackingStatus.PickupRequested:
                return "collecting-actions-widget-right-element-image-4"
        }
    }

    return (
        <div className={`${trackingStatus === TrackingStatus.Delivered ? "collecting-actions-widget-big-container" : "collecting-actions-widget-small-container"}`}>
            <div className={`collecting-actions-widget-left-element-container`}>
                <div className="collecting-actions-widget-left-element-internal-container">
                    <div className="collecting-actions-widget-left-element-heading">
                        {handleLeftElementHeading()}
                    </div>
                    <div className={`${trackingStatus === TrackingStatus.Delivered ? "collecting-actions-widget-left-element-copy-big-container" : "collecting-actions-widget-left-element-copy-small-container"}`}>
                        {handleLeftElementCopy()}
                    </div>
                    <button
                        className="collecting-actions-widget-left-element-button-desktop primary_action_color_background"
                        onClick={onButtonClick}
                    >
                        {handleLeftElementButtonText()}
                    </button>
                </div>
            </div>
            <div className={`collecting-actions-widget-right-element-container ${handleImage()}`}>
                <div className="collecting-actions-widget-right-internal-element-container">
                <button
                    className="collecting-actions-widget-left-element-button-mobile primary_action_color_background"
                    onClick={onButtonClick}
                >
                    {handleLeftElementButtonText()}
                </button>
                </div>
            </div>
        </div>
    )
}

export default CollectingActionsWidget
