export enum BundleType {
    DietSubscription = "diet-subscription",
    ThreeMonthsSubscription = "three-months-subscription",
    SixMonthsSubscription = "six-months-subscription",
    DietBundle = "diet-bundle",
    ThreeMonthsBundle = "three-months-bundle",
    SixMonthsBundle = "six-months-bundle",
}

export enum CheckoutMode {
    SubscriptionOnly = "subscription_only",
    SubscriptionBundle = "subscription_bundle"
}

export enum CheckoutBundleType {
    DietBundle = "diet_bundle",
    ThreeMonthsBundle = "3_months_bundle",
    SixMonthsBundle = "6_months_bundle",
    DietSubscription = "diet_subscription",
    ThreeMonthsSubscription = "3_months_subscription",
    SixMonthsSubscription = "6_months_subscription",
}