import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HolifyaFullLogo from "../../components/HolifyaFullLogo";
import LoadingSpinner from "../../components/LoadingSpinner";
import { BASE_URL, RELATIVE_PATH_TO_PLATFORM, requestOptionsGET } from "../../utils/Constants";
import { OrderRetrieved } from "../../utils/api-objects/OrderRetrieved";
import { useAuth } from "../../utils/context/AuthContext";
import { NavigationToQuizQ } from "../../utils/enums/NavigationToQuizQ";
import { QuizType } from "../../utils/enums/QuizType";
import { ShopifyItemName, ShopifyItemSku } from "../../utils/enums/ShopifyItemType";
import "./css/ProductChoiceSuccess.css";

const ProductChoiceSuccess = () => {

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true)

    const [productName, setProductName] = useState("")

    const handleNavigation = (productSku: ShopifyItemSku, productName: ShopifyItemName) => {
        switch (productSku) {
            case ShopifyItemSku.DnaTest: {
                (productName === ShopifyItemName.DnaTest || productName === ShopifyItemName.DnaTestOld || productName === ShopifyItemName.DnaTestComplete) ?
                    // DNA test user
                    navigate(RELATIVE_PATH_TO_PLATFORM)
                    :
                    // Bundle Test + sub
                    callRetrieveQuizQApi(ShopifyItemSku.DnaTest)
                break
            }
            case ShopifyItemSku.DnaTestOld:
            case ShopifyItemSku.ExpertConsult: {
                navigate(RELATIVE_PATH_TO_PLATFORM)
                break;
            }
            case ShopifyItemSku.NutritionistConsult:
            case ShopifyItemSku.Membership: {
                callRetrieveQuizQApi(ShopifyItemSku.Membership)
            }
        }
    }

    async function callGetOrdersByUserIdApi() {
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (response.ok) {
            const responseJson: OrderRetrieved[] = await response.json()
            const productSku = responseJson[0].items[0].type
            const productName = responseJson[0].items[0].name
            setProductName(productName)
            setTimeout(() => {
                handleNavigation(productSku, productName)
            }, 3000)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    async function callRetrieveQuizQApi(productSku: ShopifyItemSku) {
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/survey/${QuizType.QuizQ}`, requestOptionsGET(idToken))
        if (response.status !== 404) {
            navigate("/survey-a-home")
        } else {
            if (productSku === ShopifyItemSku.NutritionistConsult) {
                navigate("/quiz-q-home", { state: { fromWhere: NavigationToQuizQ.RedeemSuccess } })
            } else {
                navigate("/quiz-t-home")
            }
        }
    }

    useEffect(() => {
        callGetOrdersByUserIdApi()
    }, [])

    // **********************************************************************************************************

    return (
        <div className="App Quiz relative">
            <div className={`product-choice-success-full-container ${isLoading && "visible-0-percent"}`}>
                <div className="product-choice-success-upper-element-container">
                    <HolifyaFullLogo />
                </div>
                <div className="product-choice-success-center-element-container">
                    <div className={`product-choice-success-container`}>
                        <div className="product-choice-success-copy">
                            <div className="w700">
                                Complimenti!
                            </div>
                            <div>
                                Il tuo acquisto é un<br /><span className="w700 primary_action_color_text">{productName}</span>!
                            </div>
                        </div>
                        <div>
                            <LoadingSpinner />
                        </div>
                    </div>
                </div>
                <div className="product-choice-success-bottom-element-container" />
            </div>
            {
                isLoading && <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div>
            }
        </div>
    )
}

export default ProductChoiceSuccess
