import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseButton from "../../../../components/CloseButton";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import TernaryButton from "../../../../components/buttons/TernaryButton";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, YOUTUBE_VIDEO_TUTORIAL, requestOptionsGET } from "../../../../utils/Constants";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem";
import TrackingStatus from "../../../../utils/enums/TrackingStatus";
import MenuPopup from "../../components/MenuPopup";
import "./css/ExamsAndTestsCollectingTutorial.css";

const ExamsAndTestsCollectingTutorial = () => {

    const navigate = useNavigate();

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const { userData } = useUserData()
    const username = userData.name!
    const familyName = userData.familyName!
    const email = userData.email!

    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setSwabCode(responseJson.swabcode)
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
        callFindTrackerByUserIdAPI()
    }, [])

    // *******************************************************************************************************************

    // The swab code associated with the user
    const [swabCode, setSwabCode] = useState<string | undefined>(undefined)

    // This state determines the position on the progression bar, and also the direction of the links
    const [trackingStatus, setTrackingStatus] = useState<TrackingStatus>(TrackingStatus.Purchased)
    const [orderId, setOrderId] = useState("")

    const section1 = useRef<null | HTMLDivElement>(null);
    const section2 = useRef<null | HTMLDivElement>(null);

    const onReadInstructionsClick = () => {
        section2.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const onWatchVideoTutorialClick = () => {
        section1.current?.scrollIntoView({ behavior: 'smooth' })
    }

    // When the user confirms the sample collection
    const onSampleCollectionClick = () => {
        callUpdateTrackerAPI()
        if (swabCode !== undefined && swabCode !== null && swabCode !== "") {
            navigate("/platform/exams-and-tests/collecting/packing")
        } else {
            navigate("/platform/exams-and-tests/collecting/register-swab-code")
        }
    }

    // GET tracking by user ID
    async function callFindTrackerByUserIdAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/track/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            setTrackingStatus(responseJson[0].tracking_status)
            setOrderId(responseJson[0].order_id)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // UPDATE track item
    async function callUpdateTrackerAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/track/order/${orderId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    tracking_status: TrackingStatus.TestDone,
                }
            )
        })
        setIsLoading(false)
        const responseJson = await response.json()
        if (response.ok) {
            console.log("ok")
            console.log(responseJson)
        } else {
            console.log("error")
            console.log(responseJson)
        }
    }

    // ********************************************************************************

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading && "visible_30_percent inactive"}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        currentPage={PlatformMenuItem.ExamsAndTests}
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-exams-and-tests-collecting-tutorial-full-container ${(focusOnMenu) ? "visible_30_percent inactive" : ""}`}>
                    <div className="exams-and-tests-collecting-tutorial-title-row">
                        <div className="exams-and-tests-collecting-tutorial-heading">
                            Effettua l'autoprelievo il test del DNA!
                        </div>
                        <CloseButton
                            noFlexEnd={true}
                            onClick={() => navigate("/platform/exams-and-tests/collecting", { state: { sampleState: TrackingStatus.Purchased } })}
                        />
                    </div>
                    <div className="exams-and-tests-collecting-tutorial-full-container">
                        <div
                            ref={section1}
                            className="exams-and-tests-collecting-tutorial-full-internal-container"
                        >
                            <div className="exams-and-tests-collecting-tutorial-section-1-container">
                                <div className="exams-and-tests-collecting-tutorial-section-1-content-container">
                                    <div className="exams-and-tests-collecting-tutorial-copy-container">
                                        <div className="exams-and-tests-collecting-tutorial-copy-1">
                                            Puoi seguire le istruzioni cartacee presenti nel kit che hai ricevuto, seguire il video tutorial di autoprelievo o leggerle qui sotto.
                                        </div>
                                        <div className="exams-and-tests-collecting-tutorial-copy-2">
                                            Video tutorial: <span className="w700">Autoprelievo</span>
                                        </div>
                                    </div>
                                    <div className="exams-and-tests-collecting-tutorial-video-container">
                                        <iframe
                                            width="100%"
                                            height="100%"
                                            src={YOUTUBE_VIDEO_TUTORIAL}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                        />
                                    </div>
                                </div>
                                <div className="exams-and-tests-collecting-tutorial-section-1-buttons-container">
                                    <div className="exams-and-tests-collecting-tutorial-confirm-button">
                                        <TernaryButton
                                            text={"Leggi le istruzioni testuali"}
                                            onClick={onReadInstructionsClick}
                                            pointer={true}
                                        />
                                    </div>
                                    <div className="exams-and-tests-collecting-tutorial-confirm-button">
                                        <PrimaryButton
                                            text={"Autoprelievo effettuato! avanti"}
                                            pointer={true}
                                            disabled={false}
                                            onClick={onSampleCollectionClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            ref={section2}
                            className="exams-and-tests-collecting-tutorial-full-internal-container"
                        >
                            <div className="exams-and-tests-collecting-tutorial-section-2-container">
                                <div className="exams-and-tests-collecting-tutorial-section-2-content-container">
                                    <div className="w400 s18 lh24">
                                        Prima di effettuare il test assicurati che nei 60 minuti precedenti al prelievo tu non abbia: mangiato, masticato gomme, fumato, lavato I denti, bevuto alcun tipo di liquido eccetto acqua. Estrai lo swab dalla provetta tirando il tappo.
                                    </div>
                                    <div className="horizontal_flex_group align_items_center width_100_percent height_20_percent">
                                        <img src="/images/tutorial-1.svg" height="140px" width="140px" />
                                        <div className="margin_left_20 w400 s18 lh24">
                                            <span className="w700 s30 lh24">1</span> Inserire lo swab nel cavo orale, porlo sulla lingua e inumidirlo di saliva.
                                        </div>
                                    </div>
                                    <div className="horizontal_flex_group align_items_center width_100_percent height_20_percent">
                                        <img src="/images/tutorial-2.svg" height="140px" width="140px" />
                                        <div className="margin_left_20 w400 s18 lh24">
                                            <span className="w700 s30 lh24">2</span> Chiudere la bocca e tenere lo swab in posizione per 10 secondi.
                                        </div>
                                    </div>
                                    <div className="horizontal_flex_group align_items_center width_100_percent height_20_percent">
                                        <img src="/images/tutorial-3.svg" height="140px" width="140px" />
                                        <div className="margin_left_20 w400 s18 lh24">
                                            <span className="w700 s30 lh24">3</span> Con l’indice e il medio, premere lo swab contro l’interno della guancia e strofinare energicamente 10 volte vicino alla parete gengivale.
                                        </div>
                                    </div>
                                    <div className="horizontal_flex_group align_items_center width_100_percent height_20_percent">
                                        <img src="/images/tutorial-4.svg" height="140px" width="140px" />
                                        <div className="margin_left_20 w400 s18 lh24">
                                            <span className="w700 s30 lh24">4</span> Ripetere il passaggio sul lato opposto del cavo orale.
                                        </div>
                                    </div>
                                </div>
                                <div className="exams-and-tests-collecting-tutorial-section-2-buttons-container">
                                    <div className="exams-and-tests-collecting-tutorial-confirm-button">
                                        <TernaryButton
                                            text={"Guarda il video tutorial"}
                                            onClick={onWatchVideoTutorialClick}
                                            pointer={true}
                                        />
                                    </div>
                                    <div className="exams-and-tests-collecting-tutorial-confirm-button">
                                        <PrimaryButton
                                            text={"Autoprelievo effettuato! avanti"}
                                            pointer={true}
                                            disabled={false}
                                            onClick={onSampleCollectionClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading && <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div>
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default ExamsAndTestsCollectingTutorial
