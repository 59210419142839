import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { RELATIVE_PATH_TO_PLATFORM } from "../../../utils/Constants";
import { isANutritionistConsult, isAShopifySubbedUser } from "../../../utils/Functions";
import { getQuizQ, getSurveyA } from "../../../utils/apis/emr-service";
import { getOrders } from "../../../utils/apis/order-service";
import { getSubscription } from "../../../utils/apis/payment-service";
import { getUserInfoApi } from "../../../utils/apis/user-service";
import { useAuth } from "../../../utils/context/AuthContext";
import { useUserData } from "../../../utils/context/UserDataContext";
import { NavigationToQuizQ } from "../../../utils/enums/NavigationToQuizQ";
import { NavigationToRewardPage } from "../../../utils/enums/NavigationToRewardPage";
import { ShopifyItemName, ShopifyItemSku } from "../../../utils/enums/ShopifyItemType";
import { SubscriptionStatus } from "../../../utils/enums/SubscriptionStatus";

const LoginSuccess = () => {

    const navigate = useNavigate();
    const { auth } = useAuth();
    const { updateUserData } = useUserData()
    const idToken = auth.idToken
    const userId = auth.userId

    const [getErrorText, setGetErrorText] = useState("")

    const isUserLogged = idToken !== null && userId !== null

    const startLoginChecks =  () => {
        callGetUserInfo()
    }

    const callGetUserInfo = () => {
        isUserLogged && getUserInfoApi(
            userId,
            idToken,
            (user) => {
                updateUserData(user.data.name, user.data.family_name, user.email)
                getQuizQApi()
            },
            (error) => {
                setGetErrorText(error)
            }
        )
    }

    const getQuizQApi = () => {
        isUserLogged && getQuizQ(
            userId,
            idToken,
            () => {
                getOrdersApi(true)
            },
            () => {
                getOrdersApi(false)
            }
        )
    }

    const getSurveyAApi = () => {
        isUserLogged && getSurveyA(
            userId,
            idToken,
            () => {
                return navigate(RELATIVE_PATH_TO_PLATFORM)
            },
            () => {
                return navigate("/survey-a-home")
            }
        )
    }

    const getOrdersApi = (quizTaken: boolean) => {
        isUserLogged && getOrders(
            userId,
            idToken,
            (orders) => {
                const productsLength = orders.length
                if (quizTaken) {
                    if (productsLength >= 1) {
                        var surveyANeeded = false
                        for (const order of orders) {
                            const sku: ShopifyItemSku = order.items[0].type
                            const name: ShopifyItemName = order.items[0].name
                            if (isANutritionistConsult(sku, name) || isAShopifySubbedUser(sku, name)) {
                                surveyANeeded = true
                            }
                        }
                        if (surveyANeeded) {
                            getSurveyAApi()
                        } else {
                            getSubscription(
                                userId,
                                idToken,
                                (subscripition) => {
                                    const subStatus: SubscriptionStatus = subscripition.status
                                    if (subStatus === SubscriptionStatus.Active || subStatus === SubscriptionStatus.Trial) {
                                        getSurveyAApi()
                                    } else {
                                        navigate(RELATIVE_PATH_TO_PLATFORM)
                                    }
                                },
                                () => { }
                            )
                        }
                    } else {
                        getSubscription(
                            userId,
                            idToken,
                            (subscription) => {
                                const subStatus = subscription.status
                                handleYesQuizQNavigation(subStatus, false)
                            },
                            () => { }
                        )
                    }
                } else {
                    if (productsLength === 0) {
                        navigate("/onboarding-fork")
                    } else {
                        var quizQNeeded = false
                        var finalSku = ShopifyItemSku.DnaTest
                        var finalName = ShopifyItemName.DnaTest
                        for (const order of orders) {
                            const sku: ShopifyItemSku = order.items[0].type
                            const name: ShopifyItemName = order.items[0].name
                            if (isANutritionistConsult(sku, name) || isAShopifySubbedUser(sku, name)) {
                                quizQNeeded = true
                                finalSku = sku
                                finalName = name
                            }
                        }
                        handleNoQuizQNavigation(quizQNeeded, isANutritionistConsult(finalSku, finalName))
                    }
                }
            },
            () => { }
        )
    }

    const handleNoQuizQNavigation = (quizQNeeded: boolean, isANutritionistConsult: boolean) => {
        quizQNeeded ?
            isANutritionistConsult ?
                navigate("/quiz-q-home", { state: { fromWhere: NavigationToQuizQ.Login } }) :
                navigate("/quiz-t-home")
            :
            navigate(RELATIVE_PATH_TO_PLATFORM)
    }

    const handleYesQuizQNavigation = (status: SubscriptionStatus, ordersAvailable: boolean) => {
        if (!ordersAvailable) {
            switch (status) {
                case SubscriptionStatus.Inactive:
                    return navigate("/paywall-single-product", { state: { fromWhere: NavigationToRewardPage.LoginNoPurchase } })
                case SubscriptionStatus.Active:
                case SubscriptionStatus.Trial:
                    getSurveyAApi()
                    break;
            }
        }
    }

    useEffect(() => {
        startLoginChecks()
    }, [])

    // ********************************************************************************

    return (
        <div className="App justify_content_center">
            <div className="vertical_flex_group white_background width_392 margin_50">
                <div className="s30 w700 lh24 self_center margin_top_20 margin_bottom_20 center">
                    Accesso in corso
                </div>
                <div className="s18 w400 lh26 margin_top_20 margin_bottom_20 center self_center">
                    Stai per essere reindirizzato alla tua Area Riservata
                </div>
                <LoadingSpinner />
                {
                    getErrorText !== "" &&
                    <button
                        className="width_328 height_55 uppercase pointer w700 s14 br5 white_background black_border margin_top_10 self_center margin_bottom_10"
                        onClick={() => { navigate("/login") }}
                    >
                        Torna alla login page
                    </button>
                }
                <label className="s12 w600 lh17 error_text margin_top_10 center">{getErrorText}</label>
            </div>
        </div>
    )
}

export default LoginSuccess
