import { StripeSubscription } from "../api-objects/StripeSubscription";
import { BASE_URL, requestOptionsGET } from "../Constants";

/**
 * Type for the callback function invoked upon get orders success.
 */
type GetSubscriptionSuccessCallback = (subscription: StripeSubscription) => void;

/**
 * Type for the callback function invoked upon a generic error.
 */
type GetSubscriptionErrorCallback = (error: string) => void;

export async function getSubscription(
    userId: string,
    idToken: string,
    onSuccess: GetSubscriptionSuccessCallback,
    onError: GetSubscriptionErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/payment/subscription/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const stripeSubscription = await response.json()
            onSuccess(stripeSubscription)
        } else {
            const error = await response.json()
            onError(error)
        }
    } catch (error) {
        console.error("Get subscription failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}