import "./css/RewardPaywallOtherOptionsSection.css"

interface Props {
    onClick: () => void,
}

const RewardPaywallOtherOptionsSection: React.FC<Props> = ({
    onClick,
}) => {
    return (
        <div className="reward-paywall-other-options-section-full-container">
            <button
                className="reward-paywall-other-options-section-button"
                onClick={onClick}
            >
                Altre opzioni di acquisto
            </button>
        </div>
    )
}

export default RewardPaywallOtherOptionsSection
