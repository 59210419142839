import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Expert from "../../../components/Expert";
import HolifyaFullLogo from "../../../components/HolifyaFullLogo";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { BASE_URL, requestOptionsGET } from "../../../utils/Constants";
import { filterNutritionist, handleExpertRole } from "../../../utils/Functions";
import { CalendlyExpert } from "../../../utils/api-objects/CalendlyExpert";
import { useAuth } from "../../../utils/context/AuthContext";
import { ExpertRole } from "../../../utils/enums/ExpertRole";
import { ExpertGender, ExpertGenderRole } from "../../../utils/enums/ExpertsGender";
import { NavigationToDismissiblePaywall } from "../../../utils/enums/NavigationToDismissiblePaywall";
import { NavigationToQuizQ } from "../../../utils/enums/NavigationToQuizQ";
import { NavigationToRewardPage } from "../../../utils/enums/NavigationToRewardPage";
import "./css/AssignNutritionist.css";

const AssignNutritionist = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const fromWhere = location.state.fromWhere

    const { auth } = useAuth();
    const userId = auth.userId!
    const idToken = auth.idToken!

    const [stage, setStage] = useState(1)

    // GET all medical users by role
    async function callAPIGetMedicalUsersList() {
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/role/${ExpertRole.Nutritionist}`, requestOptionsGET(idToken))
        if (responseUserinfo.ok) {
            const responseJson: CalendlyExpert[] = await responseUserinfo.json()
            const filteredResponseJson = filterNutritionist(responseJson, userId)
            const nutriNumber = filteredResponseJson.length
            const index = Math.round(Math.random() * (nutriNumber - 1))
            const nutritionist = filteredResponseJson[index]
            const newExpert: Expert = {
                id: nutritionist.id,
                position: nutritionist.data.sex === ExpertGender.Male ? ExpertGenderRole.Dott : ExpertGenderRole.Dottssa,
                name: nutritionist.data.name,
                familyName: nutritionist.data.family_name,
                role: handleExpertRole(nutritionist.role),
                skills: nutritionist.tags,
                summary: nutritionist.data.bio,
                avatar: nutritionist.calendly_data.calendly_user.avatar_url,
            }
            callAPIAssignMedicalUserToPatient(newExpert)
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    // ASSIGN specialist to a user
    async function callAPIAssignMedicalUserToPatient(myNutri: Expert) {
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/assign/`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    patient_id: userId,
                    medicaluser_id: myNutri.id,
                })
        })
        if (responseUserinfo.ok) {
            handleNavigation()
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    const handleNavigation = () => {
        switch (fromWhere) {
            case NavigationToQuizQ.PlatformDashboard:
                return navigate("/dismissible-paywall-multiple-choice", { state: { fromWhere: NavigationToDismissiblePaywall.Platform } })
            case NavigationToQuizQ.PlatformPlan:
                return navigate("/dismissible-paywall-multiple-choice", { state: { fromWhere: NavigationToDismissiblePaywall.PlatformPlan } })
            case NavigationToQuizQ.PlatformExperts:
                return navigate("/dismissible-paywall-multiple-choice", { state: { fromWhere: NavigationToDismissiblePaywall.PlatformExperts } })
            case NavigationToQuizQ.RedeemSuccess:
            case NavigationToQuizQ.Login:
                return navigate("/reward-page", { state: { fromWhere: NavigationToRewardPage.OnboardingAfterPurchaseNutritionistConsult } })
            case NavigationToQuizQ.OnboardingFork:
                return navigate("/paywall-single-product", { state: { fromWhere: NavigationToRewardPage.LoginNoPurchase } })
            default:
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setStage(2)
        }, 2500)
        setTimeout(() => {
            callAPIGetMedicalUsersList()
        }, 5000)
    }, [])

    // ***********************************************************************************

    return (
        <div className="App Quiz justify_content_center">
            <div className="assign-nutritionist-full-container">
                <div className="">
                    <HolifyaFullLogo />
                </div>
                <div>
                    <div className="assign-nutritionist-full-text">
                        <div className="w700">Grazie!</div>
                        <div className="">Stiamo analizzando le tue risposte!</div>
                    </div>
                </div>
                <div>
                    <LoadingSpinner />
                </div>
                <div className="assign-nutritionist-stage-container">
                    <img src={`${stage === 1 ? "/images/reward-page-answers-analysis-icon.svg" : "/images/reward-page-expert-choice.svg"}`} />
                    <div className={`${stage === 1 ? "assign-nutriotionist-first-analysis-color" : "assign-nutriotionist-second-analysis-color"}`}>{stage === 1 ? "Definizione protocollo personalizzato" : "Selezione professionista dedicato"}</div>
                </div>
            </div>
        </div>
    )
}


export default AssignNutritionist
