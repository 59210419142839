import CloseButton from "../../../../components/CloseButton"
import "./css/ChangePasswordDialog.css"

interface Props {
    onClick: () => void,
}

const ChangePasswordDialog: React.FC<Props> = ({
    onClick,
}) => {
    return (
        <div className="change-password-dialog-external-container center-div">
            <div className="change-password-dialog-internal-container">
                <div className="change-password-dialog-close-button-row">
                    <CloseButton onClick={onClick}/>
                </div>
                <div className="change-password-dialog-copy">
                    Abbiamo aggiornato la piattaforma ed è necessario un cambio password.<br></br>
                    Clicca su “Non riesci ad accedere?”, inserisci la tua mail associata al tuo account e reimposta la password per accedere.
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordDialog
