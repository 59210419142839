import { TrackerWidgetDotType } from "../../../utils/enums/TrackerWidgetDotType"
import { TrackerWidgetTextType } from "../../../utils/enums/TrackerWidgetTextType"
import "./css/DNATestTrackerWidgetSingleElement.css"

interface Props {
    dotType: TrackerWidgetDotType,
    textType: TrackerWidgetTextType,
    text: string,
}

const DNATestTrackerWidgetSingleElement: React.FC<Props> = ({
    dotType,
    textType,
    text,
}) => {
    return (
        <div className="dna-test-tracker-widget-single-path-element">
            <div className={`${dotType === TrackerWidgetDotType.Empty ? "dna-test-tracker-widget-empty-dot" : (dotType === TrackerWidgetDotType.Border ? "dna-test-tracker-widget-empty-dot-blue" : "dna-test-tracker-widget-filled-dot")}`} />
            <div className={`dna-test-tracker-widget-path-states ${textType === TrackerWidgetTextType.Colored ? "dna-test-tracker-widget-blue-text" : ""}`}>
                {text}
            </div>
        </div>
    )
}

export default DNATestTrackerWidgetSingleElement