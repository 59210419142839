import { useState } from "react";
import { useNavigate } from "react-router-dom";
import HolifyaFullLogo from "../../../components/HolifyaFullLogo";
import { BASE_URL, requestOptionsGET } from "../../../utils/Constants";
import { useAuth } from "../../../utils/context/AuthContext";
import "./css/SurveyAHome.css";

const SurveyAHome = () => {

  const navigate = useNavigate();

  const { auth } = useAuth()
  const idToken = auth.idToken!
  const userId = auth.userId

  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = () => {
    callAPI();
  }

  async function callAPI() {
    setIsLoading(true)
    const responseQuizTaken = await fetch(`${BASE_URL}/api/emr/${userId}`, requestOptionsGET(idToken))
    setIsLoading(false)
    if (responseQuizTaken.status === 404) {
      console.log(responseQuizTaken)
    } else if (responseQuizTaken.ok) {
      const responseJson = await responseQuizTaken.json()
      const sex = responseJson.data.sex
      navigate("/survey-a-new", { state: { sex } })
    }
  }

  const handleRetrieveQuizQInfoButtonClick = () => {
    callRetrieveQuizQInfoAPI();
  }

  async function callRetrieveQuizQInfoAPI() {
    setIsLoading(true)
    const responseQuizTaken = await fetch(`${BASE_URL}/api/users/${userId}/survey/Quiz`, requestOptionsGET(idToken))
    setIsLoading(false)
    if (responseQuizTaken.ok) {
      const responseJson = await responseQuizTaken.json()
      console.log(responseJson)
    } else {
      console.log(responseQuizTaken)
    }
  }

  // ********************************************************************************

  return (
    <div className="App Quiz justify_content_center">
      <div className="survey-a-home-external-container">
        <div className="survey-a-home-internal-container">
          <div>
            <HolifyaFullLogo />
          </div>
          <div>
            <img src="/images/survey-a-home-logo.svg" />
          </div>
          <div className="survey-a-home-heading">
            Servono ancora pochissime informazioni per personalizzare il tuo piano!
          </div>
          <button
            className="survey-a-home-confirm-button primary_action_color_background"
            onClick={handleOnClick}
          >
            Rispondi subito
          </button>
        </div>
      </div>
    </div>
  )
}

export default SurveyAHome
