import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ExampleNumberAndLetters from "../../components/ExampleNumberAndLetters"
import HolifyaFullLogo from "../../components/HolifyaFullLogo"
import LoadingSpinner from "../../components/LoadingSpinner"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import TextInputElement from "../../components/input/TextInputElement"
import { BASE_URL, RELATIVE_PATH_TO_PLATFORM, SWAB_CODE_MINIMUM_DIGITS } from "../../utils/Constants"
import { OrderFound } from "../../utils/api-objects/OrderFound"
import { useAuth } from "../../utils/context/AuthContext"
import SwabExampleDialog from "../platform/menu/examsAndTests/components/SwabExampleDialog"
import "./css/PharmaciesRegisterSwabCode.css"

const PharmaciesRegisterSwabCode = () => {

  // Navigation parameters
  const navigate = useNavigate()
  const location = useLocation();
  const shopifyItem: OrderFound = location.state.shopifyItem

  const { auth } = useAuth()
  const idToken = auth.idToken!
  const userId = auth.userId

  // Booleans to show alternative screens
  const [isLoading, setIsLoading] = useState(false)
  const [showExampleNumbersAndLetter, setShowExampleNumbersAndLetter] = useState(false)
  const [showSwabExample, setShowSwabExample] = useState(false)

  // Swab code and its error
  const [swabcode, setSwabcode] = useState("")
  const [swabcodeErrorText, setSwabcodeErrorText] = useState("")

  // Arrow button to display the helping div
  const [arrowIcon, setArrowIcon] = useState("/images/down-arrow-blue.svg")
  const onArrowClick = () => {
    if (arrowIcon === "/images/down-arrow-blue.svg") {
      setArrowIcon("/images/up-arrow-blue.svg")
      setShowExampleNumbersAndLetter(true)
    } else {
      setArrowIcon("/images/down-arrow-blue.svg")
      setShowExampleNumbersAndLetter(false)
    }
  }

  // When the user click on the info logo
  const onInfoLogoClick = () => {
    setShowSwabExample(true)
  }

  const onInfoCloseClick = () => {
    setShowSwabExample(false)
  }

  // Boolean to determine whether the submit button is disabled
  const isSubmitButtonDisabled = swabcode.length < SWAB_CODE_MINIMUM_DIGITS
  // When the user clicks the submit button
  const onSubmitClick = (e: React.FormEvent) => {
    e.preventDefault();
    callUpdateUserInformationsAPI(swabcode)
  }

  async function callSaveOrderAPI() {
    setIsLoading(true)
    console.log(shopifyItem)
    const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': idToken,
      },
      body: JSON.stringify(
        {
          shopify_id: shopifyItem.shopify_id,
          confirmation_number: shopifyItem.confirmation_number,
          items: shopifyItem.items,
        })
    })
    setIsLoading(false)
    if (!response.ok) {
      const responseJson = await response.json()
      console.log(responseJson)
    } else {
      // callAPIAssignMedicalUserToPatient()
      navigate(RELATIVE_PATH_TO_PLATFORM)
    }
  }

  // UPDATE user informations
  async function callUpdateUserInformationsAPI(swabCode: string) {
    setIsLoading(true)
    const response = await fetch(`${BASE_URL}/api/users/${userId}`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': idToken,
      },
      body: JSON.stringify(
        {
          swabcode: swabCode,
        })
    })
    setIsLoading(false)
    if (!response.ok) {
      const responseJson = await response.json()
      console.log(responseJson)
      setSwabcodeErrorText("Inserisci un codice corretto e disponibile")
    } else {
      callSaveOrderAPI()
    }
  }

  useEffect(() => {
    console.log(location.state)
  }, [])

  // ********************************************************************************

  return (
    <div className="App Quiz justify_content_center relative">
      <div className={`pharmacies-register-swab-code-container ${(isLoading || showSwabExample) && "visible_30_percent inactive"}`}>
        <div className={`${showExampleNumbersAndLetter ? "pharmacies-register-swab-code-internal-big-container" : "pharmacies-register-swab-code-internal-container"}`}>
          <HolifyaFullLogo />
          <div className="pharmacies-register-heading-container">
            <div className="pharmacies-register-heading">Registra il tuo campione </div>
            <div
              onClick={onInfoLogoClick}
              className="pointer margin_left_10 lh0"
            >
              <img src="/images/info-logo.svg" />
            </div>
          </div>
          <div className="pharmacies-register-copy-container">
            <div>Inserisci il tup codice unico di 12 cifre.</div>
            <div className="horizontal_flex_group align_items_center">
              <div className="w400 s16 lh24 center">Presta attenzione ai <span className="w700 underline_text primary_action_color_text">caratteri più facilmente fraintendibili</span></div>
              <div
                className="pointer margin_left_10 lh0"
                onClick={onArrowClick}
              >
                <img src={arrowIcon} />
              </div>
            </div>
          </div>
          {
            showExampleNumbersAndLetter &&
            <div className="pharmacies-register-swab-code-example-numbers-and-letters">
              <ExampleNumberAndLetters image={"/images/number-0.svg"} text={"0 (numero zero)"} />
              <ExampleNumberAndLetters image={"/images/letter-O.svg"} text={"O (o maiuscola)"} />
              <ExampleNumberAndLetters image={"/images/number-1.svg"} text={"1 (uno numero)"} />
              <ExampleNumberAndLetters image={"/images/letter-l.svg"} text={"l (elle minuscola)"} />
              <ExampleNumberAndLetters image={"/images/letter-I.svg"} text={"I (i maiuscola)"} />
            </div>
          }
          <form
            className="pharmacies-register-swab-code-form"
            onSubmit={onSubmitClick}
          >
            <TextInputElement
              label="Codice campione"
              placeholder="Es: as6TLu53Gvi9"
              inputValue={swabcode}
              onChange={(e) => setSwabcode(e.target.value)} errorText={swabcodeErrorText}
            />
            <div className="pharmacies-register-swab-code-confirm-button">
              <PrimaryButton
                text="Conferma codice"
                disabled={isSubmitButtonDisabled}
                pointer={!isSubmitButtonDisabled}
              />
            </div>
          </form>
        </div>
      </div>
      {
        isLoading && <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div>
      }
      {
        showSwabExample && <SwabExampleDialog onInfoCloseClick={onInfoCloseClick} />
      }
    </div>
  )
}

export default PharmaciesRegisterSwabCode
