interface Props {
    onUploadSuccessDismissButtonClick: () => void,
    folder: string,
}

const UploadFileSuccessDialog: React.FC<Props> = ({
    onUploadSuccessDismissButtonClick,
    folder,
}) => {
    return (
        <div className={`vertical_flex_group justify_content_center absolute br20 gray_border drop_shadow white_background width_35_percent height_10_percent center-div`}>
            <div className="width_100_percent height_80_percent">
                <div className="horizontal_flex_group justify_content_space_between align_items_center margin-left-7-percent margin-right-7-percent height_50_percent w700 s20 lh40">
                    <div>File salvato</div>
                    <div
                        className="pointer"
                        onClick={onUploadSuccessDismissButtonClick}
                    >
                        <img src="/images/x.svg" />
                    </div>
                </div>
                <div className="margin-left-7-percent margin-right-7-percent height_50_percent w400 s16 lh40">File salvato nella cartella <span className="w700">{folder}</span></div>
            </div>
        </div>
    )
}

export default UploadFileSuccessDialog
