import React from "react"
import { TrackerWidgetDotType } from "../../../utils/enums/TrackerWidgetDotType"
import { TrackerWidgetTextType } from "../../../utils/enums/TrackerWidgetTextType"
import "./css/PlanTrackerWidgetSingleElement.css"

interface Props {
    dotType: TrackerWidgetDotType,
    textType: TrackerWidgetTextType,
    text: string,
}

const PlanTrackerWidgetSingleElement: React.FC<Props> = ({
    dotType,
    textType,
    text,
}) => {
    const lines = text.split("\\n")
    return (
        <div className="plan-tracker-widget-single-path-element">
            <div className={`${dotType === TrackerWidgetDotType.Filled ? "plan-tracker-widget-single-path-element-filled-dot" : "plan-tracker-widget-single-path-element-empty-dot"}`} />
            <div className={`plan-tracker-widget-single-path-element-text ${textType === TrackerWidgetTextType.Colored ? "plan-tracker-widget-green-text" : ""}`}>
                {lines.map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        {index < lines.length - 1 && <br />}
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}

export default PlanTrackerWidgetSingleElement
