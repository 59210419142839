import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import "./css/QuizQNewRewardPage.css"

const QuizQNewRewardPage = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const name = location.state.name
    const familyName = location.state.familyName
    const email = location.state.email
    const tempId = location.state.tempId

    useEffect(() => {
    }, [])

    // ****************************************************************************************

    return (
        <div className="App Quiz justify_content_center">
            <div className="quiz-q-new-reward-page-container">
                <div>
                    <img src="/images/quizQ/reward-page-icon.svg" />
                </div>
                <div className="quiz-q-new-reward-page-text-box">
                    <div className="quiz-q-new-reward-page-heading">
                        Scopri il tuo Nutrizionista esperto Holifya!
                    </div>
                </div>
                <button
                    onClick={() => navigate("/email-registration", { state: { name, familyName, email, tempId } })}
                    className="quiz-q-new-reward-page-button primary_action_color_background"
                >
                    Scoprilo adesso
                </button>
            </div>
        </div>
    )
}

export default QuizQNewRewardPage
