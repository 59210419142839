import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useUserData } from "../../../utils/context/UserDataContext"
import "./css/QuizTHome.css"

const QuizTHome = () => {

    const navigate = useNavigate()
    
    const { userData } = useUserData()
    const username = userData.name

    const onContinueClick = () => {
        navigate("/quiz-t")
    }

    useEffect(() => {
    }, [])

    // ********************************************************************************

    return (
        <div className="App Quiz">
            <div className="quiz-t-home-full-container">
                <div className="quiz-t-home-internal-container">
                    <div className="quiz-t-home-heading">Grazie per il tuo acquisto, {username}!</div>
                    <img src="/images/quizT/home-page.svg" />
                    <div className="quiz-t-home-copy">
                        Inserisci le ultime informazioni anamnestiche utili al tuo nutrizionista Holifya.
                    </div>
                    <button
                        className="quiz-t-home-continue-button primary_action_color_background"
                        onClick={onContinueClick}
                    >
                        Avanti
                    </button>
                </div>
            </div>
        </div>
    )
}

export default QuizTHome
