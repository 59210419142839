import { trackingStates } from "../../../utils/Constants"
import { handleDNATestTrackerWidgetSubtitle } from "../../../utils/Functions"
import { TrackerWidgetDotType } from "../../../utils/enums/TrackerWidgetDotType"
import { TrackerWidgetTextType } from "../../../utils/enums/TrackerWidgetTextType"
import TrackingStatus from "../../../utils/enums/TrackingStatus"
import DNATestTrackerWidgetSingleElement from "../components/DNATestTrackerWidgetSingleElement"
import "./css/DNATestTrackerWidget.css"

interface Props {
    trackingStatus: TrackingStatus,
    trackingCode: string,
    onSubtitleClick: () => void,
}

const DNATestTrackerWidget: React.FC<Props> = ({
    trackingStatus,
    trackingCode,
    onSubtitleClick,
}) => {

    const handleProgressBar = () => {
        switch (trackingStatus) {
            case TrackingStatus.Purchased:
                return "width_7_percent"
            case TrackingStatus.Delivered:
                return "width_13_percent"
            case TrackingStatus.TestDone:
                return "width_29_percent"
            case TrackingStatus.SampleRegistered:
                return "width_45_percent"
            case TrackingStatus.PickupRequested:
                return "width_45_percent"
            case TrackingStatus.Departed:
                return "width_50_percent"
            case TrackingStatus.DeliveredToLaboratory:
                return "width_67_percent"
            case TrackingStatus.StartingToExtract:
                return "width_82_percent"
            case TrackingStatus.StartingAnalysis:
                return "width_95_percent"
            case TrackingStatus.AnalysisDone:
                return "width_100_percent"
        }
    }

    return (
        <div className="dna-test-tracker-widget-container">
            <div className="dna-test-tracker-widget-internal-container">
                <div className="dna-test-tracker-widget-heading">
                    Il tuo campione
                </div>
                <div className="dna-test-tracker-widget-path-container">
                    <div className="dna-test-tracker-widget-path-internal-container">
                        <div className="dna-test-tracker-widget-path-elements-container">
                            <DNATestTrackerWidgetSingleElement
                                dotType={trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.Purchased) ? TrackerWidgetDotType.Filled : TrackerWidgetDotType.Empty}
                                textType={TrackerWidgetTextType.Black}
                                text="Acquisto"
                            />
                            <DNATestTrackerWidgetSingleElement
                                dotType={trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.TestDone) ? TrackerWidgetDotType.Filled : (trackingStatus === TrackingStatus.Delivered ? TrackerWidgetDotType.Border : TrackerWidgetDotType.Empty)}
                                textType={trackingStatus === TrackingStatus.Delivered ? TrackerWidgetTextType.Colored : TrackerWidgetTextType.Black}
                                text="Effettua il test" />
                            <DNATestTrackerWidgetSingleElement
                                dotType={trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.SampleRegistered) ? TrackerWidgetDotType.Filled : (trackingStatus === TrackingStatus.TestDone ? TrackerWidgetDotType.Border : TrackerWidgetDotType.Empty)}
                                textType={trackingStatus === TrackingStatus.TestDone ? TrackerWidgetTextType.Colored : TrackerWidgetTextType.Black}
                                text="Registra campione"
                            />
                            <DNATestTrackerWidgetSingleElement
                                dotType={trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.PickupRequested) ? TrackerWidgetDotType.Filled : (trackingStatus === TrackingStatus.SampleRegistered ? TrackerWidgetDotType.Border : TrackerWidgetDotType.Empty)}
                                textType={(trackingStatus === TrackingStatus.SampleRegistered || trackingStatus === TrackingStatus.PickupRequested) ? TrackerWidgetTextType.Colored : TrackerWidgetTextType.Black}
                                text="Richiedi il ritiro"
                            />
                            <DNATestTrackerWidgetSingleElement
                                dotType={trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.DeliveredToLaboratory) ? TrackerWidgetDotType.Filled : TrackerWidgetDotType.Empty}
                                textType={trackingStatus === TrackingStatus.DeliveredToLaboratory ? TrackerWidgetTextType.Colored : TrackerWidgetTextType.Black}
                                text="In laboratorio"
                            />
                            <DNATestTrackerWidgetSingleElement
                                dotType={trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.StartingToExtract) ? TrackerWidgetDotType.Filled : TrackerWidgetDotType.Empty}
                                textType={trackingStatus === TrackingStatus.StartingToExtract ? TrackerWidgetTextType.Colored : TrackerWidgetTextType.Black}
                                text="Inizio estrazione"
                            />
                            <DNATestTrackerWidgetSingleElement
                                dotType={trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.StartingAnalysis) ? TrackerWidgetDotType.Filled : TrackerWidgetDotType.Empty}
                                textType={trackingStatus === TrackingStatus.StartingAnalysis ? TrackerWidgetTextType.Colored : TrackerWidgetTextType.Black}
                                text="Analisi"
                            />
                            <DNATestTrackerWidgetSingleElement
                                dotType={trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.AnalysisDone) ? TrackerWidgetDotType.Filled : TrackerWidgetDotType.Empty}
                                textType={trackingStatus === TrackingStatus.AnalysisDone ? TrackerWidgetTextType.Colored : TrackerWidgetTextType.Black}
                                text="Risultato"
                            />
                        </div>
                        <div className="dna-test-tracker-widget-progression-bar-empty">
                            <div className={`dna-test-tracker-widget-progression-bar-filled ${handleProgressBar()}`} />
                        </div>
                    </div>
                </div>
                <div className="dna-test-tracker-widget-title-two">
                    {handleDNATestTrackerWidgetSubtitle(trackingStatus, trackingCode, onSubtitleClick)}
                </div>
            </div>
        </div>
    )
}

export default DNATestTrackerWidget
