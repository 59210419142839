import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseButton from "../../../../components/CloseButton";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, requestOptionsGET } from "../../../../utils/Constants";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { PlatformMenuItem } from "../../../../utils/enums/PlatformMenuItem";
import TrackingStatus from "../../../../utils/enums/TrackingStatus";
import MenuPopup from "../../components/MenuPopup";
import "./css/ExamsAndTestsCollectingPacking.css";

const ExamsAndTestsCollectingPacking = () => {

    const navigate = useNavigate();

    const { auth } = useAuth()
    const idToken = auth.idToken!
    const userId = auth.userId

    const { userData } = useUserData()
    const username = userData.name!
    const familyName = userData.familyName!
    const email = userData.email!

    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME)
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM)
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN)
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS)
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setSwabCode(responseJson.swabcode)
        } else if (responseUserinfo.status === 401) {
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
    }, [])

    // *******************************************************************************************************************

    // The swab code associated with the user
    const [swabCode, setSwabCode] = useState<string | undefined>(undefined)

    const onBackButtonClick = () => {
        if (swabCode !== undefined) {
            navigate("/platform/exams-and-tests/collecting/tutorial")
        } else {
            navigate("/platform/exams-and-tests/collecting/register-swab-code")
        }
    }

    const onGoToNextStepButtonClick = () => {
        navigate("/platform/exams-and-tests/collecting/request-pickup")
    }

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu &&
                    <MenuPopup
                        currentPage={PlatformMenuItem.ExamsAndTests}
                        onEscapeClick={onMenuIconClick}
                    />
                }
                <div className={`platform-exams-and-tests-collecting-packing-full-container ${(focusOnMenu) ? "visible_30_percent inactive" : ""}`}>
                    <div className="exams-and-tests-packing-title-button-row">
                        <div className="exams-and-tests-packing-heading">
                            Impacchetta
                        </div>
                        <CloseButton
                            noFlexEnd={true}
                            onClick={() => navigate("/platform/exams-and-tests/collecting", { state: { sampleState: TrackingStatus.SampleRegistered } })}
                        />
                    </div>
                    <div className="vertical-scroll-group-90 width_100_percent">
                        <div className="platform-final-element-container mobile-scroll-100">
                            <div className={`exams-and-tests-packing-element-container`}>
                                <div className="">
                                    Ora non ti resta che impacchettare il tuo test.
                                </div>
                                <div className="height_35" />
                                <div className="">
                                    Compila con data e orario di prelievo i due sticker codice campione.
                                </div>
                                <div className="height_35" />
                                <div className="">
                                    Incolla uno dei due sticker codice campione sulla tua Member's Card (questa card non deve essere spedita assieme al campione, ma rimarrà a te, come controprova del tuo codice campione).
                                </div>
                                <div className="height_35" />
                                <div className="">
                                    Procedi incollando il secondo sticker codice campione sul lato della provetta.<br></br>
                                    Inserisci la provetta nella busta rinforzata / busta Biohazard e chiudila.<br></br>
                                    Inserisci la busta rinforzata / busta Biohazard a sua volta nella busta del corriere e chiudi.
                                </div>
                            </div>
                            <div className="exams-and-tests-packing-buttons-container">
                                <button
                                    className={`exams-and-tests-packing-back-button`}
                                    onClick={onBackButtonClick}
                                    type="button"
                                >
                                    <img src="/images/back-arrow.svg" />
                                    <div className="no_decoration_text">
                                        Indietro
                                    </div>
                                </button>
                                <button
                                    className={`exams-and-tests-packing-confirm-button primary_action_color_background w700 s14 lh17`}
                                    onClick={onGoToNextStepButtonClick}
                                >
                                    Fatto! Avanti
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default ExamsAndTestsCollectingPacking
