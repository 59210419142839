import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { StripeEmbeddedCheckoutOptions, loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { BASE_URL, STRIPE_KEY, requestOptionsGET } from "../../../utils/Constants";
import { useAuth } from "../../../utils/context/AuthContext";
import "./css/CheckoutForm.css";

const stripePromise = loadStripe(STRIPE_KEY);

interface Props {
    mode: string,
    bundleType: string,
}

const CheckoutForm: React.FC<Props> = ({
    mode,
    bundleType,
}) => {

    const { auth } = useAuth();
    const userId = auth.userId
    const idToken = auth.idToken!

    const navigate = useNavigate()

    useEffect(() => {
        fetchClientSecret()
    }, [])

    const [isComplete, setIsComplete] = useState(false)

    async function callGetOrdersByUserIdApi() {
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, requestOptionsGET(idToken))
        if (!response.ok) {
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
            navigate("/survey-a-home")
        }
    }

    async function fetchClientSecret() {
        const response = await fetch(`${BASE_URL}/api/payment/create-checkout-session`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken
            },
            body: JSON.stringify(
                {
                    mode: mode,
                    user_id: userId,
                    environemnt: "local",
                    bundle_type: bundleType,
                }
            )
        })
        const data = await response.json()
        return data.client_secret
    }

    const handleIsComplete = () => {
        setIsComplete(true)
        // callAPIAssignMedicalUserToPatient()
        setTimeout(() => {
            callGetOrdersByUserIdApi()
        }, 2000)
    }
    const options: StripeEmbeddedCheckoutOptions = { fetchClientSecret, onComplete: handleIsComplete };

    const [isLoading, setIsLoading] = useState(false)

    // ********************************************************************************

    return (
        isComplete ?
            <div className="App justify_content_center relative">
                <div className="checkout-form-success-container">
                    <div className="checkout-form-success-heading">
                        Ordine effettuato correttamente
                    </div>
                    <div className="checkout-form-success-copy">
                        Attendi un momento
                    </div>
                    <div className="height_35" />
                    <LoadingSpinner />
                </div>
            </div>
            :
            <div
                className=""
                id="checkout"
            >
                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={options}
                >
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            </div>
    )
}

export default CheckoutForm
