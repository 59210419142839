import { Mood } from "../../../utils/enums/Mood"
import "./css/MoodWidget.css"

const ChoiceCard = (image: string, text: string, isChecked: boolean, onClick: () => void, choice: Mood) => {
    return (
        <div
            className={`${!isChecked ? "your-feelings-widget-choice-card-container" : "your-feelings-widget-choice-card-checked-container"} ${choice === "" ? "pointer" : ""}`}
            onClick={onClick}
        >
            <div className="margin_left_20">
                <img src={image} />
            </div>
            <div className="your-feelings-widget-choice-card-copy">{text}</div>
        </div>
    )
}

interface Props {
    yourFeelingsChoice: Mood
    onClick: (choice: Mood) => void,
}

const MoodWidget: React.FC<Props> = ({
    yourFeelingsChoice,
    onClick,
}) => {
    return (
        <div className="your-feelings-widget-container">
            <div className="your-feelings-widget-internal-container">
                <div className="your-feelings-widget-heading">
                    Come ti senti oggi?
                </div>
                <div className="your-feelings-widget-choice-row">
                    {ChoiceCard("/images/your-feelings-positive-vote.svg", "Mi sento bene!", yourFeelingsChoice === Mood.Good, () => onClick(Mood.Good), yourFeelingsChoice)}
                    {ChoiceCard("/images/your-feelings-negative-vote.svg", "Mmm, così così...", yourFeelingsChoice === Mood.Bad, () => onClick(Mood.Bad), yourFeelingsChoice)}
                </div>
            </div>
        </div>
    )
}

export default MoodWidget
