import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../../components/buttons/WhiteButton"
import TextInputElement from "../../../../components/input/TextInputElement"
import { EMAIL_REGEX } from "../../../../utils/Constants"
import "./css/ForgotPasswordBox.css"

interface Props {
    onSubmit: (inputData: { [key: string]: string }) => void;
    emailErrorText: string,
}

const ForgotPasswordBox: React.FC<Props> = ({
    onSubmit,
    emailErrorText,
}) => {
    const navigate = useNavigate()

    const [errorText, setErrorText] = useState(emailErrorText)

    const [inputs, setInputs] = useState<{ [key: string]: string }>({
        email: '',
    });

    const isEmailAcceptable =  EMAIL_REGEX.test(inputs.email)
    const isConfirmButtonEnabled = isEmailAcceptable

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        // Ensure the value is in lowercase for the email field
        const formattedValue = name === "email" ? value.toLowerCase() : value;

        setErrorText("")

        setInputs((prev) => ({
            ...prev,
            [name]: formattedValue,
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        onSubmit(inputs);
    }

    useEffect(() => {
        setErrorText(emailErrorText)
    }, [emailErrorText])

    // ********************************************************************************
    
    return (
        <div className="forgot-password-box-full-container">
            <div className="forgot-password-box-heading">
                Password dimenticata
            </div>
            <div className="forgot-password-box-copy">
                Inserisci l'e-mail del tuo account e ti invieremo un codice nella casella e-mail per reimpostare la password
            </div>
            <form
                className="forgot-password-box-form"
                onSubmit={handleSubmit}
            >
                <TextInputElement
                    name="email"
                    label="Indirizzo e-mail"
                    placeholder="mail@sconosciuta.com"
                    errorText={errorText}
                    inputValue={inputs.email}
                    onChange={handleInputChange}
                />
                <div className="forgot-password-box-confirm-button">
                    <PrimaryButton
                        text="Invia e-mail"
                        pointer={isConfirmButtonEnabled}
                        disabled={!isConfirmButtonEnabled}
                    />
                </div>
            </form>
            <div className="forgot-password-box-forgot-email-button">
                <WhiteButton
                    text="Hai dimenticato l'indirizzo e-mail?"
                    onClick={() => navigate("/forgot-email")}
                    pointer={true}
                />
            </div>
        </div>
    )
}

export default ForgotPasswordBox
