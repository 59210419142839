import { handleDate, handleSurveyContents, handleSurveyName } from "../../../../../utils/Functions";
import { QuizType } from "../../../../../utils/enums/QuizType";
import "./css/MyAnswersSurveyCard.css";

interface Props {
    onCardClick: (type: QuizType) => void,
    surveyType: QuizType,
    createdAt: string,
}

const MyAnswersSurveyCard: React.FC<Props> = ({
    onCardClick,
    surveyType,
    createdAt,
}) => {
    return (
        <div
            className={`my-answers-survey-card-container`}
            onClick={() => onCardClick(surveyType)}
        >
            <div className="my-answers-survey-card-internal-container">
                <div className="my-answers-survey-card-upper-element-container">
                    <div className="my-answers-survey-card-survey-name">
                        {handleSurveyName(surveyType)}
                    </div>
                    <div className="my-answers-survey-card-survey-date">
                        {handleDate(createdAt)}
                    </div>
                </div>
                <div className="my-answers-survey-card-bottom-element-container">
                    <div className="my-answers-survey-card-contents-heading">
                        Cosa contiene:
                    </div>
                    <div className="my-answers-survey-card-contents-copy">
                        {handleSurveyContents(surveyType)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyAnswersSurveyCard
